import { useNest } from './useNest'
const errMsg = 'Server is unavailable.'

export const useLog = () => {
  const { backend } = useNest()

  const createLog = (payload = {}) =>
    new Promise((resolve, reject) => {
      backend()
        .post('/log', payload)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    createLog,
  }
}
