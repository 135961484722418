// https://ant.design/theme-editor#Color

const customTheme = {
  token: {
    colorPrimary: '#0047ff',
    colorPrimaryHover: '#003cd9',
    colorLink: '#000000',
    colorBorder: '#b0b0b0',
    colorIcon: '#505050',
    colorTextPlaceholder: '#505050',
    colorError: '#d92550'
  },
}

export { customTheme }
