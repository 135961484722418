import { configureStore } from '@reduxjs/toolkit'
import ThemeOptions from 'features/ThemeOptions'
import { uiSlice } from 'features/uiSlice'
import { authSlice } from 'features/auth/authSlice'
import { pinSlice } from 'features/auth/pinSlice'
import { augmontSlice } from 'features/augmont/augmontSlice'
import { accountSlice } from 'features/accountSlice'
import { servicesSlice } from 'features/servicesSlice'
import { goalsSlice } from 'features/giftCardSlice'
import { fixerraSlice } from 'features/fixerra/fixerraSlice'
// import { tapInvestSlice } from 'features/tapInvestSlice'

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [pinSlice.name]: pinSlice.reducer,
    [augmontSlice.name]: augmontSlice.reducer,
    [servicesSlice.name]: servicesSlice.reducer,
    [goalsSlice.name]: goalsSlice.reducer,
    [fixerraSlice.name]: fixerraSlice.reducer,
    // [tapInvestSlice.name]: tapInvestSlice.reducer,
    // settings,
    [uiSlice.name]: uiSlice.reducer,
    themeOptions: ThemeOptions,
  },
  // middleware: applyMiddleware(...middleware)
})

export default store
const dispatch = store.dispatch

export { dispatch, store }
