import { useEffect, useState } from 'react'
import { FaRegArrowAltCircleLeft } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const SelectedGiftCard = ({
  service,
  opcode,
}) => {
  const history = useHistory()
  const [giftCard, setGiftCard] = useState({
    logo: '',
    bbps: false,
  })
  const giftCards = useSelector((state) => state.services.giftCards)

  useEffect(() => {
    let founded = false
    if (giftCards) {
      founded = giftCards.find((op) => op.ProductNo === opcode)
    }
    if (founded) {
      setGiftCard(founded)
    } else {
      history.push(`/service/${service}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftCards, opcode, service])

  return (
    <div className="d-flex align-items-center justify-content-between pr-3">
      <div className="d-flex align-items-center p-2 my-2">
        {giftCard?.ProductImage ? (
          <img
            src={giftCard?.ProductImage}
            alt=""
            style={{
              width: '64px',
              height: '64px',
              borderRadius: 50,
              backgroundColor: '#eee',
            }}
          />
        ) : (
          <div
            style={{
              minWidth: '64px',
              minHeight: '64px',
              borderRadius: 50,
              // backgroundColor: operator?.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2em',
              fontWeight: '500',
            }}
          >
            {giftCard?.name?.charAt(0)}
          </div>
        )}
        <div>
          <div className="ml-3">{giftCard?.ProductName}</div>
          {/* <div className="ml-3 font-weight-bold">{operator.ProductName}</div> */}
        </div>
      </div>
      <FaRegArrowAltCircleLeft
        title="Back to giftCards"
        size="24"
        className="text-secondary cursor-pointer"
        onClick={() => history.goBack()}
      />
    </div>
  )
}

export default SelectedGiftCard
