import usePageTitle from 'hooks/usePageTitle'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
// import LoadingGif from 'assets/components/react-widgets/img/loading.gif'
import CustomTable from 'Components/Table/CustomTable'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { FaArrowLeft } from 'react-icons/fa'
import dayjs from 'dayjs'
import { useAugmont } from 'backend/useAugmont'
import { useSelector } from 'react-redux'
import { selectAugmont } from 'features/augmont/augmontSlice'
// import { Button } from "reactstrap"

const FDDetails = () => {
  usePageTitle('Invested Gold-FD Details')
  const history = useHistory()
  const {
    getFdDetailsWithTransactions,
    // getFdDetailsAugmont
  } = useAugmont()
  const { augmontFDs } = useSelector(selectAugmont)

  const { transactionId } = useParams()
  const [data, setData] = useState([])
  const [fdDetails, setFdDetails] = useState(
    augmontFDs?.items.find((fd) => fd?.transactionId === transactionId)
  )

  useEffect(() => {
    getFdDetailsWithTransactions(transactionId).then((response) => {
      setData(response)
    })
    setFdDetails(
      augmontFDs?.items.find((fd) => fd?.transactionId === transactionId)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [augmontFDs, transactionId])

  if (!transactionId) return null

  // if (loading) {
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{ minHeight: '100vh', width: '100%' }}
  //     >
  //       <img src={LoadingGif} alt="Loading..." />
  //     </div>
  //   )
  // }

  // const fdDetails = location?.state

  if (!fdDetails) return null

  const tableCols = [
    {
      key: 'index',
      title: '#',
      className: 'text-start pl-4',
      render: (data, i) => <>{i}</>,
    },
    {
      key: 'date',
      title: 'DATE',
      className: 'text-center',
      render: (data) => <>{data?.date}</>,
    },
    {
      key: 'details',
      title: 'DETAILS',
      className: 'text-center',
      render: (data) => <>{data?.details}</>,
    },
    {
      key: 'nature',
      title: 'NATURE',
      className: 'text-center',
      render: (data) => (
        <span style={{ textTransform: 'capitalize' }}>{data?.nature}</span>
      ),
    },
    {
      key: 'quantity',
      title: 'QUANTITY',
      render: (data) => (
        <span
          className={`${
            data?.qty < 0
              ? 'bg-soft-danger text-danger'
              : 'bg-soft-success text-success'
          } badge`}
        >
          {data.qty} <small>GMs</small>
        </span>
      ),
    },
  ]
  return (
    <>
      {/* <Button
        outline
        className="custom-table-action-btn_danger text-danger"
        color="danger"
        onClick={() => getFdDetailsAugmont(fdDetails.transactionId)}
      >
        {fdDetails.transactionId}
      </Button>{' '} */}
      {/* ({fdDetails?._count?.goldFDTransactions}){' '} */}
      <Card className="my-4 mx-auto" style={{ maxWidth: '100%' }}>
        <CardHeader className="custom-card-header height-auto pt-4 pb-3">
          <div className="custom-table-header">
            <div className="table-title goal-investment-header">
              <div>
                <div className="d-flex align-items-center">
                  {fdDetails.schemaName}
                  <span
                    className={`badge ml-3 ${
                      fdDetails.status === 'active'
                        ? 'bg-soft-success text-success border-success'
                        : fdDetails.status === 'mature'
                        ? 'bg-soft-warning text-warning'
                        : ''
                    }`}
                  >
                    {fdDetails.status}
                  </span>
                </div>
              </div>
              <button
                onClick={() => history.goBack()}
                className="btn btn-secondary back-button"
              >
                <FaArrowLeft className="mr-2" />
                <span>Back</span>
              </button>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Gold Lease:</strong> {fdDetails.goldLease}
            </Col>
            <Col md={4} xs={12}>
              <strong>End of Lease:</strong> {fdDetails.endOfLease}
            </Col>
            <Col md={4} xs={12}>
              <strong>Interest Rate:</strong> {fdDetails.interestRate} %
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Interest Paid:</strong> {fdDetails.interestPaid}
            </Col>
            <Col md={4} xs={12}>
              <strong>Merchant Name:</strong> {fdDetails.merchantName}
            </Col>
            <Col md={4} xs={12}>
              <strong>Jeweller:</strong> {fdDetails.jewellerUserAccountName}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Start Date:</strong>{' '}
              {dayjs(fdDetails.startDate).format('DD-MM-YYYY')}
            </Col>
            <Col md={4} xs={12}>
              <strong>End Date:</strong>{' '}
              {dayjs(fdDetails.endDate).format('DD-MM-YYYY')}
            </Col>
            <Col md={4} xs={12}>
              <strong>Days:</strong> {fdDetails.noOfDays}
            </Col>
          </Row>
          <Row className="mb-3">
            {fdDetails.closeDate ? (
              <Col md={4} xs={12}>
                <strong>Closed Date:</strong>{' '}
                {dayjs(fdDetails.closeDate).format('DD-MM-YYYY')}
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
      <CustomTable
        data={data?.goldFDTransactions ?? []}
        cols={tableCols}
        tableName="transactions"
        altText="No transaction found"
      />
    </>
  )
}

export default FDDetails
