import { useDispatch } from 'react-redux'
// import { goalsActions } from 'features/giftCardSlice'
import { accountActions } from 'features/accountSlice'
import { errMsg, useNest } from './useNest'

export function useInvestment() {
  const { getItem, backend } = useNest()
  const dispatch = useDispatch()

  const getInvestment = (sku) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: false,
      }
      getItem('lendbox/portfolio', sku, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const doInvestment = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Requesting...',
          success: 'Investment Created',
        },
      }
      backend()
        .post('/investment/do', payload, config)
        .then(({ item }) => {
          dispatch(accountActions.refreshGoals())
          return resolve(item)
        })
    })

  const createGoal = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Requesting...',
          success: 'Goal Created',
        },
      }
      backend()
        .post('/investment/goal', payload, config)
        .then(({ item }) => {
          dispatch(accountActions.refreshGoals())
          return resolve(item)
        })
    })

  /**
   * Needed for landbox
   * @param {*} refId
   * @param {*} payload
   * @returns
   */
  const updateInvestmentStatus = (refId, payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: false,
        // msg: {
        //   loading: 'Requesting...',
        //   success: 'Investment Order Placed',
        // },
      }
      backend()
        .post(`/investment/active/${refId}`, payload, config)
        .then((response) => {
          // dispatch(
          //   accountActions.updateGoalById({
          //     id: refId,
          //     item: payload,
          //   })
          // )
          return resolve(response)
        })
    })

  const cancelInvestment = (id, payload = {}) =>
    new Promise((resolve) => {
      console.log('payload', payload)
      let config = {
        loader: true,
        msg: {
          loading: 'Requesting...',
          success: 'Investment Canceled',
        },
      }
      backend()
        .post(`/investment/cancel/${id}`, payload, config)
        .then((response) => {
          dispatch(
            accountActions.updateGoalById({
              id: id,
              item: {
                status: 'Canceled',
              },
            })
          )
          return resolve(response)
        })
    })

  const myInvestment = () =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: false,
      }
      backend()
        .get('/investment/my', config)
        .then((response) => {
          dispatch(accountActions.loadGoals(response.items))
          return resolve(response)
        })
    })

  return {
    getInvestment,
    createGoal,
    doInvestment,
    updateInvestmentStatus,
    cancelInvestment,
    myInvestment,
  }
}
