import { useDispatch } from 'react-redux'
import { accountActions } from 'features/accountSlice'
import { errMsg, useNest } from './useNest'

export function useLendBox() {
  const { getItem, addItem } = useNest()
  const dispatch = useDispatch()

  const getPortfolio = () =>
    new Promise((resolve, reject) => {
      let config = {
        loader: false,
      }
      getItem('lendbox', 'portfolio', config)
        .then(({ data }) => {
          dispatch(accountActions.loadLendBoxPortfolio(data.dashboard))
          return resolve(data)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const addFund = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating Payment Intent...' },
      }
      addItem('lendbox-razorpay/add-fund', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const mandateRegister = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating mandate...' },
      }
      addItem('lendbox-razorpay/mandate/register', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const confirmMandate = (id, payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Reusing existing mandate...' },
      }
      addItem(`lendbox-razorpay/mandate/confirm/${id}`, payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    getPortfolio,
    addFund,
    mandateRegister,
    confirmMandate,
  }
}
