const env = process.env.REACT_APP_ENV

const prodEnv = {
  websiteName: 'Kashewnut',
  description: 'kashewnut.com',
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.kashewnut.com', // 'https://dev.kashewnut.com'
  cdnUrl: 'https://cdn.kashewnut.com', // without slash
  digioSDKSrc: 'https://app.digio.in/sdk/v8/digio.js',
  tokenName: 'kash-prod',
  otpLength: 6,
  pinLength: 4,
  env: 'prod',
}

const uatEnv = {
  websiteName: 'UAT Kashewnut',
  description: 'kashewnut.com',
  // apiUrl: 'http://[::1]:2000',
  // apiUrl: 'https://dev.kashewnut.com',
  apiUrl: process.env.REACT_APP_API_URL || 'https://uat.kashewnut.com',
  cdnUrl: 'https://cdn.kashewnut.com', // without slash
  digioSDKSrc: 'https://app.digio.in/sdk/v8/digio.js',
  tokenName: 'kash-uat',
  otpLength: 6,
  pinLength: 4,
  env: 'uat',
}

const config = env === 'prod' ? prodEnv : uatEnv

export const cdnUrl = config.cdnUrl

export const s3GetUrl = (key) => {
  return `${config.cdnUrl}/${key}`
}
export const mandateAmount = 5000

export { config }
