import React from 'react'

import PrivateRoutes from '../../routes/PrivateRoutes'

// Investment
// import Gold from './Gold'
// import Silver from './Silver'
// import P2P from './P2P';
// import P2PTransactions from './P2P/P2PTransactions';
import Goals from './Goals'
import ShortTerm from './Goals/ShortTerm'
import LongTerm from './Goals/LongTerm'
import SortTermPlanner from './Goals/ShortTerm/Planner'
import LongTermPlanner from './Goals/LongTerm/Planner'
// import ShowVoucherTransactions from './Voucher'
import WithDrawalForm from './Goals/withdrawalForm'
import GoalFDPlannerForm from './Goals/LongTerm/Goal-FD/Planner'
import MyGoalDetails from './Goals/Goal-details/MyGoalDetails'
import CheckoutAugmont from './Checkout/CheckoutAugmont'
import CheckoutAugmontFd from './Checkout/CheckoutAugmontFd'
import CheckoutLendBox from './Checkout/CheckoutLendBox'
import FDDetails from './Goals/LongTerm/Goal-FD/FDDetails'
import FixerraFD from './Goals/FD'
import FixerraPlannerForm from './Goals/FD/plannerForm'
import FixerraSchemeList from './Goals/FD/schemeList'
import FixerraCheckout from './Goals/FD/CheckoutFixerra'
// import TapInvest from './TapInvest'
// import DealDetails from './TapInvest/DealDetails'

const InvestmentNav = ({ match }) => {
  return (
    <>
      {/* <PrivateRoutes path={`${match.url}/gold`} component={Gold} /> */}
      {/* <PrivateRoutes path={`${match.url}/silver`} component={Silver} /> */}
      <PrivateRoutes path={`${match.url}/goals`} component={Goals} />
      <PrivateRoutes
        path={`${match.url}/goal/short`}
        component={ShortTerm}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/fd`}
        component={FixerraFD}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/fd/schemes/:f_code`}
        component={FixerraSchemeList}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/fd/scheme/checkout`}
        component={FixerraCheckout}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/fd/:f_issuer_id`}
        component={FixerraPlannerForm}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/short/:goalId/:sku`}
        component={SortTermPlanner}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/long`}
        component={LongTerm}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/long/:type`}
        component={LongTermPlanner}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/long/fd/:goalFDId`}
        component={GoalFDPlannerForm}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal/long/fd/:goalFDId/:quantity`}
        component={CheckoutAugmontFd}
        exact
      />
      <PrivateRoutes
        // path={`${match.url}/goal/:type/:id/withdraw`}
        path={`${match.url}/goal/withdraw`}
        component={WithDrawalForm}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/goal-details/:goalId`}
        component={MyGoalDetails}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/fd-details/:transactionId`}
        component={FDDetails}
        exact
      />
      {/* <PrivateRoutes
        path={`${match.url}/voucher/:sku/:orderId`}
        component={ShowVoucherTransactions}
        exact
      /> */}
      <PrivateRoutes
        path={`${match.url}/short/checkout`}
        component={CheckoutLendBox}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/long/checkout`}
        component={CheckoutAugmont}
        exact
      />

      {/* tapinvest schemes */}
      {/* <PrivateRoutes
        exact
        path={`${match.url}/invoicediscounting`}
        component={TapInvest}
      /> */}
      {/* tapinvest scheme details */}
      {/* <PrivateRoutes
        path={`${match.url}/invoicediscounting/:dealId`}
        component={DealDetails}
        exact
      /> */}
      {/* tapinvest schemes */}
      {/* <PrivateRoutes
        exact
        path={`${match.url}/assetleasing`}
        component={TapInvest}
        asset={true}
      /> */}
      {/* tapinvest scheme details */}
      {/* <PrivateRoutes
        path={`${match.url}/assetleasing/:dealId`}
        component={DealDetails}
        exact
      /> */}
    </>
  )
}

export default InvestmentNav
