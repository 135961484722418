import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import { NavLink } from 'react-router-dom'
// import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  DropdownToggle,
  DropdownMenu,
  // Nav,
  // Row,
  // Col,
  // Button,
  // NavItem,
  // NavLink,
  // UncontrolledTooltip,
  UncontrolledButtonDropdown,
  DropdownItem,
} from 'reactstrap'

// import { toast } from 'react-toastify'
import { FaAngleDown } from 'react-icons/fa'

// import { checkStatus } from 'redux/actions/services'
import { getProfilePicture } from 'utils/helper'

// import city3 from 'assets/utils/images/dropdown-header/city3.jpg'
// import showLoader from 'Components/Loader'
// import { useMandate } from 'backend/useMandate'
// import { useServices } from "nest/useServices"
import { useAuth } from 'backend/useAuth'
import { selectAugmont } from 'features/augmont/augmontSlice'
import { selectAuth } from 'features/auth/authSlice'
// import { selectBank } from 'features/accountSlice'

// import { QUEUE_ADD, QUEUE_REMOVE } from 'redux/actionTypes'
// import avatar1 from "assets/utils/images/avatars/1.jpg";

// let countDown
// let countdownInterval
// let timerId

// const clearSessionInterval = () => {
//   clearInterval(countdownInterval)
// }

// const updateCountdown = (value) => {
//   countDown = value
// }

const UserBox = () => {
  // const dispatch = useDispatch()
  // const history = useHistory()
  // const { emandateRevoke } = useMandate()
  // const { checkStatus } = useServices()
  const { logout } = useAuth()
  const { user } = useSelector(selectAuth)
  const { augmontUser } = useSelector(selectAugmont)
  // const { bankAccount } = useSelector(selectBank)
  const history = useHistory()
  // const [active, setActive] = useState(false)

  // const {
  //   interval,
  //   queue
  // } = useSelector(state => state.queue)

  // useEffect(() => {
  //   // console.log('queue changed', queue)
  //   clearSessionInterval()
  //   if (queue.length < 1) {
  //     console.log('queue is empty')
  //     return
  //   }
  //   const delay = 1000 * 1
  //   if (queue.length > 0) {
  //     updateCountdown(interval)
  //     countdownInterval = setInterval(() => {
  //       // console.log('countDown', countDown)
  //       if (countDown > 0) {
  //         // eslint-disable-next-line
  //         updateCountdown(--countDown)
  //       } else {
  //         // dispatch(queueRemove({id: queue[0].id}))
  //         checkStatus({
  //           id: queue[0].id
  //         })
  //           .then((res) => {
  //             // console.log('res', res)
  //             if (res.rstatus) {
  //               toast.success(res.message)
  //             } else {
  //               toast.error(res.message)
  //             }
  //             // dispatch(queueRemove({id: queue[0].id}))
  //           })
  //           .catch(error => {
  //             console.log(error)
  //             // dispatch(queueUpdateInterval())
  //           })
  //       }
  //     }, delay)
  //   }

  //   // eslint-disable-next-line
  // }, [queue])

  const handleLogout = (e) => {
    e.preventDefault()
    logout().then(() => {
      window.location.href = '//kashewnut.com'
    })
  }

  // const revokeMandate = () => {
  //   // const payload = { ...mandate, signed: false }
  //   emandateRevoke(mandate).then((res) => {
  //     toast.success(res.message)
  //     history.push('/')
  //   })
  // }

  // const handleAddQueue = (e) => {
  //   dispatch({
  //     type: QUEUE_ADD,
  //     payload: {
  //       id: Math.round(Math.random() * 1000),
  //       type: 'RECHARGE'
  //     }
  //   }) // it is used to check status of transaction
  // }

  // const handleRemoveQueue = (e) => {
  //   dispatch({
  //     type: QUEUE_REMOVE,
  //     payload: {
  //       id: 1
  //     }
  //   }) // it is used to check status of transaction
  // }

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={42}
                    className="rounded-circle"
                    src={getProfilePicture(user)}
                    alt=""
                  />
                  <FaAngleDown className="ml-2 opacity-8" />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img
                                width={42}
                                className="rounded-circle"
                                src={getProfilePicture(user)}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {`${user.firstName} ${user.lastName}`}
                              </div>
                              <div className="widget-subheading opacity-9">
                                {user.email}
                              </div>
                              {/* <div className="widget-subheading opacity-8">
                                {user.mobile}
                              </div> */}
                            </div>
                            {/* <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={handleLogout}
                              >
                                Logout
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {window.location.href.search('auth/') === -1 &&
                  user.panVerified ? (
                    <>
                      <DropdownItem header>My Account</DropdownItem>
                      <DropdownItem
                        onClick={() => history.push('/account/profile/edit')}
                      >
                        Profile
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => history.push('/account/info')}
                      >
                        Virtual Account
                      </DropdownItem>
                      {augmontUser?.id ? (
                        <DropdownItem
                          onClick={() => history.push('/account-setup/account')}
                        >
                          Account Setup
                        </DropdownItem>
                      ) : null}
                      {/* <DropdownItem
                        onClick={() => history.push('/account/bank')}
                      >
                        Update Bank Account
                      </DropdownItem> */}
                    </>
                  ) : null}
                  <DropdownItem
                    onClick={() => history.push('/account/change-pass')}
                  >
                    Change Password
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout} className="text-danger">
                    Logout
                  </DropdownItem>
                  {/* <Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav> */}
                  {/* <div className="grid-menu grid-menu-2col">
                    <Row className="no-gutters">
                      <Col sm="6">
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="warning">
                          <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> {" "} </i>
                            {virtualAccount && virtualAccount.presentBalance}
                          </Button>
                      </Col>
                      <Col sm="6">
                        <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="danger">
                          <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> {" "} </i>
                          <b>Support Tickets</b>
                        </Button>
                      </Col>
                    </Row>
                  </div> */}
                  {/* <Nav vertical>
                    <NavItem className="nav-item-divider" />
                    <NavItem className="nav-item-btn text-center">
                      <Button size="sm" className="btn-wide" color="primary">
                        Open Messages
                        </Button>
                    </NavItem>
                  </Nav> */}
                  {/* <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={handleAddQueue}>
                    add to queue
                  </Button>
                  <Button className="btn-pill btn-shadow btn-shine" color="focus" onClick={handleRemoveQueue}>
                    remove from queue
                  </Button> */}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left ml-3 header-user-info">
              {/* <div className="widget-heading">
                {`${user.firstName} ${user.lastName}`}
              </div> */}
              {/* <div className="widget-subheading">New Member</div> */}
            </div>
            {/* <div className="widget-content-right header-user-info ml-3">
                <Button className="btn-shadow p-1" size="sm" onClick={notify2} color="info" id="Tooltip-1">
                  <IoIosCalendar color="#ffffff" fontSize="20px" />
                </Button>
                <UncontrolledTooltip placement="bottom" target={"Tooltip-1"}>
                  Click for Toastify Notifications!
                </UncontrolledTooltip>
              </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UserBox
