import React, { Suspense, Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import ScaleLoader from 'react-spinners/ScaleLoader'

// const Auth = lazy(() => import("../../Pages/Auth"))
// const Dashboard = lazy(() => import("../../Pages/Dashboard"))
// const Account = lazy(() => import("../../Pages/Account"))
// const Services = lazy(() => import("../../Pages/Recharge"))

import Dashboard from '../../Pages/Dashboard'
import Account from '../../Pages/Account'
import AccountSetup from '../../Pages/AccountSetup'
// import Verification from '../../Pages/Verification'
import Services from '../../Pages/Services'
import Investment from '../../Pages/Investment'
import QwikCilver from '../../Pages/QwikCilver'
// import Profile from "../../Pages/Profile"

// import { UpdatePinModal } from 'Components/PinModals'
import AppHeader from 'Layout/AppHeader'
import AppFooter from 'Layout/AppFooter'
import AppSidebar from 'Layout/AppSidebar'
// import ThemeOptions from "Layout/ThemeOptions"

const AppMain = () => {
  return (
    <Fragment>
      {/* <ThemeOptions /> */}
      <AppSidebar />
      <div className="app-main">
        <AppHeader />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <ScaleLoader
                        color={'#545cd8'}
                        loading={true}
                        height={40}
                        width={4}
                      />
                    </div>
                  </div>
                </div>
              }
            >
              {/* <Route path="/verification" component={Verification} /> */}
              <Route path="/account" component={Account} />
              <Route path="/account-setup" component={AccountSetup} />
              <Route path="/service" component={Services} />
              <Route path="/dashboard" component={Dashboard} />

              <Route path="/investment" component={Investment} />
              <Route path="/voucher" component={QwikCilver} />
              {/* <Route exact path="/dashboard" component={Dashboards} /> */}
              {/* <Route path="/" exact name="Dashboard" component={props => <Dashboards {...props}/>} /> */}
            </Suspense>

            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}

export default AppMain
