// import './polyfills'
// import React, { useEffect, useRef } from "react"
import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as serviceWorker from './serviceWorker'

import Main from './Pages/Main'
// import Main from "./Layout/AppMain";
import { ControlledLoader } from './Components/Loader'
import { SocketProvider } from 'context/SocketContext'
import { ClientProvider } from 'context/ClientContext'
import { useAuth } from 'backend/useAuth'

const App = () => {
  const { tokenLogin } = useAuth()

  useEffect(() => {
    tokenLogin()
    const exist = document.getElementById('razorpay')
    if (!exist) {
      const script = document.createElement('script')
      script.id = 'razorpay'
      script.src = 'https://checkout.razorpay.com/v1/checkout.js'
      script.async = true
      document.body.appendChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SocketProvider>
      <ClientProvider>
        <Main />
        <ControlledLoader />
        <ToastContainer theme="colored" />
      </ClientProvider>
    </SocketProvider>
  )
}

export default App

// serviceWorker.register()
serviceWorker.unregister()
