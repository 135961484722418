import { selectFixerra } from 'features/fixerra/fixerraSlice'
import usePageTitle from 'hooks/usePageTitle'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import calculateAge from 'utils/calculateAge'
import LoadingGif from 'assets/components/react-widgets/img/loading.gif'
import FdScrollCard from 'assets/components/custom-card/FdScrollCard'
// import { getImagePath } from '.'
import { selectAuth } from 'features/auth/authSlice'

const returnFilterByCitizen = (userDob, scheme, gender) => {
  if (userDob) {
    const age = calculateAge(userDob)
    let women = gender === 'F'
    let isSeniorCitizen = age >= 60 ? true : false
    const newSchemes = scheme?.filter(
      (data) =>
        data.product_senior_citizen === isSeniorCitizen &&
        data.product_women === women
    )
    return newSchemes
  }
}

const FixerraSchemeList = () => {
  usePageTitle('Explore Fixed Deposits')

  const { f_code } = useParams()

  const { fdSchemes } = useSelector(selectFixerra)
  const { user } = useSelector(selectAuth)

  const [yearlyData, setYearlyData] = useState([])
  const [halfYearlyData, setHalfYearlyData] = useState([])
  const [quarterlyData, setQuarterlyData] = useState([])
  const [monthlyData, setMonthlyData] = useState([])
  const [issuerData, setIssuerData] = useState({})

  useEffect(() => {
    if (fdSchemes.state === 'rejected') {
    } else if (fdSchemes?.items) {
      const filterFd = fdSchemes?.items?.filter(
        (scheme) => scheme?.issuerData?.f_code === f_code
      )?.[0]

      setIssuerData(filterFd?.issuerData)
      const result = returnFilterByCitizen(
        user.dob,
        filterFd?.vehiclesPayload,
        user.gender
      )
      const yearly = []
      const halfYearly = []
      const quarterly = []
      const monthly = []
      result?.forEach((product) => {
        // eslint-disable-next-line default-case
        switch (product.product_interest_freq) {
          case 'Yearly':
            yearly.push(product)
            break
          case 'HalfYearly':
            halfYearly.push(product)
            break
          case 'Quarterly':
            quarterly.push(product)
            break
          case 'Monthly':
            monthly.push(product)
            break
        }
      })
      setYearlyData(yearly)
      setHalfYearlyData(halfYearly)
      setQuarterlyData(quarterly)
      setMonthlyData(monthly)
    }
  }, [user.dob, fdSchemes?.items, fdSchemes.state, user.gender, f_code])

  return (
    <Row className="align-items-center">
      <Col md="12" className="mt-2">
        <div className="d-flex card-item-container flex-wrap">
          {issuerData && (
            <div className="">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    alt={issuerData?.f_code}
                    // src={getImagePath(issuerData?.f_code)}
                    src={issuerData?.sub_logo}
                    // src={issuerData?.url}
                    // crossOrigin="anonymous"
                    style={{ height: '50px' }}
                  />
                </div>
                <h5
                  className="ml-3 mb-0 font-weight-bold overflow-hidden text-lg"
                  id={issuerData?.name}
                >
                  {issuerData?.name}
                </h5>
              </div>
              <p className="mt-3">{issuerData?.description}</p>
            </div>
          )}
          {fdSchemes.state === 'fulfilled' ? (
            <>
              {[
                { name: 'Yearly', scheme: yearlyData },
                { name: 'Half Yearly', scheme: halfYearlyData },
                { name: 'Quarterly', scheme: quarterlyData },
                { name: 'Monthly', scheme: monthlyData },
              ].map((info) => (
                <FdScrollCard
                  scheme={info.scheme}
                  f_code={f_code}
                  name={info.name}
                  key={info.name}
                />
              ))}
            </>
          ) : fdSchemes.state === 'pending' ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: '100vh', width: '100vw', margin: 'auto' }}
            >
              <img src={LoadingGif} alt="Loading..." />
            </div>
          ) : (
            <h4 className="d-flex m-5">No FD schemes available</h4>
          )}
        </div>
        {/* <pre>{JSON.stringify(fdSchemes.items, null, 2)}</pre> */}
      </Col>
    </Row>
  )
}

export default FixerraSchemeList
