import FDTable from 'Components/Dashboard/FDTable'
import FixerraFDTable from 'Components/Dashboard/FixerraFDTable'
import { selectAugmont } from 'features/augmont/augmontSlice'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardHeader } from 'reactstrap'
import { loading } from 'features/status'
import usePageTitle from 'hooks/usePageTitle'

function AccountFd() {
  const [filterFd, setFilterFd] = useState({
    gold_fd: false,
    fixerra_fd: true,
  })

  usePageTitle('FDs')

  const { augmontFDs } = useSelector(selectAugmont)

  return (
    <Card className="main-card mb-3">
      <CardHeader className="custom-card-header">
        <div className="custom-table-header">
          <div className="table-title mr-5 align-content-center">
            {filterFd.gold_fd && 'Gold '} FD
          </div>
          <div className="d-flex custom-tab form-item-control ml-2 max-w-350">
            <label
              className={`custom-tab-item metal-radio-item py-1 ${
                filterFd.fixerra_fd === true ? 'custom-tab-item-active' : ''
              }`}
            >
              FD
              <input
                type="radio"
                className="d-none"
                name="investmentType"
                checked={filterFd.fixerra_fd === true}
                value={filterFd.fixerra_fd === true}
                onClick={() => {
                  setFilterFd((prev) => ({
                    ...prev,
                    fixerra_fd: true,
                    gold_fd: false,
                  }))
                  // setGoalsType('Fixerra FD')
                }}
              />
            </label>
            <label
              className={`custom-tab-item metal-radio-item py-1 ${
                filterFd.gold_fd === true ? 'custom-tab-item-active' : ''
              }`}
            >
              Gold FD
              <input
                type="radio"
                className="d-none"
                name="investmentType"
                checked={filterFd.gold_fd === true}
                value={filterFd.gold_fd === true}
                onClick={() => {
                  setFilterFd((prev) => ({
                    ...prev,
                    gold_fd: true,
                    fixerra_fd: false,
                  }))
                  // setGoalsType('FD')
                }}
              />
            </label>
          </div>
        </div>
      </CardHeader>
      {filterFd?.fixerra_fd ? (
        <FixerraFDTable limit={0} />
      ) : filterFd?.gold_fd ? (
        <FDTable
          data={augmontFDs.items}
          transactionStatus={augmontFDs.status === loading ? true : false}
          needModal={true}
          //   limit={5}
        />
      ) : (
        ''
      )}
    </Card>
  )
}

export default AccountFd
