import { selectUi, uiActions } from 'features/uiSlice'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const usePageTitle = (newPageTitle) => {
  const dispatch = useDispatch()
  const { pageTitle } = useSelector(selectUi)

  const onSetPageTitle = useCallback(
    (newTitle) => {
      dispatch(uiActions.setPageTitle(newTitle))
    },
    [dispatch]
  )

  useEffect(() => {
    if (newPageTitle) onSetPageTitle(newPageTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { pageTitle, onSetPageTitle }
}

export default usePageTitle
