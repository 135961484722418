import React from 'react'

import PrivateRoutes from '../../routes/PrivateRoutes'

import GiftCards from './GiftCard'
import VoucherPurchase from './Voucher/Purchase'
import VoucherDetailsTransactions from './Voucher/Details'

const QwikCilverNav = ({ match }) => {
  return (
    <>
      <PrivateRoutes path={`${match.url}/brands`} component={GiftCards} exact />
      <PrivateRoutes
        path={`${match.url}/brands/:sku`}
        component={VoucherPurchase}
        exact
      />
      <PrivateRoutes
        path={`${match.url}/details/:orderId`}
        component={VoucherDetailsTransactions}
        exact
      />
    </>
  )
}

export default QwikCilverNav
