import {
  getPassbook,
  getRates,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { idle } from 'features/status'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SellModule from './Sell'

import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap'
import { Form, Input, Select, Submit } from 'ReactstrapUi'
import { investmentType } from 'utils/constants'
import TabGroup from 'ReactstrapUi/Form/TabGroup'

const LONG_TERM_GOAL_OPTIONS = [
  { label: 'Marriage', value: 'Marriage' },
  { label: 'House Purchase', value: 'House Purchase' },
  { label: 'Repairs and Renovation', value: 'Repairs and Renovation' },
  { label: 'Education', value: 'Education' },
  { label: 'Loan repayment', value: 'Loan repayment' },
  { label: 'Car Purchase', value: 'Car Purchase' },
  { label: 'Retirement', value: 'Retirement' },
  { label: 'Custom', value: 'Custom' },
]

const schema = yup.object().shape({
  // name: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required('Goal is required (from label)'),
  //     value: yup.string().required('Goal is required'),
  //   })
  //   .nullable() // for handling null value when clearing options via clicking "x"
  //   .required('Goal is required (from outter null check)'),
  // name: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required('Goal is required'),
  //     value: yup.string().required('Goal is required'),
  //   })
  //   .nullable() // for handling null value when clearing options via clicking "x"
  //   .required('Goal is required'),
  // customName: yup.string().when(
  //   'name',
  //   (x, schema) => {
  //     if (name === 'Custom')
  //       return schema.required('Custom Goal is required')
  //     return schema
  //   }
  name: yup.string().required('Goal is required'),
  customName: yup.string().when('name', {
    is: 'Custom',
    then: () => yup.string().required('Custom Goal is required'),
    otherwise: () => yup.string(),
  }),
  // description: yup.string().required('Description is required'),
  investmentType: yup.string().required('Investment Type is required'),
  frequency: yup.string().when('investmentType', {
    is: investmentType.Card,
    then: () => yup.string().required('Frequency is required'),
    otherwise: () => yup.string(),
  }),
})

const PlanDetails = () => {
  const history = useHistory()
  const [selectedGoal, setSelectedGoal] = useState()
  const [selectedInvestmentType, setInvestmentType] = useState()
  const [isInvestPage, setIsInvestPage] = useState(true)
  const dispatch = useDispatch()
  const { augmontUser, augmontKyc, rates, passbook } =
    useSelector(selectAugmont)

  useEffect(() => {
    // this is for initial load
    if (augmontUser?.id) {
      if (rates.status === idle) {
        dispatch(getRates())
      }
      dispatch(getPassbook())
    }
  }, [rates.status, dispatch, augmontUser])

  useEffect(() => {
    const refreshIntervalId = setInterval(() => {
      dispatch(getRates())
    }, 1 * 60000)
    // serviceRef.current.getInvestmentGoldProductList()
    return () => {
      clearInterval(refreshIntervalId)
    }
  })

  // const handleSubmit = (selectedOption) => {
  //   history.push(`/investment/goal/long/${selectedOption}`, {
  //     name,
  //     description,
  //   })
  // }

  const onSubmit = (data) => {
    const tmpGoalName = selectedGoal === 'Custom' ? data.customName : data.name
    history.push(`/investment/goal/long/invest`, {
      name: tmpGoalName,
      description: data.description || 'Augmont',
      investmentType: data.investmentType,
      frequency: data?.frequency ?? 'Once',
    })
  }

  return (
    <Row>
      {!augmontUser?.id && (
        <Col lg="12">
          <Card className="card-hover-shadow-2x mb-3">
            <CardHeader className="card-header-tab text-danger">
              Complete Account Creation Process
            </CardHeader>
            <CardBody>
              Kindly complete the account creation process to continue your
              investment.
              <Link to="/account-setup/account"> Click here</Link> to update
              details
            </CardBody>
          </Card>
        </Col>
      )}
      {augmontUser?.id && (
        <Col lg="6">
          <Card className="main-card mb-3">
            <div className="d-flex mt-3 align-items-center flex-row m-2">
              <div className="d-flex custom-tab form-item-control sell-type-radio">
                <label
                  className={`custom-tab-item ${
                    isInvestPage ? 'custom-tab-item-active' : ''
                  }`}
                >
                  Invest
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={isInvestPage}
                    value={isInvestPage}
                    onClick={() => setIsInvestPage(true)}
                  />
                </label>
                <label
                  className={`custom-tab-item ${
                    !isInvestPage ? 'custom-tab-item-active' : ''
                  }`}
                >
                  Sell
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={!isInvestPage}
                    value={!isInvestPage}
                    onClick={() => setIsInvestPage(false)}
                  />
                </label>
              </div>
            </div>
            {isInvestPage ? (
              <CardBody>
                <Form
                  onFinish={onSubmit}
                  className="validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onTouched" // onBlur
                  // initialValues={{
                  // address: registerStep.pan.address,
                  // name: 'Marriage',
                  // }}
                >
                  <Select
                    name="name"
                    label="Select your goal"
                    placeholder="Select Goal.."
                    options={LONG_TERM_GOAL_OPTIONS}
                    className="form-control"
                    defaultValue="Marriage"
                    required
                    onChange={(e) => {
                      setSelectedGoal(e.target.value)
                    }}
                  />
                  {selectedGoal === 'Custom' ? (
                    <Input.Text
                      name="customName"
                      label="Enter Custom Goal Name"
                      className="form-control"
                      required
                    />
                  ) : null}
                  {/* <Input.TextArea
                  label="Description"
                  placeholder="Enter additional info"
                  id="description"
                  name="description"
                  className="form-control"
                  required
                /> */}
                  <TabGroup
                    name="investmentType"
                    label="Select Investment Type"
                    // placeholder="Select..."
                    options={[
                      {
                        value: investmentType.Lumpsum,
                        label: investmentType.Lumpsum,
                      },
                      {
                        value: investmentType.SIP,
                        label: investmentType.SIP,
                      },
                    ]}
                    className="form-control"
                    required
                    onChange={(e) => {
                      console.log('SelectReactControlled', e.target.value)
                      setInvestmentType(e.target.value)
                    }}
                  />
                  {selectedInvestmentType === investmentType.SIP && (
                    <TabGroup
                      name="frequency"
                      label="Frequency"
                      options={[
                        {
                          value: 'Monthly',
                          label: 'Monthly',
                        },
                        {
                          value: 'Weekly',
                          label: 'Weekly',
                        },
                        {
                          value: 'Daily',
                          label: 'Daily',
                        },
                      ]}
                      className="form-control"
                      required
                      // onChange={(e) => {
                      //   console.log('SelectReactControlled', e.target.value)
                      //   setSelectedGoal(e.target.value)
                      //   setInvestmentType(e.target.value)
                      // }}
                    />
                  )}
                  {/* {selectedInvestmentType === investmentType.SIP && (
                  <Select
                    name="frequency"
                    label="Frequency"
                    // placeholder="Select..."
                    options={[
                      {
                        value: 'Monthly',
                        label: 'Monthly',
                      },
                      {
                        value: 'Weekly',
                        label: 'Weekly',
                      },
                      {
                        value: 'Daily',
                        label: 'Daily',
                      },
                    ]}
                    className="form-control"
                    required
                    onChange={(e) => {
                      console.log('SelectReactControlled', e.target.value)
                    }}
                  />
                )} */}
                  <div className="d-flex justify-content-end">
                    <Submit
                      className="btn btn-primary"
                      // onClick={() => {
                      //   handleSubmit('lumpsum')
                      // }}
                    >
                      Proceed
                    </Submit>
                  </div>
                  {/* <Submit
                className="btn btn-primary m-2"
                // onClick={() => {
                //   handleSubmit('lumpsum')
                // }}
              >
                Lumpsum
              </Submit>
              <Submit
                className="btn btn-primary m-2"
                // onClick={() => {
                //   handleSubmit('sip')
                // }}
              >
                SIP
              </Submit> */}
                </Form>
              </CardBody>
            ) : (
              <SellModule />
            )}
          </Card>
        </Col>
      )}
      {augmontUser?.id && (
        <Col md="6">
          <Card className="mb-3">
            <CardHeader className="d-flex justify-content-between">
              Gold/Silver Balance
              {!augmontUser?.id ? (
                <Link to="/account-setup/account" className="d-inline">
                  <span className="text-info pl-1">
                    Complete Account Creation
                  </span>
                </Link>
              ) : (
                <span className="text-dark pr-2">
                  Kyc status:
                  <span className="text-primary pl-1">
                    {augmontKyc.status ? (
                      augmontKyc.status
                    ) : (
                      <div
                        className="spinner-border text-primary ml-2"
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </span>
              )}
            </CardHeader>
            <Row className="no-gutters">
              <Col md="12">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-gold" />
                    <i className="svg-icon svg-gold" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">
                      <span>Gold Balance (In grams)</span>
                    </div>
                    <div className="widget-numbers">
                      <span style={{ fontSize: '40px' }}>
                        <CountUp
                          start={0}
                          end={Number(passbook.goldGrms)}
                          separator=","
                          decimals={4}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix="g"
                          duration="1"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">
                      <span>Gold Balance (In Rupees)</span>
                    </div>
                    <div className="widget-numbers">
                      <span style={{ fontSize: '40px' }}>
                        <CountUp
                          start={0}
                          end={passbook.goldGrms * rates?.rates?.gBuy}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix="₹"
                          useEasing={true}
                          suffix=""
                          duration="1"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col md="12">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-silver" />
                    <i className="svg-icon svg-silver" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">
                      <span>Silver Balance (In grams)</span>
                    </div>
                    <div className="widget-numbers">
                      <span style={{ fontSize: '40px' }}>
                        <CountUp
                          start={0}
                          end={Number(passbook.silverGrms)}
                          separator=","
                          decimals={4}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix="g"
                          duration="1"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">
                      <span>Silver Balance (In Rupees)</span>
                    </div>
                    <div className="widget-numbers">
                      <span style={{ fontSize: '40px' }}>
                        <CountUp
                          start={0}
                          end={passbook.silverGrms * rates?.rates?.sBuy}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix="₹"
                          useEasing={true}
                          suffix=""
                          duration="1"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default PlanDetails
