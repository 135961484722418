import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import { fulfilled, idle, pending } from 'features/status'
import {
  getCities,
  getStates,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { createOrUpdateAugmontAccount } from 'features/augmont/augmontApi'
import { selectAuth } from 'features/auth/authSlice'
import { DatePicker, Form, Input, Select } from 'antd'
import { tenDigitMobileRegex } from "utils/validation"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale('en')

const AccountDetailsForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const { augmontUser, states, cities } = useSelector(selectAugmont)
  const { user } = useSelector(selectAuth)
  // const [state, setState] = useState('')
  // const [city, setCity] = useState('')

  useEffect(() => {
    if (states.status === idle) {
      dispatch(getStates())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (augmontUser.userState && cities.status === idle) {
      dispatch(getCities(augmontUser.userState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (states.status === fulfilled) {
  //     if (state === '' && augmontUser.userState) {
  //       setState(augmontUser.userState)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [states])

  // useEffect(() => {
  //   if (cities.status === fulfilled) {
  //     if (city === '' && augmontUser.userCity) {
  //       setCity(augmontUser.userCity)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cities])

  const onSubmit = (data) => {
    const payload = {
      ...data,
      dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
      nomineeDateOfBirth: dayjs(data.nomineeDateOfBirth).toISOString(),
    }
    console.log(payload)
    createOrUpdateAugmontAccount(payload).then((data) => {
      // console.log(data)
      setTimeout(() => {
        history.push('/account-setup/bank')
      }, 300)
    })
  }

  const handleStateChange = (value) => {
    form.setFieldValue('userCity', 'Loading...')
    dispatch(getCities(value))
    setTimeout(() => {
      form.setFieldValue('userCity', 'Select City')
    }, 300)
  }

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <h3>Account Details</h3>
          {/* <pre>{JSON.stringify(augmontUser, null, 2)}</pre> */}
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            className="validate-form was-validate"
            initialValues={
              augmontUser?.accountCreated
                ? {
                    userName: augmontUser.userName,
                    emailId: augmontUser.emailId,
                    mobileNumber: augmontUser.mobileNumber,
                    dateOfBirth:
                      augmontUser?.dateOfBirth &&
                      dayjs(augmontUser.dateOfBirth),
                    userAddress: augmontUser.userAddress,
                    userPincode: augmontUser.userPincode,
                    userState: augmontUser.userState,
                    userCity: augmontUser.userCity,
                    nomineeName: augmontUser.nomineeName,
                    nomineeRelation: augmontUser.nomineeRelation,
                    nomineeDateOfBirth:
                      augmontUser?.nomineeDateOfBirth &&
                      dayjs(augmontUser.nomineeDateOfBirth),
                  }
                : {
                    userName: user.firstName + ' ' + user.lastName,
                    emailId: user.email,
                    mobileNumber: user.mobile,
                    dateOfBirth: user?.dob && dayjs(user.dob),
                    userAddress: user?.address?.full,
                    userPincode: user?.address?.zip,
                  }
            }
          >
            <Row>
              <Col md="12">
                <Form.Item
                  label="Your Full Name"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: 'Full Name is required',
                    },
                  ]}
                >
                  <Input placeholder="Full Name" className="form-control" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  label="Email Id"
                  name="emailId"
                  rules={[
                    {
                      required: true,
                      message: 'Email Id is required',
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid email',
                    }
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Email Id"
                    className="form-control"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Mobile Number is required',
                    },
                    {
                      pattern: tenDigitMobileRegex,
                      message: 'Please enter valid mobile number',
                    }
                  ]}
                >
                  <Input placeholder="Mobile Number" className="form-control" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Item
                  label="Dob as per PAN"
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: 'Dob as per PAN is required',
                    },
                  ]}
                >
                  <DatePicker
                    className="form-control"
                    placeholder="Dob as per PAN"
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  name="userState"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: 'State is required',
                    },
                  ]}
                >
                  <Select
                    placeholder={
                      states.status === pending ? 'Loading...' : 'Select State'
                    }
                    required
                    options={
                      states.status === fulfilled &&
                      states.items.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    }
                    onChange={handleStateChange}
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  name="userCity"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: 'City is required',
                    },
                  ]}
                >
                  <Select
                    placeholder={
                      cities.status === pending ? 'Loading...' : 'Select City'
                    }
                    // required
                    options={
                      cities.status === fulfilled &&
                      cities.items.map((item, index) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    }
                    // showSearch
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Item
                  label="Address"
                  name="userAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Address is required',
                    },
                  ]}
                >
                  <Input placeholder="Address" className="form-control" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Item
                  label="Pin Code Code"
                  name="userPincode"
                  rules={[
                    {
                      required: true,
                      message: 'Pin Code Code is required',
                    },
                  ]}
                >
                  <Input placeholder="Pin Code Code" className="form-control" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col md="12">
                <Form.Item
                  label="Nominee Name"
                  name="nomineeName"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee Name is required',
                    },
                  ]}
                >
                  <Input placeholder="Nominee Name" className="form-control" />
                </Form.Item>
              </Col>
            </Row> */}
            {/* <Row>
              <Col md="6">
                <Form.Item
                  label="Nominee DOB"
                  name="nomineeDateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee DOB is required',
                    },
                  ]}
                >
                  <DatePicker
                    className="form-control"
                    placeholder="Nominee DOB"
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Relation With Nominee"
                  name="nomineeRelation"
                  rules={[
                    {
                      required: true,
                      message: 'Relation With Nominee is required',
                    },
                  ]}
                >
                  <Input
                    placeholder="Relation With Nominee"
                    className="form-control"
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col md="12">
                <small className="text-danger">
                  Fields marked with * are required
                </small>
              </Col>
              <Col md="12" className="d-flex justify-content-end">
                <Form.Item>
                  <button
                    type="submit"
                    className="mt-3 btn btn-primary btn-shadow"
                  >
                    Proceed
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AccountDetailsForm
