import React, { useContext, useRef } from 'react'
// import { useSelector } from 'react-redux'
import { UpdatePinModal, ValidatePinModal } from 'Components/PinModals'

import { selectAuth } from 'features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { pinActions, selectPin } from 'features/auth/pinSlice'

const Store = React.createContext()

export function usePin() {
  return useContext(Store)
}

export function PinProvider({ children }) {
  const { logged, pin } = useSelector(selectAuth)
  const dispatch = useDispatch()
  const { validated } = useSelector(selectPin)
  const formData = useRef(null)
  const customCB = useRef(() => {})
  const customHandler = useRef(() => {})

  const showUpdatePinModal = () => {
    dispatch(pinActions.openUpdateModal())
  }

  const showValidatePinModal = () => {
    dispatch(pinActions.openValidateModal())
  }

  const checkPin = (data, cb, description = 'Pin Verification') => {
    if (pin) {
      formData.current = data
      customCB.current = cb
      dispatch(
        pinActions.checkPin({
          description,
        })
      )
    } else {
      cb(data)
    }
  }

  const checkPinWithHandler = (values, handler, cb) => {
    if (pin) {
      formData.current = values
      customCB.current = cb
      customHandler.current = handler
    } else {
      cb(values, handler)
    }
  }

  const validatePin = (validated) => {
    if (validated) {
      dispatch(pinActions.validatePin())
      customCB.current(formData.current)
      // if (customHandler.current) {
      //   customCB.current(formData.current, customHandler.current)
      //   customHandler.current = null
      // } else {
      //   customCB.current(formData.current)
      // }
    }
  }

  return (
    <Store.Provider
      value={{
        pin,
        showUpdatePinModal,
        showValidatePinModal,
        validated,
        validatePin,
        checkPinWithHandler,
        checkPin,
      }}
    >
      {children}
      {logged === true && (
        <>
          <UpdatePinModal />
          <ValidatePinModal validatePin={validatePin} />
        </>
      )}
    </Store.Provider>
  )
}
