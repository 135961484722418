import { createSlice } from '@reduxjs/toolkit'
import { fulfilled, idle, loading } from './status'

const defaultState = {
  bankAccount: {
    id: null,
    status: idle,
  },
  virtualAccount: {
    id: null,
    walletBalance: 0,
    presentBalance: 0,
  },
  fixerraAccount: {
    id: null,
  },
  lendBoxAccount: {
    id: null,
  },
  lendBoxPortfolio: [
    {
      lbUserId: 0,
      investmentId: 0,
      type: 0,
      currentInvestment: 0,
      interestPerDay: 0,
      amountWithdrawn: 0,
      interestAccrued: 0,
      roi: 0,
      totalInterestEarning: 0,
      netIntPaid: 0,
      autoInterestWithdraw: 0,
      availableToWithdraw: 0,
    },
  ],
  balance: {
    walletBalance: 0,
    presentBalance: 0,
  },
  statements: {
    items: [],
    status: loading,
  },
  creditScore: 300,
  refreshStatement: new Date().valueOf(),
  refreshBalance: new Date().valueOf(),
  beneficiaries: [],
  nominee: {},
  goals: {
    items: [],
    status: loading,
  },
  // investmentBalance: 0,
}

export const accountSlice = createSlice({
  name: 'accounts',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    queueUpdateInterval: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.interval = state.interval + 5
    },
    loadAllAccounts: (state, action) => {
      state.bankAccount = action.payload.bankAccount
      state.virtualAccount = action.payload.virtualAccount
      state.fixerraAccount = action.payload.fixerraAccount
      state.lendBoxAccount = action.payload.lendBoxAccount
    },
    loadBankAccount: (state, action) => {
      state.bankAccount = action.payload
    },
    loadFixerraAccount: (state, action) => {
      state.fixerraAccount = action.payload
    },
    loadVirtualAccount: (state, action) => {
      state.virtualAccount = action.payload
    },
    loadLendBoxAccount: (state, action) => {
      state.lendBoxAccount = action.payload
    },
    loadVirtualAccountBalance: (state, action) => {
      // state.balance = action.payload || []
      state.virtualAccount.walletBalance = action.payload.walletBalance || 0
      state.virtualAccount.presentBalance = action.payload.presentBalance || 0
    },
    loadLendBoxPortfolio: (state, action) => {
      state.lendBoxPortfolio = action.payload
    },
    updateWalletBalance: (state, action) => {
      state.balance.walletBalance = action.payload || 0
      state.virtualAccount.walletBalance = action.payload || 0
    },
    loadBeneficiaries: (state, action) => {
      state.beneficiaries = action.payload || []
    },
    loadNominee: (state, action) => {
      state.nominee = action.payload || {}
    },
    loadCreditScore: (state, action) => {
      state.creditScore = action.payload
    },
    loadStatements: (state, action) => {
      state.statements = {
        ...action.payload,
        status: fulfilled,
      }
    },
    loadGoals: (state, action) => {
      state.goals = {
        items: action.payload,
        status: fulfilled,
      }
    },
    updateGoalById: (state, action) => {
      state.goals = {
        items: state.goals.items.map((item) =>
          item.id === action.payload.id
            ? { ...item, ...action.payload.item }
            : item
        ),
        status: fulfilled,
      }
    },
    updateGoalByRefId: (state, action) => {
      state.goals = {
        items: state.goals.items.map((item) =>
          item.refId === action.payload.refId
            ? { ...item, ...action.payload.item }
            : item
        ),
        status: fulfilled,
      }
    },
    refreshGoals: (state, action) => {
      state.goals = {
        status: loading,
      }
    },
    refreshData: (state, action) => {
      state.statements = {
        ...state.statements.items,
        status: loading,
      }
      state.refreshStatement = new Date().valueOf()
      state.refreshBalance = new Date().valueOf()
    },
    refreshBalance: (state, action) => {
      state.refreshBalance = new Date().valueOf()
    },
    clear: (state) => {
      state.balance = {}
      state.statements = {}
      state.refreshStatement = true
      state.refreshBalance = true
    },
  },
})

// Action creators are generated for each case reducer function
// export const {} = bankSlice.actions
export const accountActions = accountSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.bank)`
export const selectAccount = (state) => state.accounts

export default accountSlice.reducer
