import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import { FaRegArrowAltCircleLeft, FaRupeeSign } from 'react-icons/fa'

import {
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from '../../utils/validation'

import { useServices } from '../../backend/useServices'

import BillDetails from './BillDetails'
import { usePin } from 'context/PinContext'
import { s3GetUrl } from 'config'

const BillingForm = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const operators = useSelector((state) => state.services.bbpsOperators)
  const [operator, setOperator] = useState()

  const [amount, setAmount] = useState(100)
  const [billDetails, setBillDetails] = useState()
  const { checkPin } = usePin()

  const { getBillDetails, payment } = useServices()

  useEffect(() => {
    if (operators) {
      setOperator(
        operators.find((op) => op.service === service && op.code === opcode)
      )
    }
  }, [operators, opcode, service])

  useEffect(() => {
    return () => {
      setBillDetails()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, reset, setValue, trigger, formState } =
    useForm()

  const onSubmit = async (data) => {
    // console.log(data)
    if (operator.bill && !billDetails) {
      await checkBill(data)
      return
    }
    checkPin(data, handleFormSubmit)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setBillDetails()
    reset()
  }

  const checkBill = async (data) => {
    let payload = {
      ...data,
      amount: '100',
      opcode: opcode,
      opid: operator.opid,
    }
    // console.log(payload)
    const result = await getBillDetails(payload)
    console.log('data', result)
    if (result?.ref_id) {
      setBillDetails(result)
      setValue('amount', result.due_amount)
      setAmount(result.due_amount)
    }
  }

  const handleFormSubmit = (data) => {
    let payload = {
      ...data,
      service: service,
      amount: amount,
      opcode: opcode,
      opid: operator.opid,
    }
    if (billDetails?.ref_id) {
      payload.ref_id = billDetails.ref_id
    }
    payment(payload).then((data) => {
      setTimeout(() => {
        history.push('/account/transactions')
      }, 300)
    })
  }

  const handleAmount = (value) => {
    // console.log(value)
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const selectAmount = (value) => {
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const handleChangeInput = (name, value) => {
    setValue(name, value)
    trigger(name)
    setBillDetails()
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(operator) || isEmpty(operators)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Pay your {operator?.name} Bill</CardTitle>
              <div className="d-flex align-items-center justify-content-between pr-3">
                <div className="d-flex align-items-center p-2 my-2">
                  {operator.logo ? (
                    <img
                      src={s3GetUrl(operator?.logo)}
                      alt=""
                      style={{
                        width: '64px',
                        borderRadius: 50,
                        backgroundColor: '#eee',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        minWidth: '64px',
                        minHeight: '64px',
                        borderRadius: 50,
                        backgroundColor: operator?.color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '2em',
                        fontWeight: '500',
                      }}
                    >
                      {operator?.name.charAt(0)}
                    </div>
                  )}
                  <div>
                    <div className="ml-3">{operator?.name}</div>
                    {/* <div className="ml-3 font-weight-bold">{operator.name}</div> */}
                    {/* {operator?.bill && <small className="ml-3 text-muted">Bill Fetch Supported</small>} */}
                    <small className="ml-3 text-muted">
                      Bill Fetch: {operator?.bill ? 'Yes' : 'No'}
                    </small>
                    {/* {operator?.bbps && <small className="ml-3 text-muted">BBPS Enabled</small>} */}
                    <small className="ml-3 text-muted">
                      BBPS: {operator?.bbps ? 'Yes' : 'No'}
                    </small>
                    {/* <div className="ml-3">{operator?.bill ? "Yes" : "No"}</div> */}
                  </div>
                </div>
                <FaRegArrowAltCircleLeft
                  title="Back to Operators"
                  size="24"
                  className="text-secondary cursor-pointer"
                  onClick={() => history.goBack()}
                />
              </div>
              {/* <pre>{JSON.stringify(operator, null, 2)}</pre> */}
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  <Col lg="12" key="canumber">
                    <FormGroup>
                      <Label for="canumber" className="required">
                        {operator.displayname}
                      </Label>
                      <input
                        type="text"
                        placeholder={
                          operator.placeholder ||
                          `Enter ${operator.displayname}`
                        }
                        id="canumber"
                        name="canumber"
                        className="form-control"
                        {...register('canumber', {
                          required: `${operator.displayname} is required`,
                          pattern: operator.regex,
                        })}
                        onChange={(e) =>
                          handleChangeInput('canumber', e.target.value)
                        }
                      />
                      <InvalidFeedbackV7
                        name="canumber"
                        formState={formState}
                      />
                    </FormGroup>
                  </Col>
                  {operator?.ad1_name && (
                    <Col lg="12" key={operator?.ad1_name}>
                      <FormGroup>
                        <Label for={operator?.ad1_name} className="required">
                          {operator.ad1_d_name}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad1_d_name}`
                          }
                          id={operator?.ad1_name}
                          name={operator?.ad1_name}
                          className="form-control"
                          {...register(operator?.ad1_name, {
                            required: `${operator.ad1_d_name} is required`,
                            pattern: operator.ad1_regex,
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad1_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad1_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {operator?.ad2_name && (
                    <Col lg="12" key={operator?.ad2_name}>
                      <FormGroup>
                        <Label for={operator?.ad2_name} className="required">
                          {operator.ad2_d_name}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad2_d_name}`
                          }
                          id={operator?.ad2_name}
                          name={operator?.ad2_name}
                          className="form-control"
                          {...register(operator?.ad2_name, {
                            required: `${operator.ad2_d_name} is required`,
                            pattern: operator.ad2_regex,
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad2_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad2_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {operator?.ad3_name && (
                    <Col lg="12" key={operator?.ad3_name}>
                      <FormGroup>
                        <Label for={operator?.ad3_name} className="required">
                          {operator.ad3_d_name}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad3_d_name}`
                          }
                          id={operator?.ad3_name}
                          name={operator?.ad3_name}
                          className="form-control"
                          {...register(operator?.ad3_name, {
                            required: `${operator.ad3_d_name} is required`,
                            pattern: operator.ad3_regex,
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad3_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad3_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {operator?.bill ? (
                    billDetails && (
                      <Col lg="12">
                        <FormGroup>
                          <Label for="amount" className="required">
                            Amount
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              id="amount"
                              name="amount"
                              value={amount}
                              // defaultValue="100"
                              // defaultValue={0}
                              className="form-control"
                              {...register('amount', {
                                required: 'Amount is required',
                                onChange: (e) => handleAmount(e.target.value),
                              })}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaRupeeSign />
                              </InputGroupText>
                            </InputGroupAddon>
                            <InvalidFeedbackV7
                              name="amount"
                              formState={formState}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )
                  ) : (
                    <Col lg="12">
                      <FormGroup>
                        <Label for="amount" className="required">
                          Amount
                        </Label>
                        <InputGroup>
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            id="amount"
                            name="amount"
                            value={amount}
                            className="form-control"
                            {...register('amount', {
                              required: 'Amount is required',
                              onChange: (e) => handleAmount(e.target.value),
                            })}
                          />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaRupeeSign />
                            </InputGroupText>
                          </InputGroupAddon>
                          <InvalidFeedbackV7
                            name="amount"
                            formState={formState}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  {operator?.bill ? (
                    !billDetails ? (
                      <>
                        <Col lg="12" className="d-flex justify-content-end">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="primary"
                              disabled={preventSubmit(formState)}
                            >
                              Check Bill
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="danger"
                              type="button"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="success"
                              disabled={preventSubmit(formState)}
                            >
                              Pay Bill
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    )
                  ) : (
                    <Col lg="12">
                      <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Proceed
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <BillDetails
            data={billDetails}
            selectAmount={selectAmount}
            visible={true}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default BillingForm
