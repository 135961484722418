import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Label, FormGroup } from 'reactstrap'
import { FaRupeeSign } from 'react-icons/fa'
import * as yup from 'yup'

import { formattedPrice } from 'utils/helper'
import { useVirtualAccount } from 'backend/useVirtualAccount'
import { usePin } from 'context/PinContext'
import { toast } from 'react-toastify'
import { sellSilver } from 'features/augmont/augmontApi'
import { getProfile } from 'features/augmont/augmontSlice'
import { Form, Input, Submit } from 'ReactstrapUi'
import { yupResolver } from '@hookform/resolvers/yup'

const SilverSellForm = () => {
  const dispatch = useDispatch()
  const { getStatement } = useVirtualAccount()
  const { checkPin } = usePin()

  const { augmontUser, passbook, rates } = useSelector((state) => state.augmont)
  const [convertedText, setConvertedText] = useState(0)

  const [buySellRateAsPerTab, setBuySellRateAsPerTab] = useState(0)
  const [blockId, setCurrentBlockId] = useState()

  useEffect(() => {
    if (rates?.rates) {
      setBuySellRateAsPerTab(rates?.rates?.sSell)
    }
  }, [rates?.rates])

  useEffect(() => {
    if (rates?.blockId) {
      setCurrentBlockId(rates?.blockId)
    }
  }, [rates?.blockId])

  const handleQuantity = (event) => {
    let finalAmount = 0
    let fieldValue = event?.target?.value * 1
    if (fieldValue && fieldValue >= 0) {
      finalAmount = fieldValue * buySellRateAsPerTab
      // finalAmount = finalAmount.toFixed(2)
      setConvertedText(finalAmount)
    }
  }

  const validateWithPin = async (values) => {
    if (augmontUser?.id) {
      checkPin(null, () => submitForm(values), 'To sell silver')
    } else {
      toast.error('Please, Complete Account Creation Process First')
    }
  }

  const submitForm = async (values) => {
    try {
      if (augmontUser?.id) {
        let payload = {
          metalType: 'Silver',
          lockPrice: buySellRateAsPerTab,
          blockId,
        }
        if (values.selectedType === 'gram') {
          payload.quantity = values.quantity
        } else {
          payload.amount = values.amount
        }
        sellSilver(payload).then((res) => {
          dispatch(getProfile())
          getStatement({ limit: 10 })
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const schema = yup.object().shape({
    quantity: yup.number().when('selectedType', {
      is: 'gram',
      then: () =>
        yup
          .number()
          .required('Please enter quantity')
          .moreThan(0, 'Quantity should be at lease .01g')
          .test(
            'enoughBalance',
            'Weight should not more that your existing Silver balance',
            (value) => value <= passbook.silverGrms
          ),
    }),
  })

  return (
    <Form
      // initialValues={{
      //   selectedType: 'gram',
      //   quantity: 0,
      // }}
      schemaResolver={yupResolver(schema)}
      onFinish={validateWithPin}
      mode="onTouched"
    >
      <Row className="static-height-36">
        <Col md="12">
          <FormGroup>
            <div className="d-flex justify-content-between">
              <div className="text-left">
                <small>Current Rate</small>
                <div>
                  <Label
                    className="d-flex align-items-center badge custom-label bg-soft-primary"
                    for="gram"
                  >
                    <FaRupeeSign />
                    {`${buySellRateAsPerTab} / gm + GST`}
                  </Label>
                </div>
              </div>
              <div className="text-right">
                <small>Purity</small>
                <div>
                  <Label className="badge custom-label bg-soft-primary">
                    24K 99.99%
                  </Label>
                </div>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Input.Number
              key="quantity"
              id="quantity"
              autoComplete="off"
              name="quantity"
              type="number"
              step={0.0001}
              min={0.0001}
              // max={Number(passbook.silverGrms) + 0.1}
              placeholder="Gram"
              rules={{
                onChange: (e) => handleQuantity(e),
              }}
            />
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span>
                Silver Balance {Number(passbook.silverGrms).toFixed(4)}g
              </span>
              <span className="badge bg-soft-success text-success">
                {'= '}
                {formattedPrice(convertedText)}
              </span>
            </div>
            <Input.Hidden name="selectedType" defaultValue="gram" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Submit
            size="lg"
            block
            className="btn btn-primary btn-shadow"
            color="primary"
            type="submit"
            // disabled={isSubmitting || Object.keys(errors).length > 0}
          >
            Sell Silver
          </Submit>
        </Col>
      </Row>
    </Form>
  )
}

export default SilverSellForm
