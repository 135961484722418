export const lowerKeys = (obj) => {
  var key,
    keys = Object.keys(obj)
  var n = keys.length
  var newobj = {}
  while (n--) {
    key = keys[n]
    newobj[key.toLowerCase()] = obj[key]
  }
  return newobj
}

// function val() {
//   return Math.floor(Math.random() * 256)
// }

export const randomColor = () => {
  let numberOrString = Math.floor(Math.random() * 256)
  // if (typeof numberOrString === 'string') {
  //   // convert string to number
  //   numberOrString = numberOrString
  //     .toLowerCase()
  //     .split('')
  //     .reduce(function (a, b) {
  //       a = (a << 5) - a + b.charCodeAt(0) // explained here: https://stackoverflow.com/a/7616484/112731
  //       return a & a
  //     }, 0)
  // }
  const hue = numberOrString * 137.508 // use golden angle approximation
  return `hsl(${hue},50%,75%)`
  // return 'rgb(' + val() + ',' + val() + ',' + val() + ')'
}

export const objectToString = (obj) => {
  let string = []
  for (const [key, value] of Object.entries(obj)) {
    string.push(`${key}=${value}`)
  }
  return string.join(',')
}
