import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Table, Button } from 'reactstrap'

import { useHistory } from 'react-router-dom'
import { formattedPrice } from 'utils/helper'
import { useInvestment } from 'backend/useInvestment'

const GoalsTable = ({
  data,
  currency,
  goalsType,
  needModal,
  initialStatus,
  transactionStatus,
  limit,
}) => {
  const history = useHistory()
  const { cancelInvestment } = useInvestment()

  if (!data) return null
  let goals
  if (limit > 0) {
    goals = data.filter((d, i) => i < limit)
  } else {
    goals = data
  }

  const getStatus = (status) => {
    return (
      <span
        className={`badge ${
          status === 'Active' || status === 'Completed'
            ? 'bg-soft-success text-success'
            : status === 'Pending'
            ? 'bg-soft-warning text-warning'
            : status === 'Canceled'
            ? 'bg-soft-danger text-danger'
            : ''
        }`}
      >
        {status}
      </span>
    )
  }

  return (
    <>
      {initialStatus === 1 && transactionStatus === 'loading' && (
        <div className="text-center text-muted py-5">
          Your transaction will appear here
        </div>
      )}

      {transactionStatus === 'idle' && goals.length === 0 && (
        <div className="text-center text-muted py-5">
          No goal investment found
        </div>
      )}

      {goals.length > 0 && (
        <Table responsive hover className="align-middle text-truncate mb-5">
          <thead>
            <tr>
              <th className="text-center">Goal Name</th>
              {goalsType === 'long' ? (
                <th className="text-center">Metal Type</th>
              ) : null}
              {goalsType === 'short' ? (
                <th className="text-center">Brand Partner</th>
              ) : null}
              <th className="text-center">Start Date</th>
              <th className="text-center">Completion Date</th>
              <th className="text-center">Amount</th>
              <th className="text-center">Amount Invested</th>
              {/* {goalsType === 'long' ? ( */}
              <th className="text-center">Frequency</th>
              {/* ) : null} */}
              {/* <th className="text-center">Amount to be Returned</th> */}
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {goals.map((goal) => (
              <tr key={goal.createdAt}>
                <td
                  className="text-center text-primary cursor-pointer"
                  onClick={() =>
                    history.push(`/investment/goal-details/${goal.id}`)
                  }
                >
                  {goal.name}
                </td>
                {goalsType === 'long' && (
                  <td className="text-center">{goal.investIn}</td>
                )}
                {goalsType === 'short' && (
                  <td className="text-center">{goal.brand}</td>
                )}
                <td
                  className="text-center text-muted"
                  style={{ maxWidth: '9em' }}
                >
                  <span className="mr-1">
                    {dayjs(goal.startDate).format('DD-MM-YYYY')}
                  </span>
                </td>
                <td className="text-center text-muted">
                  <span className="mr-1">
                    {dayjs(goal.endDate).format('DD-MM-YYYY')}
                  </span>
                </td>
                <td className="text-center">
                  {formattedPrice(goal.amount)}
                  {goal.gst > 0 && (
                    <small className="extra-small">
                      +{formattedPrice(goal.gst)} GST
                    </small>
                  )}
                </td>
                <td className="text-center" style={{ maxWidth: '5em' }}>
                  {formattedPrice(goal.amountInvested)}
                </td>
                <td className="text-center" style={{ maxWidth: '5em' }}>
                  {goal.frequency}
                </td>
                {/* <td className="text-center" style={{ maxWidth: '5em' }}>
                  {formattedPrice(goal.amountReturned)}
                </td> */}
                <td className="text-center" style={{ maxWidth: '5em' }}>
                  {getStatus(goal.status)}
                </td>
                {goalsType === 'long' && (
                  <td className="text-center">
                    {goal.status === 'completed' && 'Completed'}
                    {goal.status !== 'canceled' && (
                      <Button
                        outline
                        className="custom-table-action-btn_danger text-danger"
                        color="danger"
                        onClick={() => alert('implements pending')}
                      >
                        CANCEL
                      </Button>
                    )}
                    {goal.status === 'canceled' && 'Canceled'}
                  </td>
                )}
                {goalsType === 'short' && (
                  <td className="text-center">
                    {goal.status === 'Completed' && (
                      <Button
                        className="custom-table-action-btn_success bg-soft-success text-success px-3"
                        onClick={() => {
                          history.push(`/voucher/brands/${goal.investIn}`)
                          //   history.push(
                          //     `/investment/goal/${goal.type}/${goal.id}/voucher`
                          //   )
                        }}
                      >
                        BUY
                      </Button>
                    )}
                    {goal.status === 'Active' && (
                      <Button
                        className="custom-table-action-btn_danger text-danger px-2"
                        color="danger"
                        onClick={() => cancelInvestment(goal.id)}
                      >
                        CANCEL
                      </Button>
                    )}
                    {goal.status === 'canceled' && 'Canceled'}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

GoalsTable.defaultProps = {
  limit: 0,
  description: false,
  currency: 'INR',
  transactionStatus: 0,
  initialStatus: 1,
  needModal: false,
}

GoalsTable.propTypes = {
  limit: PropTypes.number,
  data: PropTypes.array.isRequired,
  currency: PropTypes.string,
  description: PropTypes.bool,
  transactionStatus: PropTypes.number,
  initialStatus: PropTypes.number,
  needModal: PropTypes.bool,
}

export default GoalsTable
