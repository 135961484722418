import {
  getFixerraCollections,
  selectFixerra,
} from 'features/fixerra/fixerraSlice'
import usePageTitle from 'hooks/usePageTitle'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import ShriRam from '../../../../assets/FD-logos/shriram-Fd-logo.png'
// import Bajaj from '../../../../assets/FD-logos/Bajaj-Fd-logo.png'
import BajajOut from '../../../../assets/FD-logos/Bajaj-Fd-logo-2.png'
import Mahindra from '../../../../assets/FD-logos/Mahindra-Fd-logo.png'
import Pnb from '../../../../assets/FD-logos/Pnb-Fd-logo.png'
import fd_certi from '../../../../assets/img/fd_certi.svg'
import TapInvest from '../../../../assets/FD-logos/tapinvest.png'

export const getImagePath = (code) => {
  if (code === 'SHRI') return ShriRam
  // else if (code === 'BAJ' && window.location.pathname.includes('schemes'))
  //   return Bajaj
  else if (code === 'BAJ') return BajajOut
  else if (code === 'MAH') return Mahindra
  else if (code === 'PNBH') return Pnb
  else if (code === 'tapinvest') return TapInvest
}

const FixerraFD = () => {
  usePageTitle('Explore Fixed Deposits')
  const history = useHistory()
  const dispatch = useDispatch()

  const { collections } = useSelector(selectFixerra)

  function getCertificate(str) {
    return str.replace(/CRISIL|\/Stable|\/Positive/g, '').trim()
  }

  useEffect(() => {
    if (!collections?.items?.length) {
      dispatch(getFixerraCollections())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className="align-items-center">
      <Col md="9">
        <h5>Issuers</h5>
        {/* <pre>{JSON.stringify(collections.items, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(issuers.items, null, 2)}</pre> */}
      </Col>
      <Col md="12" className="mt-2">
        <Row>
          {collections.items.map((issuer, i) => (
            <Col
              lg="4"
              className="cursor-pointer"
              onClick={() =>
                history.push(`/investment/goal/fd/schemes/${issuer?.f_code}`)
              }
              // key={operator.id}
              key={i}
            >
              <div className="position-relative overflow-hidden d-flex align-items-center service-card bg-white pl-3 p-3 my-lg-3 my-2">
                <div
                  style={{
                    minWidth: '50px',
                    minHeight: '50px',
                    // borderRadius: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // backgroundColor: 'rgb(159, 211, 223)',
                    fontSize: '1.5em',
                    fontWeight: '500',
                    padding: '10px',
                  }}
                >
                  <img
                    alt={issuer?.f_code}
                    src={getImagePath(issuer?.f_code)}
                    // crossOrigin="anonymous"
                    style={{ width: '100px', height: '30px' }}
                  />{' '}
                  {/* {issuer?.issuer_name.charAt(0)} */}
                </div>
                <div>
                  <div
                    className="ml-3 font-weight-bold overflow-hidden h-1"
                    id={issuer?.issuer_name}
                  >
                    {issuer?.issuer_name}
                  </div>
                </div>
                <img
                  alt={issuer?.f_code}
                  src={fd_certi}
                  crossOrigin="anonymous"
                  className="crisil_image"
                  style={{ width: '70px', height: '65px' }}
                />{' '}
                <p className="crisil_rating">
                  {getCertificate(issuer?.subtag_1)}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default FixerraFD
