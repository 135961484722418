const stepperTheme = {
  light: {
    step: {
      pending: {
        background: '#ededed',
        color: '#000',
      },
      progress: {
        background: '#3c3fed',
        color: '#ffffff',
      },
      completed: {
        background: '#23c275',
        color: '#ffffff',
      },
    },
    content: {
      pending: {
        stepNumber: { color: '#fff' },
        title: { color: '#fff' },
        status: { background: '#f2f2f2', color: '#a1a3a7' },
        description: { color: '#fff' },
      },
      progress: {
        stepNumber: { color: '#fff' },
        title: { color: '#fff' },
        status: { background: '#e7e9fd', color: '#3c3fed' },
        description: { color: '#fff' },
      },
      completed: {
        stepNumber: { color: '#fff' },
        title: { color: '#fff' },
        status: { background: '#e9faf2', color: '#23c275' },
        description: { color: '#fff' },
      },
    },
    progressBar: {
      pending: {
        background: '#ededed',
      },
      progress: {
        background: '#e7e9fd',
        fill: '#3c3fed',
      },
      completed: {
        background: '#e9faf2',
        fill: '#23c275',
      },
    },
  },
  dark: {
    step: {
      pending: {
        background: '#1a1a1a',
        color: '#767676',
      },
      progress: {
        background: '#19b6fe',
        color: '#ffffff',
      },
      completed: {
        background: '#23c275',
        color: '#ffffff',
      },
    },
    content: {
      pending: {
        stepNumber: { color: '#767676' },
        title: { color: '#767676' },
        status: { background: '#1a1a1a', color: '#767676' },
        description: { color: '#767676' },
      },
      progress: {
        stepNumber: { color: '#ece4d9' },
        title: { color: '#ece4d9' },
        status: { background: '#08374c', color: '#19b6fe' },
        description: { color: '#ece4d9' },
      },
      completed: {
        stepNumber: { color: '#ece4d9' },
        title: { color: '#ece4d9' },
        status: { background: '#0b3a23', color: '#23c275' },
        description: { color: '#ece4d9' },
      },
    },
    progressBar: {
      pending: {
        background: '#1a1a1a',
      },
      progress: {
        background: '#08374c',
        fill: '#19b6fe',
      },
      completed: {
        background: '#0b3a23',
        fill: '#23c275',
      },
    },
  },
}

export default stepperTheme
