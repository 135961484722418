import React, { useContext, useEffect, useReducer } from 'react'
import Cookies from 'js-cookie'
import { config } from 'config'

const ClientContext = React.createContext()
const tokenName = config.tokenName

export function useClient() {
  return useContext(ClientContext)
}

const initialState = {
  webp: true,
}

function reducer(state, action) {
  switch (action.type) {
    case 'webp': {
      return {
        ...state,
        webp: action.value,
      }
    }
    default:
      return state
  }
}

export function ClientProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const imgs = (supports) => {
      console.log('webp supports', supports)
      dispatch({
        type: 'webp',
        value: supports,
      })
    }

    function check_webp(callback, feature = 'alpha') {
      var kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
          'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
          'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
      }
      var img = new Image()
      img.onload = function () {
        var result = img.width > 0 && img.height > 0
        callback(result)
      }
      img.onerror = function () {
        callback(false)
      }
      img.src = 'data:image/webp;base64,' + kTestImages[feature]
    }
    check_webp(imgs)
    // axios.get("http://ip-api.com/json")
    // axios.get("https://api.ipify.org?format=json")
    //   .then(res => {
    //     console.log(res.data)
    //   }).catch(error => {
    //     console.log(error)
    //   })
  }, [])

  const preferWebp = (url, fallback = 'png') => {
    return state?.webp ? url + '.webp' : url + '.' + fallback
  }

  const storeToken = (token) => {
    Cookies.set(tokenName, token)
    window.localStorage && localStorage.setItem(tokenName, token)
  }

  const getToken = () => {
    if (Cookies.get(tokenName)) {
      return Cookies.get(tokenName)
    }
    return window.localStorage && localStorage.getItem(tokenName)
  }

  const removeToken = () => {
    Cookies.remove(tokenName)
    window.localStorage && localStorage.removeItem(tokenName)
  }

  // console.log("ClientContext", state)

  return (
    <ClientContext.Provider
      value={{
        ...state,
        dispatch,
        preferWebp,
        getToken,
        storeToken,
        removeToken,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}
