import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { InvalidFeedbackV7 } from 'utils/validation'
import { preventSubmit } from 'utils/validation'
import sipSvg from 'assets/svg/sip.svg'

const WithDrawalForm = (props) => {
  // const [formData, setFormData] = useState({
  //   beneficiaryName: '',
  //   accountNumber: '',
  //   ifscCode: '',
  //   bankName: '',
  // })

  const { register, handleSubmit, formState } = useForm()
  const onError = (errors) => console.log(errors)

  const onSubmit = async (data) => {
    alert('Form Submitted!')
  }
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <img src={sipSvg} alt="" />
              </div>
              <div>
                Short Term Goal
                <div className="page-title-subheading">Yatra</div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col lg="5">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Your Plan details</CardTitle>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Amount Invested
                  </span>{' '}
                  ₹ 40,000
                </div>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Interest
                  </span>
                  ₹ 5000
                </div>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Processing Fee
                  </span>
                  - ₹ 15
                </div>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Amount to be transferred
                  </span>
                  ₹ 44,985
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="5">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Your Bank details Here</CardTitle>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="validate-form was-validate"
                >
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <Label for="canumber" className="required">
                          Beneficiary name
                        </Label>
                        <input
                          type="text"
                          placeholder="Enter Beneficiary Name"
                          id="canumber"
                          name="canumber"
                          className="form-control"
                          {...register('canumber', {
                            required: 'Beneficiary name	 is required',
                          })}
                        />
                        <InvalidFeedbackV7
                          name="canumber"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup className="select-wrapper">
                        <Label for="circle" className="required">
                          Account number
                        </Label>
                        <input
                          type="text"
                          placeholder="Enter Account Number"
                          id="canumber"
                          name="canumber"
                          className="form-control"
                          {...register('canumber', {
                            required: 'Account Number is required',
                          })}
                        />
                        <InvalidFeedbackV7
                          name="canumber"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>{' '}
                    <Col lg="12">
                      <FormGroup className="select-wrapper">
                        <Label for="circle" className="required">
                          IFSC code
                        </Label>
                        <input
                          type="text"
                          placeholder="Enter IFSC code"
                          id="canumber"
                          name="canumber"
                          className="form-control"
                          {...register('canumber', {
                            required: 'IFSC code is required',
                          })}
                        />
                        <InvalidFeedbackV7
                          name="canumber"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup className="select-wrapper">
                        <Label for="circle" className="required">
                          Bank name
                        </Label>
                        <input
                          type="text"
                          placeholder="Enter Bank name"
                          id="canumber"
                          name="canumber"
                          className="form-control"
                          {...register('canumber', {
                            required: 'Bank name	 is required',
                          })}
                        />
                        <InvalidFeedbackV7
                          name="canumber"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Withdraw
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default WithDrawalForm
