import { useState, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { s3GetUrl } from 'config'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip,
} from 'reactstrap'

const CustomToolTip = ({ operator }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        className="ml-3 font-weight-bold overflow-hidden h-1"
        id={operator?.code}
      >
        {operator?.name}
      </div>
      <Tooltip
        placement={operator?.service === 'OTTBUNDLE' ? 'bottom' : 'top'}
        isOpen={isOpen}
        target={operator?.code}
        toggle={(value) => {
          setIsOpen(!isOpen)
        }}
        // className="tooltip-light"
      >
        {operator?.service === 'OTTBUNDLE'
          ? operator?.channels.map((channel, i) => (
              <p style={{ marginBottom: '5px' }} key={i}>
                {channel}
              </p>
            ))
          : operator?.name}
      </Tooltip>
    </>
  )
}

const CustomToolTip2 = ({ operator }) => {
  return (
    <>
      <div
        className="ml-3 font-weight-bold overflow-hidden h-1"
        id={operator?.id}
        title={operator?.name}
      >
        {operator?.name}
      </div>
    </>
  )
}

const OperatorList = ({
  title,
  service,
  category = '',
  url,
  showBill = true,
  endpoint = '',
}) => {
  const [list, setList] = useState([])
  const [currentList, setCurrentListList] = useState([])
  const [total, setTotal] = useState(0)
  const history = useHistory()
  const operators = useSelector((state) => state.services.operators)
  const bbpsOperators = useSelector((state) => state.services.bbpsOperators)

  useEffect(() => {
    const myArray = category
      ? bbpsOperators.filter((operator) => operator.service === category)
      : operators.filter((operator) => operator.service === service)

    const tmpArray = endpoint
      ? myArray.filter((operator) => operator.endpoint === endpoint)
      : myArray
    setList(tmpArray)
    setCurrentListList(tmpArray)
    setTotal(tmpArray.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, service])

  const handleSearch = (e) => {
    const myArray = currentList
    setList(
      myArray.filter(
        (item) => new RegExp(e.target.value, 'i').test(item.name)
        // new RegExp('^.*' + e.target.value + '.*', 'i').test(item.name)
      )
    )
  }

  return (
    <Row className="align-items-center">
      {/* <Col md="12">
        <div>service: {service}</div>
        <div>category: {category}</div>
        <div>endpoint: {endpoint}</div>
        <hr />
        <pre>{JSON.stringify(list, null, ' ')}</pre>
      </Col> */}
      <Col md="9">
        <h5>{title || 'All Providers'}</h5>
      </Col>
      <Col md="3">
        {total > 15 && (
          <FormGroup>
            <InputGroup>
              <input
                type="text"
                className="form-control"
                onChange={handleSearch}
                placeholder="Type to Search..."
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        )}
      </Col>
      <Col md="12" className="mt-2">
        <Row>
          {/* {
            <pre style={{ width: '100%' }}>
              {JSON.stringify(list, null, ' ')}
            </pre>
          } */}
          {category
            ? list
                // .filter(
                //   (operator) =>
                //     String(operator.category).toLowerCase() === category
                // )
                .map((operator, i) => (
                  <Col
                    lg="4"
                    className="cursor-pointer"
                    onClick={() => history.push(`${url}/${operator?.id}`)}
                    // key={operator.id}
                    key={i}
                  >
                    <div className="d-flex service-card align-items-center bg-white pl-3 p-2 my-lg-3 my-2">
                      {operator?.logo ? (
                        <div
                          style={{
                            borderRadius: 50,
                            // background: 'black',
                            width: '50px',
                            height: '50px',
                            display: 'flex',
                          }}
                        >
                          <img
                            src={operator?.logo}
                            alt=""
                            style={{
                              width: '38px',
                              height: '38px',
                              margin: 'auto auto',
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            minWidth: '50px',
                            minHeight: '50px',
                            borderRadius: 50,
                            backgroundColor: operator?.color,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '1.5em',
                            fontWeight: '500',
                          }}
                        >
                          {operator?.name.charAt(0)}
                        </div>
                      )}
                      <div>
                        <CustomToolTip2 operator={operator} />
                        {/* <div
                          className="ml-3 font-weight-bold overflow-hidden h-1"
                          id={operator.code}
                          title={operator.name}
                        >
                          {operator.name}
                        </div> */}

                        {showBill && (
                          <small className="ml-3 text-muted">
                            Bill Fetch: {operator?.bill ? 'Yes' : 'No'}
                          </small>
                        )}
                        {/* <small className="ml-3 text-muted">
                          endpoint: {operator?.endpoint}
                        </small> */}
                      </div>
                    </div>
                  </Col>
                ))
            : list
                // .filter((operator) => operator.service === service)
                .map((operator, i) => (
                  <Col
                    lg="4"
                    className="cursor-pointer"
                    onClick={() => history.push(`${url}/${operator.code}`)}
                    key={i}
                    // key={operator.code}
                  >
                    <div className="d-flex align-items-center bg-white pl-3 p-2 my-lg-3 my-2">
                      {operator.logo ? (
                        <img
                          src={s3GetUrl(operator?.logo)}
                          alt=""
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: 50,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            minWidth: '50px',
                            minHeight: '50px',
                            borderRadius: 50,
                            backgroundColor: '#eee',
                          }}
                        ></div>
                      )}
                      <div>
                        <CustomToolTip operator={operator} />
                        {showBill && (
                          <small className="ml-3 text-muted">
                            Bill Fetch: {operator?.bill ? 'Yes' : 'No'}
                          </small>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
        </Row>
      </Col>
    </Row>
  )
}

export default OperatorList
