import React, { Fragment } from 'react'
import { Route } from 'react-router'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import OperatorList from '../OperatorList'
import FasTagBillingForm from "./FastTagForm"
import usePageTitle from 'hooks/usePageTitle'

const service = 'fastag'

const FasTag = ({ match }) => {
  usePageTitle('Fastag')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Route path={`${match.url}`} exact>
          <OperatorList
            title="All Fastag Providers"
            category={service}
            url={match.url}
            endpoint="fasTag"
          />
        </Route>
      </CSSTransitionGroup>
      <Route path={`${match.url}/:opcode`} exact>
        <FasTagBillingForm service={service} />
      </Route>
    </Fragment>
  )
}

export default FasTag
