import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import { FaRupeeSign } from 'react-icons/fa'

import { useForm } from 'react-hook-form'

import {
  InvalidFeedbackV7,
  // validationStatusSelect,
  preventSubmit,
  validMobileRegex,
} from 'utils/validation'

import PlanDetails from './PlanDetails'
import SelectedOperatorBBPS from '../SelectedOperatorBBPS'
import { useRecharge } from 'backend/useRecharge'

const defaultPlanData = {
  title: '',
  plans: [],
}

const PrepaidForm = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const { fetchMobilePlan } = useRecharge()

  const { bbpsOperators: operators, mobileCircles } = useSelector(
    (state) => state.services
  )
  const [operator, setOperator] = useState()

  const [plansData, setPlansData] = useState(defaultPlanData)

  useEffect(() => {
    setOperator(
      operators.find((op) => op.service === service && op.code === opcode)
    )
  }, [operators, opcode, service])

  const { register, handleSubmit, setValue, trigger, clearErrors, formState } =
    useForm()

  const onSubmit = async (data) => {
    handleFormSubmit(data)
  }

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      operator: operator.code,
      opcode: opcode,
    }
    console.log('payload', payload)
    history.push({
      pathname: `/service/checkout`,
      state: {
        services: [
          {
            amount: data.amount,
            name: operator.name,
            description: `Recharge your ${operator?.name} mobile`,
            quantity: 1,
            service: operator.category,
          },
        ],
        provider: payload,
        meta: {
          bill: operator?.bill,
          operator,
        },
      },
    })
  }

  const selectAmount = (value) => {
    setValue('amount', value, { shouldValidate: true })
  }

  const handleChangeCircle = (option) => {
    if (option.value) {
      clearErrors('circle')
      // setShowPlans(false)
      setPlansData(defaultPlanData)

      const payload = {
        op: operator.name,
        circle: option.value,
      }
      fetchMobilePlan(payload).then((data) => {
        console.log(data)
        setPlansData({
          plans: data.items,
          // title: `${operator.name} Plans`,
          circle: option.value,
        })
      })
    } else {
      setPlansData(defaultPlanData)
      trigger('circle')
      return
    }
  }

  // const handleChangeInput = (name, value) => {
  //   setValue(name, value)
  //   trigger(name)
  //   // setBillDetails()
  // }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Recharge your {operator?.name} mobile</CardTitle>
              {/* <SelectedOperator opcode={opcode} service={service} /> */}
              <SelectedOperatorBBPS
                opcode={opcode}
                category={service}
                // showBillStatus
              />
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  {/* {operator?.fields &&
                    operator?.fields.map((field) => (
                      <Col lg="12" key={field?.name}>
                        <FormGroup>
                          <Label for={field?.name} className="required">
                            {field.label}
                          </Label>
                          <input
                            type="text"
                            placeholder={field.placeholder}
                            id={field?.name}
                            name={field?.name}
                            className="form-control"
                            {...register(field?.name, {
                              required: `${field.label} is required`,
                              pattern: field.regex && {
                                value: new RegExp(field.regex),
                                message: `Please enter valid ${field.label}`,
                              },
                              onChange: (e) =>
                                handleChangeInput(field?.name, e.target.value),
                            })}
                          />
                          <InvalidFeedbackV7
                            name={field?.name}
                            formState={formState}
                          />
                        </FormGroup>
                      </Col>
                    ))} */}
                  <Col lg="12">
                    <FormGroup>
                      <Label for="canumber" className="required">
                        Mobile Number
                      </Label>
                      <input
                        type="text"
                        placeholder="Enter Mobile Number"
                        id="canumber"
                        name="canumber"
                        className="form-control"
                        {...register('canumber', {
                          required: 'Mobile Number is required',
                          pattern: {
                            value: new RegExp(validMobileRegex),
                            message: `Please enter valid mobile number`,
                          },
                        })}
                      />
                      <InvalidFeedbackV7
                        name="canumber"
                        formState={formState}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup className="select-wrapper">
                      <Label for="circle" className="required">
                        Circle
                      </Label>
                      <select
                        id="circle"
                        name="circle"
                        className="form-control custom-select"
                        {...register('circle', {
                          onChange: (e) => handleChangeCircle(e.target),
                        })}
                      >
                        <option value="">Select Circle...</option>
                        {mobileCircles.map((circle, i) => (
                          <option value={circle.value} key={i}>
                            {circle.label}
                          </option>
                        ))}
                      </select>
                      <InvalidFeedbackV7 name="circle" formState={formState} />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <Label for="amount" className="required">
                        Amount
                      </Label>
                      <InputGroup>
                        <input
                          type="text"
                          placeholder="Enter Amount"
                          id="amount"
                          name="amount"
                          min="10"
                          step="1"
                          className="form-control"
                          {...register('amount', {
                            required: 'Amount is required',
                          })}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaRupeeSign />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InvalidFeedbackV7
                          name="amount"
                          formState={formState}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          className="btn btn-primary proceed-btn"
                          block
                          color="primary"
                          disabled={preventSubmit(formState)}
                          // onClick={() => {
                          //   handleSubmit('lumpsum')
                          // }}
                        >
                          Proceed
                        </Button>
                      </div>
                      {/* <Button
                        size="lg"
                        block
                        className="mt-3"
                        color="primary"
                        disabled={preventSubmit(formState)}
                      >
                        Proceed
                      </Button> */}
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          {plansData.plans.length ? (
            <PlanDetails
              data={plansData}
              selectAmount={selectAmount}
              service={service}
            />
          ) : null}
          {/* {
            <pre style={{ width: '100%' }}>
              {JSON.stringify(plansData, null, 2)}
            </pre>
          } */}
        </Col>
      </Row>
    </Fragment>
  )
}

export default PrepaidForm
