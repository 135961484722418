import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

import { DatePicker, Form, Input, Select } from 'antd'
import { useBank } from 'backend/useBank'
import { selectAccount } from 'features/accountSlice'
import { validPANNumberRegex } from 'utils/validation'
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale('en')

const salutation = [
  {
    value: 'Mr.',
    label: 'Mr.',
  },
  {
    value: 'Mrs.',
    label: 'Mrs.',
  },
  {
    value: 'Ms.',
    label: 'Ms.',
  },
]

const NomineeDetailsForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { nominee } = useSelector(selectAccount)
  const { updateNominee } = useBank()
  const [isUnderAge, setIsUnderAge] = useState(false)

  useEffect(() => {
    if (nominee) {
      setIsUnderAge(nominee.isUnderAge)
      // form.setFieldsValue(nominee)
    }
  }, [nominee])

  const onSubmit = (data) => {
    const payload = {
      ...data,
      nomineeDateOfBirth:
        data?.nomineeDateOfBirth && dayjs(data.nomineeDateOfBirth).toISOString(),
      guardianDateOfBirth:
        data?.guardianDateOfBirth && dayjs(data.guardianDateOfBirth).toISOString(),
      isUnderAge,
    }
    console.log(payload)
    updateNominee(payload).then((data) => {
      // console.log(data)
      setTimeout(() => {
        history.push('/account-setup/personal')
      }, 300)
    })
  }

  const checkIfUnderAge = (date) => {
    if (new Date().getFullYear() - dayjs(date).get('year') < 18) {
      setIsUnderAge(true)
    } else {
      setIsUnderAge(false)
    }
  }

  const onFinishFailed = (e) => {
    console.log('onFinishFailed', e)
  }

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <h3>Nominee Details</h3>
          {/* <pre>{JSON.stringify(nominee, null, 2)}</pre> */}
          <Form
            form={form}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="validate-form was-validate"
            initialValues={{
              ...nominee,
              nomineeDateOfBirth:
                nominee?.nomineeDateOfBirth &&
                dayjs(nominee?.nomineeDateOfBirth),
              guardianDateOfBirth:
                nominee?.guardianDateOfBirth &&
                dayjs(nominee?.guardianDateOfBirth),
              isUnderAge,
            }}
          >
            <Row>
              <Col md="4">
                <Form.Item
                  label="Nominee First Name"
                  name="nomineeFirstName"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee First Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Nominee First Name"
                  />
                </Form.Item>
              </Col>
              <Col md="4">
                <Form.Item
                  label="Nominee Middle Name"
                  name="nomineeMiddleName"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee Middle Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Nominee Middle Name"
                  />
                </Form.Item>
              </Col>
              <Col md="4">
                <Form.Item
                  label="Nominee Last Name"
                  name="nomineeLastName"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee Last Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Nominee Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  label="Nominee Pan Number"
                  name="nomineePanNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Pan Number is required',
                    },
                    {
                      pattern: validPANNumberRegex,
                      message: 'Invalid Pan Number',
                    }
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Nominee Pan Number"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Nominee DOB"
                  name="nomineeDateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee date of birth is required',
                    },
                  ]}
                >
                  <DatePicker
                    className="form-control"
                    format={'DD-MM-YYYY'}
                    style={{ width: '100%' }}
                    placeholder="Nominee DOB (DD-MM-YYYY)"
                    // onChange={checkIfUnderAge}
                    onChange={(date, dateString) => {
                      console.log(date.$y, dateString)
                      checkIfUnderAge(date)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  name="nomineeSalutation"
                  label="Nominee Salutation"
                  rules={[
                    {
                      required: true,
                      message: 'Nominee Salutation is required',
                    },
                  ]}
                >
                  <Select
                    options={salutation}
                    placeholder="Nominee Salutation"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Relation With Nominee"
                  name="nomineeRelation"
                  rules={[
                    {
                      required: true,
                      message: 'Relation With Nominee is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Relation With Nominee"
                  />
                </Form.Item>
              </Col>
            </Row>
            {isUnderAge && (
              <div>
                <br />
                <h3>Nominee Guardian Details</h3>
                <Row>
                  <Col md="6">
                    <Form.Item
                      label="Guardian First Name"
                      name="guardianFirstName"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian First Name is required',
                        },
                      ]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Guardian First Name"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col md="6">
                    <Form.Item
                      label="Guardian Middle Name"
                      name="guardianMiddleName"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian Middle Name is required',
                        },
                      ]}
                    >
                      <Input className="form-control" placeholder="Guardian Middle Name" />
                    </Form.Item>
                  </Col> */}
                  <Col md="6">
                    <Form.Item
                      label="Guardian Last Name"
                      name="guardianLastName"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian Last Name is required',
                        },
                      ]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Guardian Last Name"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Item
                      label="Guardian Pan Number"
                      name="guardianPanNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian Pan Number is required',
                        },
                        {
                          pattern: validPANNumberRegex,
                          message: 'Invalid Pan Number',
                        }
                      ]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Guardian Pan Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col md="6">
                    <Form.Item
                      label="Guardian DOB"
                      name="guardianDateOfBirth"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian date of birth is required',
                        },
                      ]}
                    >
                      <DatePicker
                        className="form-control"
                        format={'DD-MM-YYYY'}
                        style={{ width: '100%' }}
                        placeholder="Guardian DOB (DD-MM-YYYY)"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Item
                      name="guardianSalutation"
                      label="Guardian Salutation"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian salutation is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Guardian Salutation"
                        options={salutation}
                      />
                    </Form.Item>
                  </Col>
                  <Col md="6">
                    <Form.Item
                      label="Nominee Relation With Guardian"
                      name="guardianRelation"
                      rules={[
                        {
                          required: true,
                          message: 'Guardian relation with nominee is required',
                        },
                      ]}
                    >
                      <Input
                        className="form-control"
                        placeholder="Nominee Relation With Guardian"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col md="12">
                <small className="text-danger">
                  Fields marked with * are required
                </small>
              </Col>
              <Col md="12" className="d-flex justify-content-end">
                <Form.Item name="isUnderAge">
                  <Input
                    className="form-control"
                    type="hidden"
                    // value={nominee?.isUnderAge}
                  />
                </Form.Item>
                <Form.Item>
                  <button
                    type="submit"
                    className="mt-3 btn btn-primary btn-shadow"
                  >
                    Proceed
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}

export default NomineeDetailsForm
