import React from 'react'
import { Card, CardHeader, Table } from 'reactstrap'

const CustomTable = ({ cols, data, tableName, altText = 'No data found' }) => {
  return (
    <Card className="main-card mb-3">
      <CardHeader className="custom-card-header">
        <div className="custom-table-header">
          <div className="table-title">{tableName}</div>
        </div>
      </CardHeader>
      {data.length ? (
        <Table responsive hover className="align-middle text-truncate mb-3">
          <thead>
            <tr>
              {cols.map((item) => (
                <th
                  className={`${
                    item?.className ? item.className : 'text-center'
                  }`}
                  key={item.key}
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((renderData, i) => (
              <tr key={i}>
                {cols.map((item) => (
                  <td
                    className={`${
                      item?.className ? item.className : 'text-center'
                    }`}
                  >
                    {item.render(renderData, i + 1)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-3 mb-3">
          <p className="no-transaction-msg">{altText}</p>
        </div>
      )}
    </Card>
  )
}

export default CustomTable
