import { useState } from 'react'
import cx from 'classnames'
import { useFormContext } from 'react-hook-form'
import { emailRegex } from './regex'

const Text = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const {
    id = '',
    name = '',
    label = '',
    type = 'text',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    prepend = null,
    append = null,
    rules,
    readOnly = false,
  } = props
  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      {prepend || append ? (
        <div
          className={cx('input-group', className, {
            'is-invalid': errors[name]?.message,
          })}
        >
          {prepend && <div className="input-group-prepend">{prepend}</div>}
          <input
            id={id || name}
            name={name}
            type={type}
            className={cx('form-control', className, {
              'is-invalid': errors[name]?.message,
            })}
            placeholder={placeholder}
            defaultValue={defaultValue}
            readOnly={readOnly}
            {...register(name, { ...rules })}
          />
          {append && <div className="input-group-append">{append}</div>}
        </div>
      ) : (
        <input
          id={id || name}
          name={name}
          type={type}
          className={cx('form-control', className, {
            'is-invalid': errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          readOnly={readOnly}
          {...register(name, { ...rules })}
        />
      )}
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Date = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const {
    id = '',
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    prepend = null,
    append = null,
    rules,
  } = props
  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      {prepend || append ? (
        <div
          className={cx('input-group', className, {
            'is-invalid': errors[name]?.message,
          })}
        >
          {prepend && <div className="input-group-prepend">{prepend}</div>}
          <input
            id={id || name}
            name={name}
            type="date"
            className={cx('form-control', className, {
              'is-invalid': errors[name]?.message,
            })}
            placeholder={placeholder}
            defaultValue={defaultValue}
            {...register(name, { ...rules })}
          />
          {append && <div className="input-group-append">{append}</div>}
        </div>
      ) : (
        <input
          id={id || name}
          name={name}
          type="date"
          className={cx('form-control', className, {
            'is-invalid': errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name, { ...rules })}
        />
      )}
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Hidden = (props) => {
  const { register } = useFormContext() // retrieve all hook methods
  const { name = '', defaultValue = '', ...restParams } = props
  return (
    <input
      name={name}
      type="hidden"
      defaultValue={defaultValue}
      {...restParams}
      {...register(name)}
    />
  )
}

const Number = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const {
    id = '',
    name = '',
    label = '',
    step = 1,
    min = 0,
    max = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    prepend = null,
    append = null,
    rules,
    readOnly = false,
    // icon,
  } = props
  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      {prepend || append ? (
        <div
          className={cx('input-group', className, {
            'is-invalid': errors[name]?.message,
          })}
        >
          {prepend && <div className="input-group-prepend">{prepend}</div>}
          <input
            id={id || name}
            name={name}
            className={cx('form-control', className, {
              'is-invalid': errors[name]?.message,
            })}
            placeholder={placeholder}
            defaultValue={defaultValue}
            step={step}
            min={min}
            max={max}
            type="number"
            readOnly={readOnly}
            {...register(name, { ...rules })}
          />
          {append && <div className="input-group-append">{append}</div>}
        </div>
      ) : (
        <input
          id={id || name}
          name={name}
          className={cx('form-control', className, {
            'is-invalid': errors[name]?.message,
          })}
          placeholder={placeholder}
          defaultValue={defaultValue}
          step={step}
          min={min}
          max={max}
          type="number"
          readOnly={readOnly}
          {...register(name, { ...rules })}
        />
      )}
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Email = (props) => {
  const {
    id = '',
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    readOnly = false,
    rules,
  } = props
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      <input
        id={id || name}
        name={name}
        className={cx('form-control', className, {
          'is-invalid': errors[name]?.message,
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        readOnly={readOnly}
        {...register(name, {
          ...rules,
          pattern: {
            value: emailRegex,
            message: 'Email is not valid',
          },
        })}
      />
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Password = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const [type, setType] = useState('password')

  const togglePassword = () => {
    let newType = type === 'password' ? 'text' : 'password'
    setType(newType)
  }
  const {
    id = '',
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    rules = {},
  } = props
  const { compareWith, ...options } = rules
  // rules.onChange((e) => {console.log('inner', e.target.value)})
  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      <input
        id={id}
        name={name}
        className={cx('form-control', className, {
          'is-invalid': errors[name]?.message,
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, options)}
        type={type}
      />
      {type === 'password' ? (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 576 512"
          className="fa"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={togglePassword}
        >
          <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
        </svg>
      ) : (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 640 512"
          className="fa"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={togglePassword}
        >
          <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"></path>
        </svg>
      )}
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const TextArea = (props) => {
  const {
    id = '',
    name = '',
    label = '',
    required = false,
    placeholder = '',
    defaultValue = '',
    className = '',
    rules,
  } = props
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods

  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      <textarea
        name={name}
        className={cx('form-control', className, {
          'is-invalid': errors[name]?.message,
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...register(name, { ...rules })}
      />
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

const Input = {
  Text,
  Date,
  Hidden,
  Number,
  Email,
  Password,
  TextArea,
}
export default Input
