import React, { Fragment, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import CountUp from 'react-countup'
import { FaRupeeSign } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import ReactSlider from 'react-slider'
import { toast } from 'react-toastify'
import { Button, Col, Row, Table } from 'reactstrap'
import usePageTitle from 'hooks/usePageTitle'
import { formattedPrice } from 'utils/helper'
import { useSelector } from 'react-redux'
import { selectFixerra } from 'features/fixerra/fixerraSlice'

const minTenure = 1
const maximumTenure = 5
const minInvestment = 10000
const maximumInvestment = 500000

const FixerraPlannerForm = () => {
  usePageTitle('Fixed Deposits')

  const location = useLocation()
  const history = useHistory()
  const [selectedIssuer, setSelectedIssuer] = useState({ name: '' })
  const { collections } = useSelector(selectFixerra)

  const selectedScheme = location.state?.scheme

  const [schemeCalculations, setSchemeCalculations] = useState({
    endOfTheFD: 0,
    completionBonus: 0,
    interest: 0,
    brandDiscount: 0,
    duration: 0,
    amountToBeInvest: 0,
  })
  const [goalAmountInput, setGoalAmountInput] = useState(minInvestment)
  const [daysInput, setDaysInput] = useState(minTenure)

  useEffect(() => {
    if (selectedScheme) {
      const duration = minTenure
      const interestRate = +selectedScheme.product_interest
      const amountToBeInvest = +minInvestment ?? 0

      const countInterest = duration

      const interest = ((amountToBeInvest * interestRate) / 100) * countInterest

      const endOfTheFD = amountToBeInvest + interest
      setSchemeCalculations((pre) => ({
        ...pre,
        interest,
        duration,
        amountToBeInvest,
        endOfTheFD,
      }))
      getIssuersData(selectedScheme.f_issuer_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScheme])

  const getIssuersData = (issuerId) => {
    const tmp = collections.items.find((item) => item?.f_issuer_id === issuerId)
    setSelectedIssuer(tmp)
  }

  const onChangeSchemeCalculations = (key, value) => {
    const newSchemeCalculation = { ...schemeCalculations }
    newSchemeCalculation[key] = value ?? 0
    if (key === 'amountToBeInvest') {
      setGoalAmountInput(value)
    }
    if (key === 'duration') {
      setDaysInput(value)
    }
    const duration = newSchemeCalculation.duration
    const interestRate = +selectedScheme.product_interest
    const amountToBeInvest = Number(newSchemeCalculation.amountToBeInvest)

    const countInterest = duration

    const interest = ((amountToBeInvest * interestRate) / 100) * countInterest
    const endOfTheFD = +amountToBeInvest + interest

    newSchemeCalculation.endOfTheFD = endOfTheFD
    newSchemeCalculation.interest = interest

    setSchemeCalculations(newSchemeCalculation)
  }

  const handleChangeAmountInput = (e) => {
    const { value } = e.target
    onChangeSchemeCalculations('amountToBeInvest', value)
  }

  const handleChangeDurationInput = (e) => {
    const { value } = e.target

    onChangeSchemeCalculations('duration', value)
  }

  const onSubmit = () => {
    history.push({
      pathname: `/investment/goal/fd/scheme/checkout`,
      state: {
        scheme: selectedScheme,
        calculation: schemeCalculations,
        duration: daysInput,
      },
    })
  }

  return (
    <Fragment>
      <Row>
        <Col md={4} sm={12} className="rounded-card custom-border p-0">
          <div className="custom-card">
            <div className="chart-container">
              <Doughnut
                data={{
                  labels: [
                    ' You Invest',
                    ' Interest Amount',
                    ' Completion Bonus',
                  ],
                  datasets: [
                    {
                      data: [
                        schemeCalculations.amountToBeInvest,
                        schemeCalculations.interest,
                        schemeCalculations.completionBonus,
                      ],
                      backgroundColor: [
                        'rgba(53, 62, 209, 0.8)',
                        'rgba(12, 191, 188, 0.8)',
                        'rgba(255, 165, 2, 0.8)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <div className="center-text">
                <span>You will get</span>
                <CountUp
                  className="chart-numbers"
                  start={0}
                  end={Math.round(schemeCalculations.endOfTheFD) || 0}
                  prefix="₹"
                  useEasing={true}
                  duration="1"
                />
              </div>
            </div>
          </div>
          <div>
            <Table className="plan-details">
              <tbody>
                <tr>
                  <td>Minimum Tenure</td>
                  <td className="font-weight-bolder">{minTenure ?? '-'}</td>
                </tr>
                <tr>
                  <td>Maximum Tenure</td>
                  <td className="font-weight-bolder">{maximumTenure ?? '-'}</td>
                </tr>
                <tr>
                  <td>Minimum Investment</td>
                  <td className="font-weight-bold">
                    {formattedPrice(minInvestment)}
                  </td>
                </tr>
                <tr>
                  <td>Maximum Investment</td>
                  <td className="font-weight-bold">
                    {formattedPrice(maximumInvestment)}
                  </td>
                </tr>
                <tr>
                  <td>Interest Return</td>
                  <td className="font-weight-bold">
                    {selectedScheme?.product_max_investment ? (
                      <CountUp
                        key={'interest-rate'}
                        start={0}
                        end={selectedScheme?.product_interest || 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix="%"
                        duration="1"
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Interest Frequency</td>
                  <td className="font-weight-bolder">
                    {selectedScheme?.product_interest_freq ?? '-'}
                  </td>
                </tr>
                <tr>
                  <td>Product Rank</td>
                  <td className="font-weight-bolder">
                    {selectedScheme?.product_rank ?? '-'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={8} sm={12}>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the goal amount you would like to spend?
              </p>

              <div className="amount-input-group">
                <FaRupeeSign />
                <input
                  type="number"
                  placeholder="Amount"
                  onChange={handleChangeAmountInput}
                  disabled={!selectedScheme}
                  min={Number(minInvestment)}
                  max={Number(maximumInvestment)}
                  value={goalAmountInput}
                  onBlur={() => {
                    if (
                      goalAmountInput &&
                      goalAmountInput > maximumInvestment
                    ) {
                      toast.error(
                        `Maximum Goal amount should be ${maximumInvestment}`
                      )
                      setGoalAmountInput(maximumInvestment)
                    }
                    if (goalAmountInput && goalAmountInput < minInvestment) {
                      toast.error(
                        `Minimum Goal amount should be ${minInvestment}`
                      )
                      setGoalAmountInput(minInvestment)
                    }
                  }}
                  style={{ width: '60px', minWidth: '2em' }}
                />
              </div>
            </div>
            <ReactSlider
              // step={schemeCalculations?.amountToBeInvest}
              disabled={!selectedScheme}
              defaultValue={Number(minTenure)}
              min={Number(minInvestment)}
              max={Number(maximumInvestment)}
              value={goalAmountInput}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) =>
                onChangeSchemeCalculations('amountToBeInvest', value)
              }
            />
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the duration you would like to invest ?
                <small>
                  <i>In Years</i>
                </small>
              </p>

              <div className="amount-input-group">
                <input
                  type="number"
                  placeholder="Duration"
                  onChange={handleChangeDurationInput}
                  disabled={!selectedScheme}
                  min={Number(minTenure)}
                  max={Number(maximumTenure)}
                  value={daysInput}
                  style={{ width: '60px', minWidth: '2em' }}
                />
              </div>
            </div>
            <ReactSlider
              // step={schemeCalculations?.product_loc_in_period}
              disabled={!selectedScheme}
              defaultValue={0}
              min={Number(minTenure)}
              max={Number(maximumTenure)}
              value={daysInput}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) =>
                onChangeSchemeCalculations('duration', value)
              }
            />
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <Row>
              <Col className="d-flex flex-column justify-content-between">
                <div>You Invest</div>
                <div className="font-weight-bold">
                  <CountUp
                    start={0}
                    end={schemeCalculations.amountToBeInvest}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="₹"
                    useEasing={true}
                    suffix=""
                    duration=".5"
                  />
                </div>
              </Col>
              <Col className="d-flex flex-column justify-content-between">
                <div>Return On Investment</div>
                <div className="font-weight-bold">
                  {schemeCalculations?.interest ? (
                    <CountUp
                      start={0}
                      end={schemeCalculations?.interest}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹"
                      useEasing={true}
                      suffix=""
                      duration=".5"
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </Col>

              <Col className="d-flex flex-column justify-content-between">
                <div>You Get</div>
                <div className="font-weight-bold">
                  {schemeCalculations.endOfTheFD ? (
                    <CountUp
                      start={0}
                      end={schemeCalculations.endOfTheFD}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹"
                      useEasing={true}
                      suffix=""
                      duration=".5"
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </Col>
              <Col className="d-flex flex-column justify-content-between">
                <Button color="primary" className="p-2 px-3" onClick={onSubmit}>
                  Plan Now
                </Button>
              </Col>
            </Row>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <span className="text-danger" style={{ fontSize: '14px' }}>
                  * These are approximate values.
                </span>
              </div>
              <div>
                Your money will be invested with RBI registered{' '}
                {selectedIssuer?.issuer_name}.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default FixerraPlannerForm
