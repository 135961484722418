import { useDispatch } from 'react-redux'
import { nest } from 'backend'
import { errMsg, useNest } from 'backend/useNest'
import { getAugmontFds } from 'features/augmont/augmontSlice'
// import { loadPassbook, loadRates } from 'features/augmont/augmontSlice'

export const useAugmont = () => {
  const { addItem } = useNest()
  const dispatch = useDispatch()

  //   const createAugmontAC = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //           success: 'Account created successfully.',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/user', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const verifyPan = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //           success: 'Pan verified successfully.',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/user/kyc', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const updateBank = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //           success: 'Bank details updated successfully.',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/user/banks', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const fetchStateList = () =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .get('/augmont/states?count=100', null, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const buyGoldOrSilver = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/buy', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const buyGold = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/buy/gold', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const sellGold = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/sell/gold', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const buySilver = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/buy/silver', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const sellSilver = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/sell/silver', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const scheduleSip = (payload = {}) =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .post('/augmont/sip', payload, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const getGoldAndSilverBalance = () =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .get('/augmont/getGoldAndSilverBalance', null, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const getAugmontPassbook = () =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         // msg: {
  //         //   loading: 'Processing...',
  //         // },
  //       }
  //       backend()
  //         .get('/augmont/user/passbook', null, config)
  //         .then((response) => {
  //           dispatch(loadPassbook(response?.result?.data))
  //           return resolve(response)
  //         })
  //     })

  //   const getAugmontKycStatus = () =>
  //     new Promise((resolve) => {
  //       let config = {
  //         loader: true,
  //         msg: {
  //           loading: 'Processing...',
  //         },
  //       }
  //       backend()
  //         .get('/augmont/user/kyc', null, config)
  //         .then((response) => {
  //           return resolve(response)
  //         })
  //     })

  //   const getInvestmentRates = () =>
  //     new Promise((resolve, reject) => {
  //       let config = null // { loader: true, msg: '' }
  //       backend()
  //         .get(`/augmont/rates`, config)
  //         .then((response) => {
  //           const { statusCode, result, message } = response
  //           console.log('response', response)
  //           console.log('message', message)
  //           if (statusCode === 200 && result.data) {
  //             dispatch(loadRates(result)) // it is used to check status of transaction
  //             return resolve(result)
  //           }
  //           reject(message ? message : errMsg)
  //         })
  //     })

  //   const getInvestmentRatesByDate = (date) =>
  //     new Promise((resolve, reject) => {
  //       let config = null // { loader: true, msg: '' }
  //       backend()
  //         .get(`/augmont/rates/by-date/${date}`, config)
  //         .then((response) => {
  //           console.log('response.data', response.data)
  //           return resolve(response.data)
  //         })
  //     })

  const addFund = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating Payment Intent...' },
      }
      addItem('augmont-razorpay/add-fund', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const mandateRegister = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating mandate...' },
      }
      addItem('augmont-razorpay/mandate/register', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const preCloseCalculateFd = (transactionId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      nest
        .get(`/augmont/fd/pre-close-calculate/${transactionId}`, config)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const preCloseOrderFd = (transactionId, payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      nest
        .put(`/augmont/fd/pre-close-order/${transactionId}`, payload, config)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const createOrderFd = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      nest
        .post(`/augmont/fd/createOrder`, payload, config)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getFdDetailsWithTransactions = (transactionId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      nest
        .get(`/augmont/fd/fd-transactions/${transactionId}`, config)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getFdDetailsAugmont = (transactionId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      nest
        .get(`/augmont/fd/fd-transactions-sync/${transactionId}`, config)
        .then((res) => {
          dispatch(getAugmontFds())
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    //     createAugmontAC,
    //     verifyPan,
    //     updateBank,
    //     fetchStateList,
    //     buyGoldOrSilver,
    //     buyGold,
    //     sellGold,
    //     buySilver,
    //     sellSilver,
    //     scheduleSip,
    //     getGoldAndSilverBalance,
    //     getAugmontPassbook,
    //     getAugmontKycStatus,
    //     getInvestmentRates,
    //     getInvestmentRatesByDate,
    addFund,
    mandateRegister,
    preCloseCalculateFd,
    preCloseOrderFd,
    createOrderFd,
    getFdDetailsWithTransactions,
    getFdDetailsAugmont,
  }
}
