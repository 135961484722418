import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import {
  Row,
  Col,
  Card,
  // CardBody,
  CardHeader,
} from 'reactstrap'
import CountUp from 'react-countup'

import StatementTable from 'Components/Dashboard/StatementTable'
import showLoader from 'Components/Loader'

import { useVirtualAccount } from 'backend/useVirtualAccount'

import {
  getAugmontFds,
  getFDSchemas,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { useSocket } from 'context/SocketContext'
import { selectAuth } from 'features/auth/authSlice'
import { useInvestment } from 'backend/useInvestment'
import { fulfilled, idle, loading } from 'features/status'
import { selectAccount } from 'features/accountSlice'
import { useLendBox } from 'backend/useLendBox'

import GoalsTableLongTerm from 'Components/Dashboard/GoalsTableLongTerm'
import GoalsTableShortTerm from 'Components/Dashboard/GoalsTableShortTerm'
import usePageTitle from 'hooks/usePageTitle'
import FDTable from 'Components/Dashboard/FDTable'
import InvestedBalanceIcon from '../../assets/svg/investment.svg'
import GoldSilverBalanceIcon from '../../assets/svg/gold_and_silver.svg'
// import WalletBalanceIcon from '../../assets/svg/wallet.svg'
import {
  getFixerraCollections,
  getFixerraMyFds,
  getFixerraPortfolio,
  // getIssuers,
  getSchemesByIssuer,
  getUpcomingMaturityFd,
  selectFixerra,
} from 'features/fixerra/fixerraSlice'
import FixerraFDTable from 'Components/Dashboard/FixerraFDTable'
import VirtualAccountIcon from '../../assets/svg/virtual_account.svg'
import FdIcon from '../../assets/FD-logos/money.png'

const Dashboard = () => {
  const { isReady, socket } = useSocket()
  // const history = useHistory()
  // const [url, setUrl] = useState(null)
  // const date = new Date()
  // const firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth() - 1)
  // const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)

  const dispatch = useDispatch()

  const {
    virtualAccount,
    fixerraAccount,
    refreshStatement,
    refreshBalance,
    statements,
    lendBoxPortfolio,
    goals,
  } = useSelector(selectAccount)

  const { user } = useSelector(selectAuth)
  const { lendBoxAccount } = useSelector(selectAccount)
  const { augmontUser, passbook, rates, augmontFDs } =
    useSelector(selectAugmont)
  const {
    collections,
    upcomingMaturityFd,
    portfolio: { data: fixerraPortfolio },
  } = useSelector(selectFixerra)
  const { myInvestment } = useInvestment()
  const { getPortfolio } = useLendBox()

  const { getBalance, getStatement } = useVirtualAccount()
  // const [buySellRateAsPerTab, setBuySellRateAsPerTab] = useState(0)
  // const [currentGoldBuyRate, setCurrentGoldBuyRate] = useState(0)
  // const [currentGoldBuyGST, setCurrentGoldBuyGST] = useState(0)
  // const [currentGoldSellRate, setCurrentGoldSellRate] = useState(0)
  // const [currentSilverBuyRate, setCurrentSilverBuyRate] = useState(0)
  // const [currentSilverBuyGST, setCurrentSilverBuyGST] = useState(0)
  // const [currentSilverSellRate, setCurrentSilverSellRate] = useState(0)
  // const [activeCat, setActiveCat] = useState('buy')
  // const [augmontKycStatus, setAugmontKycStatus] = useState()

  const [initialStatus, setInitialStatus] = useState(1)
  const [goalsType, setGoalsType] = useState('Long Term')
  const [transactionStatus, setTransactionStatus] = useState(0)
  const [transactions, setTransactions] = useState({
    all: [],
    // debited: [],
    // credited: [],
    // pending: [],
    // failed: []
  })
  const [filterFd, setFilterFd] = useState({
    fixerra_fd: true,
    gold_fd: false,
  })

  const [filterGoal, setFilterGoal] = useState({
    long_term: true,
    short_term: false,
  })

  const { onSetPageTitle } = usePageTitle()

  useEffect(() => {
    onSetPageTitle('Dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async () => {
    const uniqueCodes = new Set() // Create a Set for unique f_code values

    for (const code of collections.items) {
      if (!uniqueCodes.has(code)) {
        // Check if code exists in the set
        uniqueCodes.add(code) // Add it if not present

        try {
          dispatch(getSchemesByIssuer(code?.f_code))
        } catch (error) {
          console.error('Error fetching schemes for f_code:', code, error)
        }
      }
    }
  }

  useEffect(() => {
    if (fixerraAccount?.id) {
      dispatch(getFixerraMyFds())
      dispatch(getFixerraPortfolio())

      if (upcomingMaturityFd.state === idle) {
        dispatch(getUpcomingMaturityFd())
      }
      if (collections.state === idle) {
        dispatch(getFixerraCollections())
      }
      if (collections?.items && collections?.state === fulfilled) {
        if (collections?.items?.length > 0) {
          fetchData()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixerraAccount, collections])

  // useEffect(() => {
  //   dispatch(getIssuers())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (virtualAccount?.id) {
      getBalance()
    }
    // eslint-disable-next-line
  }, [virtualAccount?.id, refreshBalance])

  useEffect(() => {
    if (augmontUser?.id) {
      if (augmontFDs.status === idle) {
        dispatch(getAugmontFds())
      }
      dispatch(getFDSchemas())
    }
    // eslint-disable-next-line
  }, [augmontUser?.id])

  useEffect(() => {
    if (lendBoxAccount?.id) {
      getPortfolio()
    }
    // eslint-disable-next-line
  }, [lendBoxAccount?.id, refreshBalance])

  useEffect(() => {
    console.log('socket', socket)
    if (isReady) {
      socket.on('orderStatus', (data) => {
        console.log('orderStatus', data)
        if (user.id === data.userId) {
          loadAndSetTransactions()
        }
      })
    }
    return () => {
      socket?.off('orderStatus')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  const loadAndSetTransactions = async () => {
    if (refreshStatement || (statements && !statements.statement)) {
      getStatement({
        limit: 10,
      }).then((data) => {
        console.log(data.statements)
        if (data?.statements?.statement?.length > 0) {
          setInitialStatus(0)
          setTransactionStatus(1)
        }
      })
    }
  }

  useEffect(() => {
    loadAndSetTransactions()
    // eslint-disable-next-line
  }, [virtualAccount, refreshStatement])

  useEffect(() => {
    if (goals.status === loading) {
      myInvestment()
    }
    // eslint-disable-next-line
  }, [goals])

  useEffect(() => {
    if (statements.statement && statements.statement.length > 0) {
      // setInitialStatus(0)
      setTransactionStatus(1)
      setTransactions({
        all: statements.statement,
        // debited: statements.statement.filter(statement => statement.withdrawal_amount > 0 && statement.money_transfer_status === 'SUCCESS'),
        // credited: statements.statement.filter(statement => statement.deposit_amount > 0),
        // pending: statements.statement.filter(statement => statement.money_transfer_status === 'PENDING'),
        // failed: statements.statement.filter(statement => statement.money_transfer_status === 'FAILURE'),
      })
    }
    showLoader(false)
  }, [statements, virtualAccount, refreshStatement])

  // const {
  //   data: myFDs,
  //   fetchData,
  //   loading: fdLoading,
  // } = useFetch(`/augmont/fd/orderList`)

  const portfolio = lendBoxPortfolio?.reduce((pre, curr) => {
    pre.currentInvestment =
      curr.currentInvestment + (pre?.currentInvestment ?? 0)
    pre.totalInterestEarning =
      curr.totalInterestEarning + (pre?.totalInterestEarning ?? 0)
    return pre
  }, {})

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row>
          <Col>
            <div className="mb-4">
              <Row>
                <Col md="3">
                  <Card className="widget-chart text-left">
                    <div className="widget-chart-content">
                      <div className="widget-upper-container">
                        <div className="widget-left-part">
                          <div className="widget-subheading">
                            Invested Balance
                          </div>
                          <div className="widget-numbers">
                            {/* <FaRupeeSign /> */}
                            <span>
                              {lendBoxPortfolio.length > 0 ? (
                                <CountUp
                                  start={0}
                                  end={portfolio?.currentInvestment}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <img
                          alt=""
                          className="widget-img"
                          src={InvestedBalanceIcon}
                        />
                      </div>
                      <div className="widget-description opacity-8 text-focus">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>Interest Earned</div>
                            <b className="bg-soft-success badge text-success">
                              <CountUp
                                start={0}
                                end={portfolio?.totalInterestEarning}
                                separator=","
                                decimals={2}
                                decimal="."
                                prefix="₹ "
                                useEasing={true}
                                suffix=""
                                duration="1"
                              />
                            </b>
                          </div>
                          <div>
                            {/* <div>Available to Withdraw</div>
                            <b>
                              <CountUp
                                start={0}
                                end={
                                  lendBoxPortfolio[0]?.availableToWithdraw
                                }
                                separator=","
                                decimals={2}
                                decimal="."
                                prefix="₹"
                                useEasing={true}
                                suffix=""
                                duration="1"
                              />
                            </b> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="widget-chart text-left">
                    <div className="widget-chart-content">
                      <div className="widget-upper-container">
                        <div className="widget-left-part">
                          <div className="widget-subheading">
                            Gold/Silver Balance
                          </div>
                          <div className="widget-numbers">
                            {/* <FaRupeeSign /> */}
                            <span>
                              {rates?.rates.gBuy ? (
                                <CountUp
                                  start={0}
                                  end={
                                    passbook.goldGrms * rates?.rates?.gBuy +
                                    passbook.silverGrms * rates?.rates?.sBuy
                                  }
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <img
                          alt=""
                          className="widget-img"
                          src={GoldSilverBalanceIcon}
                        />
                      </div>
                      <div className="widget-description opacity-8 text-focus">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>Gold (in grams)</div>
                            <b>
                              <CountUp
                                start={0}
                                end={Number(passbook.goldGrms)}
                                separator=","
                                decimals={4}
                                decimal="."
                                prefix=""
                                useEasing={true}
                                suffix=" g"
                                duration="1"
                              />
                            </b>
                          </div>
                          <div>
                            <div>Silver (in grams)</div>
                            <b>
                              <CountUp
                                start={0}
                                end={Number(passbook.silverGrms)}
                                separator=","
                                decimals={4}
                                decimal="."
                                prefix=""
                                useEasing={true}
                                suffix=" g"
                                duration="1"
                              />
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                {/* <Col md="3">
                  <Card className="widget-chart text-left">
                    <div className="widget-chart-content">
                      <div className="widget-upper-container">
                        <div className="widget-left-part">
                          <div className="widget-subheading">
                            Wallet Balance
                          </div>
                          <div
                            className={cx(
                              'widget-numbers',
                              virtualAccount.walletBalance === 0 && 'opacity-7'
                            )}
                          >
                            <span>
                              {virtualAccount.walletBalance > 0 ? (
                                <CountUp
                                  start={0}
                                  end={virtualAccount.walletBalance}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <img
                          alt=""
                          className="widget-img"
                          src={WalletBalanceIcon}
                        />
                      </div>
                      <div className="widget-description opacity-8 text-focus">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>&nbsp; </div>
                            <Link
                              to="/account/transactions"
                              className="d-inline"
                            >
                              <span className="text-primary pl-1">
                                View Statement
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col> */}
                <Col md="3">
                  <Card className="widget-chart text-left">
                    <div className="widget-chart-content">
                      <div className="widget-upper-container">
                        <div className="widget-left-part">
                          <div className="widget-subheading">
                            Virtual Account Balance
                          </div>
                          <div
                            className={cx(
                              'widget-numbers',
                              virtualAccount.presentBalance === 0 && 'opacity-7'
                            )}
                          >
                            <span>
                              {virtualAccount.presentBalance > 0 ? (
                                <CountUp
                                  start={0}
                                  end={virtualAccount.presentBalance}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                  // redraw
                                  // formattingFn={(value) => formattedPrice(value, 'INR', 'en-IN')}
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <img
                          alt=""
                          className="widget-img"
                          src={VirtualAccountIcon}
                        />
                      </div>
                      <div className="widget-description opacity-8 text-focus">
                        {virtualAccount.walletBalance > 0 ? (
                          <div className="d-flex justify-content-between">
                            <div>
                              <div>&nbsp; </div>
                              <Link to="/voucher/brands" className="d-inline">
                                <span className="text-primary pl-1">
                                  Buy Coupon
                                </span>
                              </Link>
                            </div>
                            <div>
                              <div>&nbsp; </div>
                              <Link
                                to="/account/withdraw"
                                // to="/investment/goal/withdraw"
                                className="d-inline"
                              >
                                <span className="text-primary pl-1">
                                  Withdraw
                                </span>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between">
                            <div>
                              <div>&nbsp; </div>
                              <span className="text-primary pl-1">
                                Start Invest
                              </span>
                            </div>
                            <div>
                              <div>&nbsp; </div>
                              <Link
                                to="/account/withdraw"
                                // to="/investment/goal/withdraw"
                                className="d-inline"
                              >
                                <span className="text-info pl-1">Withdraw</span>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col md="3">
                  <Card className="widget-chart text-left">
                    <div className="widget-chart-content">
                      <div className="widget-upper-container">
                        <div className="widget-left-part">
                          <div className="widget-subheading">FD Details</div>
                          <div className="widget-numbers">
                            {/* <FaRupeeSign /> */}
                            <span>
                              {fixerraPortfolio.total_investment_amount > 0 ? (
                                <CountUp
                                  start={0}
                                  end={
                                    fixerraPortfolio?.total_investment_amount
                                  }
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <img alt="" className="widget-img" src={FdIcon} />
                      </div>
                      <div className="widget-description opacity-8 text-focus">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>Interest Earned</div>
                            <b className="bg-soft-success badge text-success">
                              {fixerraPortfolio?.total_interest_amount > 0 ? (
                                <CountUp
                                  start={0}
                                  end={fixerraPortfolio?.total_interest_amount}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={2}
                                  decimal="."
                                  prefix="₹ "
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </b>
                          </div>
                          <div>
                            <div>Total FDs</div>
                            <b>
                              {fixerraPortfolio?.total_fd > 0 ? (
                                <CountUp
                                  start={0}
                                  end={fixerraPortfolio?.total_fd}
                                  separator=","
                                  decimals={0}
                                  decimal="."
                                  prefix=""
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              ) : (
                                <CountUp
                                  start={0}
                                  end={0}
                                  separator=","
                                  decimals={0}
                                  decimal="."
                                  prefix=""
                                  useEasing={true}
                                  suffix=""
                                  duration="1"
                                />
                              )}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Card className="main-card mb-3">
          <CardHeader className="custom-card-header">
            <div className="custom-table-header">
              <div className="table-title mr-5 align-content-center">
                Goals - {goalsType} {goalsType && ' Goal'}
              </div>
              <div className="d-flex custom-tab form-item-control ml-2 max-w-350">
                <label
                  className={`custom-tab-item metal-radio-item py-1 ${
                    filterGoal.long_term === true
                      ? 'custom-tab-item-active'
                      : ''
                  }`}
                >
                  Long
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={filterGoal.long_term === true}
                    value={filterGoal.long_term === true}
                    onClick={() => {
                      setFilterGoal((prev) => ({
                        ...prev,
                        long_term: true,
                        short_term: false,
                      }))
                      setGoalsType('Long Term')
                    }}
                  />
                </label>
                <label
                  className={`custom-tab-item metal-radio-item py-1 ${
                    filterGoal.short_term === true
                      ? 'custom-tab-item-active'
                      : ''
                  }`}
                >
                  Short
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={filterGoal.short_term === true}
                    value={filterGoal.short_term === true}
                    onClick={() => {
                      setFilterGoal((prev) => ({
                        ...prev,
                        short_term: true,
                        long_term: false,
                      }))
                      setGoalsType('Short Term')
                    }}
                  />
                </label>
              </div>
            </div>
          </CardHeader>
          {goalsType === 'Long Term' ? (
            <GoalsTableLongTerm
              goalsType="long"
              data={goals?.items?.filter((goal) => goal.term === 'long')}
              currency={statements.currency}
              description={true}
              initialStatus={initialStatus}
              transactionStatus={transactionStatus}
              limit={5}
            />
          ) : goalsType === 'Short Term' ? (
            <GoalsTableShortTerm
              goalsType="short"
              data={goals?.items?.filter((goal) => goal.term === 'short')}
              currency={statements.currency}
              description={true}
              initialStatus={initialStatus}
              transactionStatus={transactionStatus}
              limit={5}
            />
          ) : (
            ''
          )}
        </Card>
        <Card className="main-card mb-3">
          <CardHeader className="custom-card-header">
            <div className="custom-table-header">
              <div className="table-title mr-5 align-content-center">
                {filterFd.gold_fd && 'Gold '} FD
              </div>
              <div className="d-flex custom-tab form-item-control ml-2 max-w-350">
                <label
                  className={`custom-tab-item metal-radio-item py-1 ${
                    filterFd.fixerra_fd === true ? 'custom-tab-item-active' : ''
                  }`}
                >
                  FD
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={filterFd.fixerra_fd === true}
                    value={filterFd.fixerra_fd === true}
                    onClick={() => {
                      setFilterFd((prev) => ({
                        ...prev,
                        fixerra_fd: true,
                        gold_fd: false,
                      }))
                      // setGoalsType('Fixerra FD')
                    }}
                  />
                </label>
                <label
                  className={`custom-tab-item metal-radio-item py-1 ${
                    filterFd.gold_fd === true ? 'custom-tab-item-active' : ''
                  }`}
                >
                  Gold FD
                  <input
                    type="radio"
                    className="d-none"
                    name="investmentType"
                    checked={filterFd.gold_fd === true}
                    value={filterFd.gold_fd === true}
                    onClick={() => {
                      setFilterFd((prev) => ({
                        ...prev,
                        gold_fd: true,
                        fixerra_fd: false,
                      }))
                      // setGoalsType('FD')
                    }}
                  />
                </label>
              </div>
            </div>
          </CardHeader>
          {filterFd?.fixerra_fd ? (
            <FixerraFDTable limit={5} />
          ) : filterFd?.gold_fd ? (
            <FDTable
              data={augmontFDs.items}
              transactionStatus={augmontFDs.status === loading ? true : false}
              needModal={true}
              limit={5}
            />
          ) : (
            ''
          )}
        </Card>

        <Card className="main-card">
          <CardHeader className="custom-card-header">
            <div className="custom-table-header">
              <div className="table-title">Transactions</div>
            </div>
          </CardHeader>
          <StatementTable
            data={transactions.all}
            currency={statements.currency}
            description={true}
            initialStatus={initialStatus}
            transactionStatus={transactionStatus}
            needModal={true}
            limit={5}
          />
          <Link
            to="/account/transactions"
            className="mx-auto mb-3 text-center bg-soft-primary badge"
          >
            Load more...
          </Link>
          {/* <CardFooter className="d-block p-4 text-center">
            <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
            <span className="mr-2 opacity-7">
            <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
            </span>
            <span className="mr-1">View Complete Report</span>
            </Button>
          </CardFooter> */}
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Dashboard
