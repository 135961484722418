import useFetch from 'backend/useFetch'
import usePageTitle from 'hooks/usePageTitle'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import CountUp from 'react-countup'
import ReactSlider from 'react-slider'
import { Button, Col, Row, Table } from 'reactstrap'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { FaRupeeSign } from 'react-icons/fa'
import dayjs from 'dayjs'
import { nest } from 'backend'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAugmont } from 'features/augmont/augmontSlice'
import { toast } from 'react-toastify'

ChartJS.register(ArcElement, Tooltip)

const GoalFDPlannerForm = ({ match }) => {
  usePageTitle('Goal FD')

  const history = useHistory()

  const { passbook } = useSelector(selectAugmont)

  const maxQuantity = Number(passbook.goldGrms ?? 1)

  const [preCalculationResult, setPreCalculationResult] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [loading, setLoading] = useState(false)

  const onClickPreCalculation = useCallback(
    async (quantityForCal) => {
      setLoading(true)
      try {
        if (match?.params?.goalFDId && quantityForCal <= maxQuantity) {
          setQuantity(quantityForCal)

          const response = await nest.post(`/augmont/fd/calculations`, {
            fdSchemeId: match?.params?.goalFDId,
            quantity: quantityForCal,
          })

          if (response?.result?.data)
            setPreCalculationResult(response.result.data)
        }
      } catch (error) {
        history.push('/investment/goal/long')
      } finally {
        setLoading(false)
      }
    },
    [match?.params?.goalFDId, history, maxQuantity]
  )

  const { data: goalSchemes } = useFetch(`/augmont/fd/schemes`)

  const goalScheme = goalSchemes?.find(
    (item) => item.id === match?.params?.goalFDId
  )

  useEffect(() => {
    if (goalScheme?.minQtyLease) {
      if (goalScheme?.minQtyLease > maxQuantity) {
        history.push('/investment/goal/long')
        toast.error('Insufficient gold amount')
      } else onClickPreCalculation(Math.round(goalScheme.minQtyLease || 1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalScheme])

  return (
    <Fragment>
      <Row className="">
        <Col md={4} sm={12}>
          <div className="custom-card">
            <div className="chart-container">
              <Doughnut
                data={{
                  labels: [' You Invest', ' Return On Investment'],
                  datasets: [
                    {
                      data: [
                        quantity,
                        preCalculationResult?.endOfLease - quantity,
                      ],
                      backgroundColor: [
                        'rgba(53,62,209, 0.8)',
                        'rgba(12,191,188, 0.8)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <div className="center-text">
                <span>You will get</span>
                <CountUp
                  className="chart-numbers"
                  start={0}
                  end={preCalculationResult?.endOfLease ?? 0}
                  prefix=""
                  useEasing={true}
                  duration="1"
                />
              </div>
            </div>
          </div>
          <div>
            <Table className="plan-details">
              <tbody>
                <tr>
                  <td>Jeweller Name</td>
                  <td className="font-weight-bolder">
                    {goalScheme?.jewellerUserAccountName}
                  </td>
                </tr>
                <tr>
                  <td>Interest Rate:</td>
                  <td className="font-weight-bolder">
                    {goalScheme?.interestRate} %
                  </td>
                </tr>
                <tr>
                  <td>Start Date:</td>
                  <td className="font-weight-bolder">
                    {dayjs(goalScheme?.startDate).format('DD-MM-YYYY') || 'NA'}
                  </td>
                </tr>
                <tr>
                  <td>End Date:</td>
                  <td className="font-weight-bolder">
                    {dayjs(goalScheme?.endDate).format('DD-MM-YYYY') || 'NA'}
                  </td>
                </tr>
                <tr>
                  <td>Minimum Quantity:</td>
                  <td className="font-weight-bolder">
                    {Math.round(goalScheme?.minQtyLease ?? 0)} GMs
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col md={8} sm={12}>
          <div className="content-cards p-3 mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the goal amount you would like to spend?
              </p>
              <div>
                <div className="amount-input-group">
                  <FaRupeeSign />
                  <input
                    type="number"
                    placeholder="Amount"
                    onChange={(e) => onClickPreCalculation(e.target.value || 1)}
                    min={goalScheme?.minQtyLease ?? 1}
                    max={maxQuantity}
                    value={quantity}
                    disabled={loading}
                    style={{ width: '60px', minWidth: '2em' }}
                  />
                </div>
              </div>
            </div>
            <ReactSlider
              step={0.1}
              min={goalScheme?.minQtyLease ?? 1}
              max={maxQuantity}
              disabled={loading}
              value={quantity}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) => onClickPreCalculation(value)}
            />
          </div>
          {quantity > 0 && (
            <div className="content-cards p-3 mb-2">
              <Row>
                <Col className="d-flex flex-column justify-content-between">
                  <div>You Invest</div>
                  <div className="font-weight-bold">
                    <CountUp
                      start={0}
                      end={quantity}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹"
                      useEasing={true}
                      suffix=""
                      duration=".5"
                    />
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <div>Return On Investment</div>
                  <div className="font-weight-bold">
                    <CountUp
                      start={0}
                      end={goalScheme?.interestRate ?? 0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix=" GMs"
                      duration=".5"
                    />
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <div>Number of Days</div>
                  <div className="font-weight-bold">
                    <CountUp
                      start={0}
                      end={preCalculationResult?.noOfDays ?? 0}
                      separator=","
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix=""
                      duration=".5"
                    />
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <div>You Get</div>
                  <div className="font-weight-bold">
                    {preCalculationResult?.endOfLease ? (
                      <CountUp
                        start={0}
                        end={preCalculationResult.endOfLease ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=" GMs"
                        duration=".5"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <Button
                    color="primary"
                    disabled={!preCalculationResult}
                    className="p-2 px-3"
                    onClick={() =>
                      history.push(
                        `/investment/goal/long/fd/${goalScheme.id}/${quantity}`
                      )
                    }
                  >
                    Plan Now
                  </Button>
                </Col>
              </Row>
              <div
                className="d-flex mt-2"
                style={{ justifyContent: 'space-between' }}
              >
                <div>
                  <span className="text-danger" style={{ fontSize: '14px' }}>
                    * These are approximate values.
                  </span>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default GoalFDPlannerForm
