import cx from 'classnames'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const RadioGroup = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // retrieve all hook methods
  const {
    id = '',
    name = '',
    label = '',
    required = false,
    defaultValue = '',
    className = '',
    rules,
    options = [],
  } = props

  return (
    <div className="form-group">
      <label htmlFor={id || name} className={cx({ required })}>
        {label}
      </label>
      <div className="form-item-control pt-1 d-flex">
        {options.map((option) => (
          <div
            className={cx('custom-control form-check-inline', {
              'is-invalid': errors[name]?.message,
            })}
            key={option.label + option.value}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              className={cx('custom-control-input', className, {
                'is-invalid': errors[name]?.message,
              })}
              defaultChecked={option.value === defaultValue}
              id={option.label + option.value}
              {...register(name, { ...rules })}
            />
            <label
              htmlFor={option.label + option.value}
              className="custom-control-label"
            >
              {option.label}
            </label>
          </div>
        ))}
        {errors[name]?.message && (
          <div className="invalid-feedback">{errors[name]?.message}</div>
        )}
      </div>
    </div>
  )
}

export default RadioGroup
