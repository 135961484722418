import React, { Fragment } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// import { setTitle } from 'helper'

import { passwordLength } from 'utils/validation'

import pattern from 'assets/utils/images/sidebar/pattern-img_dark.png'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'

// import bg1 from "assets/utils/images/originals/city.jpg"
// import bg2 from "assets/utils/images/originals/citydark.jpg"
// import bg3 from "assets/utils/images/originals/citynights.jpg"

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
  password: yup
    .string()
    .required('Password is required')
    .min(
      passwordLength,
      `Password should at least ${passwordLength} character`
    ),
})

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
}

const Login = () => {
  const { login } = useAuth()
  const location = useLocation()
  const history = useHistory()

  const onSubmit = (data) => {
    let { from } = location.state || { from: { pathname: '/' } }
    login(data.email, data.password, true).then((user) => {
      if (!user.panVerified) {
        history.push('/auth/pan')
      } else if (!user.aadhaarVerified) {
        history.push('/auth/aadhaar')
      } else {
        setTimeout(() => {
          history.push(from)
        }, 300)
      }
    })
  }

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg opacity-10"
                      style={{
                        backgroundImage: 'url(' + pattern + ')',
                      }}
                    />
                  </div>
                  {/* <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Perfect Balance</h3>
                    <p>
                      ArchitectUI is like a dream. Some think it's too good to
                      be true! Extensive collection of unified React Bootstrap
                      Components and Elements.
                    </p>
                  </div>
                </div> */}
                  {/* <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Scalable, Modular, Consistent</h3>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across
                      all elements and components
                    </p>
                  </div>
                </div> */}
                  {/* <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Complex, but lightweight</h3>
                    <p>
                      We've included a lot of components that cover almost all
                      use cases for any type of application.
                    </p>
                  </div> */}
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="10" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4 className="mb-0">
                <div>Welcome back,</div>
                <span>Please sign in to your account.</span>
              </h4>
              <h6 className="mt-3">
                No account?{' '}
                <Link to="/auth/signup" className="text-primary">
                  Sign up now
                </Link>
              </h6>
              <Row className="divider" />
              <div>
                <Form
                  onFinish={onSubmit}
                  className="login100-form validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onTouched" // onBlur
                >
                  <Row form>
                    <Col md={6}>
                      <Input.Email
                        label="Email"
                        name="email"
                        placeholder="Enter email"
                        required
                        // rules={{
                        //   required: 'Email is required',
                        // }}
                        autoFocus
                      />
                    </Col>
                    <Col md={6}>
                      <Input.Password
                        label="Password"
                        name="password"
                        placeholder="Enter password"
                        required
                        // rules={{
                        //   required: 'Password is required',
                        //   maxLength: {
                        //     passwordLength,
                        //     message: `Password should at least ${passwordLength} character`,
                        //   },
                        // }}
                      />
                    </Col>
                  </Row>
                  {/* <FormGroup check>
                    <Input type="checkbox" name="check" id="exampleCheck" />
                    <Label for="exampleCheck" check>
                      Keep me logged in
                    </Label>
                  </FormGroup> */}
                  <Row className="divider" />
                  <div className="d-flex align-items-center justify-content-end ml-auto">
                    <Link
                      to="/auth/password-forgot"
                      className="btn-lg btn bg-soft-primary custom-label text-primary mr-2"
                    >
                      Recover Password
                    </Link>

                    <Submit className="btn custom-btn-animation h-37">
                      Login to Dashboard
                    </Submit>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default Login
