import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
// import { useHistory } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  // CardFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  InvalidFeedbackV7,
  passwordLength,
  mediumRegex,
  preventSubmit,
} from 'utils/validation'

// import { ValidatePinModal } from 'Components/PinModals'
import PasswordEye from 'Components/PasswordInput'
import { useAuth } from 'backend/useAuth'
import usePageTitle from 'hooks/usePageTitle'

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Password is required')
    .min(
      passwordLength,
      `Password should at least ${passwordLength} character`
    ),
  newPassword: yup
    .string()
    .required('New Password is required')
    .matches(
      mediumRegex,
      'Password should one lowercase one uppercase one number and one special character'
    )
    .min(
      passwordLength,
      `Password should at least ${passwordLength} character`
    ),
  newRetypedPassword: yup
    .string()
    .required('Retype Password is required')
    .test(
      'match',
      'Password and retype Password does not match',
      (value) => value === document.querySelector('#newPassword').value
    ),
})

const ChangePass = () => {
  const { updatePassword } = useAuth()
  usePageTitle('Password Manager')
  // const [formData, setFormData] = useState('')
  // const [pinModal, setPinModal] = useState(false)

  // const togglePinModal = () => {
  //   setPinModal(!pinModal)
  // }

  const onSubmit = (data) => {
    updatePassword(
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      },
      { logout: true }
    )
    // setFormData(data)
    // setPinModal(true)
  }

  // const validatePin = (validated) => {
  //   if (validated) {
  //     // setState({ pinValidated: validated })
  //     setPinModal(false)
  //     // toast.success('Pin Verified')
  //     handleFormSubmit(formData)
  //   } else {
  //     handleFormSubmit(formData)
  //   }
  // }

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
  })

  // const handleFormSubmit = (data) => {
  //   updatePassword({
  //     oldPassword: data.oldPassword,
  //     newPassword: data.newPassword,
  //   })
  // }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row>
          <Col lg={6}>
            <Card className="main-card">
              <CardBody>
                <CardTitle>Change Password</CardTitle>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="validate-form was-validate"
                >
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label for="oldPassword" className="required">
                              Old Password
                            </Label>
                            <input
                              type="password"
                              placeholder="Enter Old Password"
                              id="oldPassword"
                              name="oldPassword"
                              autoComplete="current-password"
                              {...register('oldPassword')}
                              className="form-control"
                            />
                            <PasswordEye target="oldPassword" />
                            <InvalidFeedbackV7
                              name="oldPassword"
                              formState={formState}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="newPassword" className="required">
                              New Password
                            </Label>
                            <input
                              type="password"
                              placeholder="Enter New Password"
                              id="newPassword"
                              name="newPassword"
                              autoComplete="new-password"
                              className="form-control"
                              {...register('newPassword')}
                            />
                            <PasswordEye target="newPassword" />
                            <InvalidFeedbackV7
                              name="newPassword"
                              formState={formState}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label
                              for="newRetypedPassword"
                              className="required"
                            >
                              Retype Password
                            </Label>
                            <input
                              type="password"
                              placeholder="Retype New Password"
                              id="newRetypedPassword"
                              name="newRetypedPassword"
                              autoComplete="new-password"
                              className="form-control"
                              {...register('newRetypedPassword')}
                            />
                            <PasswordEye target="newRetypedPassword" />
                            <InvalidFeedbackV7
                              name="newRetypedPassword"
                              formState={formState}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md="12"
                          className="mt-4 d-flex justify-content-end"
                        >
                          <Button
                            color="primary"
                            disabled={preventSubmit(formState)}
                          >
                            Update Password
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </CSSTransitionGroup>
      {/* <ValidatePinModal pin={pin} isOpen={pinModal} toggle={togglePinModal} validatePin={validatePin} /> */}
    </Fragment>
  )
}

export default ChangePass
