import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

// import Slider from 'react-slick'

import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { validMobileRegex } from 'utils/validation'
import showLoader from 'Components/Loader'

// import pattern from 'assets/utils/images/sidebar/pattern-img.png'
// import bg3 from "assets/utils/images/originals/citynights.jpg"
import { config } from 'config'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'
import { authActions } from 'features/auth/authSlice'
import UserDetails from './UserDetails'
import Timer from './timer'

const otpGenerator = require('otp-generator')
const otpLength = config.otpLength

// let settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   arrows: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   initialSlide: 0,
//   autoplay: true,
//   adaptiveHeight: true,
// }

const Register = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formRef = useRef()
  const { sendOTP, isMobileExist } = useAuth()
  const [otpBlock, showOtpBlock] = useState(false)
  const [otpStatus, setOtpStatus] = useState('')
  const [otp, setOtp] = useState('')
  const [mobile, setMobile] = useState('')
  const [minutes, setMinutes] = useState(0)
  const [mobileExist, setMobileExist] = useState(false)
  const params = new URLSearchParams(window ? window.location.search : {})

  useEffect(() => {
    if (otpBlock === true) {
      document.querySelector('#otp').focus()
    }
  }, [otpBlock])

  const schema = yup.object().shape({
    mobile: yup
      .string()
      .required('Mobile is required')
      .matches(validMobileRegex, 'Please enter valid mobile number')
      .min(10, 'Mobile Number should 10 digits')
      .max(10, 'Mobile Number should 10 digits')
      .test(
        'exist',
        'This mobile number is already exists',
        // async (value) => (await fetch('/is-jimmy/' + value)).responseText === 'true'
        // async (value) => await isMobileExist(value) //.then(res => console.log(res))
        (value) => validateMobile(value)
      ),
    otpStatus: yup.string(),
    otp: yup.string().when('otpStatus', {
      is: 'sent',
      then: () => yup
        .string()
        .required('OTP is required')
        .min(otpLength, `'OTP should at least ${otpLength} character`)
        .matches(otp, 'OTP is not valid!'),
      otherwise: () => yup.string(),
    }),
  })

  const onSubmit = (data) => {
    showLoader(true)
    if (data.otpStatus !== 'sent') {
      handleSendOTP(data)
    } else {
      if (validateOTP(data)) {
        // toast.success('OTP is validated')
        dispatch(authActions.signupStepMobile(data.mobile))
        setTimeout(() => {
          showLoader(false)
          history.push('/auth/signup/create')
        }, 300)
      } else {
        showLoader(false)
        toast.error('OTP is not validated')
      }
    }
  }

  const handleSendOTP = (data) => {
    let otp = otpGenerator.generate(otpLength, {
      upperCase: false,
      alphabets: false,
      specialChars: false,
    })
    // const message = `Your OTP for signup in Kashewnut is ${otp}`
    // const message = `Welcome to Kashewnut. Your OTP for mobile verification is ${otp}. Thanks.`
    // if (true) {
    //   toast.success(`Your OTP is ${otp}.`)
    //   console.log(otp)
    //   setOtpStatus('sent')
    //   setMobile(data.mobile)
    //   setOtp(otp)
    //   showOtpBlock(true)
    //   showLoader(false)
    //   return
    // }

    const payload = {
      mobile: data.mobile,
      otp: otp,
      otpLength: otpLength,
    }
    // console.log(payload)

    sendOTP(payload).then((res) => {
      console.log(res)
      if (res.type === 'success') {
        // toast.success(res.message)
        // toast.success('OTP sent to your mobile.')
        setOtpStatus('sent')
        setMobile(data.mobile)
        setOtp(otp)
        showOtpBlock(true)
        return
      }
    })
  }

  const handleReSendOTP = () => {
    setOtpStatus('')
    setMinutes(minutes + 1)
    handleSendOTP({ mobile: mobile })
  }

  const validateOTP = (data) => {
    return data.mobile === mobile && data.otp === otp
  }

  const validateMobile = (value) =>
    new Promise((resolve) => {
      // return resolve(true)
      if (value.length === 10) {
        if (mobileExist && mobile === value) {
          return resolve(mobileExist)
        }
        isMobileExist(value).then((exist) => {
          setMobileExist(!exist)
          return resolve(!exist)
        })
      } else {
        return resolve(false)
      }
    })

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col
            lg="7"
            md="12"
            className="d-md-flex d-sm-block bg-white justify-content-center align-items-center"
          >
            <Col lg="6" md="10" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Welcome,</div>
                <span>
                  It only takes a{' '}
                  <span className="text-success">few seconds</span> to create
                  your account
                </span>
              </h4>
              <div>
                <Form
                  onFinish={onSubmit}
                  className="login100-form validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onTouched" // onBlur
                  initialValues={{
                    mobile: mobile || params.get('mobile') || '',
                    otpStatus: otpStatus,
                  }}
                  reset={formRef.current}
                >
                  <Row form>
                    <Col md="12">
                      <Input.Text
                        label="Your Mobile number"
                        name="mobile"
                        placeholder="Mobile Number"
                        required
                        prepend={
                          <span className="input-group-text">
                            {/* <FontAwesomeIcon icon={faMobileAlt} /> */}
                            +91
                          </span>
                        }
                        rules={{
                          onBlur: (e) => setMobile(e.target.value),
                        }}
                        // defaultValue={params.get('mobile')}
                        // rules={{
                        //   required: 'Email is required',
                        // }}
                      />
                      <Input.Hidden
                        id="otpStatus"
                        name="otpStatus"
                        // value={otpStatus}
                      />
                    </Col>
                    {!otpBlock && (
                      <Col
                        md="12"
                        className="text-right d-flex justify-content-end"
                      >
                        <Submit className="btn custom-btn-animation h-37">
                          Get OTP
                        </Submit>
                      </Col>
                    )}
                    {otpBlock && (
                      <Col md="12">
                        <Input.Text
                          label="OTP"
                          id="otp"
                          name="otp"
                          required
                          // rules={{
                          //   required: 'Email is required',
                          // }}
                        />
                      </Col>
                    )}
                    {otpBlock && (
                      <Col md="12" className="d-flex justify-content-between align-items-center">
                        <Timer
                          initialMinute={minutes}
                          initialSeconds={30}
                          resend={handleReSendOTP}
                          resentText="Resend"
                        />
                        <Submit className="btn btn-primary btn-wide btn-pill btn-shadow btn-hover-shine">
                          Validate OTP
                        </Submit>
                      </Col>
                    )}
                  </Row>
                  <div className="mt-4 d-flex align-items-center">
                    <h5 className="mb-0">
                      Already have an account?{' '}
                      <Link to="/auth/login" className="text-primary">
                        Sign in
                      </Link>
                    </h5>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
          <UserDetails />
        </Row>
      </div>
    </Fragment>
  )
}

export default Register
