import React, { Fragment, useState } from 'react'
import usePageTitle from 'hooks/usePageTitle'
import { Card, Col, Row, Button, Flex, Checkbox } from 'antd'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAugmontFds,
  getPassbook,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { usePin } from 'context/PinContext'
import { uiActions } from 'features/uiSlice'
import { useAugmont } from 'backend/useAugmont'
import UserConsent from 'Components/UserConsent'

const CheckoutAugmontFd = ({ match }) => {
  usePageTitle('Goal FD')
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(true)
  const [acceptCheck, setAcceptCheck] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const { checkPin } = usePin()

  const { passbook, fdSchemas } = useSelector(selectAugmont)
  const { createOrderFd } = useAugmont()

  const goalSchemes = fdSchemas.items

  const goalScheme = goalSchemes?.find(
    (item) => item.id === match?.params?.goalFDId
  )

  const handleBack = () => {
    history.goBack()
  }

  const quantity = +match?.params?.quantity ?? 1

  const onClickOnOrder = async () => {
    if (match?.params?.goalFDId) {
      setLoading(true)
      try {
        createOrderFd({
          fdSchemeId: match?.params?.goalFDId,
          quantity,
        }).then(() => {
          dispatch(
            uiActions.showSuccessModal({
              title: 'Success',
              description: 'Your Gold FD is created successfully',
              buttonText: 'Go Back To Dashboard',
              redirectUrl: '/dashboard',
            })
          )
          dispatch(getPassbook())
          dispatch(getAugmontFds())
        })
      } catch (error) {
        toast.error('Something want wrong, Please try again later')
      }
      setLoading(false)
    }
  }

  const validatePin = () => {
    checkPin('', onClickOnOrder, `To Gold FD checkout`)
  }

  return (
    <Fragment>
      <Row gutter={[24, 24]}>
        <Col span={24} md={16}>
          <Card className="main-card card" title="Checkout">
            <table className="align-middle text-truncate table table-borderless table-hover mb-0">
              <thead>
                <tr>
                  <th>Service</th>
                  <th className="text-right">GMS</th>
                  <th className="text-right">Total GMS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: '60%' }}>
                    <div>{goalScheme?.jewellerUserAccountName}</div>
                    <div>
                      <small>{goalScheme?.name}</small>
                    </div>
                  </td>
                  <td className="text-right">{quantity}</td>
                  <td className="text-right font-weight-bold">{quantity}</td>
                </tr>
              </tbody>
            </table>
            <table className="align-middle text-truncate table table-borderless mb-0">
              <tbody>
                <tr className="border-bottom">
                  <td className="text-right" colSpan={3}></td>
                </tr>
                <tr>
                  <td className="p-0" style={{ width: '75%' }}>
                    <Button
                      icon={<FaArrowLeft />}
                      type="text"
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  </td>
                  <td className="text-right font-weight-bold">Total:</td>
                  <td className="text-right font-weight-bold">{quantity} gms</td>
                </tr>
              </tbody>
            </table>
          </Card>
          <div className="mt-4">
            <UserConsent
              message="*I hereby give consent to share my data with Augmont Goldtech Pvt. Ltd. to facilitate FD booking."
              onAccept={(accepted) => setAcceptCheck(accepted)}
            />
          </div>
        </Col>
        <Col span={24} md={8}>
          <Card className="main-card card" title="Booking">
            <div className="mb-2">Booking Method</div>
            <Flex justify="space-between">
              <Checkbox onChange={() => setChecked(!checked)} checked={checked}>
                Gold Balance
              </Checkbox>
              <b>{Number(passbook.goldGrms ?? 0)} gms</b>
            </Flex>
            <div className="mt-5">
              <Button
                onClick={validatePin}
                icon={<FaArrowRight />}
                iconPosition="end"
                disabled={!checked || !acceptCheck || loading}
                type="primary"
                block
              >
                Checkout
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default CheckoutAugmontFd
