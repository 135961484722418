import React, { Fragment, useState } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import cx from 'classnames'

import { toast } from 'react-toastify'

import { FaRupeeSign } from 'react-icons/fa'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  validationStatus,
  InvalidFeedbackV7,
  preventSubmit,
} from '../../../utils/validation'

import { useEffect } from 'react'
import { useServices } from 'backend/useServices'
import { usePin } from 'context/PinContext'

// const service = 'LIC'
const schema = yup.object().shape({
  canumber: yup.string().required('CA number is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
  // mobile: yup.string()
  //   .required('Mobile is required')
  //   .matches(validMobileRegex, 'Please enter valid mobile number')
  //   .min(10, 'Mobile Number should 10 digits')
})

const emptyBill = {
  response_code: 1,
  status: true,
  amount: '2863.00',
  name: 'Abhishek Tripathi',
  duedate: '',
  bill_fetch: {
    bill_number: '334489350',
    bill_date: '',
    due_date: '',
    bill_amount: '2863.00',
    bill_partial: 'N',
    bill_customer: 'Abhishek Tripathi',
  },
  ad2: 40887,
  ad3: 32321,
  message: 'Bill Fetched Success.',
}

// const emptyBill = {
//   "response_code": 1,
//   "status": true,
//   "amount": "1548.00",
//   "name": null,
//   "duedate": null,
//   "bill_fetch": [
//     {
//       "billNumber": "LICI2122000037474753",
//       "billAmount": "1548.00",
//       "billnetamount": "1548.00",
//       "billdate": "25-05-2021 01:28:18",
//       "acceptPayment": true,
//       "acceptPartPay": false,
//       "cellNumber": "905514651",
//       "dueFrom": "11/05/2021",
//       "dueTo": "11/05/2021",
//       "validationId": "HGA8V1521B0382562321",
//       "billId": "HGA8V1521B0382562321B"
//     }
//   ],
//   "message": "Bill Fetched Success."
// }

const LICService = () => {
  const history = useHistory()
  const [geoLocation, setGeolocation] = useState({
    latitude: 0,
    longitude: 0,
  })
  // const [operators] = useState([
  //   { code: "VISA", name: 'Visa', service: 'CC' },
  //   { code: "MASTERCARD", name: 'Mastercard', service: 'CC' }
  // ])

  const [billDetails, setBillDetails] = useState(emptyBill)
  const [billStatus, setBillStatus] = useState(false)
  const { checkPin } = usePin()

  const { licCheckBill, licPayBill } = useServices()

  const {
    register,
    handleSubmit,
    reset,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    // mode: "onTouched",
    // mode: "onTouched|onChanged"
  })

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition)
    }
    reset({
      canumber: '334489350',
      // ad1: "pramodpatwa31@gmail.com",
      email: 'pramodpatwa31@gmail.com',
      mode: 'online',
      // mode: "offline",
    })
  }, [reset])

  // const setError = (data) => {
  //   toast.error("Please allow Geolocation access")
  //   setGeolocation({
  //     latitude: 0,
  //     longitude: 0
  //   })
  // }

  const setPosition = (data) => {
    setGeolocation(data.coords)
  }

  const onSubmit = (data) => {
    // console.log(data)
    if (!billStatus) {
      checkBill(data)
      return
    }
    checkPin(data, handleFormSubmit)
  }

  const handleReset = () => {
    setBillDetails()
    reset()
    setBillStatus(false)
  }

  const checkBill = async (data) => {
    if (geoLocation.latitude === 0) {
      navigator.geolocation.getCurrentPosition(setPosition)
      toast.info('Please allow Geolocation access')
      return
    }
    // data.remarks = 'Pay {operatorName} Bill'
    data.type = 'PAY'
    // data.remarks = generateDescription(data.remarks, data.network, operators)
    data.remarks = 'Pay LIC Premium'
    licCheckBill({
      canumber: data.canumber, //  "4281021019786316",
      ad1: data.email, //  "pramodpatwa31@gmail.com",
      mode: data.mode, //  "offline",
      remarks: data.remarks,
    })
      .then((data) => {
        setBillStatus(true)
        setBillDetails(data.data)
      })
      .catch((error) => {
        // showLoader(false)
        setBillStatus(false)
        toast.error(error)
      })
  }

  const handleFormSubmit = (data) => {
    licPayBill({
      latitude: geoLocation.latitude,
      longitude: geoLocation.longitude,
      canumber: data.canumber, //  "4281021019786316",
      ...billDetails,
    }).then((data) => {
      setBillStatus(false)
      console.log('response', data)
      handleReset()
      setTimeout(() => {
        history.push('/account/transactions')
      }, 300)
    })
  }

  const handleChangeInput = () => {
    setBillDetails()
    setBillStatus(false)
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-id icon-gradient bg-ripe-malin" />
              </div>
              <div>
                LIC Premium
                <div className="page-title-subheading">
                  Pay your LIC Premium Bill...
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col lg="5">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Pay your LIC Premium</CardTitle>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="validate-form was-validate"
                >
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="canumber" className="required">
                              CA Number
                            </Label>
                            <input
                              type="text"
                              placeholder="Enter Card Holder Name"
                              id="canumber"
                              name="canumber"
                              className={cx(
                                'form-control',
                                validationStatus('canumber', formState)
                              )}
                              {...register('canumber')}
                              onChange={handleChangeInput}
                            />
                            <InvalidFeedbackV7
                              name="canumber"
                              formState={formState}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="email">Email</Label>
                            <input
                              name="email"
                              type="text"
                              placeholder="Enter email"
                              className={cx(
                                'form-control',
                                validationStatus('email', formState)
                              )}
                              {...register('email')}
                              onChange={handleChangeInput}
                            />
                            <InvalidFeedbackV7
                              name="email"
                              formState={formState}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {!billStatus && (
                          <Col lg="12">
                            <input
                              name="mode"
                              type="hidden"
                              // className={cx('form-control', validationStatus('mode', formState))}
                              {...register('mode')}
                            />
                            <FormGroup>
                              <Button
                                size="lg"
                                block
                                className="mt-3"
                                color="primary"
                                disabled={preventSubmit(formState)}
                              >
                                Check Due Bill
                              </Button>
                            </FormGroup>
                          </Col>
                        )}
                        {billStatus && (
                          <>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  size="lg"
                                  block
                                  className="mt-3"
                                  color="danger"
                                  type="button"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  size="lg"
                                  block
                                  className="mt-3"
                                  color="success"
                                  disabled={preventSubmit(formState)}
                                >
                                  Pay Bill
                                </Button>
                              </FormGroup>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            {billStatus && (
              <Card className="card-shadow-primary card-border mb-3">
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-dark">
                    <div className="menu-header-content">
                      <div>
                        <h5 className="menu-header-title">LIC Bill Detail</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <ListGroup className="rm-list-borders" flush>
                    <ListGroupItem className="cursor-pointer">
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper align-items-start d-flex ">
                          {/* <div className="widget-content-left mr-3">
                            <img width={42} className="rounded-circle" src={logo} alt="" />
                          </div> */}
                          <div className="widget-content-left">
                            <div className="widget-heading">
                              Customer Name: {billDetails.name}
                            </div>
                          </div>
                          {/* <div className="widget-content-right">
                            <div className="font-size-lg text-success">
                              <small className="text-success pl-2">
                                <FaRupeeSign />
                                {billDetails.amount}
                              </small>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="widget-content p-0">
                        <div className="widget-subheading font-weight-bold">
                          Amount: <FaRupeeSign />
                          {billDetails.amount}
                        </div>
                      </div>
                      {/* {billDetails && billDetails.bill_fetch.length > 0 && billDetails.bill_fetch.map(bill => (
                        <div className="widget-content p-0">
                          <div className="widget-subheading">
                            Mobile Number: {bill.cell_number}
                          </div>
                          <div className="widget-subheading">
                            Bill ID: {bill.bill_id}
                          </div>
                          <div className="widget-subheading">
                            Bill Number: {bill.bill_number}
                          </div>
                          <div className="widget-subheading">
                            Bill Date: {bill.bill_date}
                          </div>
                          <div className="widget-subheading">
                            Bill From: {bill.due_from}
                          </div>
                          <div className="widget-subheading">
                            Bill Due To: {bill.due_date}
                          </div>
                          <div className="widget-subheading text-muted">
                            Bill Amount: {bill.bill_amount}
                          </div>
                          <div className="widget-subheading text-muted">
                            Bill Partial: {bill.bill_partial}
                          </div>
                          <div className="widget-subheading text-muted">
                            Bill Validation ID: {bill.validation_id}
                          </div>
                        </div>
                      ))} */}

                      <div className="widget-content p-0">
                        {/* <div className="widget-content-wrapper">
                          <div className="widget-subheading text-muted">
                            {billDetails.message}
                          </div>
                        </div> */}
                        <div className="widget-subheading">
                          <strong>Due Date: {billDetails.duedate}</strong>
                        </div>
                        {billDetails && billDetails.bill_fetch && (
                          <>
                            <div className="widget-subheading">
                              Mobile Number:{' '}
                              {billDetails.bill_fetch.cell_number}
                            </div>
                            <div className="widget-subheading">
                              Bill Number: {billDetails.bill_fetch.bill_number}
                            </div>
                            <div className="widget-subheading">
                              Bill Date: {billDetails.bill_fetch.bill_date}
                            </div>
                            <div className="widget-subheading">
                              Bill From: {billDetails.bill_fetch.due_from}
                            </div>
                            <div className="widget-subheading">
                              Bill Due To: {billDetails.bill_fetch.due_date}
                            </div>
                            <div className="widget-subheading text-muted">
                              Bill Amount: {billDetails.bill_fetch.bill_amount}
                            </div>
                            <div className="widget-subheading text-muted">
                              Bill Partial:{' '}
                              {billDetails.bill_fetch.bill_partial}
                            </div>
                            <div className="widget-subheading text-muted">
                              Bill Validation ID:{' '}
                              {billDetails.bill_fetch.validation_id}
                            </div>
                          </>
                        )}
                        <div className="widget-subheading text-muted">
                          AD2: {billDetails.ad2}
                        </div>
                        <div className="widget-subheading text-muted">
                          AD3: {billDetails.ad3}
                        </div>
                        <div className="widget-subheading text-muted">
                          Latitude: {geoLocation.latitude}
                        </div>
                        <div className="widget-subheading text-muted">
                          Longitude: {geoLocation.longitude}
                        </div>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Card>
            )}
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default LICService
