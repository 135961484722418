import React, { useContext, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'
import { toast } from 'react-toastify'
import { config } from 'config'
import { selectAuth } from 'features/auth/authSlice'

import { getAugmontFds } from 'features/augmont/augmontSlice'
import { accountActions } from 'features/accountSlice'

const SocketContext = React.createContext()
const baseURL = config.apiUrl

export function useSocket() {
  return useContext(SocketContext)
}

export function SocketProvider({ children }) {
  const [isReady, setIsReady] = useState(false)
  const { user } = useSelector(selectAuth)
  const dispatch = useDispatch()

  const socket = useRef(null)

  useEffect(() => {
    const newSocket = io(baseURL)
    // const newSocket = io(baseURL, {
    //   autoConnect: false,
    // })

    // newSocket.auth = { username: 'vicky' }
    // newSocket.connect()
    newSocket.on('connect', (id) => {
      setIsReady(true)
      // newSocket.emit('uid', { uid: newSocket.id })
      // newSocket.emit('events', { test: 'test' })
      // newSocket.emit('identity', 0, response =>
      //   console.log('Identity:', response),
      // )
      // newSocket.on('paymentStatus', (data) => {
      //   console.log('paymentStatus', data)
      // })

      console.log('Connected', newSocket)
      // newSocket.on('orderStatus', (data) => {
      //   console.log('orderStatus', data)
      //   // if (user?.id === data.userId) {
      //   //   loadAndSetTransactions()
      //   // }
      // })
    })
    newSocket.on('disconnect', () => {
      setIsReady(false)
      console.log('Disconnected')
    })

    window.socketContext = newSocket
    socket.current = newSocket

    return () => {
      newSocket.close()
    }
  }, [])

  useEffect(() => {
    if (!isReady) return
    if (user?.id) {
      socket.current.on('virtual.account.created', virtualAccountCreated)
      socket.current.on('fixerra.account.created', fixerraAccountCreated)
      socket.current.on('lendBox.account.created', lendBoxAccountCreated)
      socket.current.on('augmont.fd.updated', fdUpdated)
      socket.current.on('investment.payment.success', investmentPaymentSuccess)
    }

    return () => {
      socket.current.off('virtual.account.created')
      socket.current.off('fixerra.account.created')
      socket.current.off('lendBox.account.created')
      socket.current.off('augmont.fd.updated')
      socket.current.off('investment.payment.success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, user])

  const virtualAccountCreated = (data) => {
    if (user?.id === data.userId) {
      toast.info('Virtual account created.')
      dispatch(accountActions.loadVirtualAccount(data.account))
    }
  }
  const fixerraAccountCreated = (data) => {
    if (user?.id === data.userId) {
      toast.info('FD account created.')
      dispatch(accountActions.loadFixerraAccount(data.account))
    }
  }

  const lendBoxAccountCreated = (data) => {
    if (user?.id === data.userId) {
      toast.info('LendBox account created.')
      dispatch(accountActions.loadLendBoxAccount(data.account))
    }
  }

  const fdUpdated = (data) => {
    console.log('augmont.fd.updated', user?.id, data.userId)
    if (user?.id === data.userId) {
      dispatch(getAugmontFds())
    }
  }
  const investmentPaymentSuccess = (data) => {
    if (user?.id === data.userId) {
      toast.info('Investment Balance Updated.')
      dispatch(accountActions.refreshBalance())
      dispatch(accountActions.updateWalletBalance(data.walletBalance))
    }
  }
  // const ret = [isReady, socket.current]

  // console.log("SocketContext", state)

  return (
    <SocketContext.Provider value={{ isReady, socket: socket.current }}>
      {children}
    </SocketContext.Provider>
  )
}
