import React from "react"

// ACCOUNT
import Transactions from "./Transactions"
// import CreditScore from "./CreditScore"
// import Payments from "./Payments"
import Withdraw from "./Withdraw"
import AccountInfo from './Info'
import ProfileEdit from './Profile/ProfileEdit'
import ChangePass from './Profile/ChangePass'
// import Beneficiary from './Profile/Beneficiary'
import PanVerification from "./PanVerification"

import PrivateRoutes from "../../routes/PrivateRoutes"
// import BankAccount from "./BankAccount"
import AccountFd from "./AccountFds"

const AccountNav = ({ match }) => {
  return (
    <>
      <PrivateRoutes
        path={`${match.url}/transactions`}
        component={Transactions}
      />
      <PrivateRoutes
        path={`${match.url}/Fds`}
        component={AccountFd}
      />

      {/* <PrivateRoutes path={`${match.url}/payments`} component={Payments} /> */}
      {/* <PrivateRoutes path={`${match.url}/bank`} component={BankAccount} /> */}
      <PrivateRoutes path={`${match.url}/withdraw`} component={Withdraw} />
      <PrivateRoutes path={`${match.url}/info`} component={AccountInfo} />
      <PrivateRoutes
        path={`${match.url}/profile/edit`}
        component={ProfileEdit}
      />
      <PrivateRoutes
        path={`${match.url}/pan-verification`}
        component={PanVerification}
      />
      <PrivateRoutes path={`${match.url}/change-pass`} component={ChangePass} />
      {/* <PrivateRoutes
        path={`${match.url}/credit-score`}
        component={CreditScore}
      /> */}
      {/* <PrivateRoutes
        path={`${match.url}/beneficiary`}
        component={Beneficiary}
      /> */}
    </>
  )
}

export default AccountNav
