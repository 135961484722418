import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  goalsWithGiftCards: [],
  giftCard: {},
  giftCards: [],
}

export const goalsSlice = createSlice({
  name: 'goals',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loadGoalsWithGiftCards: (state, action) => {
      // const tmpPayLoad = {
      //   id: '6444e319d964e041a159ce78',
      //   name: 'API SANDBOX B2B',
      //   term: 'sort',
      //   status: 'Publish',
      //   description: null,
      //   count: 0,
      //   giftCardSKUs: [],
      //   createdAt: '2023-04-23T07:49:45.500Z',
      //   updatedAt: '2023-04-24T06:17:06.891Z',
      //   giftCards: [
      //     {
      //       sku: 'EGCGBFK001',
      //       name: 'Fastrack E-Gift Card',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'fastrack-e-gift-card',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/thumbnail/66_microsite.png',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/mobile/66_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/image/66_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBFK001/d/small_image/66_microsite.png',
      //       },
      //       createdAt: '2015-06-25T08:55:00+00:00',
      //       updatedAt: '2023-03-21T14:59:18+00:00',
      //     },
      //     {
      //       sku: 'EGVGBSHSS001',
      //       name: "Shopper's Stop E-Gift Voucher-B2B-CORP",
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'shoppers-stop-e-gift-voucher',
      //       minPrice: '500',
      //       maxPrice: '2500',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/thumbnail/107_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/mobile/107_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/image/107_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGVGBSHSS001/d/small_image/107_microsite.jpg',
      //       },
      //       createdAt: '2015-06-26T00:25:01+00:00',
      //       updatedAt: '2022-06-07T10:55:47+00:00',
      //     },
      //     {
      //       sku: 'EGCGBAMZ001',
      //       name: 'Amazon Pay E-Gift Card-B2B-CORP',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'amazon-pay-e-gift-card',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/thumbnail/135_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/mobile/135_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/image/135_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBAMZ001/d/small_image/135_microsite.jpg',
      //       },
      //       createdAt: '2015-06-28T06:25:03+00:00',
      //       updatedAt: '2023-03-21T16:22:58+00:00',
      //     },
      //     {
      //       sku: 'EGCGBBMS001',
      //       name: 'BookMyShow E-Gift Card-B2B-CORP',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'bookmyshow-e-gift-card',
      //       minPrice: '100',
      //       maxPrice: '5000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/thumbnail/217_microsite.png',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/mobile/217_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/image/217_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/EGCGBBMS001/d/small_image/217_microsite.png',
      //       },
      //       createdAt: '2016-05-19T13:41:11+00:00',
      //       updatedAt: '2022-06-07T10:55:27+00:00',
      //     },
      //     {
      //       sku: 'UBEREGV001',
      //       name: 'UBER EGV',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'uber-egv',
      //       minPrice: '1',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail: '',
      //         mobile: '',
      //         base: '',
      //         small: '',
      //       },
      //       createdAt: '2018-05-24T09:27:23+00:00',
      //       updatedAt: '2020-04-14T05:48:21+00:00',
      //     },
      //     {
      //       sku: 'CNPIN',
      //       name: 'API TESTING - CN & PIN',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-cn-pin',
      //       minPrice: '1',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/thumbnail/324_microsite.png',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/mobile/324_microsite.png',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/image/324_microsite.png',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/small_image/324_microsite.png',
      //       },
      //       createdAt: '2018-07-26T06:58:11+00:00',
      //       updatedAt: '2023-02-23T05:33:21+00:00',
      //     },
      //     {
      //       sku: 'VOUCHERCODE',
      //       name: 'API TESTING - Voucher code',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-voucher-code',
      //       minPrice: '100',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/VOUCHERCODE/d/thumbnail/325_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/VOUCHERCODE/d/mobile/325_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/VOUCHERCODE/d/image/325_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/VOUCHERCODE/d/small_image/325_microsite.jpg',
      //       },
      //       createdAt: '2018-07-26T07:00:33+00:00',
      //       updatedAt: '2023-03-20T10:09:32+00:00',
      //     },
      //     {
      //       sku: 'UBEFLOW',
      //       name: 'API TESTING - UBE',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-ube',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/UBEFLOW/d/thumbnail/326_microsite.png',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/UBEFLOW/d/mobile/326_microsite.png',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/UBEFLOW/d/image/326_microsite.png',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/UBEFLOW/d/small_image/326_microsite.png',
      //       },
      //       createdAt: '2018-07-26T07:04:03+00:00',
      //       updatedAt: '2023-03-16T06:42:43+00:00',
      //     },
      //     {
      //       sku: 'CLAIMCODE',
      //       name: 'API TESTING - Claim Code',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-claim-code',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/thumbnail/327_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/mobile/327_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/image/327_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/small_image/327_microsite.png',
      //       },
      //       createdAt: '2018-07-26T07:25:07+00:00',
      //       updatedAt: '2023-02-09T04:52:49+00:00',
      //     },
      //     {
      //       sku: 'PROCESSINGSTS',
      //       name: 'API TESTING - Processing Status',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-processing-status',
      //       minPrice: '100',
      //       maxPrice: '100000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROCESSINGSTS/d/thumbnail/328_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROCESSINGSTS/d/mobile/328_microsite.jpg',
      //         base: '',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROCESSINGSTS/d/small_image/328_microsite.jpg',
      //       },
      //       createdAt: '2018-07-26T07:30:36+00:00',
      //       updatedAt: '2023-01-05T09:18:49+00:00',
      //     },
      //     {
      //       sku: 'DISABLEDSTS',
      //       name: 'API TESTING - Disabled Status',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-disabled-status',
      //       minPrice: '100',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/DISABLEDSTS/d/thumbnail/329_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/DISABLEDSTS/d/mobile/329_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/DISABLEDSTS/d/image/329_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/DISABLEDSTS/d/small_image/329_microsite.jpg',
      //       },
      //       createdAt: '2018-07-26T07:34:38+00:00',
      //       updatedAt: '2023-03-20T07:27:11+00:00',
      //     },
      //     {
      //       sku: 'PROGPARAMSNA',
      //       name: 'API TESTING - Program Parameters not found',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-program-parameters-not-found',
      //       minPrice: '100',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROGPARAMSNA/d/thumbnail/331_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROGPARAMSNA/d/mobile/331_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/PROGPARAMSNA/d/image/331_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/PROGPARAMSNA/d/small_image/331_microsite.jpg',
      //       },
      //       createdAt: '2018-07-26T07:41:06+00:00',
      //       updatedAt: '2021-06-03T11:14:36+00:00',
      //     },
      //     {
      //       sku: 'CARDAPINA',
      //       name: 'API TESTING - Card API Missing',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-card-api-missing',
      //       minPrice: '100',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CARDAPINA/d/thumbnail/333_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CARDAPINA/d/mobile/333_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/CARDAPINA/d/image/333_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/CARDAPINA/d/small_image/333_microsite.jpg',
      //       },
      //       createdAt: '2018-07-26T07:43:50+00:00',
      //       updatedAt: '2021-06-03T11:15:21+00:00',
      //     },
      //     {
      //       sku: 'APITESTTIMFAIL',
      //       name: 'API TESTING - Timeout Failure',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-timeout-failure',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/APITESTTIMFAIL/d/thumbnail/336_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/APITESTTIMFAIL/d/mobile/336_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/APITESTTIMFAIL/d/image/336_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/APITESTTIMFAIL/d/small_image/336_microsite.jpg',
      //       },
      //       createdAt: '2018-08-17T11:43:36+00:00',
      //       updatedAt: '2023-02-17T09:49:24+00:00',
      //     },
      //     {
      //       sku: 'testsuccess001',
      //       name: 'API TESTING - Timeout Success',
      //       currency: {
      //         code: 'INR',
      //         symbol: '₹',
      //         numericCode: '356',
      //       },
      //       url: 'api-testing-timeout-sucess',
      //       minPrice: '10',
      //       maxPrice: '10000',
      //       price: {
      //         cpg: [],
      //       },
      //       images: {
      //         thumbnail:
      //           'https://gbdev.s3.amazonaws.com/uat/product/testsuccess001/d/thumbnail/338_microsite.jpg',
      //         mobile:
      //           'https://gbdev.s3.amazonaws.com/uat/product/testsuccess001/d/mobile/338_microsite.jpg',
      //         base: 'https://gbdev.s3.amazonaws.com/uat/product/testsuccess001/d/image/338_microsite.jpg',
      //         small:
      //           'https://gbdev.s3.amazonaws.com/uat/product/testsuccess001/d/small_image/338_microsite.jpg',
      //       },
      //       createdAt: '2018-08-28T09:29:40+00:00',
      //       updatedAt: '2022-12-05T11:23:19+00:00',
      //     },
      //   ],
      // }
      // state.goalsWithGiftCards = { tmpPayLoad, ...action.payload } || []
      state.goalsWithGiftCards = action.payload || []
      // state.goalsWithGiftCards.push(tmpPayLoad)
      // state.goalsWithGiftCards = state.goalsWithGiftCards.reverse()
    },
    loadSingleGiftCard: (state, action) => {
      state.giftCard = action.payload
    },
    // clear: (state) => {
    //   state.balance = {}
    //   state.statements = {}
    //   state.refreshStatement = true
    //   state.refreshBalance = true
    // },
  },
})

// Action creators are generated for each case reducer function
// export const {} = bankSlice.actions
export const goalsActions = goalsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.augmont.value)`
export const selectGoals = (state) => state.goals

export default goalsSlice.reducer
