/* eslint-disable no-useless-escape */
import React from 'react'

export const passwordLength = 6
export const tenDigitMobileRegex = /\d{10}/
export const validMobileRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validPANNumberRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
export const validAadhaarNumberRegex = /\d{12}/

// export const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
// export const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})')
export const mediumRegex = new RegExp(
  '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.{6,}$)'
)

export const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/
// export const mediumRegex = new RegExp('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)')

// const hasErrors = (obj) => {
//   // null and undefined are "empty"
//   if (obj == null) return false

//   // Assume if it has a length property with a non-zero value
//   // that that property is correct.
//   if (obj.length > 0) return true
//   if (obj.length === 0) return false

//   // If it isn't an object at this point
//   // it is empty, but it can't be anything *but* empty
//   // Is it empty?  Depends on your application.
//   if (typeof obj !== 'object') return false

//   // Otherwise, does it have any properties of its own?
//   // Note that this doesn't handle
//   // toString and valueOf enumeration bugs in IE < 9
//   // eslint-disable-next-line no-restricted-syntax
//   for (const key in obj) {
//     if (hasOwnProperty.call(obj, key)) return true
//   }
//   return false
// }

export const preventSubmit = (formState) => {
  if (formState.isSubmitting) {
    return true
  }
  return !isEmpty(formState.errors)
}

export const isEmpty = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }
  return true
}

// Separate function for email validation
export function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

// Separate function for password strength validation
export function verifyPassword(value) {
  const passwordRex = /^[\S]{6,15}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

// Separate function for field length validation
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true
  }
  return false
}

export function verifyLink(value) {
  // eslint-disable-next-line no-useless-escape
  const linkRex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  if (linkRex.test(value)) {
    return true
  }
  return false
}

export function verifyMobileNumber(value) {
  const mobRex = /^[0-9]{10,14}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

export function verifyUsername(value) {
  const usernameRex = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
  if (usernameRex.test(value)) {
    return true
  }
  return false
}

export function verifyFullName(value) {
  const fullNameRegex = /^[a-zA-Z ]+$/
  if (fullNameRegex.test(value)) {
    return true
  }
  return false
}

// validation
export const validationStatus = (name, formState) => {
  console.log('validationStatus', formState)
  if (formState) {
    if (
      formState &&
      formState.errors &&
      formState.errors[name] &&
      formState.errors[name].message
    ) {
      return 'is-invalid'
    }
    // if (formState.dirtyFields[name]) {
    //   return ''
    // }
    // if (formState.touched[name]) {
    //   return 'is-valid'
    // }
  }
  return ''
}

export const validationRadioStatus = (name, value, formState) => {
  console.log('validationStatus', formState)
  // if (formState.errors[name] && formState.errors[name].message) {
  //   return 'is-invalid'
  // }
  // return ''
}

// export const InvalidFeedback = ({ name, errors }) => {
//   if (errors && errors[name] && errors[name].message) {
//     return <div className="invalid-feedback">{errors[name].message}</div>
//   }
//   return ''
// }

export const validationStatusSelect = (name, formState) => {
  // console.log('validationStatus', formState)
  if (formState) {
    if (
      formState &&
      formState.errors &&
      formState.errors[name] &&
      formState.errors[name].message
    ) {
      return 'is-invalid'
    }
    if (formState.touched[name] && formState.touched[name].value) {
      return 'is-valid'
    }
  }
  return ''
}

export const CustomInvalidFeedbackSelect = ({ name, formState }) => {
  if (
    formState &&
    formState.errors &&
    formState.errors[name] &&
    formState.errors[name].message
  ) {
    return (
      <div className="invalid-feedback d-block">
        {formState.errors[name].message}
      </div>
    )
  }
  return ''
}

export const validationStatusFormik = (name, errors, touched) => {
  if (touched[name] && errors[name]) {
    return 'is-invalid'
  }
  if (touched[name] && !errors[name]) {
    return 'is-valid'
  }
  return ''
}

export const InvalidFeedbackFormic = ({ name, errors, touched }) => {
  if (touched[name] && errors[name]) {
    return <div className="invalid-feedback">{errors[name]}</div>
  }
  return ''
}

export const InvalidFeedbackV7 = ({
  formState,
  name,
  needsValidate = false,
}) => {
  // console.log('errors', name, formState.errors)
  let el = document.querySelector(`.el-${name}`)
  if (
    formState.errors &&
    formState.errors[name] &&
    formState.errors[name].message
  ) {
    if (!el) {
      el = formState.errors[name].ref
      if (el?.props) {
        el = document.querySelector(`input[name='${el.props.name}']`)
      }
      el.classList.add(`el-${name}`)
    }
    needsValidate && el.classList.remove('is-valid')
    el.classList.add('is-invalid')
    console.log('element', name, el)

    return (
      <div className="invalid-feedback">{formState.errors[name].message}</div>
    )
  } else {
    el && el.classList.remove('is-invalid')
    needsValidate && el && el.classList.add('is-valid')
  }
  return ''
}
