import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Row, Col, Card, CardBody } from 'reactstrap'
import BankDetailsForm from './BankDetailsForm'
import FormWizard from "../FormWizard"

const BankDetails = () => {
  document.querySelector('body').style = 'overflow:auto'

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-safe icon-gradient bg-ripe-malin" />
              </div>
              <div>
                Updating Your Bank Details
                <div className="page-title-subheading">Your Bank Details</div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col md="9" lg="9">
            <Card className="main-card mb-3">
              <CardBody>
                <div className="forms-wizard-alt">
                  <div>
                    <FormWizard currentStep={2} />
                    <BankDetailsForm />
                    <div className="divider" />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default BankDetails
