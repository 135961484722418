import { Checkbox } from 'antd'
import React, { useState } from 'react'

const UserConsent = ({
  message = 'I hear by know that I am using this service',
  onAccept = () => {},
}) => {
  const [acceptCheck, setAcceptCheck] = useState(true)
  return (
    <Checkbox
      checked={acceptCheck}
      onChange={(e) => {
        setAcceptCheck(e.target.checked)
        onAccept(e.target.checked)
      }}
      className="ml-1"
    >
      {message}
    </Checkbox>
  )
}

export default UserConsent
