import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const FormWizard = ({ currentStep = 1 }) => {
  const history = useHistory()

  const steps = [
    {
      title: 'Account Details',
      link: 'account',
    },
    {
      title: 'Bank Details',
      link: 'bank',
    },
    {
      title: 'Nominee Details',
      link: 'nominee',
    },
    {
      title: 'Personal Details',
      link: 'personal',
    },
    {
      title: 'Finish',
      link: 'finish',
    },
  ]
  // const currentStepNumber = steps.findIndex((e) => e.link === currentStep) || 1

  const currentStepNumber = currentStep

  const getClassName = (step) => {
    if (step < currentStepNumber) {
      return 'form-wizard-step-done'
    }
    if (step === currentStepNumber) {
      return 'form-wizard-step-doing'
    }
    if (step > currentStepNumber) {
      return 'form-wizard-step-todo'
    }
  }

  return (
    <ol className="forms-wizard mb-3">
      {steps.map((step, i) => (
        <li
          className={getClassName(i + 1)}
          onClick={() => history.push(step.link)}
        >
          <em>.</em>
          <span>{step.title}</span>
        </li>
      ))}
    </ol>
  )
}

export default FormWizard
