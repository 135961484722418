import React, { Fragment } from 'react'
import { Route } from 'react-router'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import BBPSBillingForm from "../BBPSBillingForm"
import OperatorList from '../OperatorList'
import usePageTitle from 'hooks/usePageTitle'

const service = 'traffic-challan'

const TrafficChallan = ({ match }) => {
  usePageTitle('Traffic Challan')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Route path={`${match.url}`} exact>
          <OperatorList
            title="All Traffic Challan Providers"
            category={service}
            url={match.url}
          />
        </Route>
      </CSSTransitionGroup>
      <Route path={`${match.url}/:opcode`} exact>
        <BBPSBillingForm service={service} />
      </Route>
    </Fragment>
  )
}

export default TrafficChallan
