import React, { Fragment, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { useGiftCard } from 'backend/useGiftCard'
import { useSelector } from 'react-redux'
import { selectGoals } from 'features/giftCardSlice'
import { useState } from 'react'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import { FaSearch } from 'react-icons/fa'
import usePageTitle from 'hooks/usePageTitle'
import BrandCardContainer from 'assets/components/custom-card/BrandCardContainer'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { selectAccount } from 'features/accountSlice'

const ShortTerm = () => {
  // const type = 'short'
  const { getGoalsWithGiftCards } = useGiftCard()
  const { goalsWithGiftCards } = useSelector(selectGoals)
  const [list, setList] = useState(goalsWithGiftCards)
  const { bankAccount } = useSelector(selectAccount)

  usePageTitle('Short Term Goal')

  useEffect(() => {
    setList(goalsWithGiftCards)
  }, [goalsWithGiftCards])

  useEffect(() => {
    getGoalsWithGiftCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (e) => {
    const myArray = goalsWithGiftCards
    setList(
      myArray
        .map((goals) => {
          const giftCards = goals.giftCards.filter((card) =>
            new RegExp(e.target.value, 'i').test(card.name)
          )
          if (giftCards.length) {
            return { ...goals, giftCards }
          }
          return null
        })
        .filter((p) => p)
    )
  }

  // const history = useHistory()

  return !bankAccount.id ? (
    <Redirect
      to={{
        pathname: '/account-setup/account',
        state: {
          title: 'Setup Bank Account First to add Short Term Goal',
          redirectTo: '/investment/goal/short',
        },
      }}
    />
  ) : (
    <Fragment>
      {/* <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      > */}
      {/* <div className="app-page-title mb-2">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">
              <img src={sipSvg} alt="" />
            </div>
            <div>
              Short Term Goal
              <div className="page-title-subheading">
                <small>Powered by Lendbox and QwikCilver</small>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Row className="align-items-center mt-3">
        <Col md="9">
          {/* <h5 className="font-weight-bold mb-3">Title</h5> */}
        </Col>
        <Col md="3">
          <FormGroup>
            <InputGroup>
              <input
                type="text"
                className="form-control"
                onChange={handleSearch}
                placeholder="Type to Search..."
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      {list.length > 0 &&
        list.map((goal) => (
          <BrandCardContainer data={goal} cardFor="goal" key={goal.id} />
        ))}
      {/* </CSSTransitionGroup> */}
    </Fragment>
  )
}

export default ShortTerm
