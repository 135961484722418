import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import classnames from 'classnames'
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  // ModalFooter
} from 'reactstrap'

import { FaEllipsisH, FaMinus, FaPlus } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'

import {
  // generateInitialImage,
  formattedPrice,
  statusIcon,
  statusClass,
  statusLabel,
} from '../../utils/helper'
// import { cancelSip } from 'features/augmont/augmontApi'
import { config } from 'config'
import { useHistory } from 'react-router-dom'
import { s3GetUrl } from 'config'

const baseUrl = config.apiUrl

// import avatar2 from "../assets/utils/images/avatars/2.jpg"

// const servicesTransactions = ['RECHARGE', 'PAY']
// const transferTransactions = ['NEFT', 'RTGS', 'IMPS', 'UPI', 'WALLET']

const StatementTable = ({
  data,
  currency,
  description,
  needModal,
  initialStatus,
  transactionStatus,
  limit,
  isGiftCard,
}) => {
  const [modal, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const history = useHistory()

  const toggleModal = (e, selectedStatement) => {
    setModal(!modal)
    if (selectedStatement) {
      setSelectedItem(selectedStatement)
    }
  }

  if (!data) return null
  let statements
  if (limit > 0) {
    statements = data.filter((d, i) => i < limit)
  } else {
    statements = data
  }

  const getStatus = (isGiftCard, statement) => {
    const status = isGiftCard
      ? statement?.status
      : statement.moneyTransferStatus

    return (
      <span className={`badge ${statusClass('bg-soft-', status)}`}>
        {status}
      </span>
    )
  }

  return (
    <>
      {initialStatus === 1 && transactionStatus === 'loading' && (
        <div className="text-center text-muted py-5">
          Your transaction will appear here
        </div>
      )}

      {/*
      {transactionStatus === 0 &&
        <div className="text-center text-muted py-5">
          No transaction found
        </div>
      } */}

      {transactionStatus === 'idle' && statements.length === 0 && (
        <div className="text-center text-muted py-5">No transaction found</div>
      )}

      {statements.length > 0 && (
        <Table
          responsive
          hover
          className="align-middle text-truncate statement-table overflow-hidden"
        >
          <thead>
            <tr>
              <th className="text-left" style={{ paddingLeft: '40px' }}>
                Description
              </th>
              <th className="text-center">Status</th>
              <th className="text-center">Date</th>
              <th className="text-center">Amount</th>
              <th className="text-center">{needModal && 'Action'}</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td colSpan="100">
                <pre>{JSON.stringify(statements, null, 2)}</pre>
              </td>
            </tr> */}
            {statements.map((statement) => (
              <tr key={statement.createdAt}>
                <td
                  className="text-left"
                  style={{
                    paddingLeft: '40px',
                    // display: 'inline-block',
                    overflow: 'hidden',
                  }}
                >
                  {isGiftCard ? (
                    statement.notes
                  ) : (
                    <div className="d-flex align-items-center">
                      <div>
                        {statement?.logo ? (
                          <img
                            width={42}
                            className="rounded-circle"
                            src={s3GetUrl(
                              statement?.logo || 'img/transaction-icon.png'
                            )}
                            alt=""
                          />
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center rounded-circle bg-dark text-light "
                            style={{ width: 40, height: 40 }}
                          >
                            {statement.transferType}
                          </div>
                        )}
                      </div>
                      <div className="ml-3">
                        <div>{statement.title}</div>
                        <small>
                          {description === true && (
                            <span>{statement?.description}</span>
                          )}
                          {description === false && (
                            <span>
                              {statement?.description.length <= 70 &&
                                statement?.description}
                              {statement?.description.length > 70 &&
                                `${statement?.description
                                  .split('; ')
                                  .reverse()
                                  .pop()} ...`}
                            </span>
                          )}
                        </small>
                      </div>
                    </div>
                  )}
                </td>
                <td className="text-center">
                  <div
                    className={`d-flex align-items-center justify-content-center ${statement.moneyTransferStatus}`}
                  >
                    {getStatus(isGiftCard, statement)}
                  </div>
                </td>
                <td
                  className="text-center text-muted"
                  // style={{ minWidth: '3em' }}
                >
                  <span className="mr-1">
                    {dayjs(
                      isGiftCard ? statement?.date : statement.createdAt
                    ).format('DD-MMM-YYYY')}
                    {/* ).format('DD-MMM-YYYY Do')} */}
                  </span>
                  <small>
                    {dayjs(
                      isGiftCard ? statement?.date : statement.createdAt
                    ).format('hh:mm a')}
                  </small>
                </td>
                <td
                  className="text-center"
                  style={{ maxWidth: '5em', minWidth: '3em' }}
                >
                  {isGiftCard && statement.amount && (
                    <b className="text-danger">
                      <FaMinus className="pr-1" />
                      <span className="pr-1">
                        {formattedPrice(statement.amount, currency)}
                      </span>
                      <span>{currency}</span>
                    </b>
                  )}
                  {statement.withdrawalAmount > 0 && (
                    <b className="text-danger">
                      <FaMinus className="pr-1" />
                      <span className="pr-1">
                        {formattedPrice(statement.withdrawalAmount, currency)}
                      </span>
                      <span>{currency}</span>
                    </b>
                  )}
                  {statement.depositAmount > 0 && (
                    <b className="text-success">
                      <FaPlus className="pr-1" />
                      <span className="pr-1">
                        {formattedPrice(statement.depositAmount, currency)}
                      </span>
                      <span>{currency}</span>
                    </b>
                  )}
                  {/* {statement.displayAmount > 0 && (
                    <b className="text-info">
                      {formattedPrice(statement.displayAmount, currency)}
                    </b>
                  )} */}
                </td>
                <td className="text-center" style={{ minWidth: '3em' }}>
                  {statement.service === 'qwikCilver' &&
                    statement.moneyTransferStatus === 'COMPLETE' && (
                      <Button
                        className="btn-icon btn-icon-only"
                        color="link"
                        onClick={() =>
                          history.push(`/voucher/details/${statement.orderId}`)
                        }
                        title="View Card Voucher"
                      >
                        <BsCreditCard
                          className="text-success"
                          size={'1.25em'}
                        />
                      </Button>
                    )}
                  {needModal && (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        className="btn-icon btn-icon-only"
                        color="link"
                      >
                        <FaEllipsisH className="text-info" size={'1.25em'} />
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-shadow dropdown-menu-hover-link"
                        right
                      >
                        <DropdownItem>
                          <i className="dropdown-icon lnr-eye"></i>
                          <span onClick={(e) => toggleModal(e, statement)}>
                            View Details
                          </span>
                        </DropdownItem>
                        {statement.service === 'qwikCilver' &&
                          statement.moneyTransferStatus === 'COMPLETE' && (
                            <>
                              <DropdownItem>
                                <i className="dropdown-icon lnr-eye"></i>
                                <span
                                  onClick={() =>
                                    history.push(
                                      `/voucher/details/${statement.orderId}`
                                    )
                                  }
                                >
                                  Gift Card Details
                                </span>
                              </DropdownItem>
                            </>
                          )}

                        {statement.moneyTransferStatus === 'SUCCESS' &&
                          (statement.opcode === 'AGB' ||
                            statement.opcode === 'ASB') && (
                            <>
                              <DropdownItem>
                                <i className="dropdown-icon lnr-eye"></i>
                                <span
                                  onClick={(e) =>
                                    window.open(
                                      `${baseUrl}/v1/invoice/${statement.id}`,
                                      '_blank'
                                    )
                                  }
                                >
                                  View Invoice
                                </span>
                              </DropdownItem>
                              <DropdownItem>
                                <i className="dropdown-icon lnr-cloud-download"></i>
                                <span
                                  onClick={(e) =>
                                    window.open(
                                      `${baseUrl}/v1/invoice/${statement.id}?view=download`,
                                      '_blank'
                                    )
                                  }
                                >
                                  Download Invoice
                                </span>
                              </DropdownItem>
                            </>
                          )}
                        {/* {statement.moneyTransferStatus === 'SCHEDULED' &&
                          statement.transferType === 'SIP' && (
                            <DropdownItem className="text-danger">
                              <i className="dropdown-icon lnr-cross-circle"></i>
                              <span onClick={(e) => cancelSip(statement.id)}>
                                Cancel SIP
                              </span>
                            </DropdownItem>
                          )} */}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        centered
        size="md"
        className="shadow-none"
      >
        <ModalHeader
          toggle={toggleModal}
          className={classnames(
            statusClass('bg-', selectedItem.moneyTransferStatus)
          )}
        >
          {statusLabel(
            selectedItem.moneyTransferStatus,
            selectedItem.depositAmount > 0
          )}
        </ModalHeader>
        {modal && (
          <ModalBody className="text-center">
            <div className="modal-status-icon">
              <span className="bg-orange brround">
                <i
                  className={classnames(
                    'las',
                    statusIcon(selectedItem.moneyTransferStatus)
                  )}
                ></i>
              </span>
            </div>
            <h4 className="m-3">{selectedItem.description}</h4>
            {selectedItem.provider === 'Augmont' &&
              selectedItem.providerDetails && (
                <>
                  6
                  {selectedItem.providerDetails.rate && (
                    <p className="m-0">
                      (at {selectedItem.providerDetails.rate} per gram price)
                    </p>
                  )}
                  <strong>
                    Augmont Transaction Id:{' '}
                    {selectedItem.providerDetails.transactionId}
                  </strong>
                  {(selectedItem.opcode === 'AGS' ||
                    selectedItem.opcode === 'ASS') && (
                    <>
                      <br />
                      Amount will transfer to{' '}
                      {selectedItem?.providerDetails?.bankInfo?.accountNumber}
                    </>
                  )}
                  <br />
                </>
              )}

            <div className="badge badge-pill badge-default">
              <span
                className={classnames(
                  'avatar-status',
                  statusClass(selectedItem.moneyTransferStatus)
                )}
              ></span>
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <div className="text-center">
                <span className="font-weight-bold">
                  Order Id: {selectedItem.orderId}
                </span>
              </div>
              <div className="text-center">
                <span className="light-text pr-1">
                  {selectedItem.transferType}
                </span>
                <span>
                  {selectedItem.withdrawalAmount !== 0 && (
                    <b className="text-danger">
                      <FaMinus className="pr-1" />
                      {formattedPrice(selectedItem.withdrawalAmount, currency)}
                    </b>
                  )}
                  {selectedItem.depositAmount !== 0 && (
                    <b className="text-success">
                      <FaPlus className="pr-1" />
                      {formattedPrice(selectedItem.depositAmount, currency)}
                    </b>
                  )}
                  {selectedItem.displayAmount !== 0 && (
                    <b className="text-info">
                      {formattedPrice(selectedItem.displayAmount, currency)}
                    </b>
                  )}
                </span>
                <br />
                <span className="light-text">
                  {dayjs(selectedItem.createdAt).format('MMM Do yyyy h:mm A')}
                </span>
              </div>
            </div>
          </ModalBody>
        )}
      </Modal>
    </>
  )
}

StatementTable.defaultProps = {
  limit: 0,
  description: false,
  currency: 'INR',
  transactionStatus: 0,
  initialStatus: 1,
  needModal: false,
}

StatementTable.propTypes = {
  limit: PropTypes.number,
  data: PropTypes.array.isRequired,
  currency: PropTypes.string,
  description: PropTypes.bool,
  transactionStatus: PropTypes.number,
  initialStatus: PropTypes.number,
  needModal: PropTypes.bool,
}

export default StatementTable

// const StatusIndicatorLabel = ({ status }) => {
//   return (
//     <>
//       <div
//         className={classnames(
//           'badge badge-dot badge-dot-lg mr-2',
//           statusClass('badge-', status)
//         )}
//       >
//         {status}
//       </div>
//       <span>{statusLabel(status)}</span>
//     </>
//   )
// }
