import { accountActions } from 'features/accountSlice'
import { serviceActions } from 'features/servicesSlice'
import { useDispatch } from 'react-redux'
import { useNest } from './useNest'

function useLPG() {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const getOperators = () => {
    let config = { loader: true, msg: '' }
    backend()
      .get('/lpg/operators', config)
      .then(({ data }) => {
        // console.log(data)
        dispatch(serviceActions.loadLPGOperators(data))
      })
  }

  const fetchBill = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/lpg/fetchDetails', payload, config)
        .then((response) => {
          return resolve(response)
        })
    })

  const bookCylinder = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Recharge success.' },
      }
      backend()
        .post('/lpg/book-cylinder', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const checkStatus = (referenceId) =>
    new Promise((resolve) => {
      backend()
        .get('/lpg/status', { referenceId })
        .then((response) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(response.data)
        })
    })

  return {
    getOperators,
    fetchBill,
    bookCylinder,
    checkStatus,
  }
}

export { useLPG }
