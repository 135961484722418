import axios from 'axios'
import { getErrorMessage } from 'backend'
import showLoader from 'Components/Loader'
import { config } from 'config'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
// import { showLoader } from "../utils/loader"
import { useCookie, useLocalStorage } from 'react-use'

const baseURL = config.apiUrl
const tokenName = config.tokenName

export const errMsg = 'Server is unavailable.'

export const useNest = () => {
  const history = useHistory()

  const [tokenC, updateTokenC, deleteTokenC] = useCookie(tokenName)
  const [tokenL, updateTokenL, deleteTokenL] = useLocalStorage(tokenName)

  const backend = (needLoader = false) => {
    const nest = axios.create({
      baseURL: `${baseURL}/v1`,
      timeout: 0, // 30 * 1000,
    })

    // Add a request interceptor
    nest.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (config.loader) {
          // if (needLoader) {
          showLoader(true)
          // window.showLoader = true
        }
        if (config.msg) {
          if (config.msg.loading) {
            config.msg.id = toast.loading(config.msg.loading)
          }
        }
        if (getToken()) {
          config.headers = { Authorization: `Bearer ${getToken()}` }
        }
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    // Add a response interceptor
    nest.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.config.msg) {
          let message =
            response?.data?.message || response?.config?.msg?.success
          if (response?.config?.msg?.id) {
            toast.update(response.config.msg.id, {
              render: message,
              type: 'success',
              isLoading: false,
              autoClose: true,
            })
          } else {
            toast.success(message)
          }
        }
        // if (response?.data?.token) {
        //   storeToken(response?.data?.token)
        // }

        if (response?.config?.loader) {
          // if (needLoader) {
          showLoader(false)
          // window.showLoader = false
        }
        return response?.data || response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let message = getErrorMessage(error)
        if (error?.response?.config?.msg) {
          if (error?.response?.config?.msg?.id) {
            toast.update(error.response.config.msg.id, {
              render: message,
              type: 'error',
              isLoading: false,
              autoClose: true,
            })
          } else {
            toast.error(message)
          }
        } else if (error?.config?.msg) {
          if (error?.config?.msg?.id) {
            toast.update(error.config.msg.id, {
              render: message,
              type: 'error',
              isLoading: false,
              autoClose: true,
            })
          } else {
            toast.error(message)
          }
        } else {
          // toast.error(message)
        }
        // if (error?.response?.config?.loader) {
        // if (needLoader) {
        // showLoader(false)
        // window.showLoader = false
        // }
        if (
          error?.response?.status === 401 &&
          window.location.href.search('auth') === -1
        ) {
          removeToken()
          window.location.href = '/auth/login'
        }
        showLoader(false)
        return Promise.reject(error)
      }
    )
    return nest
  }

  const updateToken = (token, remember = false) => {
    // console.log('updateToken')
    updateTokenL(token)
    document.cookie = 'isLogin=1; Domain=kashewnut.com; Path=/; Secure'
    remember === true &&
      updateTokenC(token, { expires: 7, sameSite: 'strict', secure: true })
  }

  const removeToken = (token) => {
    // console.log('removeToken')
    document.cookie = 'isLogin=0; Domain=kashewnut.com; Path=/; Secure'
    deleteTokenL(token)
    deleteTokenC(token)
  }

  const getToken = () => {
    return tokenL || tokenC || null
  }

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      backend()
        .get(`/${endpoint}/${id}`, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const deleteItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true, msg: { loading: "Deleting...", success: "Deleted successfully." }
      // }
      backend()
        .delete(`/${endpoint}/${id}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            history.push(config?.redirectTo)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const updateItem = (endpoint, id, payload, config) =>
    new Promise((resolve) => {
      backend()
        .put(`/${endpoint}/${id}`, payload, config)
        .then((data) => {
          if (config?.redirectTo) {
            history.push(config?.redirectTo)
          }

          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getList = (endpoint, query = {}, config = {}) =>
    new Promise((resolve) => {
      const defaultQuery = { limit: 50 }
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      const params = new URLSearchParams({
        ...defaultQuery,
        ...query,
      }).toString()

      backend()
        .get(`/${endpoint}?${params}`, config)
        .then((data) => {
          return resolve(data)
          // dispatch({
          //   type: endpoint,
          //   items: data,
          //   status: "idle"
          // })
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const addItem = (endpoint, payload, config = {}) =>
    new Promise((resolve) => {
      backend()
        .post(`/${endpoint}`, payload, config)
        .then((data) => {
          if (config?.redirectTo) {
            history.push(config?.redirectTo)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const uploadFile = (endpoint, id, payload, config = {}) =>
    new Promise((resolve) => {
      config.headers = { 'Content-Type': 'multipart/form-data' }
      backend()
        .post(`/${endpoint}/file/${id}`, payload, config)
        .then((data) => {
          if (config?.redirectTo) {
            history.push(config?.redirectTo)
          }

          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const deleteFile = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      backend()
        .delete(`/${endpoint}/file/${id}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            history.push(config?.redirectTo)
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
        })
    })

  const s3GetUrl = (key) => {
    return `${config.cdnUrl}/${key}`
  }

  return {
    backend,
    getToken,
    updateToken,
    removeToken,
    baseURL,
    getItem,
    getList,
    addItem,
    deleteItem,
    updateItem,
    uploadFile,
    deleteFile,
    s3GetUrl,
  }
}
