import React, { useState } from 'react'
import GoldSellForm from './GoldSell'
import SilverSellForm from './SilverSell'

const SellModule = () => {
  const [sell, setSell] = useState('Gold')
  return (
    <div className="gold-sell-container ml-3 mt-4">
      <div className="d-flex align-items-start flex-column">
        <div>Choose your metal:</div>
        <div className="d-flex custom-tab form-item-control metal-radio-radio mt-1">
          <label
            className={`custom-tab-item metal-radio-item ${
              sell === 'Gold' ? 'custom-tab-item-active' : ''
            }`}
          >
            Gold
            <input
              type="radio"
              className="d-none"
              name="investmentType"
              checked={sell === 'Gold'}
              value={sell === 'Gold'}
              onClick={() => setSell('Gold')}
            />
          </label>
          <label
            className={`custom-tab-item metal-radio-item ${
              sell === 'Silver' ? 'custom-tab-item-active' : ''
            }`}
          >
            Silver
            <input
              type="radio"
              className="d-none"
              name="investmentType"
              checked={sell === 'Silver'}
              value={sell === 'Silver'}
              onClick={() => setSell('Silver')}
            />
          </label>
        </div>
      </div>
      <div className="mr-3 mt-2">
        {sell === 'Gold' ? <GoldSellForm /> : <SilverSellForm />}
      </div>
    </div>
  )
}

export default SellModule
