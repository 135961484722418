import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
// import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
// import validator from 'validator'
// import cx from "classnames";
import {
  // Button,
  CardHeader,
  Card,
} from 'reactstrap'

// import showLoader from "Components/Loader"
import StatementTable from 'Components/Dashboard/StatementTable'
import { useVirtualAccount } from 'backend/useVirtualAccount'
import { useSocket } from 'context/SocketContext'
import { selectAuth } from 'features/auth/authSlice'
import usePageTitle from 'hooks/usePageTitle'
import { selectAccount } from 'features/accountSlice'

// All filter options for dropdown
const filterOption = [
  {
    value: 'all',
    label: 'All',
    default: true,
  },
  {
    value: 'augmont',
    label: 'Metal',
  },
  {
    value: 'wallet',
    label: 'Wallet',
  },
]

// Default filter value
const filterDefaultValue = filterOption.find((item) => item.default)

const Transactions = () => {
  // const date = new Date()
  // const firstDayOfTheMonth = new Date(dayjs([date.getFullYear(), date.getMonth()]))
  const { getStatement } = useVirtualAccount()
  const { user } = useSelector(selectAuth)
  const { statements, refreshStatement, account } = useSelector(selectAccount)

  // This state will store all the filtered transaction based on user selection
  // If not selected any filter then they will store the all transactions
  const [filteredTransaction, setFilteredTransaction] = useState({
    data: [],
    filterValue: filterDefaultValue,
  })

  const [initialStatus, setInitialStatus] = useState(1)

  const [transactions, setTransactions] = useState({
    all: [],
    // debited: [],
    // credited: [],
    // pending: [],
    // failed: []
  })
  const { isReady, socket } = useSocket()

  usePageTitle('Transaction')

  useEffect(() => {
    console.log('socket', socket)
    if (isReady) {
      socket.on('orderStatus', (data) => {
        console.log('orderStatus', data)
        if (user.id === data.userId) {
          if (account?.id) {
            const date = new Date()
            const from = dayjs([
              date.getFullYear(),
              date.getMonth() - 2,
            ]).format('YYYY-MM-DD')
            // const from = dayjs([date.getFullYear(), date.getMonth()]).format('YYYY-MM-DD')
            const to = dayjs([
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
            ]).format('YYYY-MM-DD')
            getStatements({ from, to })
          }
        }
      })
    }
    return () => {
      socket?.off('orderStatus')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  useEffect(() => {
    if (account?.id) {
      if (refreshStatement) {
        const from = dayjs().subtract(2, 'month').format('YYYY-MM-DD')
        const to = dayjs().format('YYYY-MM-DD')
        getStatements({ from, to })
      }
    }
  }, [refreshStatement]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (statements.statement && statements.statement.length > 0) {
      setInitialStatus(0)
      // setTransactionStatus(1)
      setTransactions({
        all: statements.statement,
      })

      setFilteredTransaction({
        data: statements.statement,
        filterValue: filterDefaultValue,
      })
    } else {
      // setTransactionStatus(-1)
    }
  }, [statements.statement]) // eslint-disable-line react-hooks/exhaustive-deps

  const getStatements = (range, customDate = false, order = 'DESC') => {
    getStatement({ range, order, customDate })
      .then((data) => {
        if (data?.statements?.statement?.length > 0) {
          if (initialStatus === 1) {
            setInitialStatus(0)
            setTransactions({
              all: data?.statements?.statement,
            })
            console.log('called')
            setFilteredTransaction({
              data: data?.statements?.statement ?? [],
              filterValue: filterDefaultValue,
            })
          }
        } else {
          toast.error('No statements found, in between dates')
        }
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  /**
   * Use this function for apply filter for transactions like view All transaction or another
   * This will filter from already stored backend data in state "transactions"
   */
  const applyFilter = useCallback(
    (payload) => {
      let data = []

      // Apply filter as per the payload filter value
      switch (true) {
        case payload.default:
          data = transactions?.all
          break
        case payload.value === 'wallet':
          data = transactions?.all?.filter(
            (item) => item.provider !== 'augmont' && item.provider !== 'lendbox'
          )
          break
        default:
          data = transactions?.all?.filter(
            (item) => item.provider === payload.value
          )
          break
      }

      setFilteredTransaction({
        data,
        filterValue: payload,
      })
    },
    [transactions]
  )

  return (
    <Fragment>
      <CSSTransitionGroup
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Card className="main-card mb-3">
          <CardHeader className="custom-card-header">
            <div className="custom-table-header">
              <div className="table-title">Transactions</div>
              <div className="d-flex custom-tab form-item-control ml-2">
                {filterOption.map((filters) => {
                  return (
                    <label
                      key={filters.label}
                      className={`custom-tab-item metal-radio-item py-1 ${
                        filters.value === filteredTransaction.filterValue.value
                          ? 'custom-tab-item-active'
                          : ''
                      }`}
                    >
                      {filters.label}
                      <input
                        type="radio"
                        className="d-none"
                        name={filters.value}
                        checked={
                          filters.value ===
                          filteredTransaction.filterValue.value
                        }
                        value={
                          filters.value ===
                          filteredTransaction.filterValue.value
                        }
                        onClick={() => {
                          applyFilter(filters)
                        }}
                      />
                    </label>
                  )
                })}
              </div>
            </div>
          </CardHeader>
          {!filteredTransaction?.data?.length ? (
            <div className="w-100 d-flex justify-content-center align-items-center">
              <CardHeader>No Transactions Found</CardHeader>
            </div>
          ) : (
            <StatementTable
              data={filteredTransaction?.data}
              currency={statements.currency}
              description={false}
              transactionStatus={statements.status}
              initialStatus={initialStatus}
              needModal={true}
            />
          )}
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Transactions
