import { useEffect, useState } from 'react'
import { FaRegArrowAltCircleLeft } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const SelectedOperatorBBPS = ({
  category = '',
  service,
  opcode,
  showBillStatus = false,
}) => {
  const history = useHistory()
  const [operator, setOperator] = useState({
    logo: '',
    bbps: false,
  })
  const operators = useSelector((state) => state.services.bbpsOperators)

  useEffect(() => {
    let founded = false
    if (category) {
      founded = operators.find(
        (op) => op.service === category && op.id === opcode
      )
    }
    if (founded) {
      setOperator(founded)
    } else {
      history.push(`/service/${service}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operators, opcode, service])

  return (
    <div className="d-flex align-items-center justify-content-between pr-3">
      <div className="d-flex align-items-center p-2 my-2">
        {operator?.logo ? (
          <div
            style={{
              borderRadius: 50,
              // background: 'black',
              width: '50px',
              height: '50px',
              display: 'flex',
            }}
          >
            <img
              src={operator?.logo}
              alt={operator?.name}
              style={{
                width: '38px',
                height: '38px',
                margin: 'auto auto',
              }}
            />
          </div>
        ) : (
          <div
            style={{
              minWidth: '64px',
              minHeight: '64px',
              borderRadius: 50,
              backgroundColor: operator?.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2em',
              fontWeight: '500',
            }}
          >
            {operator?.name?.charAt(0)}
          </div>
        )}
        <div>
          <div className="ml-3">{operator?.name}</div>
          {/* <div className="ml-3 font-weight-bold">{operator.name}</div> */}
          {/* {operator?.bbps && <small className="ml-3 text-muted">BBPS Enabled</small>} */}
          {showBillStatus && (
            <small className="ml-3 text-muted">
              Bill Fetch: {operator?.bill ? 'Yes' : 'No'}
            </small>
          )}
          {/* {showBBPSStatus && <small className="ml-3 text-muted">BBPS: {operator?.bbps ? "Yes" : "No"}</small>} */}
        </div>
      </div>
      <FaRegArrowAltCircleLeft
        title="Back to Operators"
        size="24"
        className="text-secondary cursor-pointer"
        onClick={() => history.goBack()}
      />
    </div>
  )
}

export default SelectedOperatorBBPS
