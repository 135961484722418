import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Route } from 'react-router'
import OperatorList from '../OperatorList'
// import FormBilling from "../FormBilling"
// import MunicipalityForm from './MunicipalityForm'
import BBPSBillingForm from '../BBPSBillingForm'
import usePageTitle from 'hooks/usePageTitle'

const service = 'municipality'

const MunicipalityService = ({ match }) => {
  usePageTitle('Municipality')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Route path={`${match.url}`} exact>
          <OperatorList
            title="All Municipality Providers"
            category={service}
            url={match.url}
            showBill={true}
            endpoint="municipality"
          />
        </Route>
      </CSSTransitionGroup>
      <Route path={`${match.url}/:opcode`} exact>
        <BBPSBillingForm service={service} />
      </Route>
    </Fragment>
  )
}

export default MunicipalityService
