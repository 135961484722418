import React, { Fragment } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { setTitle } from 'utils/helper'

import pattern from 'assets/utils/images/sidebar/pattern-img_dark.png'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'
// import bg1 from "assets/utils/images/originals/city.jpg"
// import bg2 from "assets/utils/images/originals/citydark.jpg"
// import bg3 from "assets/utils/images/originals/citynights.jpg"

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
})

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
}

const ForgotPassword = () => {
  const { forgotPassword } = useAuth()
  setTitle('Forgot Password')
  const location = useLocation()
  const history = useHistory()
  const onSubmit = (data) => {
    let { from } = location.state || { from: { pathname: '/' } }
    forgotPassword(data.email).then(() => {
      setTimeout(() => {
        history.push(from)
      }, 1000)
    })
  }

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-10"
                    style={{
                      backgroundImage: 'url(' + pattern + ')',
                    }}
                  />
                </div>
                {/* <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slide-img-bg opacity-10"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Perfect Balance</h3>
                    <p>
                      ArchitectUI is like a dream. Some think it's too good to
                      be true! Extensive collection of unified React Bootstrap
                      Components and Elements.
                    </p>
                  </div>
                </div> */}
                {/* <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Scalable, Modular, Consistent</h3>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across
                      all elements and components
                    </p>
                  </div>
                </div> */}
                {/* <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }} />
                  <div className="slider-content">
                    <h3>Complex, but lightweight</h3>
                    <p>
                      We've included a lot of components that cover almost all
                      use cases for any type of application.
                    </p>
                  </div>
                </div> */}
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="10" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Forgot your Password?</div>
                <span>Use the form below to recover it.</span>
              </h4>
              <div>
                <Form
                  onFinish={onSubmit}
                  className="login100-form validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onTouched" // onBlur
                >
                  <Row form>
                    <Col md={12}>
                      <Input.Email
                        label="Email"
                        name="email"
                        placeholder="Enter email"
                        required
                        // rules={{
                        //   required: 'Email is required',
                        // }}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex align-items-center">
                    <h6 className="mb-0">
                      <Link to="/auth/login" className="text-primary">
                        Sign in existing account
                      </Link>
                    </h6>
                    <div className="ml-auto">
                      <Submit className="btn btn-primary btn-lg">
                        Recover Password
                      </Submit>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default ForgotPassword
