import { React, Fragment } from 'react'
// import { Link } from 'react-router-dom'
import PrivateRoutes from '../../routes/PrivateRoutes'
import BankDetails from './BankDetails'
import AccountDetails from './AccountDetails'
import NomineeDetails from "./NomineeDetails"
import PersonalDetails from "./PersonalDetails"
import FinishPage from './Finish'
// import PanVerification from './PanVerification'

const AccountSetupNav = ({ match }) => {
  return (
    <Fragment>
      <PrivateRoutes path={`${match.url}/account`} component={AccountDetails} />
      {/* <PrivateRoutes path={`${match.url}/pan`} component={PanVerification} /> */}
      <PrivateRoutes path={`${match.url}/bank`} component={BankDetails} />
      <PrivateRoutes path={`${match.url}/nominee`} component={NomineeDetails} />
      <PrivateRoutes path={`${match.url}/personal`} component={PersonalDetails} />
      <PrivateRoutes path={`${match.url}/finish`} component={FinishPage} />
      {/* <div className="d-flex justify-between">
        <Link className="btn btn-primary mr-3" to="account">
          Account Details
        </Link>
        <Link className="btn btn-primary mr-3" to="pan">
          Pan Verification
        </Link>
        <Link className="btn btn-primary mr-3" to="bank">
          Bank Details
        </Link>
        <Link className="btn btn-primary mr-3" to="nominee">
          Nominee Details
        </Link>
        <Link className="btn btn-primary mr-3" to="finish">
          Finish
        </Link>
      </div> */}
    </Fragment>
  )
}

export default AccountSetupNav
