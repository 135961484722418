import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fulfilled, idle, pending } from 'features/status'

import { nest } from 'backend'
import dayjs from 'dayjs'

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getRates = createAsyncThunk('augmont/rates', async () => {
  const response = await new Promise((resolve, reject) => {
    let config = null // { loader: true, msg: '' }
    nest.get(`/augmont/rates`, config).then((response) => {
      const { statusCode, result } = response
      // console.log('response', response)
      // console.log('message', message)
      if (statusCode === 200 && result.data) {
        return resolve(result)
      }
      reject()
    })
  })
  return response.data
})

export const getSipRates = createAsyncThunk('augmont/sip/rates', async () => {
  const response = await new Promise((resolve, reject) => {
    let config = null // { loader: true, msg: '' }
    nest.get(`/augmont/sip/rates`, config).then((response) => {
      const { statusCode, result } = response
      // console.log('response', response)
      // console.log('message', message)
      if (statusCode === 200 && result.data) {
        return resolve(result)
      }
      reject()
    })
  })
  return response.data
})

export const getRatesByDate = createAsyncThunk(
  'augmont/rates/old',
  async () => {
    const response = await new Promise((resolve) => {
      let config = null // { loader: true, msg: '' }
      const dateBeforeOneYear = dayjs().add(-1, 'year').format('DD-MM-YYYY')
      nest
        .get(`/augmont/rates/by-date/${dateBeforeOneYear}`, config)
        .then((response) => {
          return resolve(response)
        })
    })
    return response.data
  }
)

export const getPassbook = createAsyncThunk('augmont/passbook', async () => {
  let config = {
    loader: true,
    // msg: {
    //   loading: 'Processing...',
    // },
  }
  const response = await nest.get('/augmont/user/passbook', null, config)
  return response?.result?.data
})

export const getProfile = createAsyncThunk('augmont/profile', async () => {
  let config = {
    loader: false,
    // msg: { loading: 'retrieving' },
  }
  const response = await nest.get('/augmont/profile', null, config)
  console.log('getAugmontProfile', response)
  return response
})

export const getFDSchemas = createAsyncThunk('augmont/getFDSchemas', async () => {
  let config = {
    loader: false,
  }
  const response = await nest.get('/augmont/fd/schemes', null, config)
  return response?.result?.data
})

export const getAugmontFds = createAsyncThunk('augmont/getAugmontFds', async () => {
  let config = {
    loader: false,
  }
  const response = await nest.get('/augmont/fd/orderList', null, config)
  return response?.items || response
})

export const getStates = createAsyncThunk('augmont/states', async () => {
  let config = {
    loader: true,
    msg: {
      loading: 'Processing...',
    },
  }
  const response = await nest.get('/augmont/states?count=100', null, config)
  // console.log('getStateList', response?.result?.data)
  return response?.result?.data
})

export const getCities = createAsyncThunk('augmont/cities', async (stateId) => {
  let config = {
    loader: true,
    msg: {
      loading: 'Processing...',
    },
  }
  const response = await nest.get(`/augmont/cities/${stateId}`, null, config)
  // console.log('getCitiesByState', response?.result?.data)
  return response?.result?.data
})

// export const getGoldProducts = createAsyncThunk(
//   'augmont/goldProducts',
//   async (pagination) => {
//     const response = await new Promise((resolve, reject) => {
//       let config = { loader: true, msg: '', params: { ...pagination } }
//       nest.get(`/augmont/products/`, config).then((response) => {
//         const { statusCode, result, message } = response
//         if (statusCode === 200 && result.data) {
//           // it is used to check status of transaction
//           return resolve(result)
//         }
//         reject(message ? message : errMsg)
//       })
//     })
//     return response
//   }
// )

// export const getSilverProducts = createAsyncThunk(
//   'augmont/silverProducts',
//   async () => {
//     const response = await new Promise((resolve, reject) => {
//       let config = { loader: true, msg: '' }
//       nest.get(`/augmont/products/`, config).then((response) => {
//         const { statusCode, result, message } = response
//         if (statusCode === 200 && result.data) {
//           return resolve(result)
//         }
//         reject(message ? message : errMsg)
//       })
//     })
//     return response
//   }
// )

export const augmontSlice = createSlice({
  name: 'augmont',
  initialState: {
    rates: {
      blockId: null,
      taxes: [],
      status: idle,
      rates: {},
    },
    sipRates: {
      blockId: null,
      taxes: [],
      status: idle,
      rates: {},
    },
    oldRates: {
      status: idle,
      rates: {},
    },
    passbook: {
      goldGrms: 0,
      silverGrms: 0,
      status: idle,
    },
    augmontKyc: {
      status: 'Pending',
    },
    augmontBank: {
      status: idle,
    },
    augmontUser: {
      id: null,
      status: idle,
    },
    states: {
      items: [],
      status: idle,
    },
    cities: {
      items: [],
      status: idle,
    },
    fdSchemas: {
      items: [],
      status: idle,
    },
    augmontFDs: {
      items: [],
      status: idle,
    },
    // goldProducts: {
    //   items: [],
    //   status: idle,
    // },
    // silverProducts: {
    //   items: [],
    //   status: idle,
    // },
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loadAugmontAccount: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.augmontUser = {
      //   ...action.payload,
      //   status: fulfilled,
      // }
      if (action?.payload != null) {
        const { kyc, bank, passbook, ...rest } = action.payload
        state.augmontUser = {
          ...rest,
          status: fulfilled,
        }
        if (passbook) {
          state.passbook = {
            ...passbook,
            status: fulfilled,
          }
        }
        if (kyc) state.augmontKyc = kyc
        if (bank) state.augmontBank = bank
      }
    },
    loadPassbook: (state, action) => {
      state.passbook = {
        ...action.payload,
        status: fulfilled,
      }
    },
    loadRates: (state, action) => {
      state.rates = {
        ...action.payload,
        status: fulfilled,
      }
    },
    getGoldProducts: (state, action) => {
      if (action?.payload?.data?.data) {
        state.goldProducts.items = action?.payload?.data?.data.filter(
          (item) => item.metalType === 'gold'
        )
        state.goldProducts.state = fulfilled
      }
    },
    getSilverProducts: (state, action) => {
      if (action?.payload?.data?.data) {
        state.silverProducts.items = action?.payload?.data?.data.filter(
          (item) => item.metalType === 'silver'
        )
        state.silverProducts.state = fulfilled
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getRates.pending, (state) => {
        state.rates.status = pending
        // state.rates = { ...state.rates, status: 'loading' }
      })
      .addCase(getRates.fulfilled, (state, action) => {
        state.rates = {
          ...action.payload,
          status: fulfilled,
        }
      })
      .addCase(getSipRates.pending, (state) => {
        state.sipRates.status = pending
        // state.rates = { ...state.rates, status: 'loading' }
      })
      .addCase(getSipRates.fulfilled, (state, action) => {
        state.sipRates = {
          ...action.payload,
          status: fulfilled,
        }
      })
      .addCase(getRatesByDate.pending, (state) => {
        state.rates.status = pending
        // state.rates = { ...state.rates, status: 'loading' }
      })
      .addCase(getRatesByDate.fulfilled, (state, action) => {
        state.oldRates = {
          rates: action.payload.rates,
          status: fulfilled,
        }
      })
      .addCase(getPassbook.pending, (state) => {
        state.passbook.status = pending
        // state.passbook = { ...state.passbook, status: 'loading' }
      })
      .addCase(getPassbook.fulfilled, (state, action) => {
        state.passbook = {
          ...action.payload,
          status: fulfilled,
        }
      })
      .addCase(getStates.pending, (state) => {
        state.states.status = pending
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.states = {
          items: action.payload,
          status: fulfilled,
        }
      })
      .addCase(getCities.pending, (state) => {
        state.cities.status = pending
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.cities = {
          items: action.payload,
          status: fulfilled,
        }
      })
      .addCase(getFDSchemas.fulfilled, (state, action) => {
        state.fdSchemas = {
          items: action.payload,
          status: fulfilled,
        }
      })
      .addCase(getAugmontFds.fulfilled, (state, action) => {
        state.augmontFDs = {
          items: action.payload,
          status: fulfilled,
        }
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        const { kyc, bank, passbook, ...rest } = action.payload
        state.augmontUser = {
          ...rest,
          status: fulfilled,
        }
        if (passbook) {
          state.passbook = {
            ...passbook,
            status: fulfilled,
          }
        }
        if (kyc) state.augmontKyc = kyc
        if (bank) state.augmontBank = bank
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  loadAugmontAccount: loadAugmontUser,
  loadPassbook,
  loadRates,
} = augmontSlice.actions

export const augmontActions = augmontSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.augmont.value)`

export const selectAugmont = (state) => state.augmont
export const selectGoldFDs = (state) => state.augmont.fdSchemas
export const selectRates = (state) => state.augmont.rates
export const selectPassbook = (state) => state.augmont.passbook
export const selectAugmontUser = (state) => state.augmont.augmontUser

export default augmontSlice.reducer
