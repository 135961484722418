import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { nest } from 'backend'
import { fulfilled, idle, pending, rejected } from 'features/status'

export const getIssuers = createAsyncThunk('fixerra/getIssuers', async () => {
  let config = {
    loader: true,
    msg: { loading: 'Processing...' },
  }
  const response = await nest.get(`/fixerra/get-all-issuers`, null, config)
  return response
})

export const getFixerraCollections = createAsyncThunk(
  'fixerra/getCollections',
  async () => {
    let config = {
      loader: true,
      msg: { loading: 'Processing...' },
    }
    const response = await nest.get(`/fixerra/get-fd-collections`, null, config)
    return response
  }
)

export const getSchemesByIssuer = createAsyncThunk(
  'fixerra/fdSchemes',
  async (f_code, { rejectWithValue }) => {
    let config = {
      loader: true,
      msg: { loading: 'Processing...' },
    }
    const response = await nest.get(
      `/fixerra/get-fd-schemes/${f_code}`,
      null,
      config
    )
    if (response.statusCode === 400) {
      return rejectWithValue('No value')
    }
    return response?.payload
  }
)

export const getFixerraMyFds = createAsyncThunk('fixerra/getMyFds', async () => {
  let config = {
    loader: true,
    msg: { loading: 'Processing...' },
  }
  const response = await nest.get(`/fixerra/my-fds`, null, config)
  return response
})

export const getFixerraPortfolio = createAsyncThunk(
  'fixerra/getMyPortfolio',
  async () => {
    let config = {
      loader: true,
      msg: { loading: 'Processing...' },
    }
    const response = await nest.get(`/fixerra/portfolio-view`, null, config)
    return response
  }
)

export const getUpcomingMaturityFd = createAsyncThunk(
  'fixerra/getUpcomingMaturityFds',
  async () => {
    let config = {
      loader: true,
      msg: { loading: 'Processing...' },
    }
    const response = await nest.get(
      `/fixerra/upcoming-maturity-fd`,
      null,
      config
    )
    return response
  }
)

export const fixerraSlice = createSlice({
  name: 'fixerra',
  initialState: {
    issuers: { state: idle, items: [] },
    collections: { state: idle, items: [] },
    fds: { state: idle, items: [] },
    fdSchemes: { state: idle, items: [] },
    portfolio: { state: idle, data: {} },
    upcomingMaturityFd: { state: idle, items: [] },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIssuers.pending, (state) => {
        state.issuers.state = pending
      })
      .addCase(getIssuers.fulfilled, (state, action) => {
        state.issuers.state = fulfilled
        state.issuers.items = action.payload
      })
      .addCase(getFixerraCollections.pending, (state) => {
        state.collections.state = pending
      })
      .addCase(getFixerraCollections.fulfilled, (state, action) => {
        state.collections.state = fulfilled
        state.collections.items = action.payload.payload
      })
      .addCase(getFixerraMyFds.pending, (state) => {
        state.fds.state = pending
      })
      .addCase(getFixerraMyFds.fulfilled, (state, action) => {
        state.fds.state = fulfilled
        state.fds.items = action.payload?.items || []
      })
      .addCase(getFixerraMyFds.rejected, (state) => {
        state.fds.state = rejected
      })
      .addCase(getFixerraPortfolio.pending, (state) => {
        state.portfolio.state = pending
      })
      .addCase(getFixerraPortfolio.fulfilled, (state, action) => {
        state.portfolio.state = fulfilled
        state.portfolio.data = action.payload?.payload
      })
      .addCase(getFixerraPortfolio.rejected, (state) => {
        state.portfolio.state = rejected
      })
      .addCase(getUpcomingMaturityFd.pending, (state) => {
        state.upcomingMaturityFd.state = pending
      })
      .addCase(getUpcomingMaturityFd.fulfilled, (state, action) => {
        state.upcomingMaturityFd.state = fulfilled
        state.upcomingMaturityFd.items = action.payload?.payload
      })
      .addCase(getUpcomingMaturityFd.rejected, (state) => {
        state.upcomingMaturityFd.state = rejected
      })
      .addCase(getSchemesByIssuer.pending, (state) => {
        state.fdSchemes.state = pending
      })
      .addCase(getSchemesByIssuer.fulfilled, (state, action) => {
        state.fdSchemes.state = fulfilled
        state.fdSchemes.items = [...state.fdSchemes.items, action.payload]
      })
      .addCase(getSchemesByIssuer.rejected, (state) => {
        state.fdSchemes.state = rejected
      })
  },
})

export const fixerraActions = fixerraSlice.actions

export const selectFixerra = (state) => state.fixerra

export default fixerraSlice.reducer
