import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'

const TabGroup = (props) => {
  const {
    register,
    getValues,
    formState: { errors },
    setValue: setValue2,
  } = useFormContext()

  const { name, label, defaultValue, required = false, options = [] } = props

  const [value, setValue] = useState(options.length > 0 ? options[0].value : '')

  useEffect(() => {
    if (value) {
      setValue2(name, value)
    } else if (defaultValue) {
      setValue(defaultValue)
      setValue2(name, defaultValue)
    } else {
      setValue(getValues(name))
      setValue2(name, getValues(name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name, errors])

  return (
    <div className="form-group">
      <label htmlFor={name} className={cx({ required })}>
        {label}
      </label>
      <div className="d-flex custom-tab form-item-control">
        {options.map((option) => (
          <label
            className={`custom-tab-item ${
              option.value === value ? 'custom-tab-item-active' : ''
            }`}
            key={option.value}
          >
            {option.label}
            <input
              type="radio"
              key={option.label + option.value}
              className="d-none"
              value={option.label}
              {...register(name, {
                onChange: (e) => {
                  setValue(e.target.value)
                  setValue2(e.target.value)
                  props?.onChange && props?.onChange(e)
                },
              })}
            />
          </label>
        ))}
      </div>
      {errors[name]?.message && (
        <div className="invalid-feedback">{errors[name]?.message}</div>
      )}
    </div>
  )
}

export default TabGroup
