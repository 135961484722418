import React, { useState, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Card, Col, Row, Button, Checkbox, Flex } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
// import goldSvg from 'assets/svg/gold.svg'
// import { useBank } from 'backend/useBank'
import { formattedPrice } from 'utils/helper'
import { usePin } from 'context/PinContext'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
// import { buyMetal } from 'features/augmont/augmontApi'
import {
  getRates,
  // getSipRates,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { investmentType, metalType, providers } from 'utils/constants'
import { useInvestment } from 'backend/useInvestment'
import { idle } from 'features/status'
// import MandateSignModal from './MandateSignModal'
import { toast } from 'react-toastify'
// import { selectAuth } from 'features/auth/authSlice'
import { uiActions } from 'features/uiSlice'
import { useLog } from 'backend/useLog'
import { useAugmont } from 'backend/useAugmont'
import usePageTitle from 'hooks/usePageTitle'
import UserConsent from 'Components/UserConsent'
// import { getRates } from 'features/augmont/augmontSlice'
const short = require('short-uuid')

const CheckoutAugmont = () => {
  usePageTitle('Long Term')
  // const { getStatement } = useBank()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [buySellRateAsPerTab, setBuySellRateAsPerTab] = useState(0)
  const [checked, setChecked] = useState(true)
  const [acceptCheck, setAcceptCheck] = useState(true)
  const [total, setTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [taxTotal, setTaxTotal] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const { checkPin } = usePin()
  // const { user } = useSelector(selectAuth)
  // const [isOpenMandate, setIsOpenMandate] = useState(false)
  // const [order, setOrder] = useState({ id: '' })
  // const [walletBalance] = useState(virtualAccount.walletBalance)
  const { doInvestment } = useInvestment()
  const { addFund, mandateRegister } = useAugmont()
  const { createLog } = useLog()

  const { rates } = useSelector(selectAugmont)
  // const [currentRates, setCurrentRates] = useState(rates)

  useEffect(() => {
    if (rates.status === idle) {
      dispatch(getRates())
    }
    // if (location.state.meta.investmentType === investmentType.SIP) {
    //   if (sipRates.status === idle) {
    //     dispatch(getSipRates())
    //   }
    // }
    // if (location.state.meta.investmentType === investmentType.Lumpsum) {
    //   if (rates.status === idle) {
    //     dispatch(getRates())
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state.meta.investmentType])

  useEffect(() => {
    if (location.state.meta.investmentType === investmentType.SIP) {
      // if (sipRates?.rates) {
      //   // setCurrentRates(sipRates)
      //   if (location.state.meta.metalType === metalType.Gold) {
      //     setBuySellRateAsPerTab(sipRates?.rates?.gBuy)
      //     calculateTotalAndTax(sipRates?.rates?.gBuy, sipRates?.rates?.gBuyGst)
      //   } else if (location.state.meta.metalType === metalType.Silver) {
      //     setBuySellRateAsPerTab(sipRates?.rates?.sBuy)
      //     calculateTotalAndTax(sipRates?.rates?.sBuy, rates?.rates?.sBuyGst)
      //   }
      // }
      if (rates?.rates) {
        // setCurrentRates(rates)
        if (location.state.meta.metalType === metalType.Gold) {
          setBuySellRateAsPerTab(rates?.rates?.gBuy)
          calculateTotalAndTax(rates?.rates?.gBuy, rates?.rates?.gBuyGst)
        } else if (location.state.meta.metalType === metalType.Silver) {
          setBuySellRateAsPerTab(rates?.rates?.sBuy)
          calculateTotalAndTax(rates?.rates?.sBuy, rates?.rates?.sBuyGst)
        }
      }
    }
    if (location.state.meta.investmentType === investmentType.Lumpsum) {
      if (rates?.rates) {
        // setCurrentRates(rates)
        if (location.state.meta.metalType === metalType.Gold) {
          setBuySellRateAsPerTab(rates?.rates?.gBuy)
          calculateTotalAndTax(rates?.rates?.gBuy, rates?.rates?.gBuyGst)
        } else if (location.state.meta.metalType === metalType.Silver) {
          setBuySellRateAsPerTab(rates?.rates?.sBuy)
          calculateTotalAndTax(rates?.rates?.sBuy, rates?.rates?.sBuyGst)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rates?.rates,
    location.state.meta.metalType,
    location.state.meta.investmentType,
  ])

  const calculateTotalAndTax = async (rate, tax) => {
    let qty = ''
    let totalPrice = 0
    // if (location.state.quantity) {
    //   qty = location.state.quantity
    //   totalPrice = qty * rate
    // } else {
    //   qty = location.state.amount / rate
    //   totalPrice = location.state.amount
    // }
    qty = location.state.meta.total / rate
    totalPrice = location.state.meta.total

    // const totalTax = await rates.taxes
    //   .filter((item) => item.type !== 'IGST')
    //   .reduce((prev, next) => {
    //     return prev + parseFloat((next.taxPerc * totalPrice) / 100)
    //   }, 0)
    // const taxRoundUp = Math.ceil(tax)
    setQuantity(qty)
    setTaxTotal(Math.round(tax * qty))
    setSubTotal(totalPrice)
    // setTotal((totalPrice + parseFloat(taxRoundUp) * qty).toFixed(2))
    setTotal(Math.round(totalPrice + parseFloat(tax) * qty).toFixed(2))
  }

  const validatePin = () => {
    checkPin('', handleCheckout, 'Goal Checkout Augmont')
    // if (location.state.meta.investmentType === investmentType.SIP) {
    //   checkPin('', handleCheckout, 'Goal Checkout Augmont')
    // } else {
    //   toast.info('Lumpsum implementation is pending')
    // }
  }

  // const handleCheckout = () => {
  //   const payload = {
  //     checkout: {
  //       ...location.state.services[0],
  //       // amount: total,
  //       gst: taxTotal,
  //     },
  //     goal: { ...location.state?.goal, gst: taxTotal },
  //     // provider: location.state.provider,
  //     uuid: short.generate(),
  //   }
  //   if (location.state.meta.operator.endpoint === providers.augmont) {
  //     // doInvestment(payload).then((res) => {
  //     //   history.push('/dashboard')
  //     // })
  //     if (location.state.meta.investmentType === investmentType.SIP) {
  //       doInvestment(payload).then((data) => {
  //         setOrder(data.item)
  //         setIsOpenMandate(true)
  //         // alert('done')
  //         // console.log('doInvestment', data)
  //       })
  //     }
  //   }
  // }

  const openRazorPay = (options) => {
    const razorPayOptions = {
      ...options,
      // amount: 500000,
      // orderId: 'order_M4UpQ3lzlWWX3G',
      // notes: {
      //   productInfo: 'PA Add funds',
      // },
      // key: 'rzp_live_YuOAO1TLGadXVB',
      // custName: 'VIKESH AGRAVAT',
      // email: 'vicky.agravat@gmail.com',
      // mobile: '9824256363',
      // currency: 'INR',
      // prefill: {
      //   name: resData.data.custName,
      //   contact: resData.data.mobile,
      //   email: resData.data.email,
      // },
      name: 'Kashewnut',
      image: 'https://cdn.kashewnut.com/img/logo-for-razorpay.png',
      // description: location.state.services[0]?.description,
      // handler: function (response) {
      //   if (response.razorpay_payment_id) {
      //     // toast.success('Payment success')
      //     // doInvestment(payload).then((res) => {
      //     //   history.push('/dashboard')
      //     // })
      //   }
      // },
    }
    console.log('razorPayOptions', razorPayOptions)

    var rzpIntent = new window.Razorpay(razorPayOptions)
    rzpIntent.open()
    rzpIntent.on('payment.failed', function (response) {
      createLog({
        name: 'rzorpay.frontend.log',
        body: response.error,
      })
      console.log(response.error)
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
  }

  const handleCheckout = () => {
    if (location.state.meta.operator.endpoint === providers.augmont) {
      if (location.state.meta.investmentType === investmentType.SIP) {
        // https://razorpay.com/docs/api/payments/recurring-payments/emandate/create-authorization-transaction/#113-create-an-authorization-payment
        mandateRegister({
          amount: total,
        }).then(async (resData) => {
          console.log('mandateRegister', resData)
          if (resData.success) {
            const payload = {
              checkout: {
                ...location.state.services[0],
              },
              goal: {
                ...location.state?.goal,
                amount: subTotal,
                gst: taxTotal,
                orderId: resData.data.orderId,
                partner: resData.data.partner,
              },
              // provider: location.state.provider,
              uuid: short.generate(),
            }
            await doInvestment(payload)
            const razorPayOptions = {
              key: resData.data.key,
              amount: resData.data.amount,
              order_id: resData.data.orderId,
              customer_id: resData.data.custId,
              method: resData.data.method,
              recurring: resData.data.recurring,
              expireBy: resData.data.expireBy,
              max_amount: resData.data.maxAmount,
              // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
              prefill: {
                name: resData.data.custName,
                email: resData.data.email,
                contact: resData.data.contact,
              },
              notes: resData.data.notes,
              description: location.state.services[0]?.description,
              handler: function (response) {
                if (response.razorpay_payment_id) {
                  createLog({
                    name: 'razorpay.mandate.success',
                    body: response,
                  })
                  console.log(response)
                  toast.success('Mandate signed success')
                  successCallback()
                  // updateInvestmentStatus(resData.data.orderId, {
                  //   status: 'Active',
                  // }).then((res) => {
                  //   successCallback()
                  // })
                }
              },
            }
            openRazorPay(razorPayOptions)
          }
        })
      } else {
        addFund({
          amount: total,
        }).then(async (resData) => {
          console.log('addFund', resData)
          if (resData.success) {
            const payload = {
              checkout: {
                ...location.state.services[0],
              },
              goal: {
                ...location.state?.goal,
                amount: subTotal,
                gst: taxTotal,
                // amountGoal: total,
                orderId: resData.data.orderId,
                partner: resData.data.partner,
              },
              // provider: location.state.provider,
              uuid: short.generate(),
            }
            await doInvestment(payload)
            const razorPayOptions = {
              key: resData.data.key,
              amount: resData.data.amount,
              currency: resData.data.currency,
              order_id: resData.data.orderId,
              // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
              prefill: {
                name: resData.data.custName,
                email: resData.data.email,
                contact: resData.data.contact,
              },
              notes: resData.data.notes,
              description: location.state.services[0]?.description,
              handler: function (response) {
                if (response.razorpay_payment_id) {
                  createLog({
                    name: 'razorpay.payment.success',
                    body: response,
                  })
                  console.log(response)
                  toast.success('Payment success')
                  successCallback()
                  // updateInvestmentStatus(resData.data.orderId, {
                  //   status: 'Active',
                  // }).then((res) => {
                  //   successCallback()
                  // })
                  // doInvestment(payload).then((res) => {
                  //   history.push('/dashboard')
                  // })
                }
              },
            }
            openRazorPay(razorPayOptions)
          }
        })
      }
    }
  }

  const successCallback = () => {
    dispatch(
      uiActions.showSuccessModal({
        title: 'Success',
        description: `${location.state.services[0].description} is in progress`,
        buttonText: 'Go Back To Dashboard',
        redirectUrl: '/dashboard',
      })
    )
    // history.push('/dashboard')
  }

  const handleBack = () => {
    history.goBack()
    // if (location.state.meta.metalType === 'Gold') {
    //   history.push('/investment/gold')
    // } else {
    //   history.push('/investment/silver')
    // }
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={16}>
            <Card className="main-card card" title="Checkout">
              <table className="align-middle text-truncate table table-borderless mb-0">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-center">Quantity(gms)</th>
                    <th className="text-right">Current Rates(per gms)</th>
                    <th className="text-right">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {location.state.services.map((service, i) => (
                    <tr key={i}>
                      <td style={{ width: '60%' }}>
                        <div>{service.name}</div>
                        <small>{service.description}</small>
                      </td>
                      <td className="text-center">{quantity}</td>
                      <td className="text-right">
                        {formattedPrice(buySellRateAsPerTab)}
                      </td>
                      <td className="text-right font-weight-bold">
                        {formattedPrice(service.amount * service.quantity)}
                      </td>
                    </tr>
                  ))}
                  {/* <tr>
                    <td>{location.state.meta.metalType}</td>
                    <td className="text-center">{quantity}</td>
                    <td className="text-right">
                      {formattedPrice(buySellRateAsPerTab)}
                    </td>
                    <td className="text-right">
                      <b>{formattedPrice(subTotal)}</b>
                    </td>
                  </tr> */}
                  {rates.taxes
                    .filter((item) => item.type !== 'IGST')
                    .map((tax, i) => (
                      <tr className="bg-light" key={i}>
                        <td>
                          <small>{tax.type}</small>
                        </td>
                        <td className="text-center"></td>
                        <td className="text-right">{tax.taxPerc}%</td>
                        <td className="text-right">
                          <b>
                            {formattedPrice((tax.taxPerc * subTotal) / 100)}
                          </b>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <table className="align-middle text-truncate table table-borderless mb-0">
                <tbody>
                  <tr className="border-bottom">
                    <td className="text-right" colSpan={3}></td>
                  </tr>
                  <tr className="small">
                    <td className="text-right" colSpan={2}>
                      Subtotal:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(subTotal)}
                    </td>
                  </tr>
                  <tr className="small border-bottom">
                    <td className="text-right" colSpan={2}>
                      Tax:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(taxTotal)}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0" style={{ width: '75%' }}>
                      <Button
                        icon={<FaArrowLeft />}
                        type="text"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </td>
                    <td className="text-right font-weight-bold">Total:</td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div className="mt-4">
              <UserConsent
                message="*I hereby give consent to share my data with Augmont Goldtech Pvt. Ltd."
                onAccept={(accepted) => setAcceptCheck(accepted)}
              />
            </div>
          </Col>
          <Col span={24} md={8}>
            <Card className="main-card card" title="Payment">
              <div className="mb-2">Payment Method</div>
              <Flex justify="space-between">
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                >
                  RazorPay
                </Checkbox>
                <b>{formattedPrice(total)}</b>
              </Flex>
              {/* {location.state.meta.investmentType === investmentType.SIP && (
                <Flex justify="space-between">
                  <Checkbox
                    onChange={() => setChecked(!checked)}
                    checked={checked}
                  >
                    Digio Mandate
                  </Checkbox>
                  <b>{formattedPrice(total)}</b>
                </Flex>
              )} */}
              {/* {location.state.meta.investmentType ===
                investmentType.Lumpsum && (
                <Flex justify="space-between">
                  <Checkbox
                    onChange={() => setChecked(!checked)}
                    checked={checked}
                  >
                    RazorPay
                  </Checkbox>
                  <b>{formattedPrice(total)}</b>
                </Flex>
              )} */}
              <div className="mt-5">
                <Button
                  onClick={validatePin}
                  icon={<FaArrowRight />}
                  iconPosition="end"
                  disabled={!checked || !acceptCheck}
                  type="primary"
                  block
                >
                  Checkout
                </Button>
              </div>
            </Card>
          </Col>
          <Col span={24}>
            {/* <pre>{JSON.stringify(currentRates, null, 2)}</pre> */}
            {/* <pre>
                {JSON.stringify({ ...location.state, amount: total }, null, 2)}
              </pre> */}
            {/* <pre>{JSON.stringify(location.state, null, 2)}</pre> */}
          </Col>
        </Row>
      </CSSTransitionGroup>
      {/* {isOpenMandate && (
        <MandateSignModal
          isOpen={isOpenMandate}
          toggle={() => setIsOpenMandate(!isOpenMandate)}
          bankDetails={augmontBank}
          amount={total}
          user={user}
          order={order}
          successCallback={successCallback}
        />
      )} */}
    </Fragment>
  )
}

export default CheckoutAugmont
