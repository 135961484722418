import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import showLoader from 'Components/Loader'
import { useNest, errMsg } from './useNest'
import { accountActions } from 'features/accountSlice'
import { isEmpty } from 'utils/validation'
// import { history } from '../../index'

export const useVirtualAccount = () => {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const createVirtualAccount = () =>
    new Promise((resolve, reject) => {
      return resolve(true)
      //   let config = {
      //     loader,
      //     msg: { loading: 'Creating...', success: 'Account Created.' },
      //   }
      //   backend()
      //     .post('/bank/create-virtual-account', {}, config)
      //     .then(({ data, message }) => {
      //       console.log(data)
      //       dispatch(accountActions.loadVirtualAccount(data.account))
      //       return resolve(data)
      //     })
      //     .catch((error) => {
      //       reject(error.response ? error.response.data.message : errMsg)
      //     })
    })

  const getBalance = () =>
    new Promise((resolve, reject) => {
      backend()
        .get('/bank/balance')
        .then(({ data }) => {
          dispatch(accountActions.loadVirtualAccountBalance(data))
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })

  const getStatement = ({
    range = {},
    order = 'DESC',
    limit = -1,
    customDate = false,
  } = {}) =>
    new Promise((resolve, reject) => {
      if (isEmpty(range)) {
        range = {
          from: dayjs().subtract(2, 'month').format('YYYY-MM-DD'),
          to: dayjs().format('YYYY-MM-DD'),
        }
      }
      backend()
        .post('/bank/statement', {
          from: range.from,
          to: range.to,
          order: order,
          limit: limit,
          customDate,
        })
        .then(({ data }) => {
          // console.log('statement', data)
          if (data?.statements?.statement?.length > 0) {
            dispatch(accountActions.loadStatements(data.statements))
          }
          return resolve(data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })

  const getCreditScore = () =>
    new Promise((resolve, reject) => {
      showLoader(true)
      backend()
        .get('/bank/credit-score')
        .then(({ data }) => {
          dispatch(accountActions.loadCreditScore(data.creditScore))
          return resolve(data.creditScore)
        })
        .catch((error) => {
          toast.error(error.message || errMsg)
          reject(error)
        })
        .finally(() => {
          showLoader(false)
        })
    })

  const manageBeneficiary = (payload = {}) =>
    new Promise((resolve, reject) => {
      backend()
        .post('/beneficiary', payload)
        .then(({ data }) => {
          dispatch(accountActions.loadBeneficiaries(data.beneficiaries))
          return resolve(data)
        })
        .catch((error) => {
          toast.error(error.message)
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const searchByISFC = (isfc = '') =>
    new Promise((resolve, reject) => {
      backend()
        .get(`bank/isfc/${isfc}`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const initiateTransfer = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing payment...' },
      }
      backend()
        .post('/bank/initiate-transfer', payload, config)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const withdrawTransfer = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing payment withdrawal...' },
      }
      backend()
        .post('/bank/withdraw', payload, config)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })

  return {
    createVirtualAccount,
    getBalance,
    getStatement,
    getCreditScore,
    manageBeneficiary,
    searchByISFC,
    initiateTransfer,
    withdrawTransfer,
  }
}
