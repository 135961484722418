import React, { useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Button, Card, Checkbox, Col, Flex, Row } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useVirtualAccount } from 'backend/useVirtualAccount'
import { formattedPrice } from 'utils/helper'
import { usePin } from 'context/PinContext'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useBBPS } from 'backend/useBBPS'
import { useRecharge } from 'backend/useRecharge'
// import { calculateInvestmentAmount } from 'business/logic'
// import { selectAugmontUser } from 'features/augmont/augmontSlice'
import { useFasTag } from 'backend/useFasTag'
import { useMunicipality } from 'backend/useMunicipality'
import { useGiftCard } from 'backend/useGiftCard'
import { usePhonePay } from 'backend/usePhonePay'
import { providers } from 'utils/constants'
import { selectAccount } from 'features/accountSlice'
import { uiActions } from 'features/uiSlice'
import usePageTitle from 'hooks/usePageTitle'
import { useLog } from 'backend/useLog'
import { toast } from 'react-toastify'

const short = require('short-uuid')

const CheckoutServices = () => {
  const dispatch = useDispatch()
  const { getStatement } = useVirtualAccount()
  const location = useLocation()
  const history = useHistory()
  const { virtualAccount } = useSelector(selectAccount)
  // const { accountCreated } = useSelector(selectAugmontUser)
  // const [accountCreated] = useState(true)
  const [useWallet, setUseWallet] = useState(true)
  const [autoPayment, setAutoPayment] = useState(true)
  // const [invest, setInvest] = useState(accountCreated)
  const { checkPin } = usePin()
  const [walletBalance] = useState(virtualAccount.walletBalance)
  const { payBill, mandateRegister: mandateRegisterBbps } = useBBPS()
  const { doRecharge } = useRecharge()
  const { doFasTagRecharge } = useFasTag()
  const { payBill: payMuniBill } = useMunicipality()
  const { makeQwikCilverOrder } = useGiftCard()
  const { addMoney } = usePhonePay()
  const { createLog } = useLog()

  usePageTitle('Checkout Options')
  let total = 0
  let subTotal = 0
  let totalPrice = 0
  let investAmount = 0
  let tax = 0

  if (location.state.services.length > 0) {
    totalPrice = location.state.services.reduce((prev, next) => {
      return prev + parseFloat(next.amount * next.quantity)
    }, 0)
    // if (accountCreated) {
    //   investAmount = invest ? calculateInvestmentAmount(totalPrice) : 0
    // }
    subTotal = totalPrice + investAmount
    total = subTotal + parseFloat(tax)
  }

  const validatePin = () => {
    checkPin('', handleCheckout, `To checkout`)
  }

  const redirectToDashboard = () => {
    getStatement({ limit: 10 })
    // setTimeout(() => {
    //   history.push('/dashboard')
    // }, 300)
    successCallback()
  }

  const openRazorPay = (options) => {
    const razorPayOptions = {
      ...options,
      name: 'Kashewnut',
      image: 'https://cdn.kashewnut.com/img/logo-for-razorpay.png',
      // description: location.state.services[0]?.description,
      // handler: function (response) {
      //   if (response.razorpay_payment_id) {
      //     // toast.success('Payment success')
      //     // doInvestment(payload).then((res) => {
      //     //   history.push('/dashboard')
      //     // })
      //   }
      // },
    }
    console.log('razorPayOptions', razorPayOptions)

    var rzpIntent = new window.Razorpay(razorPayOptions)
    rzpIntent.open()
    rzpIntent.on('payment.failed', function (response) {
      createLog({
        name: 'rzorpay.frontend.log',
        body: response.error,
      })
      console.log(response.error)
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
  }

  const handleCheckout = () => {
    // const payload = location.state
    const payload = {
      checkout: {
        ...location.state.services[0],
      },
      provider: {
        ...location.state.provider,
        latitude: '27.2232',
        longitude: '78.26535',
      },
      autoPayment,
      uuid: short.generate(),
      latitude: '27.2232',
      longitude: '78.26535',
    }
    console.log({ payload })
    // if (invest) {
    //   payload.invest = {
    //     amount: investAmount,
    //     investIn: 'gold',
    //     startDate: dayjs(new Date()).format('YYYY-MM-DD'),
    //     duration: 1,
    //     frequency: 'single',
    //   }
    // }
    if (location.state.meta.operator.endpoint === 'rechargeV1') {
      doRecharge(payload).then((res) => {
        if (autoPayment) {
          mandateRegisterBbps({
            amount: location.state.services[0]?.amount,
            // goalId:2345665
          })
            .then(async (resData) => {
              if (resData.success) {
                const razorPayOptions = {
                  key: resData.data.key,
                  amount: resData.data.amount,
                  order_id: resData.data.orderId,
                  customer_id: resData.data.custId,
                  method: resData.data.method,
                  recurring: resData.data.recurring,
                  expireBy: resData.data.expireBy,
                  max_amount: resData.data.maxAmount,
                  // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
                  prefill: {
                    name: resData.data.custName,
                    email: resData.data.email,
                    contact: resData.data.contact,
                  },
                  // notes: {
                  //   product_info: resData.data.notes.productInfo,
                  // },
                  // description: location.state.services[0]?.description,
                  handler: function (response) {
                    if (response.razorpay_payment_id) {
                      createLog({
                        name: 'razorpay.mandate.success',
                        body: response,
                      })
                      console.log(response)
                      toast.success('Mandate signed success')
                      successCallback()
                      // updateInvestmentStatus(resData.data.orderId, {
                      //   status: 'Active',
                      // }).then((res) => {
                      //   successCallback()
                      //   // history.push('/dashboard')
                      // })
                      // doInvestment(payload).then((res) => {
                      //   history.push('/dashboard')
                      // })
                    }
                  },
                }
                openRazorPay(razorPayOptions)
              }
            })
            .catch((err) => {
              console.log('Error', err)
            })
        } else {
          redirectToDashboard()
        }
      })
    }

    if (location.state.meta.operator.endpoint === 'rechargeV1') {
      doRecharge(payload).then((res) => {
        if (autoPayment) {
          mandateRegisterBbps({
            amount: location.state.services[0]?.amount,
            // goalId:2345665
          })
            .then(async (resData) => {
              if (resData.success) {
                const razorPayOptions = {
                  key: resData.data.key,
                  amount: resData.data.amount,
                  order_id: resData.data.orderId,
                  customer_id: resData.data.custId,
                  method: resData.data.method,
                  recurring: resData.data.recurring,
                  expireBy: resData.data.expireBy,
                  max_amount: resData.data.maxAmount,
                  // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
                  prefill: {
                    name: resData.data.custName,
                    email: resData.data.email,
                    contact: resData.data.contact,
                  },
                  // notes: {
                  //   product_info: resData.data.notes.productInfo,
                  // },
                  // description: location.state.services[0]?.description,
                  handler: function (response) {
                    if (response.razorpay_payment_id) {
                      createLog({
                        name: 'razorpay.mandate.success',
                        body: response,
                      })
                      console.log(response)
                      toast.success('Mandate signed success')
                      successCallback()
                      // updateInvestmentStatus(resData.data.orderId, {
                      //   status: 'Active',
                      // }).then((res) => {
                      //   successCallback()
                      //   // history.push('/dashboard')
                      // })
                      // doInvestment(payload).then((res) => {
                      //   history.push('/dashboard')
                      // })
                    }
                  },
                }
                openRazorPay(razorPayOptions)
              }
            })
            .catch((err) => {
              console.log('Error', err)
            })
        } else {
          redirectToDashboard()
        }
      })
    }

    if (location.state.meta.operator.endpoint === 'fasTag') {
      doFasTagRecharge(payload).then((res) => {
        if (autoPayment) {
          mandateRegisterBbps({
            amount: location.state.services[0]?.amount,
            // goalId:2345665
          })
            .then(async (resData) => {
              if (resData.success) {
                const razorPayOptions = {
                  key: resData.data.key,
                  amount: resData.data.amount,
                  order_id: resData.data.orderId,
                  customer_id: resData.data.custId,
                  method: resData.data.method,
                  recurring: resData.data.recurring,
                  expireBy: resData.data.expireBy,
                  max_amount: resData.data.maxAmount,
                  // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
                  prefill: {
                    name: resData.data.custName,
                    email: resData.data.email,
                    contact: resData.data.contact,
                  },
                  // notes: {
                  //   product_info: resData.data.notes.productInfo,
                  // },
                  // description: location.state.services[0]?.description,
                  handler: function (response) {
                    if (response.razorpay_payment_id) {
                      createLog({
                        name: 'razorpay.mandate.success',
                        body: response,
                      })
                      console.log(response)
                      toast.success('Mandate signed success')
                      successCallback()
                      // updateInvestmentStatus(resData.data.orderId, {
                      //   status: 'Active',
                      // }).then((res) => {
                      //   successCallback()
                      //   // history.push('/dashboard')
                      // })
                      // doInvestment(payload).then((res) => {
                      //   history.push('/dashboard')
                      // })
                    }
                  },
                }
                openRazorPay(razorPayOptions)
              }
            })
            .catch((err) => {
              console.log('Error', err)
            })
        } else {
          redirectToDashboard()
        }
      })
    }

    if (location.state.meta.operator.endpoint === 'bbps') {
      payBill(payload).then((res) => {
        if (autoPayment) {
          mandateRegisterBbps({
            amount: location.state.services[0]?.amount,
            // goalId:2345665
          })
            .then(async (resData) => {
              if (resData.success) {
                const razorPayOptions = {
                  key: resData.data.key,
                  amount: resData.data.amount,
                  order_id: resData.data.orderId,
                  customer_id: resData.data.custId,
                  method: resData.data.method,
                  recurring: resData.data.recurring,
                  expireBy: resData.data.expireBy,
                  max_amount: resData.data.maxAmount,
                  // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
                  prefill: {
                    name: resData.data.custName,
                    email: resData.data.email,
                    contact: resData.data.contact,
                  },
                  // notes: {
                  //   product_info: resData.data.notes.productInfo,
                  // },
                  // description: location.state.services[0]?.description,
                  handler: function (response) {
                    if (response.razorpay_payment_id) {
                      createLog({
                        name: 'razorpay.mandate.success',
                        body: response,
                      })
                      console.log(response)
                      toast.success('Mandate signed success')
                      successCallback()
                      // updateInvestmentStatus(resData.data.orderId, {
                      //   status: 'Active',
                      // }).then((res) => {
                      //   successCallback()
                      //   // history.push('/dashboard')
                      // })
                      // doInvestment(payload).then((res) => {
                      //   history.push('/dashboard')
                      // })
                    }
                  },
                }
                openRazorPay(razorPayOptions)
              }
            })
            .catch((err) => {
              console.log('Error', err)
            })
        } else {
          redirectToDashboard()
        }
      })
    }

    if (location.state.meta.operator.endpoint === 'municipality') {
      payMuniBill(payload).then((res) => {
        if (autoPayment) {
          mandateRegisterBbps({
            amount: location.state.services[0]?.amount,
            // goalId:2345665
          })
            .then(async (resData) => {
              if (resData.success) {
                const razorPayOptions = {
                  key: resData.data.key,
                  amount: resData.data.amount,
                  order_id: resData.data.orderId,
                  customer_id: resData.data.custId,
                  method: resData.data.method,
                  recurring: resData.data.recurring,
                  expireBy: resData.data.expireBy,
                  max_amount: resData.data.maxAmount,
                  // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
                  prefill: {
                    name: resData.data.custName,
                    email: resData.data.email,
                    contact: resData.data.contact,
                  },
                  // notes: {
                  //   product_info: resData.data.notes.productInfo,
                  // },
                  // description: location.state.services[0]?.description,
                  handler: function (response) {
                    if (response.razorpay_payment_id) {
                      createLog({
                        name: 'razorpay.mandate.success',
                        body: response,
                      })
                      console.log(response)
                      toast.success('Mandate signed success')
                      successCallback()
                      // updateInvestmentStatus(resData.data.orderId, {
                      //   status: 'Active',
                      // }).then((res) => {
                      //   successCallback()
                      //   // history.push('/dashboard')
                      // })
                      // doInvestment(payload).then((res) => {
                      //   history.push('/dashboard')
                      // })
                    }
                  },
                }
                openRazorPay(razorPayOptions)
              }
            })
            .catch((err) => {
              console.log('Error', err)
            })
        } else {
          redirectToDashboard()
        }
      })
    }

    if (location.state.meta.operator.endpoint === providers.qwikCilver) {
      makeQwikCilverOrder(payload)
        .then((res) => {
          redirectToDashboard()
        })
        .catch((e) => {
          history.push('/dashboard')
        })
    }

    if (location.state.meta.operator.endpoint === providers.phonePay) {
      addMoney(payload).then((res) => {
        console.log(res)
      })
    }

    // if (location.state.meta.operator.endpoint === 'e-gift') {
    //   createGiftVoucher(payload).then((res) => {
    //     redirectToDashboard()
    //   })
    // }
  }

  const successCallback = () => {
    dispatch(
      uiActions.showSuccessModal({
        title: 'Success',
        description: `Your order for ${location.state.services[0].description} is in progress`,
        buttonText: 'Go Back To Dashboard',
        redirectUrl: '/dashboard',
      })
    )
    // history.push('/dashboard')
  }

  const handleBack = () => {
    // successCallback()
    history.goBack()
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={16}>
            <Card className="main-card card" title="Checkout">
              <table className="align-middle text-truncate table table-borderless mb-0">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th className="text-right">Amount</th>
                    <th className="text-right">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {location.state.services.map((service) => (
                    <tr>
                      <td style={{ width: '60%' }}>
                        <div>{service.name}</div>
                        <small>{service.description}</small>
                      </td>
                      <td className="text-right">
                        {formattedPrice(service.amount)}
                      </td>
                      <td className="text-right font-weight-bold">
                        {formattedPrice(service.amount * service.quantity)}
                      </td>
                    </tr>
                  ))}
                  {/* {invest && (
                      <tr>
                        <td>
                          <div>Invest in Gold</div>
                          <div>
                            <small>Rounded amount will invest in Gold.</small>
                          </div>
                        </td>
                        <td className="text-right">
                          {formattedPrice(investAmount)}
                        </td>
                        <td className="text-right">
                          <b>{formattedPrice(investAmount)}</b>
                        </td>
                      </tr>
                    )} */}
                </tbody>
              </table>
              <table className="align-middle text-truncate table table-borderless mb-0">
                <tbody>
                  <tr className="border-bottom">
                    <td className="text-right" colSpan={3}></td>
                  </tr>
                  <tr className="small">
                    <td className="text-right" colSpan={2}>
                      Subtotal:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(total)}
                    </td>
                  </tr>
                  <tr className="small border-bottom">
                    <td className="text-right" colSpan={2}>
                      Tax:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0" style={{ width: '75%' }}>
                      <Button
                        icon={<FaArrowLeft />}
                        type="text"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </td>
                    <td className="text-right font-weight-bold">Total:</td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card className="main-card card" title="Payment">
              <div className="mb-2">Payment Method</div>
              <Flex justify="space-between">
                <Checkbox
                  onChange={() => setUseWallet(!useWallet)}
                  checked={useWallet}
                >
                  Wallet Balance
                </Checkbox>
                <b>{formattedPrice(walletBalance)}</b>
              </Flex>
              {location.state.meta.bill && (
                <Flex justify="space-between">
                  <Checkbox
                    onChange={() => setAutoPayment(!autoPayment)}
                    checked={autoPayment}
                  >
                    Do you want to setup automatic payment?
                  </Checkbox>
                </Flex>
              )}
              <div className="mt-5">
                <Button
                  onClick={validatePin}
                  icon={<FaArrowRight />}
                  iconPosition="end"
                  disabled={useWallet ? false : true}
                  type="primary"
                  block
                >
                  Checkout
                </Button>
              </div>
            </Card>
          </Col>
          <Col span={24}>
            {/* <pre>{JSON.stringify(rates, null, 2)}</pre> */}
            {/* <pre style={{ width: '100%' }}>
              {JSON.stringify(location.state, null, 2)}
            </pre> */}
            {/* <pre style={{ width: '100%' }}>
              {JSON.stringify(
                {
                  checkout: {
                    ...location.state.services[0],
                  },
                  provider: location.state.provider,
                  uuid: short.generate(),
                },
                null,
                2
              )}
            </pre> */}
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default CheckoutServices
