import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Col, Row, Table } from 'reactstrap'
import ReactSlider from 'react-slider'

// import dayjs from 'dayjs'
import CountUp from 'react-countup'
import { useDispatch, useSelector } from 'react-redux'
import { idle } from 'features/status'
import {
  getRates,
  getSipRates,
  getRatesByDate,
} from 'features/augmont/augmontSlice'

import {
  // cdnImg,
  investmentType,
  longTermConfig,
  metalType,
  providers,
} from 'utils/constants'
import {
  getMonthsFromFrequency,
  lumpsumCalculator,
  sipCalculatorV2,
} from 'utils/finCalculators'
// import { s3GetUrl } from 'config'
import { FaRupeeSign } from 'react-icons/fa'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip)

const LongTermPlannerForm = ({ data, url }) => {
  const amountRef = useRef(null)
  const durationRef = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const [amount, setAmount] = useState(longTermConfig.amount.min)
  const [timeDurations, setTimeDurations] = useState(0)
  const [selectedMetalType, setSelectedMetalType] = useState(metalType.Gold)
  const [selectedInvestmentType] = useState(location.state.investmentType)
  // const [investmentInterest, setInvestmentInterest] = useState(0)
  // const [investedAmount, setInvestedAmount] = useState(0)
  const [todaysRate, setTodaysRate] = useState(1)
  const [lastYearRate, setLastYearRate] = useState(1)
  const [annualizedReturn, setAnnualizedReturn] = useState()
  const { rates, oldRates, sipRates } = useSelector((state) => state.augmont)
  const dispatch = useDispatch()

  const handleInvestType = (value) => {
    setSelectedMetalType(value)
    // reset the sliders
    setTimeDurations(longTermConfig.duration.min)
    setAmount(0)
    if (lastYearRate > 0) {
      // setAnnualizedReturn(10)
      setAnnualizedReturn(((todaysRate - lastYearRate) / lastYearRate) * 100)
    }
  }

  useEffect(() => {
    if (selectedInvestmentType === investmentType.SIP) {
      if (sipRates?.rates) {
        if (selectedMetalType === metalType.Gold) {
          setTodaysRate(sipRates?.rates?.gBuy)
        } else if (selectedMetalType === metalType.Silver) {
          setTodaysRate(sipRates?.rates?.sBuy)
        }
      }
    }
    if (selectedInvestmentType === investmentType.Lumpsum) {
      if (rates?.rates) {
        if (selectedMetalType === metalType.Gold) {
          setTodaysRate(rates?.rates?.gBuy)
        } else if (selectedMetalType === metalType.Silver) {
          setTodaysRate(rates?.rates?.sBuy)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates, sipRates, selectedMetalType, selectedInvestmentType])

  useEffect(() => {
    if (lastYearRate > 0) {
      // setAnnualizedReturn(10)
      setAnnualizedReturn(((todaysRate - lastYearRate) / lastYearRate) * 100)
    }
  }, [todaysRate, lastYearRate, selectedMetalType])

  useEffect(() => {
    if (oldRates && selectedMetalType) {
      let todaysRateClone = 0
      selectedMetalType === metalType.Gold
        ? (todaysRateClone = oldRates.rates?.gBuy)
        : selectedMetalType === metalType.Silver
        ? (todaysRateClone = oldRates.rates?.sBuy)
        : (todaysRateClone = 0)
      setLastYearRate(todaysRateClone)
    }
  }, [oldRates, selectedMetalType])

  useEffect(() => {
    if (selectedInvestmentType === investmentType.SIP) {
      if (sipRates.status === idle) {
        dispatch(getSipRates())
      }
    }
    if (selectedInvestmentType === investmentType.Lumpsum) {
      if (rates.status === idle) {
        dispatch(getRates())
      }
    }
    if (oldRates.status === idle) {
      dispatch(getRatesByDate())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestmentType])

  useEffect(() => {
    setSelectedMetalType(metalType.Gold)
    // setAmount(0)
    setTimeDurations(longTermConfig.duration.min)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculateInvestment = (amount, timeDurations, annualizedReturn) => {
    if (selectedInvestmentType === investmentType.Lumpsum) {
      let interest = lumpsumCalculator(amount, timeDurations, annualizedReturn)
      return {
        investmentInterest: interest,
        investedAmount: amount,
      }
    }
    if (selectedInvestmentType === investmentType.SIP) {
      if (selectedMetalType === metalType.Gold) {
        // let interest = amount * (1 * annualizedReturn) * timeDurations
        // let interest = 0
        // const interestPerDay = (amount * annualizedReturn) / 100 / 365
        // const endDate = dayjs().add(timeDurations, 'years')
        // const durationInMonths = timeDurations * getMonthsFromFrequency(location.state.frequency)
        // for (let i = 1; i <= durationInMonths; i++) {
        //   const noOfMonth = i - 1
        //   const startDate = dayjs().add(noOfMonth, 'months')
        //   const noOfDays = endDate.diff(startDate, 'days') - 1
        //   interest += interestPerDay * noOfDays
        // }
        let interest = sipCalculatorV2(
          amount,
          timeDurations,
          annualizedReturn,
          location.state.frequency
        )
        return {
          investmentInterest: interest,
          investedAmount:
            amount *
            timeDurations *
            getMonthsFromFrequency(location.state.frequency),
        }
      } else if (selectedMetalType === metalType.Silver) {
        // let interest = amount * (1 * annualizedReturn) * timeDurations
        // let interest = 0
        // const interestPerDay = (amount * annualizedReturn) / 100 / 365
        // const endDate = dayjs().add(timeDurations, 'years')
        // const durationInMonths = timeDurations * 12
        // for (let i = 1; i <= durationInMonths; i++) {
        //   const noOfMonth = i - 1
        //   const startDate = dayjs().add(noOfMonth, 'months')
        //   const noOfDays = endDate.diff(startDate, 'days') - 1
        //   interest += interestPerDay * noOfDays
        // }
        let interest = sipCalculatorV2(
          amount,
          timeDurations,
          annualizedReturn,
          location.state.frequency
        )
        return {
          investmentInterest: interest,
          investedAmount:
            amount *
            timeDurations *
            getMonthsFromFrequency(location.state.frequency),
        }
      }
    }
    return {
      investmentInterest: 0,
      investedAmount: 500,
    }
  }

  const { investmentInterest, investedAmount } = calculateInvestment(
    amount,
    timeDurations,
    annualizedReturn
  )

  const handleChangeAmountInput = (e) => {
    setAmount(e.target.value * 1)
  }

  const handleChangeDurationInput = (e) => {
    setTimeDurations(e.target.value * 1)
  }

  const handleSubmit = () => {
    if (timeDurations < longTermConfig.duration.min) {
      alert(`Minimum duration should ${longTermConfig.duration.min}`)
      setTimeDurations(longTermConfig.duration.min)
      durationRef.current.focus()
      return
    }
    if (timeDurations > longTermConfig.duration.max) {
      alert(`Maximum duration should ${longTermConfig.duration.max}`)
      setTimeDurations(longTermConfig.duration.max)
      durationRef.current.focus()
      return
    }
    const maxAmount = longTermConfig.amount.max
    if (parseFloat(amount) > maxAmount) {
      alert(`Maximum goal amount should ${maxAmount}`)
      setAmount(maxAmount)
      amountRef.current.focus()
      return
    }
    if (parseFloat(amount) < longTermConfig.amount.min) {
      alert(`Minimum goal amount should ${longTermConfig.amount.min}`)
      setAmount(longTermConfig.amount.min)
      amountRef.current.focus()
      return
    }

    let payload = {
      services: [
        {
          name: `${selectedMetalType}`,
          amount: amount,
          description: `${selectedInvestmentType} in ${selectedMetalType} for ${location.state.name} for ${timeDurations} year/s`,
          quantity: 1,
          service: providers.augmont,
          duration: timeDurations * 12, // to convert into Months
          investmentType: selectedInvestmentType,
          investmentDescription: location.state.description,
        },
      ],
      provider: providers.augmont,
      goal: {
        name: location.state.name,
        // description: location.state.description,
        description: `${selectedInvestmentType} in ${selectedMetalType} for ${location.state.name} for ${timeDurations} year/s`,
        amount: amount,
        gst: 0,
        amountGoal: investedAmount,
        amountReturned: investmentInterest + investedAmount ?? 0,
        provider: providers.augmont,
        brand: providers.augmont,
        investIn: selectedMetalType,
        duration: timeDurations * 12, // to convert into Months
        frequency: location.state.frequency,
        type: selectedInvestmentType,
        term: 'long',
      },
      meta: {
        bill: false,
        operator: {
          endpoint: providers.augmont,
        },
        total: amount,
        metalType: selectedMetalType,
        investmentType: selectedInvestmentType,
      },
    }
    // console.log(payload)
    history.push({
      pathname: '/investment/long/checkout',
      state: payload,
    })
  }

  return (
    <Fragment>
      <Row className="">
        {/* <pre style={{ width: '100%' }}>
          {JSON.stringify(location.state, null, 2)}
          {JSON.stringify(oldRates, null, 2)}
          {lastYearRate}
        </pre> */}

        <Col md={4} sm={12} className="custom-border radius-10 p-0">
          {/* <img
            // src={
            //   selectedMetalType === metalType.Gold
            //     ? cdnImg.augmont.gold
            //     : cdnImg.augmont.silver
            // }
            src={s3GetUrl(
              selectedMetalType === metalType.Gold
                ? cdnImg.augmont.gold
                : cdnImg.augmont.silver
            )}
            width="100%"
            style={{ borderRadius: '.25em' }}
            alt=""
          /> */}
          <div className="custom-card">
            <div className="chart-container">
              <Doughnut
                data={{
                  labels: [' You Invest', ' Return On Investment'],
                  datasets: [
                    {
                      data: [investedAmount, investmentInterest ?? 0],
                      backgroundColor: [
                        'rgba(53,62,209, 0.8)',
                        'rgba(12,191,188, 0.8)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <div className="center-text">
                <span>You will get</span>
                <CountUp
                  className="chart-numbers"
                  start={0}
                  end={Math.round(investmentInterest + investedAmount) || 0}
                  prefix="₹"
                  useEasing={true}
                  duration="1"
                />
              </div>
            </div>
          </div>
          <div>
            <Table className="plan-details">
              <tbody>
                <tr>
                  <td>Today's Rate</td>
                  <td className="font-weight-bolder">
                    {todaysRate && (
                      <CountUp
                        key={todaysRate}
                        start={0}
                        end={todaysRate ?? 'NA'}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration="1"
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Last Year's Rate</td>
                  <td className="font-weight-bold">
                    <CountUp
                      key={lastYearRate}
                      start={'0'}
                      end={String(lastYearRate) ?? 'NA'}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹"
                      useEasing={true}
                      suffix=""
                      duration="1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Annualized Return</td>
                  <td className="font-weight-bold">
                    <CountUp
                      key={annualizedReturn}
                      start={'0'}
                      end={String(annualizedReturn) ?? 'NA'}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Purity</td>
                  <td className="font-weight-bold flex">
                    <CountUp
                      key={'purity'}
                      start={0}
                      end={24}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix=""
                      duration="1"
                    />
                    <CountUp
                      key={'purity-carate'}
                      start={0}
                      end={99.99}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="K "
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <div className="d-flex align-items-center">
              <div>Today's Rate: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  <CountUp
                    key={todaysRate}
                    start={0}
                    end={todaysRate ?? 'NA'}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="₹"
                    useEasing={true}
                    suffix=""
                    duration="1"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Last Year's Rate: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  <CountUp
                    key={lastYearRate}
                    start={0}
                    end={lastYearRate ?? 'NA'}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="₹"
                    useEasing={true}
                    suffix=""
                    duration="1"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Annualized Return: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  <CountUp
                    key={annualizedReturn}
                    start={0}
                    end={annualizedReturn ?? 'NA'}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix=""
                    useEasing={true}
                    suffix="%"
                    duration="1"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Purity: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>24K 99.99%</span>
              </div>
            </div> */}
          </div>
        </Col>
        <Col md={8} sm={12}>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-start justify-content-between">
              <div className="d-flex align-items-center flex-row">
                <div>Choose your metal:</div>
                <div className="d-flex custom-tab form-item-control metal-radio ml-2">
                  <label
                    className={`custom-tab-item metal-radio-item ${
                      metalType.Gold === selectedMetalType
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                  >
                    {metalType.Gold}
                    <input
                      type="radio"
                      className="d-none"
                      name="investmentType"
                      checked={selectedMetalType === metalType.Gold}
                      value={selectedMetalType === metalType.Gold}
                      onClick={() => {
                        handleInvestType(metalType.Gold)
                      }}
                    />
                  </label>
                  <label
                    className={`custom-tab-item metal-radio-item ${
                      metalType.Silver === selectedMetalType
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                  >
                    {metalType.Silver}
                    <input
                      type="radio"
                      className="d-none"
                      name="investmentType"
                      checked={selectedMetalType === metalType.Silver}
                      value={selectedMetalType === metalType.Silver}
                      onClick={() => {
                        handleInvestType(metalType.Silver)
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="my-2 text-right">
                Goal Name:
                <label className="mx-2" htmlFor="Gold">
                  <b>{location.state?.name}</b>
                </label>
              </div>
              <div className="mx-2 my-2 text-right">
                Invest Type:
                <label className="mx-2" htmlFor="Gold">
                  <b>{selectedInvestmentType}</b>
                </label>
              </div>
              {selectedInvestmentType === investmentType.SIP && (
                <div className="mx-2 my-2 text-right">
                  Frequency:
                  <label className="mx-2" htmlFor="Gold">
                    <b>{location.state?.frequency}</b>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the goal amount you would like to spend?
              </p>
              <div>
                {/* <div className="amount-display p-3 text-center m-1">
                  <label for="transfer_amount" className="required">
                    Amount
                  </label>
                  {formattedPrice(amount)}
                </div> */}
                <div className="amount-input-group">
                  <FaRupeeSign />
                  <input
                    type="number"
                    placeholder="Amount"
                    onChange={handleChangeAmountInput}
                    step={longTermConfig.amount.min}
                    min={0}
                    max={longTermConfig.amount.max}
                    value={amount}
                    style={{ width: '60px', minWidth: '2em' }}
                    ref={amountRef}
                  />
                </div>

                {/* <div className="amount-input-group">
                  <span
                    onInput={handleChangeAmountInput}
                    max={longTermConfig.amount.max}
                    style={{
                      display: 'inline-block',
                      textAlign: 'right',
                      minWidth: '2em',
                      marginLeft: '.5em',
                    }}
                    contentEditable
                  >
                    {amount}
                    <br />
                  </span>
                </div> */}
              </div>
            </div>
            <ReactSlider
              step={longTermConfig.amount.min}
              min={0}
              max={longTermConfig.amount.max}
              value={amount}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) => setAmount(value)}
            />
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the duration for which you would like to invest?
              </p>
              {/* <div className="amount-display p-3 text-center">
                <span contentEditable>{timeDurations}</span> Year/s
              </div> */}
              <div className="amount-input-group">
                <input
                  type="number"
                  onChange={handleChangeDurationInput}
                  style={{
                    display: 'inline-block',
                    minWidth: '1em',
                    width: '20px',
                    textAlign: 'right',
                    marginRight: '.5em',
                  }}
                  step={1}
                  min={longTermConfig.duration.min}
                  max={longTermConfig.duration.max}
                  value={timeDurations}
                  ref={durationRef}
                />
                Year/s
              </div>
              {/* <div className="amount-input-group">
                <span
                  onInput={handleChangeDurationInput}
                  style={{
                    display: 'inline-block',
                    minWidth: '1em',
                    marginRight: '.5em',
                  }}
                  contentEditable
                >
                  {timeDurations}
                  <br />
                </span>
                Year/s
              </div> */}
            </div>
            <ReactSlider
              step={1}
              min={longTermConfig.duration.min}
              max={longTermConfig.duration.max}
              value={timeDurations}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) => setTimeDurations(value)}
            />
          </div>
          {amount > 0 && (
            <div className="content-cards p-3 mb-2 custom-border">
              <Row>
                <Col className="d-flex flex-column justify-content-between">
                  <div>You Invest</div>
                  <div className="font-weight-bold">
                    <CountUp
                      start={0}
                      end={investedAmount}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹"
                      useEasing={true}
                      suffix=""
                      duration=".5"
                    />
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <div>Return On Investment</div>
                  <div className="font-weight-bold">
                    {investmentInterest ? (
                      <CountUp
                        start={0}
                        end={investmentInterest ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration=".5"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <div>You Get</div>
                  <div className="font-weight-bold">
                    {investedAmount ? (
                      <CountUp
                        start={0}
                        end={investmentInterest + investedAmount ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration=".5"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <Button
                    color="primary"
                    className="p-2 px-3"
                    onClick={handleSubmit}
                  >
                    Plan Now
                  </Button>
                </Col>
              </Row>
              <div className="d-flex justify-content-between mt-3">
                <div>
                  <span className="text-danger" style={{ fontSize: '14px' }}>
                    * These are approximate values.
                  </span>
                </div>
                <div>
                  Your money will be invested with Augmont Goldtech Pvt. Ltd.
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default LongTermPlannerForm
