import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Button,
  Table,
  // ModalFooter
} from 'reactstrap'

import { useHistory } from 'react-router-dom'
import { formattedPrice } from 'utils/helper'
import { useInvestment } from 'backend/useInvestment'
import { usePin } from 'context/PinContext'
import { Link } from 'react-router-dom'

// const shortTermColumns = [
//   'Goal Name',
//   'Brand Partner',
//   'Start Date',
//   'Completion Date',
//   'Amount Invested',
//   'Amount to be Returned',
// ]

const GoalsTableShortTerm = ({
  data,
  currency,
  initialStatus,
  transactionStatus,
  limit,
}) => {
  const history = useHistory()
  const { cancelInvestment } = useInvestment()
  const { checkPin } = usePin()

  const validatePinBeforeCancel = (goalId) => {
    checkPin(goalId, cancelInvestment, `To cancel short term mandate`)
  }

  if (!data) return null
  let goals
  if (limit > 0) {
    goals = data.filter((d, i) => i < limit)
  } else {
    goals = data
  }

  return (
    <>
      {initialStatus === 1 && transactionStatus === 'loading' && (
        <div className="text-center text-muted py-5">
          Your goals will appear here
        </div>
      )}

      {transactionStatus === 'idle' && goals.length === 0 && (
        <div className="text-center text-muted py-5">
          No goal investment found
        </div>
      )}

      {goals.length > 0 && (
        <Table responsive hover className="align-middle text-truncate mb-3">
          <thead>
            <tr>
              <th className="pl-4" style={{ width: '15%' }}>
                <div className="d-inline-block">Goal Name</div>
              </th>
              <th className="text-center">Brand Partner</th>
              <th className="text-center">Start Date</th>
              <th className="text-center">Completion Date</th>
              <th className="text-center">Amount Invested</th>
              <th className="text-center">Status</th>
              <th className="text-center" style={{ width: '10%' }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td colSpan="100">
                <pre>{JSON.stringify(goals, null, 2)}</pre>
              </td>
            </tr> */}
            {goals.map((goal) => (
              <tr key={goal.createdAt} title={`Goal Id: ${goal.id}`}>
                <td className="text-primary cursor-pointer pl-4">
                  <div
                    className="d-inline-block"
                    onClick={() =>
                      history.push(`/investment/goal-details/${goal.id}`)
                    }
                  >
                    {goal.name}
                  </div>
                  {/* {goal.name} */}
                  {/* <br />
                  <small>{goal.id}</small> */}
                </td>
                <td className="text-center">{goal.brand}</td>
                <td
                  className="text-center text-muted"
                  style={{ maxWidth: '9em' }}
                >
                  <span className="mr-1">
                    {dayjs(goal.startDate).format('DD-MM-YYYY')}
                  </span>
                  {/* <small>{dayjs(goal.startDate).format('hh:mm a')}</small> */}
                </td>
                <td className="text-center text-muted">
                  <span className="mr-1">
                    {dayjs(goal.endDate).format('DD-MM-YYYY')}
                  </span>
                  {/* <small>{dayjs(goal.endDate).format('hh:mm a')}</small> */}
                </td>
                <td className="text-center" style={{ maxWidth: '5em' }}>
                  {formattedPrice(goal.amountInvested)}
                </td>
                <td className={`text-center`} style={{ maxWidth: '5em' }}>
                  <span
                    className={`badge ${
                      goal.status === 'Active' || goal.status === 'Completed'
                        ? 'bg-soft-success text-success'
                        : goal.status === 'Pending'
                        ? 'bg-soft-warning text-warning'
                        : goal.status === 'Canceled'
                        ? 'bg-soft-danger text-danger'
                        : ''
                    }`}
                  >
                    {goal.status}
                  </span>
                </td>
                <td className="text-center">
                  {goal.status === 'Completed' && (
                    <Button
                      className="custom-table-action-btn_success bg-soft-success text-success px-3"
                      onClick={() => {
                        history.push(
                          `/voucher/brands/${goal.investIn}`
                        )
                      }}
                    >
                      BUY
                    </Button>
                  )}
                  {goal.status === 'Active' && (
                    <Button
                      className="custom-table-action-btn_danger text-danger px-2"
                      color="danger"
                      // onClick={() => cancelInvestment(goal.id)}
                      onClick={() => validatePinBeforeCancel(goal.id)}
                    >
                      CANCEL
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Link
        to="/investment/goals?tab=short"
        className="mx-auto mb-3 text-center bg-soft-primary badge"
      >
        Load more...
      </Link>
    </>
  )
}

GoalsTableShortTerm.defaultProps = {
  limit: 0,
  description: false,
  currency: 'INR',
  transactionStatus: 0,
  initialStatus: 1,
}

GoalsTableShortTerm.propTypes = {
  limit: PropTypes.number,
  data: PropTypes.array.isRequired,
  currency: PropTypes.string,
  description: PropTypes.bool,
  transactionStatus: PropTypes.number,
  initialStatus: PropTypes.number,
}

export default GoalsTableShortTerm
