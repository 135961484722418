import React, { Fragment, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'

// import HeaderLogo from '../AppLogo'

// import SearchBox from './Components/SearchBox'
// import MegaMenu from './Components/MegaMenu'
import UserBox from './Components/UserBox'
// import HeaderRightDrawer from './Components/HeaderRightDrawer'
// import HeaderDots from './Components/HeaderDots'
import { useServices } from 'backend/useServices'

import {
  getPassbook,
  getRates,
  selectAugmont,
} from 'features/augmont/augmontSlice'
import { idle } from 'features/status'
import { useBBPS } from 'backend/useBBPS'
import { useRecharge } from 'backend/useRecharge'
import { useFasTag } from 'backend/useFasTag'
import { useLPG } from 'backend/useLPG'
import { useMunicipality } from 'backend/useMunicipality'
import { usePin } from 'context/PinContext'
import { selectAuth } from 'features/auth/authSlice'
import { selectAccount } from 'features/accountSlice'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  selectThemeOptions,
  setEnableClosedSidebar,
  // setPageTitle,
} from 'features/ThemeOptions'
import { Slider } from 'react-burgers'
import usePageTitle from 'hooks/usePageTitle'
import HeaderLogo from 'Layout/AppLogo'
import CountUp from 'react-countup'

const Header = () => {
  // const history = useHistory()
  const { user, logged, pin } = useSelector(selectAuth)

  const dispatch = useDispatch()
  const { augmontUser, rates, passbook } = useSelector(selectAugmont)
  const serviceRef = useRef(useServices())
  const bbpsRef = useRef(useBBPS())
  const rechargeRef = useRef(useRecharge())
  const fasTagRef = useRef(useFasTag())
  const municipalityRef = useRef(useMunicipality())
  const lpgRef = useRef(useLPG())
  // const { getOperators, getCircles } = useServices()
  const { refreshOperator } = useSelector((state) => state.services)
  const {
    enableHeaderShadow,
    // closedSmallerSidebar,
    headerBackgroundColor,
    enableMobileMenuSmall,
  } = useSelector(selectThemeOptions)
  const { showUpdatePinModal } = usePin()
  const [active, setActive] = useState(false)
  const { enableClosedSidebar } = useSelector(selectThemeOptions)

  const { virtualAccount } = useSelector(selectAccount)

  const { pageTitle } = usePageTitle()

  useEffect(() => {
    // this is for initial load
    if (rates.status === idle) {
      if (augmontUser?.id) {
        dispatch(getRates())
      }
    }
    if (passbook.status === idle) {
      if (augmontUser?.id) {
        dispatch(getPassbook())
      }
    }
    // if (augmontUser.status === idle) {
    //   if (augmontUser?.id) {
    //     dispatch(getProfile())
    //   }
    // }
  }, [augmontUser, rates, passbook, dispatch])

  useEffect(() => {
    if (logged === true) {
      if (user.panVerified) {
        if (!pin) {
          showUpdatePinModal()
        }
        if (refreshOperator) {
          serviceRef.current.getOperators()
          serviceRef.current.getCircles()
          bbpsRef.current.getOperators()
          rechargeRef.current.getOperators()
          fasTagRef.current.getOperators()
          municipalityRef.current.getOperators()
          lpgRef.current.getOperators()
          // giftCardRef.current.getProducts()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, refreshOperator, pin])

  // NOTE: For PhonePay not used now
  // useEffect(() => {
  //   // const channel = new BroadcastChannel('app-data')
  //   // channel.addEventListener('message', (event) => {
  //   //   console.log(event.data)
  //   // })
  //   window.addEventListener('storage', (event) => {
  //     if (event.storageArea !== localStorage) return
  //     if (event.key === 'PhonePay') {
  //       console.log(event)
  //       if (event.newValue === 'Payment.Redirect') {
  //         window.localStorage.clear('PhonePay')
  //         toast.success('Payment in Process...')
  //         console.log(event.newValue)
  //         dispatch(accountActions.refreshData())
  //         history.push('/dashboard')
  //         // Do something with event.newValue
  //       }
  //     }
  //   })
  //   return () => {
  //     window.removeEventListener('storage', window)
  //     // channel.removeEventListener('message')
  //   }
  //   // eslint-disable-next-line
  // }, [])

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar))
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        className={cx('app-header', headerBackgroundColor, {
          'header-shadow': enableHeaderShadow,
          'header-margin': enableClosedSidebar,
        })}
        classNames="HeaderAnimation"
        appear={true}
        timeout={{ appear: 1500 }}
        enter={false}
        exit={false}
      >
        <HeaderLogo />
        <div
          className={cx('app-header__content', {
            'header-mobile-open': enableMobileMenuSmall,
          })}
        >
          <div className="app-header-left">
            {window.location.href.search('auth/') === -1 && (
              <div className="header__pane ml-auto mt-2">
                <div onClick={toggleEnableClosedSidebar}>
                  <Slider
                    width={26}
                    lineHeight={2}
                    lineSpacing={5}
                    color="#6c757d"
                    active={active}
                    onClick={() => setActive(!active)}
                  />
                </div>
              </div>
            )}
            <div className="app-header-breadcrumbs">{pageTitle}</div>
            {/* <SearchBox />
              <MegaMenu /> */}
          </div>
          <div className="app-header-right">
            {/* <div className="widget-chart-content">
              <div className="widget-upper-container">
                <div className="widget-left-part">
                  <div className="widget-subheading">Wallet Balance</div>
                  <div
                    className={cx(
                      'widget-numbers',
                      virtualAccount.walletBalance === 0 && 'opacity-7'
                    )}
                  > */}
            {window.location.href.search('auth/') === -1 && (
              <>
                <span>Wallet Balance </span>
                <span className="font-weight-bold pl-1">
                  {virtualAccount.walletBalance > 0 ? (
                    <CountUp
                      start={0}
                      end={virtualAccount.walletBalance}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹ "
                      useEasing={true}
                      suffix=""
                      duration="1"
                    />
                  ) : (
                    <CountUp
                      start={0}
                      end={0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix="₹ "
                      useEasing={true}
                      suffix=""
                      duration="1"
                    />
                  )}
                </span>
              </>
            )}

            {/* </div>
                </div> */}
            {/* <img alt="" className="widget-img" src={WalletBalanceIcon} /> */}
            {/* </div> */}
            {/* <div className="widget-description opacity-8 text-focus">
                <div className="d-flex justify-content-between">
                  <div>
                    <div>&nbsp; </div>
                    <Link to="/account/transactions" className="d-inline">
                      <span className="text-primary pl-1">View Statement</span>
                    </Link>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <HeaderDots /> */}
            <UserBox />
            {/* <HeaderRightDrawer /> */}
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Header
