import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const Form = ({
  schemaResolver,
  initialValues,
  onFinish,
  onFinishFailed,
  children,
  mode,
  reset,
  // setValue,
  // formControl,
}) => {
  const methods = useForm({
    defaultValues: initialValues,
    resolver: schemaResolver || '',
    mode: mode || 'onBlur',
  })
  // formControl = methods
  // useMemo(() => first, [reset])

  useEffect(() => {
    if (initialValues) {
      methods.reset(initialValues)
    }

    return () => {
      if (reset) {
        methods.reset()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, reset])

  const onValid = (data) => {
    console.log('onValid', data)
    onFinish && onFinish(data)
  }

  // const setValue = (name, value) => {
  //   methods.setValue(name, value)
  // }

  const onInvalid = (data) => {
    console.log('onInvalid', data)
    onFinishFailed && onFinishFailed(data)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onValid, onInvalid)}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
