import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Col, Row } from 'reactstrap'
import dayjs from 'dayjs'

// import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// import bg3 from "assets/utils/images/originals/citynights.jpg"
// import bg3 from 'assets/utils/images/originals/pan-aadhaar-2.jpg'
import { useAuth } from 'backend/useAuth'
import { DatePicker, Form, Input, Select, Submit } from 'ReactstrapUi'
import RadioGroup from 'ReactstrapUi/Form/RadioGroup'
import UserDetails from './UserDetails'
import { selectAuth } from 'features/auth/authSlice'
// import { validateKYCAction } from 'redux/actions/bank'

// let settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   arrows: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   initialSlide: 0,
//   autoplay: true,
//   adaptiveHeight: true,
// }

const PanProfile = () => {
  const history = useHistory()
  const { registerStep } = useSelector(selectAuth)

  const { signupProfile } = useAuth()

  if (!registerStep?.pan?.panNumber) {
    history.push(`/auth/signup`)
  }

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    middleName: yup.string().required('Middle name is required'),
    lastName: yup.string().required('Last name is required'),
    gender: yup.string().required('Gender is required'),
    dob: yup.string().required('Date of birth is required'),
    // sAddress: yup.string()
    //   .required('Address is required'),
    line1: yup.string().required('Address line 1 is required'),
    // line2: yup.string()
    //   .required('Address 2 is required'),
    city: yup.string().required('City is required'),
    zip: yup.string().required('Pin Code is required'),
    // .min('Pin Code must be 6 digits'),
    state: yup.string().required('State is required'),
  })

  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      panVerified: true,
      // fullName: registerStep?.pan?.fullName,
      gender: data.gender,
      dob: dayjs(data.dob).toISOString(),
      // address: { ...registerStep?.pan?.address, ...data.address },
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      zip: data.zip,
      state: data.state,
      country: registerStep?.aadhaar?.address?.country,
      pan: registerStep?.pan?.panNumber,
      aadhaarNoMasked: registerStep?.pan?.maskedAadhaar,
      // mobile: registerStep?.pan?.mobile,
      accountType: registerStep?.pan?.category,
    }

    console.log(payload)
    signupProfile(payload).then((res) => {
      history.push(`/dashboard`)
      setTimeout(() => {}, 300)
      // showSuccessBlock(true)
    })
  }

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col
            lg="7"
            md="12"
            className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
            style={{ height: '60vh', overflow: 'auto' }}
          >
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                {/* <div>Welcome,</div> */}
                <span>
                  Verify your{' '}
                  <span className="text-success">profile details</span>
                </span>
              </h4>
              <div>
                {/* <pre>{JSON.stringify(registerStep, null, 2)}</pre> */}
                {registerStep?.pan?.panNumber && (
                  <Form
                    onFinish={onSubmit}
                    className="login100-form validate-form"
                    schemaResolver={yupResolver(schema)}
                    mode="onTouched" // onBlur
                    initialValues={{
                      title:
                        registerStep?.aadhaar?.gender === 'M' ? 'Mr.' : 'Mrs.',
                      firstName: registerStep?.pan?.fullNameSplit[0],
                      // firstName:
                      //   registerStep?.pan.fullName &&
                      //   registerStep?.pan.fullName.split(' ')[0],
                      lastName: registerStep?.pan?.fullNameSplit[2],
                      // lastName:
                      //   registerStep?.pan.fullName &&
                      //   registerStep?.pan.fullName.split(' ')[1],
                      gender:
                        // registerStep.pan.gender ||
                        registerStep?.aadhaar?.gender,
                      dob: dayjs(registerStep.pan.dob).format('YYYY-MM-DD'),
                      //   dob:
                      //     (registerStep?.pan?.dob &&
                      //       format(registerStep.pan.dob)) ||
                      //     (registerStep?.address?.dob &&
                      //       format(registerStep.address.dob)),
                      line1:
                        registerStep?.pan?.address?.line1 ||
                        registerStep?.aadhaar?.address?.house,
                      line2:
                        registerStep?.pan?.address?.line2 ||
                        registerStep?.aadhaar?.address?.street,
                      state:
                        registerStep?.pan?.address?.state ||
                        registerStep?.aadhaar?.address?.state,
                      city:
                        registerStep?.pan?.address?.city ||
                        registerStep?.aadhaar?.address?.dist,
                      zip:
                        registerStep?.pan?.address?.zip ||
                        registerStep?.aadhaar.zip,
                      //   // address: registerStep?.pan?.address,
                    }}
                  >
                    <Row form>
                      <Col md="5">
                        <Input.Text
                          label="First Name"
                          id="firstName"
                          name="firstName"
                          prepend={
                            <Select
                              name="title"
                              id="title"
                              label={null}
                              className="form-control"
                              options={[
                                { value: 'Mr.', label: 'Mr.' },
                                { value: 'Mrs.', label: 'Mrs.' },
                                { value: 'Ms.', label: 'Ms.' },
                              ]}
                              onChange={(e) =>
                                console.log('title', e.target.value)
                              }
                            ></Select>
                          }
                          required
                        />
                      </Col>
                      <Col md="3">
                        <Input.Text
                          label="Middle Name"
                          id="middleName"
                          name="middleName"
                          required
                        />
                      </Col>
                      <Col md="4">
                        <Input.Text
                          label="Last Name"
                          id="lastName"
                          name="lastName"
                          required
                        />
                      </Col>
                      <Col md="6">
                        <RadioGroup
                          id="gender"
                          name="gender"
                          label="Gender"
                          required
                          options={[
                            {
                              value: 'M',
                              label: 'Male',
                            },
                            {
                              value: 'F',
                              label: 'Female',
                            },
                          ]}
                        />
                      </Col>
                      <Col md="6">
                        <DatePicker
                          label="Date of birth"
                          name="dob"
                          pickerOptions={{
                            dateFormat: 'dd-MM-yyyy',
                            showYearDropdown: true,
                            showMonthDropdown: true,
                            maxDate: dayjs().subtract(18, 'years').toDate(),
                          }}
                          required
                        />
                        {/* <Input.Text
                          type="date"
                          name="dob"
                          label="Date of birth"
                          required
                          min={addYears(new Date(), 60).format('YYYY-MM-DD')}
                          max={subYears(new Date(), 18).format('YYYY-MM-DD')}
                        /> */}
                      </Col>
                      <Col md="12">
                        <Input.Text
                          id="line1"
                          name="line1"
                          label="Address Line 1"
                          required
                        />
                      </Col>
                      <Col md="12">
                        <Input.Text
                          id="line2"
                          name="line2"
                          label="Address Line 2"
                        />
                      </Col>
                      <Col md="6">
                        <Input.Text
                          id="city"
                          name="city"
                          label="City"
                          required
                        />
                      </Col>
                      <Col md="6">
                        <Input.Text
                          id="zip"
                          name="zip"
                          label="Pin/Zip Code"
                          required
                        />
                      </Col>
                      <Col md="6">
                        <Input.Text
                          id="state"
                          name="state"
                          label="State"
                          required
                        />
                      </Col>
                    </Row>
                    <div className="mt-4 d-flex align-items-center">
                      {/* <h5 className="mb-0">
                      Already have an account?{" "}
                      <Link to="/auth/login" className="text-primary">
                        Sign in
                      </Link>
                    </h5> */}
                      <div className="d-flex justify-content-end">
                        <Submit className="btn custom-btn-animation h-37">
                          Proceed
                        </Submit>
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </Col>
          </Col>
          <UserDetails />
        </Row>
      </div>
      {/* <pre>{JSON.stringify(registerStep, null, 2)}</pre> */}
    </Fragment>
  )
}

export default PanProfile
