import axios from 'axios'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { config } from 'config'
import showLoader from '../Components/Loader'

const baseURL = config.apiUrl
const tokenName = config.tokenName

export const errMsg = 'Server is unavailable.'

const storeToken = (token) => {
  Cookies.set(tokenName, token)
  window.localStorage && localStorage.setItem(tokenName, token)
}

const getToken = () => {
  if (Cookies.get(tokenName)) {
    return Cookies.get(tokenName)
  }
  return window.localStorage && localStorage.getItem(tokenName)
}

const removeToken = () => {
  Cookies.remove(tokenName)
  window.localStorage && localStorage.removeItem(tokenName)
}

const nest = axios.create({
  baseURL: `${baseURL}/v1`,
})

const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.message?.message ||
    error?.response?.data?.message ||
    error?.response?.data?.error ||
    error?.response?.statusText ||
    'Network error'
  if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
    message = 'Connection Timeout'
  }
  if (error?.response?.status === 400) {
    // message = message + '\n' + error?.response?.data?.message.join('\n')
    alert(error?.response?.data?.message?.join('\n'))
  }
  return message
}

// Add a request interceptor
nest.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.loader) {
      showLoader(true)
    }
    if (config.msg) {
      if (config.msg.loading) {
        config.msg.id = toast.loading(config.msg.loading)
      }
    }
    if (getToken()) {
      config.headers = { Authorization: `Bearer ${getToken()}` }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
nest.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.config.msg) {
      let message = response?.data?.message || response?.config?.msg?.success
      if (response?.config?.msg?.id) {
        toast.update(response.config.msg.id, {
          render: message,
          type: 'success',
          isLoading: false,
          autoClose: true,
        })
      } else {
        toast.success(message)
      }
    }
    // if (response?.data?.token) {
    //   storeToken(response?.data?.token)
    // }

    if (response?.config?.loader) {
      showLoader(false)
    }
    return response?.data || response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let message =
      error?.response?.data?.message?.message ||
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.response?.statusText ||
      'Network error'
    if (error?.response?.config?.msg) {
      if (error?.response?.config?.msg?.id) {
        toast.update(error.response.config.msg.id, {
          render: message,
          type: 'error',
          isLoading: false,
          autoClose: true,
        })
      } else {
        toast.error(message)
      }
    } else if (error?.config?.msg) {
      if (error?.config?.msg?.id) {
        toast.update(error.config.msg.id, {
          render: message,
          type: 'error',
          isLoading: false,
          autoClose: true,
        })
      } else {
        toast.error(message)
      }
    } else {
      toast.error(message)
    }
    // if (error?.response?.config?.loader) {
    // if (needLoader) {
    // showLoader(false)
    // window.showLoader = false
    // }
    if (
      error?.response?.status === 401 &&
      window.location.href.search('auth') === -1
    ) {
      removeToken()
      window.location.href = '/auth/login'
    }
    showLoader(false)
    return Promise.reject(error)
  }
)

export { nest, storeToken, removeToken, getToken, getErrorMessage }
