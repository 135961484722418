import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'

import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  Input,
} from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectAccount } from 'features/accountSlice'
import usePageTitle from 'hooks/usePageTitle'

const AccountInfo = () => {
  const { virtualAccount } = useSelector(selectAccount)

  usePageTitle('Virtual Account Details')

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row>
          <Col lg="9">
            <Card className="main-card">
              <CardBody>
                <CardTitle>Account Info</CardTitle>
                {/* <pre>{JSON.stringify(selectedAccount, null, 2)}</pre> */}
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Bank Name</Label>
                      <Input defaultValue={virtualAccount.bank} readOnly />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>IFSC Code</Label>
                      <Input defaultValue={virtualAccount.ifsc} readOnly />
                    </FormGroup>
                  </Col>
                  {/* {selectedAccount.upiQrCode &&
                    <Col md="12">
                      <FormGroup>
                        <Label>QR Code</Label>
                        <textarea
                          className="form-control"
                          defaultValue={selectedAccount.upiQrCode}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  } */}
                  {virtualAccount.customerId && (
                    <Col md="12">
                      <FormGroup>
                        <Label>Customer ID</Label>
                        <Input
                          defaultValue={virtualAccount.customerId}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {virtualAccount.vaId && (
                    <Col md="12">
                      <FormGroup>
                        <Label>Virtual Account ID</Label>
                        <Input defaultValue={virtualAccount.vaId} readOnly />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="12">
                    <FormGroup>
                      <Label>Account Number</Label>
                      <Input
                        defaultValue={virtualAccount.accountNumber}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md="6">
                    <FormGroup>
                      <Label>Minimum Balance</Label>
                      <Input
                        defaultValue={virtualAccount.minimumBalance}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Transaction Limit</Label>
                      <Input
                        defaultValue={virtualAccount.transactionLimit}
                        readOnly
                      />
                    </FormGroup>
                  </Col> */}
                  {/* {virtualAccount.upiId !== 'UNDEFINED' && (
                    <Col md="12">
                      <FormGroup>
                        <Label>UPI ID</Label>
                        <Input defaultValue={virtualAccount.upiId} readOnly />
                      </FormGroup>
                    </Col>
                  )} */}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {virtualAccount.upiQrCode && (
            <Col lg="3">
              <Card className="main-card">
                <CardBody>
                  <CardTitle>UPI Qr Code</CardTitle>
                  <Row>
                    <Col md="12 text-center">
                      <img
                        src={`data:image/png;base64, ${virtualAccount.upiQrCode}`}
                        alt="qr code"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default AccountInfo
