import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FaEye,
  FaEyeSlash
} from "react-icons/fa"

const PasswordEye = ({ target }) => {
  const [show, setShow] = useState(false)

  const handleShowPassword = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }

    if (target) {
      const el = document.querySelector(`#${target}`)

      if (el) {
        if (el.type.toLowerCase() === 'password') {
          el.type = 'text'
        } else {
          el.type = 'password'
        }
      } else {
        alert(`${target} not found`)
      }
    }
  }

  return (show ? <FaEye
    className="fa"
    onClick={handleShowPassword}
  /> :
    <FaEyeSlash
      className="fa"
      onClick={handleShowPassword}
    />
  )
}

PasswordEye.propTypes = {
  target: PropTypes.string.isRequired
}

export default PasswordEye
