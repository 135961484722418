import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import { FaRupeeSign } from 'react-icons/fa'

import {
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from 'utils/validation'

import SelectedOperatorBBPS from '../SelectedOperatorBBPS'
import { useRecharge } from 'backend/useRecharge'
import DTHPlanDetails from "./DTHPlanDetails"

const DTHForm = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const operators = useSelector((state) => state.services.bbpsOperators)
  const [operator, setOperator] = useState()

  const [amount, setAmount] = useState(100)
  const [billDetails, setBillDetails] = useState()

  const { checkDTH } = useRecharge()

  useEffect(() => {
    if (operators) {
      setOperator(
        operators.find((op) => op.service === service && op.code === opcode)
      )
    }
  }, [operators, opcode, service])

  useEffect(() => {
    return () => {
      setBillDetails()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, reset, setValue, trigger, formState } =
    useForm()

  const onSubmit = async (data) => {
    if (operator.bill && !billDetails) {
      await checkBill(data)
      return
    }
    handleFormSubmit(data)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setBillDetails()
    reset()
  }

  const checkBill = async (data) => {
    let payload = {
      canumber: data.canumber,
      op: operator.op,
      mode: 'online',
    }
    checkDTH(payload).then((data) => {
      if (data) {
        console.log(data)
        setBillDetails(data)
        setValue(
          'amount',
          data.info.length > 0 ? data.info[0]?.MonthlyRecharge : 0
        )
      }
    })
  }

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      operator: operator.id,
      canumber: data.number,
      amount: data.amount,
      // referenceid: '20018575947', generated at backend.
      mode: 'online',
    }
    history.push({
      pathname: `/service/checkout`,
      state: {
        services: [
          {
            amount: data.amount,
            name: operator.name,
            description: `Recharge ${operator.name}`,
            quantity: 1,
            service: operator.category,
          },
        ],
        provider: payload,
        meta: {
          bill: operator?.bill,
          operator,
        },
      },
    })
  }

  const handleAmount = (value) => {
    // console.log(value)
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const selectAmount = (value) => {
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const handleChangeInput = (name, value) => {
    setValue(name, value)
    trigger(name)
    setBillDetails()
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(operator) || isEmpty(operators)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Recharge your {operator?.name}</CardTitle>
              <SelectedOperatorBBPS
                opcode={opcode}
                category={service}
                showBillStatus={false}
              />
              {/* <pre>{JSON.stringify(operator, null, 2)}</pre> */}
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  {operator?.fields &&
                    operator?.fields.map((field) => (
                      <Col lg="12" key={field?.name}>
                        <FormGroup>
                          <Label for={field?.name} className="required">
                            {field.label}
                          </Label>
                          <input
                            type="text"
                            placeholder={field.placeholder}
                            id={field?.name}
                            name={field?.name}
                            className="form-control"
                            {...register(field?.name, {
                              required: `${field.label} is required`,
                              pattern: field.regex && {
                                value: new RegExp(field.regex),
                                message: `Please enter valid ${field.label}`,
                              },
                            })}
                            onChange={(e) =>
                              handleChangeInput(field?.name, e.target.value)
                            }
                          />
                          <InvalidFeedbackV7
                            name={field?.name}
                            formState={formState}
                          />
                        </FormGroup>
                      </Col>
                    ))}

                  {operator?.bill ? (
                    billDetails && (
                      <Col lg="12">
                        <FormGroup>
                          <Label for="amount" className="required">
                            Amount
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              id="amount"
                              name="amount"
                              value={amount}
                              // defaultValue="100"
                              // defaultValue={0}
                              className="form-control"
                              {...register('amount', {
                                required: 'Amount is required',
                                onChange: (e) => handleAmount(e.target.value),
                              })}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaRupeeSign />
                              </InputGroupText>
                            </InputGroupAddon>
                            <InvalidFeedbackV7
                              name="amount"
                              formState={formState}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )
                  ) : (
                    <Col lg="12">
                      <FormGroup>
                        <Label for="amount" className="required">
                          Amount
                        </Label>
                        <InputGroup>
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            id="amount"
                            name="amount"
                            value={amount}
                            className="form-control"
                            {...register('amount', {
                              required: 'Amount is required',
                              onChange: (e) => handleAmount(e.target.value),
                            })}
                          />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaRupeeSign />
                            </InputGroupText>
                          </InputGroupAddon>
                          <InvalidFeedbackV7
                            name="amount"
                            formState={formState}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  {operator?.bill ? (
                    !billDetails ? (
                      <>
                        <Col lg="12">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="primary"
                              disabled={preventSubmit(formState)}
                            >
                              Check Details
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="danger"
                              type="button"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="success"
                              disabled={preventSubmit(formState)}
                            >
                              Recharge
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    )
                  ) : (
                    <Col lg="12" className="d-flex justify-content-end">
                      <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Proceed
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <DTHPlanDetails
            data={billDetails}
            selectAmount={selectAmount}
            visible={operator?.bill}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default DTHForm
