import { accountActions } from 'features/accountSlice'
import { serviceActions } from 'features/servicesSlice'
import { useDispatch } from 'react-redux'
import { errMsg, useNest } from './useNest'

export function useBBPS() {
  const { backend, addItem } = useNest()
  const dispatch = useDispatch()

  const getOperators = () => {
    let config = { loader: true, msg: '' }
    backend()
      .get('/bbps/operators', config)
      .then(({ data }) => {
        // console.log(data)
        dispatch(serviceActions.loadBBPSOperators(data))
      })
  }

  const fetchBill = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/bbps/fetchBill', payload, config)
        .then((response) => {
          return resolve(response)
        })
    })

  const payBill = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/bbps/payBill', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          // dispatch(
          //   queueActions.queueAdd({
          //     id: data.recharge_id,
          //     type: 'BBPS',
          //   })
          // ) // it is used to check status of transaction
          return resolve(data)
        })
    })

  const checkStatus = (referenceId) =>
    new Promise((resolve) => {
      backend()
        .get('/bbps/status', { referenceId })
        .then((response) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(response.data)
        })
    })

  const mandateRegister = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating mandate...' },
      }
      addItem('razorpay/mandate/register', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })
  return {
    getOperators,
    fetchBill,
    payBill,
    checkStatus,
    mandateRegister,
  }
}
