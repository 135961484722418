export const MainNav = [
  {
    icon: 'svg-dashboard',
    label: 'Dashboards',
    to: '/dashboard',
  },

  // {
  //   icon: 'pe-7s-wallet',
  //   label: "Account",
  //   content: [
  //     {
  //       icon: 'pe-7s-wallet',
  //       label: "Transaction",
  //       to: "/account/transactions",
  //     },
  //     {
  //       icon: 'pe-7s-back-2',
  //       label: "Payments",
  //       to: "/account/payments",
  //     }
  //   ]
  // }

  // {
  //   icon: "pe-7s-plugin",
  //   label: "Applications",
  //   content: [
  //     {
  //       label: "Mailbox",
  //       to: "/apps/mailbox",
  //     },
  //     {
  //       label: "Chat",
  //       to: "/apps/chat",
  //     },
  //     {
  //       label: "Split Layout",
  //       to: "/apps/split-layout",
  //     },
  //     {
  //       label: "FAQ Section",
  //       to: "/apps/faq-section",
  //     },
  //   ],
  // },
]

export const AccountNav = [
  {
    icon: 'svg-transaction',
    label: 'Transactions',
    to: '/account/transactions',
  },
  {
    icon: 'svg-longTerm',
    label: 'Goals',
    to: '/investment/goals?tab=long',
  },
  {
    icon: 'png-fd',
    label: 'FDs',
    to: '/account/Fds',
  },
  // {
  //   icon: 'pe-7s-back-2',
  //   label: "Payments",
  //   to: "/account/payments",
  // },
]

// export const QcNav = [
//   {
//     icon: 'pe-7s-gift',
//     label: 'QwikCilver',
//     to: '/voucher/brands',
//   },
// ]

export const GoalNav = [
  // {
  //   icon: 'svg-gold',
  //   label: 'Gold',
  //   to: '/investment/gold',
  // },
  // {
  //   icon: 'svg-silver',
  //   label: 'Silver',
  //   to: '/investment/silver',
  // },
  // {
  //   icon: 'svg-sip',
  //   label: 'Goals',
  //   to: '/investment/goal/long',
  //   content: [
  //     {
  //       label: 'Long Term',
  //       to: '/investment/goal/long',
  //     },
  //     {
  //       label: 'Short Term',
  //       to: '/investment/goal/short',
  //     },
  //   ],
  // },
  {
    icon: 'svg-longTerm',
    label: 'Long Term',
    to: '/investment/goal/long',
  },
  {
    icon: 'svg-shortTerm',
    label: 'Short Term',
    to: '/investment/goal/short',
  },
  {
    icon: 'png-fd',
    label: 'FDs',
    to: '/investment/goal/fd',
  },
  // {
  //   icon: 'svg-gold',
  //   label: 'Gold',
  //   to: '/investment/gold',
  // },
  // {
  //   icon: 'svg-silver',
  //   label: 'Silver',
  //   to: '/investment/silver',
  // },

  // {
  //   icon: 'svg-gold',
  //   label: "P2P",
  //   to: "/investment/p2p",
  // },
  // {
  //   icon: 'png-fd',
  //   label: 'ID Deals',
  //   to: '/investment/invoicediscounting',
  // },
  // {
  //   icon: 'png-fd',
  //   label: 'AL Deals',
  //   to: '/investment/assetleasing',
  // },
]

export const ServiceNav = [
  // {
  //   icon: "pe-7s-credit",
  //   label: "Credit Card",
  //   to: "/service/cc",
  // },
  // {
  //   icon: "pe-7s-phone",
  //   label: "Mobile",
  //   to: "/service/mobile",
  // },
  {
    icon: 'svg-prepaid',
    label: 'Prepaid',
    to: '/service/prepaid',
  },
  {
    icon: 'pe-7s-stopwatch',
    label: 'Postpaid',
    to: '/service/postpaid',
  },
  {
    icon: 'svg-datacard',
    label: 'Datacard Prepaid',
    to: '/service/datacard-prepaid',
  },
  {
    icon: 'svg-brand-voucher',
    label: 'Brand Voucher',
    to: '/voucher/brands',
  },
  // {
  //   icon: 'pe-7s-gift',
  //   label: 'Digital Voucher',
  //   to: '/service/digital-voucher',
  // },
  // {
  //   icon: 'pe-7s-gift',
  //   label: 'E Gift Card',
  //   to: '/service/e-gift',
  // },
  {
    icon: 'svg-landline',
    label: 'Landline',
    to: '/service/landline',
  },
  {
    icon: 'pe-7s-monitor',
    label: 'Cable',
    to: '/service/cable',
  },
  // {
  //   icon: 'svg-ott',
  //   label: 'OTT',
  //   to: '/service/ott',
  // },
  {
    icon: 'svg-dth',
    label: 'DTH',
    to: '/service/dth',
  },
  {
    icon: 'svg-wifi',
    label: 'Broadband',
    to: '/service/broadband',
  },
  {
    icon: 'svg-fastTag',
    label: 'Fastag',
    to: '/service/fastag',
  },
  {
    icon: 'svg-traffic-challan',
    label: 'Traffic Challan',
    to: '/service/traffic-challan',
  },
  {
    icon: 'svg-cylinder',
    label: 'LPG Cylinder',
    to: '/service/lpg',
  },
  {
    icon: 'svg-lpg',
    label: 'Gas Pipeline',
    to: '/service/gas',
  },
  {
    icon: 'svg-electricity',
    label: 'Electricity',
    to: '/service/electricity',
  },
  {
    icon: 'svg-Water',
    label: 'Water',
    to: '/service/water',
  },
  {
    icon: 'svg-insurance',
    label: 'Insurance',
    to: '/service/insurance',
  },
  {
    icon: 'svg-emi',
    label: 'EMI',
    to: '/service/emi',
  },
  // {
  //   // icon: 'pe-7s-help2',
  //   icon: 'pe-7s-cash',
  //   label: 'Loan',
  //   to: '/service/loan',
  // },
  // {
  //   icon: 'pe-7s-id',
  //   label: "LIC Premium",
  //   to: "/service/lic",
  // },
  {
    icon: 'svg-municipality',
    label: 'Municipality',
    to: '/service/municipality',
  },
]
