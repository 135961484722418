import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import FormWizard from "../FormWizard"

const FinishPage = () => {
  const history = useHistory()
  const gotoHome = () => {
    history.push('/')
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-check icon-gradient bg-ripe-malin" />
              </div>
              <div>
                Finished.!
                <div className="page-title-subheading">
                  You have successfully finished KYC Process
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          {/* <Col md={1} /> */}
          <Col md="9" lg="9">
            <Card className="main-card mb-3">
              <CardBody>
                <div className="forms-wizard-alt">
                  <div>
                    <FormWizard currentStep={5} />
                    <div className="form-wizard-content">
                      <div className="no-results">
                        <div className="sa-icon sa-success animate">
                          <span className="sa-line sa-tip animateSuccessTip" />
                          <span className="sa-line sa-long animateSuccessLong" />
                          <div className="sa-placeholder" />
                          <div className="sa-fix" />
                        </div>
                        <div className="results-subtitle mt-4">Finished!</div>
                        <div className="results-title">
                          You have completed KYC Process.
                        </div>
                        <div className="mt-3 mb-3" />
                        <div className="text-center">
                          <Button
                            color="success"
                            size="lg"
                            className="btn-shadow btn-wide"
                            onClick={gotoHome}
                          >
                            Go To Dashboard
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="divider" />
                    <div className="clearfix"></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default FinishPage
