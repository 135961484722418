import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'
import showLoader from 'Components/Loader'
import { useNest, errMsg } from './useNest'
import { accountActions } from 'features/accountSlice'
// import { history } from '../../index'

export const useBank = () => {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const searchByISFC = (isfc = '') =>
    new Promise((resolve, reject) => {
      backend()
        .get(`bank/isfc/${isfc}`)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const getCreditScore = () =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: null,
      }
      backend()
        .get('/bank/credit-score', config)
        .then(({ data }) => {
          dispatch(accountActions.loadCreditScore(data.creditScore))
          return resolve(data.creditScore)
        })
        .catch((error) => {
          toast.error(error.message || errMsg)
          reject(error)
        })
        .finally(() => {
          showLoader(false)
        })
    })

  const bankVerification = (payload = {}) =>
    new Promise((resolve, reject) => {
      showLoader(true)
      backend()
        .post('/bank/verify-bank-account', payload)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          showLoader(false)
        })
    })

  const updateBankAccount = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Updating...', success: 'Account Updated.' },
      }
      backend()
        .post('/bank/update-bank-account', payload, config)
        .then(({ data }) => {
          console.log(data)
          dispatch(accountActions.loadBankAccount(data))
          return resolve(data)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const updateNominee = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Updating...', success: 'Nominee Updated.' },
      }

      backend()
        .post('/bank/nominee', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.loadNominee(data))
          return resolve(data)
        })
        .catch((error) => {
          toast.error(error.message)
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    searchByISFC,
    getCreditScore,
    bankVerification,
    updateBankAccount,
    updateNominee,
  }
}
