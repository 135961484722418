import { Button } from 'antd'
import React from 'react'
import { useState, useEffect } from 'react'

const Timer = ({
  initialMinute = 1,
  initialSeconds = 30,
  resentText = 'Resend',
  resend = () => {},
}) => {
  // const { initialMinute = 1, initialSeconds = 0 } = props
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <Button
          onClick={resend}
          type="primary"
          shape="round"
          size="small"
          danger
        >
          {resentText}
        </Button>
      ) : (
        <Button
          type="link"
          shape="round"
          size="small"
          danger
          className="p-0"
          style={{ pointerEvents: 'none' }}
        >
          {resentText} in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Button>
      )}
    </div>
  )
}

export default Timer
