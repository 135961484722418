import React from 'react'
import { getAverageRGB } from 'utils/constants'
import Logo from '../../../../../assets/img/augmont.png'

const GoalCard = ({ goal }) => {
  return (
    <div
      className={`d-flex flex-column sip-cards gold-fd-cards card-${goal.id}`}
      title={goal.name}
    >
      <div className="d-flex align-item-center justify-content-center gold-fd-image-holder">
        <img
          alt=""
          src={Logo} //TODO: change img
          onLoad={getAverageRGB}
          productid={goal?.id}
          crossOrigin="anonymous"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      <div className="sip-card-body">
        <div className="brandStrokeContainer">
          <div className={`brandStroke brandStroke-${goal.id}`}></div>
        </div>
        <div
          className={`font-weight-bold overflow-hidden font-poppins-md text-center mt-2 brandText-${goal.id}`}
          id={goal?.id}
        >
          {goal?.interestRate}% Interest
        </div>
      </div>
    </div>
  )
}

export default GoalCard
