import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import { ConfigProvider } from 'antd'
import { customTheme } from 'antd.theme'
import './assets/base.scss'
import App from './App'
import { LoaderSuspense } from './Components/Loader'
// import reportWebVitals from './reportWebVitals'
import { store } from './features/configureStore'

ReactDOM.render(
  <React.Suspense fallback={<LoaderSuspense />}>
    <ConfigProvider theme={customTheme}>
      <StyleProvider hashPriority="low">
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </StyleProvider>
    </ConfigProvider>
  </React.Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
