import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { Modal } from 'reactstrap'
import { Button, Card, Checkbox, Col, Flex, Row } from 'antd'
import { formattedPrice } from 'utils/helper'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { useHistory, useLocation } from 'react-router-dom'
import { usePin } from 'context/PinContext'
import { useFixerra } from 'backend/useFixerra'
import { useSocket } from 'context/SocketContext'
import { selectFixerra } from 'features/fixerra/fixerraSlice'
import usePageTitle from 'hooks/usePageTitle'
import UserConsent from 'Components/UserConsent'

const short = require('short-uuid')

export function convertDaysToYMDSimple(days) {
  const years = Math.floor(days)
  const months = Math.floor(((days * 365) % 365) / 30)

  return `${years === 1 ? '1 Year' : years + ' Years'}  ${months} Months`
  // return {
  //   years,
  //   months,
  //   days: remainingDays,
  // }
}

const FixerraCheckout = () => {
  const [modal, setModal] = useState(false)
  const sessionIdRef = useRef(null)
  const { isReady, socket } = useSocket()
  const location = useLocation()
  const { getPurchaseUrl } = useFixerra()
  const history = useHistory()
  const { checkPin } = usePin()
  // console.log('year', location.state)
  usePageTitle('Checkout Fixed Deposits')

  const [checked, setChecked] = useState(true)
  const [acceptCheck, setAcceptCheck] = useState(true)
  const [purchaseUrl, setPurchaseUrl] = useState('')
  const [issuerData, setIssuerData] = useState({ name: '' })
  const { collections } = useSelector(selectFixerra)

  const selectedScheme = location.state.scheme
  const duration = location.state.duration
  const calculation = location.state.calculation

  // const toggleModal = () => {
  //   setModal(!modal)
  // }

  useEffect(() => {
    getIssuersData(selectedScheme.f_issuer_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScheme])

  useEffect(() => {
    if (isReady) {
      socket.on('fixerra-fd.callback', handleCallback)
    }
    return () => {
      socket?.off('fixerra-fd.callback', handleCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  // useEffect(() => {
  //   setModal(true)
  //   setPurchaseUrl('https://kashewnut-beta.fixerra.in/login?authCode=249a22814bf213a7ff2e68cfa58da9d3%253A46a8f190a8333b71487956496c2f12afd1f5f4fc1b886f3cadeebb49a5ac61649aad35de4efc6850f6e410f81ca0b0aa2cb44460d119d0ad92348e74b1f980a5fe041ae74b8c466d8695df936eef29a6ae75599812f9bdda0060f15b131621218f51fb53cfc4b6c894aa56db9b4a0318927afe23bf34c96d57cad01b2ca94d65&issuer=shriram&tenure=365&amount=10000&payout=Maturity&senior=false&tax=false&women=false')
  // }, [])

  const getIssuersData = (issuerId) => {
    const tmp = collections.items.find((item) => item?.f_issuer_id === issuerId)
    setIssuerData(tmp)
  }

  const handleCallback = (data) => {
    console.log('fixerra-fd.callback', data)
    console.log(sessionIdRef.current, '==', data.sessionId)
    if (sessionIdRef.current === data.sessionId) {
      setModal(false)
      setPurchaseUrl('')
      history.push('/')
      // if (data.status === 'success') {
      // }
    }
  }

  const handleBack = () => history.goBack()

  const validatePin = () => {
    checkPin('', handleCheckout, 'Goal Checkout Fixerra')
  }

  // useEffect(() => {
  //   if (purchaseUrl) {
  //     let windowName = 'Kashewnut'
  //     let customizations =
  //       'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=450,height=830'

  //     window.open(purchaseUrl, windowName, customizations)
  //   }
  // }, [purchaseUrl])

  if (!selectedScheme || !calculation) return null

  const handleCheckout = () => {
    try {
      const payload = {
        schemeCode: issuerData.f_code,
        issuer: issuerData.f_issuer,
        // issuer:
        //   schemeCode === 'SHRI'
        //     ? 'shriram'
        //     : schemeCode === 'BAJ'
        //     ? 'bajaj'
        //     : schemeCode === 'MAH'
        //     ? 'mahindra'
        //     : schemeCode === 'PNBH'
        //     ? 'pnb'
        //     : '',
        tenure: Math.ceil(duration) * 365,
        amount: calculation.amountToBeInvest,
        payout: 'Maturity',
        senior: selectedScheme.product_senior_citizen,
        tax: selectedScheme.product_tax_saver,
        women: selectedScheme.product_women,
      }
      const tmpSessionId = String(payload.issuer + '-' + short.generate())
      sessionIdRef.current = tmpSessionId
      getPurchaseUrl(tmpSessionId, payload).then((data) => {
        if (data.url) {
          setModal(true)
          setPurchaseUrl(data.url)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  console.log(sessionIdRef.current, 'sessionIdRef.current')
  // console.log(issuerData)

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={16}>
            <Card className="main-card card" title="Checkout">
              {/* <pre>{JSON.stringify(issuerData, null, 2)}</pre> */}
              {/* <pre>
                {JSON.stringify({ ...location.state, amount: total }, null, 2)}
              </pre> */}
              {/* <pre>{JSON.stringify(location.state, null, 2)}</pre> */}
              <table className="align-middle text-truncate table table-borderless table-hover mb-0">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-center">Amount</th>
                    <th></th>
                    <th className="text-right">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        {issuerData?.issuer_name}{' '}
                        {selectedScheme?.product_interest}% FD
                      </div>
                      <small>{convertDaysToYMDSimple(duration)}</small>
                    </td>
                    <td className="text-center">
                      {formattedPrice(calculation.amountToBeInvest)}
                    </td>
                    <td></td>
                    <td className="text-right">
                      <b>{formattedPrice(calculation.amountToBeInvest)}</b>
                    </td>
                  </tr>
                  {/* <tr className="bg-light small">
                      <td className="">
                        <small>{scheme.product_interest}</small>
                      </td>
                      <td className="text-center"></td>
                      <td className="text-right">{scheme.product_interest}%</td>
                      <td className="text-right">
                        <b>{formattedPrice(calculation.amountToBeInvest)}</b>
                      </td>
                    </tr> */}
                </tbody>
              </table>
              <table className="align-middle text-truncate table table-borderless mb-0">
                <tbody>
                  <tr className="border-bottom">
                    <td className="text-right" colSpan={3}></td>
                  </tr>
                  <tr className="small">
                    <td className="text-right" colSpan={2}>
                      Subtotal:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(calculation.amountToBeInvest)}
                    </td>
                  </tr>
                  <tr className="small border-bottom">
                    <td className="text-right" colSpan={2}>
                      Tax:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0" style={{ width: '75%' }}>
                      <Button
                        icon={<FaArrowLeft />}
                        type="text"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </td>
                    <td className="text-right font-weight-bold">Total:</td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(calculation.amountToBeInvest)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div className="mt-4">
              <UserConsent
                message="*I hereby give consent to share my data with Fixerra Pvt Ltd to facilitate FD booking."
                onAccept={(val) => setAcceptCheck(val)}
              />
            </div>
          </Col>
          <Col span={24} md={8}>
            <Card className="main-card card" title="Payment">
              <div className="mb-2">
                You will redirect to provider payment gateway
              </div>
              <Flex justify="space-between">
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                >
                  Need to Pay
                </Checkbox>
                <b>{formattedPrice(calculation.amountToBeInvest)}</b>
              </Flex>
              <div className="mt-5">
                <Button
                  onClick={validatePin}
                  icon={<FaArrowRight />}
                  iconPosition="end"
                  disabled={!checked || !acceptCheck}
                  type="primary"
                  block
                >
                  Checkout
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
      {modal && purchaseUrl && (
        <Modal isOpen={modal} className="h-100" centered>
          {/* <ModalHeader toggle={toggleModal}>
              <i className="pe-7s-cart" />
              Fixerra
            </ModalHeader> */}
          {/* <ModalBody className=""> */}
          <div className="custom-close-btn" onClick={() => setModal(false)}>
            <span aria-hidden="true">×</span>
          </div>
          <iframe
            src={purchaseUrl}
            height="100%"
            title="purchase-fixerra"
          ></iframe>
          {/* </ModalBody> */}
        </Modal>
      )}
    </Fragment>
  )
}

export default FixerraCheckout
