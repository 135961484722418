import React from 'react'
import ScaleLoader from "react-spinners/ScaleLoader";

export const ControlledLoader = () => {
  return (
    <div
      id="loader"
      className="loading-container align-items-center justify-content-center"
      style={{
        // height: '100vh',
        display: 'none'
      }}>
      <ScaleLoader
        color={'#545cd8'}
        loading={true}
        height={40}
        width={4}
      />
    </div>
  )
}

export const LoaderSuspense = () => {
  return (
    // <div
    //   id="loader"
    //   className="loading-container align-items-center justify-content-center"
    //   style={{
    //     // height: '100vh',
    //   }}>
    //   <Loader type="line-scale-party" active={true} color="#545cd8" />
    // </div>
    <div className="align-items-center d-flex justify-content-center" style={{ height: '100vh' }}>
      <ScaleLoader
        color={'#545cd8'}
        loading={true}
        height={40}
        width={4}
      />
    </div>
  )
}

const showLoader = (loading) => {
  let loader = document.getElementById('loader')
  if (loader) {
    if (loader && loading === true) {
      loader.classList.add('d-flex')
    } else {
      loader.classList.remove('d-flex')
    }
  }
}

export default showLoader

if(typeof window !== 'undefined') {
  window.showLoader = showLoader
}
