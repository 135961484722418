import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'

import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap'
import dayjs from 'dayjs'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAuth } from 'backend/useAuth'
import { DatePicker, Form, Input, Select, Submit } from 'ReactstrapUi'
import RadioGroup from 'ReactstrapUi/Form/RadioGroup'
import { selectAuth } from 'features/auth/authSlice'
import usePageTitle from 'hooks/usePageTitle'

const ProfileEdit = () => {
  const { updateUserProfile } = useAuth()
  const { user } = useSelector(selectAuth)

  usePageTitle('Profile')

  // const [mobile, setMobile] = useState('')
  // const [mobileExist, setMobileExist] = useState(false)

  // const [email, setEmail] = useState('')
  // const [emailExist, setEmailExist] = useState(false)

  // const validateEmail = (value) => new Promise((resolve) => {
  //   if (user.email === value) {
  //     return resolve(true)
  //   }
  //   if (value.length > 5) {
  //     if (emailExist && email === value) {
  //       return resolve(emailExist)
  //     }
  //     isEmailExist(value).then(exist => {
  //       setEmailExist(exist)
  //       return resolve(exist)
  //     })
  //   } else {
  //     return resolve(false)
  //   }
  // })

  // const validateMobile = (value) => new Promise((resolve) => {
  //   if (user.mobile === value) {
  //     return resolve(true)
  //   }
  //   if (value.length === 10) {
  //     if (mobileExist && mobile === value) {
  //       return resolve(mobileExist)
  //     }
  //     isMobileExist(value).then(exist => {
  //       setMobileExist(exist)
  //       return resolve(exist)
  //     })
  //   } else {
  //     return resolve(false)
  //   }
  // })

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    // sMiddleName: yup.string()
    //   .required('Middle name is required'),
    lastName: yup.string().required('Last name is required'),
    gender: yup.string().required('Gender is required'),
    dob: yup.string().required('Date of birth is required'),
    // sAddress: yup.string()
    //   .required('Address is required'),
    line1: yup.string().required('Address line 1 is required'),
    // line2: yup.string()
    //   .required('Address 2 is required'),
    city: yup.string().required('City is required'),
    zip: yup.string().required('Pin Code is required'),
    // .min('Pin Code must be 6 digits'),
    state: yup.string().required('State is required'),
    // address: yup.string().required('Address is required'),
    // mobile: yup.string()
    //   .required('Mobile is required')
    //   .matches(validMobileRegex, 'Please enter valid mobile number')
    //   .min(10, 'Mobile Number should 10 digits')
    //   .test(
    //     'exist',
    //     'This mobile number is already exists',
    //     // async (value) => (await fetch('/is-jimmy/' + value)).responseText === 'true'
    //     // async (value) => await validateMobile(value) //.then(res => console.log(res))
    //     value => validateMobile(value)
    //   ),
    // email: yup.string()
    //   .required('Email is required')
    //   .email('Please enter valid email')
    //   .test(
    //     'exist',
    //     'This email id already exists',
    //     // async (value) => (await fetch('/is-jimmy/' + value)).responseText === 'true'
    //     value => validateEmail(value)
    //   ),
    // pan: yup.string()
    //   .required('PAN number is required')
    //   .matches(validPANNumberRegex, 'Please enter valid PAN Number')
    //   .min(10, 'PAN Number should 10 digits'),
    // aadhaarNo: yup.string()
    //   .required('Aadhaar Card Number is required')
    //   // .matches(validAadhaarNumberRegex, 'Please enter valid Aadhaar Card Number'),
    //   .min(12, 'Aadhaar Card Number should 12 digits'),
  })

  const onSubmit = (data) => {
    const payload = {
      ...data,
      dob: dayjs(data.dob).toISOString(),
    }
    console.log(payload)
    updateUserProfile(payload)
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row>
          <Col lg={9}>
            <Card className="main-card">
              <CardBody>
                <CardTitle>Edit Profile</CardTitle>
                <Form
                  onFinish={onSubmit}
                  schemaResolver={yupResolver(schema)}
                  className="validate-form was-validate"
                  initialValues={{
                    title: user.title,
                    firstName: user.firstName,
                    // sMiddleName: user.sMiddleName,
                    lastName: user.lastName,
                    gender: user.gender,
                    dob: dayjs(user.dob).format('YYYY-MM-DD'),
                    line1: user.address.line1,
                    line2: user.address.line2,
                    city: user.address.city,
                    zip: user.address.zip,
                    state: user.address.state,
                    // email: user.email,
                    // pan: user.pan,
                    // zip: user.zip,
                    // aadhaarNo: user.aadhaarNo,
                    // mobile: user.mobile
                  }}
                >
                  <Row>
                    <Col md="12">
                      {/* <Input.Text
                        label="Aadhaar number"
                        name="aadhaarNo"
                        readOnly
                      /> */}
                    </Col>
                    <Col md="6">
                      <Input.Text
                        label="First Name"
                        id="firstName"
                        name="firstName"
                        prepend={
                          <Select
                            name="title"
                            id="title"
                            label={null}
                            className="form-control"
                            options={[
                              { value: 'Mr.', label: 'Mr.' },
                              { value: 'Ms.', label: 'Ms.' },
                            ]}
                            onChange={(e) =>
                              console.log('title', e.target.value)
                            }
                          ></Select>
                        }
                        required
                      />
                    </Col>
                    {/* <Col md="4">
                    <Input.Text label="Middle Name" name="middleName" required />
                    </Col> */}
                    <Col md="6">
                      <Input.Text label="Last Name" name="lastName" required />
                    </Col>
                    <Col md="6">
                      <DatePicker
                        label="Date of birth"
                        name="dob"
                        required
                        pickerOptions={{
                          dateFormat: 'dd-MM-yyyy',
                          showYearDropdown: true,
                          showMonthDropdown: true,
                          maxDate: dayjs().subtract(18, 'years').toDate(),
                        }}
                      />
                      {/* <Input.Text
                        label="Date of birth"
                        type="date"
                        name="dob"
                        required
                        min={dayjs([new Date().getFullYear() - 60]).format(
                          'YYYY-MM-DD'
                        )}
                        max={dayjs([new Date().getFullYear() - 18]).format(
                          'YYYY-MM-DD'
                        )}
                      /> */}
                    </Col>
                    <Col md="6">
                      <RadioGroup
                        id="gender"
                        name="gender"
                        label="Gender"
                        required
                        options={[
                          {
                            value: 'M',
                            label: 'Male',
                          },
                          {
                            value: 'F',
                            label: 'Female',
                          },
                        ]}
                      />
                    </Col>
                    <Col md="12">
                      <Input.Text
                        id="line1"
                        name="line1"
                        label="Address Line 1"
                        required
                      />
                    </Col>
                    <Col md="12">
                      <Input.Text
                        id="line2"
                        name="line2"
                        label="Address Line 2"
                      />
                    </Col>
                    <Col md="6">
                      <Input.Text id="city" name="city" label="City" required />
                    </Col>
                    <Col md="6">
                      <Input.Text
                        id="zip"
                        name="zip"
                        label="Pin/Zip Code"
                        required
                      />
                    </Col>
                    <Col md="6">
                      <Input.Text
                        id="state"
                        name="state"
                        label="State"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="text-right">
                      <small className="text-danger">
                        Fields marked with * are required
                      </small>
                    </Col>
                    <Col md="12" className="d-flex justify-content-end">
                      <Submit className="btn btn-primary mt-3">
                        Update Profile
                      </Submit>
                      {/* <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Update Profile
                        </Button>
                      </FormGroup> */}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default ProfileEdit
