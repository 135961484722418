import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
} from 'reactstrap'

import { useForm } from 'react-hook-form'

import {
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from '../../../utils/validation'

import SelectedOperatorBBPS from '../SelectedOperatorBBPS'

const Form = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const operators = useSelector((state) => state.services.bbpsOperators)
  const [operator, setOperator] = useState()

  useEffect(() => {
    if (operators) {
      setOperator(
        operators.find((op) => op.service === service && op.code === opcode)
      )
    }
  }, [operators, opcode, service])

  useEffect(() => {
    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, reset, setValue, trigger, formState } =
    useForm()

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      operator: operator.code,
      amount: data.canumber,
      // referenceid: '20018575947', generated at backend.
      latitude: '27.2232',
      longitude: '78.26535',
      mode: 'online',
    }
    console.log('payload', payload)
    history.push({
      pathname: `/service/checkout`,
      state: {
        services: [
          {
            amount: data.canumber,
            name: operator.name,
            description: `Payment for ${operator.name} ${operator.category}`,
            quantity: 1,
            service: operator.category,
          },
        ],
        provider: payload,
        meta: {
          bill: operator?.bill,
          operator,
        },
      },
    })
  }

  const handleChangeInput = (name, value) => {
    setValue(name, value)
    trigger(name)
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(operator) || isEmpty(operators)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Generate {operator?.name}</CardTitle>
              <SelectedOperatorBBPS
                opcode={opcode}
                category={service}
                // showBillStatus
              />
              {/* <pre>{JSON.stringify(operator, null, 2)}</pre> */}
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  {operator?.fields &&
                    operator?.fields.map((field) => (
                      <Col lg="12" key={field?.name}>
                        <FormGroup>
                          <Label for={field?.name} className="required">
                            {field.label}
                          </Label>
                          <input
                            type="text"
                            placeholder={field.placeholder}
                            id={field?.name}
                            name={field?.name}
                            className="form-control"
                            {...register(field?.name, {
                              required: `${field.label} is required`,
                              pattern: field.regex && {
                                value: new RegExp(field.regex),
                                message: `Please enter valid ${field.label}`,
                              },
                            })}
                            onChange={(e) =>
                              handleChangeInput(field?.name, e.target.value)
                            }
                          />
                          <InvalidFeedbackV7
                            name={field?.name}
                            formState={formState}
                          />
                        </FormGroup>
                      </Col>
                    ))}
                </Row>
                <Row>
                  <Col lg="12" className="d-flex justify-content-end">
                    <FormGroup>
                      <Button
                        size="lg"
                        block
                        className="mt-3"
                        color="primary"
                        disabled={preventSubmit(formState)}
                      >
                        Proceed
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7"></Col>
      </Row>
    </Fragment>
  )
}

export default Form
