import React from 'react'
import '../assets/components/bootstrap4/index.css'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { LoaderSuspense } from 'Components/Loader'
import { loading } from 'features/status'
import { selectAuth } from 'features/auth/authSlice'
import ScrollToTop from 'Components/ScrollToTop'

export default function PrivateRoute({ component: Component, ...rest }) {
  const { roles, logged, status, user } = useSelector(selectAuth)

  const LoggedAndHasRole = (props) => {
    if (status === loading) {
      return <LoaderSuspense />
    } else {
      if (!logged) {
        if (window.location.href.search('auth/') === -1) {
          return <Redirect to="/auth/login" />
        }
        return <></>
      } else if (roles.length === 0) {
        return <LoaderSuspense />
      } else if (
        roles.includes('User') &&
        user?.aadhaarVerified &&
        user?.panVerified
      ) {
        return <Component {...props} />
      } else {
        return <Redirect to="/403" />
      }
    }
  }

  return (
    <>
      <ScrollToTop />

      <Route
        exact={rest.exact}
        {...rest}
        render={(props) => {
          return <LoggedAndHasRole {...props} />
        }}
      />
    </>
  )
}
