import React, { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
// import { useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Card, Col, Row, Button, Flex, Checkbox } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { usePin } from 'context/PinContext'
// import sipSvg from 'assets/svg/sip.svg'
import { formattedPrice } from 'utils/helper'
// import { usePin } from 'context/PinContext'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useInvestment } from 'backend/useInvestment'
// import { investmentType } from 'utils/constants'
// import { selectAuth } from 'features/auth/authSlice'
import { useLendBox } from 'backend/useLendBox'
import { toast } from 'react-toastify'
import { useLog } from 'backend/useLog'
import { investmentType, providers } from 'utils/constants'
import { uiActions } from 'features/uiSlice'
import usePageTitle from 'hooks/usePageTitle'
import UserConsent from 'Components/UserConsent'

const short = require('short-uuid')

const CheckoutLendBox = () => {
  usePageTitle('Investment')
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  // const { virtualAccount } = useSelector(selectBank)
  // const { user } = useSelector(selectAuth)
  const [checked, setChecked] = useState(true)
  const [acceptCheck, setAcceptCheck] = useState(true)
  const [taxTotal] = useState(0)
  const { checkPin } = usePin()
  // const [walletBalance] = useState(virtualAccount.walletBalance)
  const { createGoal } = useInvestment()
  const { addFund, mandateRegister, confirmMandate } = useLendBox()
  const { createLog } = useLog()
  // https://razorpay.com/docs/payments/payment-gateway/web-integration/standard/build-integration/#12-integrate-with-checkout-on-client-side
  // https://razorpay.com/docs/payments/server-integration/nodejs/payment-gateway/build-integration/
  // https://razorpay.com/docs/payments/server-integration/nodejs/faqs/#3-can-i-integrate-razorpay-checkout-with-reactjs

  // const razorPayOptions = {
  //   custName: 'VIKESH AGRAVAT',
  //   email: 'vicky.agravat@gmail.com',
  //   contact: '9824256363',
  //   recurring: 1,
  //   amount: 50,
  //   maxAmount: 50000,
  //   orderId: 'order_Lycgz2uEmomZPH',
  //   custId: 'cust_LcVD2GICgsJoTk',
  //   method: 'emandate',
  //   name: 'LENDBOX',
  //   expireBy: 2709971120,
  //   key: 'rzp_test_ol1baELlGDQuDp',
  //   notes: {
  //     productInfo: 'User E-Mandate',
  //   },
  // }
  // let razorPayOptions = {
  //   key: 'rzp_test_HJG5Rtuy8Xh2NB',
  //   amount: '100', //  = INR 1
  //   name: 'Kashewnut',
  //   description: 'some description',
  //   // image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
  //   handler: function (response) {
  //     alert(response.razorpay_payment_id)
  //   },
  //   // prefill: {
  //   //   name: 'Gaurav',
  //   //   contact: '9999999999',
  //   //   email: 'demo@demo.com',
  //   // },
  //   prefill: {
  //     name: user.firstName + ' ' + user.lastName,
  //     contact: user.mobile,
  //     email: user.email,
  //   },
  //   notes: {
  //     address: 'some address',
  //     productInfo: 'User E-Mandate',
  //   },
  //   theme: {
  //     color: '#555CD8',
  //     hide_topbar: false,
  //   },
  // }

  let total = 0
  let subTotal = 0
  let totalPrice = 0
  let investAmount = 0
  let tax = 0

  if (location.state.services.length > 0) {
    totalPrice = location.state.services.reduce((prev, next) => {
      return prev + parseFloat(next.amount * next.quantity)
    }, 0)
    // if (accountCreated) {
    //   investAmount = invest ? calculateInvestmentAmount(totalPrice) : 0
    // }
    subTotal = totalPrice + investAmount
    total = subTotal + parseFloat(tax)
  }

  const validatePin = () => {
    // mandateExistCallback()
    checkPin('', handleCheckout, `To checkout`)
  }

  const openRazorPay = (options) => {
    const razorPayOptions = {
      ...options,
      // amount: 500000,
      // orderId: 'order_M4UpQ3lzlWWX3G',
      // notes: {
      //   productInfo: 'PA Add funds',
      // },
      // key: 'rzp_live_YuOAO1TLGadXVB',
      // custName: 'VIKESH AGRAVAT',
      // email: 'vicky.agravat@gmail.com',
      // mobile: '9824256363',
      // currency: 'INR',
      // prefill: {
      //   name: resData.data.custName,
      //   contact: resData.data.mobile,
      //   email: resData.data.email,
      // },
      name: 'Kashewnut',
      image: 'https://cdn.kashewnut.com/img/logo-for-razorpay.png',
      // description: location.state.services[0]?.description,
      // handler: function (response) {
      //   if (response.razorpay_payment_id) {
      //     // toast.success('Payment success')
      //     // createGoal(payload).then((res) => {
      //     //   history.push('/dashboard')
      //     // })
      //   }
      // },
    }
    console.log('razorPayOptions', razorPayOptions)

    var rzpIntent = new window.Razorpay(razorPayOptions)
    rzpIntent.open()
    rzpIntent.on('payment.failed', function (response) {
      createLog({
        name: 'rzorpay.frontend.log',
        body: response.error,
      })
      console.log(response.error)
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
  }

  const handleCheckout = async () => {
    // const payload = {
    //   checkout: {
    //     ...location.state.services[0],
    //   },
    //   goal: location.state?.goal,
    //   // provider: location.state.provider,
    //   uuid: short.generate(),
    // }
    if (location.state.meta.operator.endpoint === providers.qwikCilver) {
      const payload = {
        checkout: {
          ...location.state.services[0],
        },
        goal: {
          ...location.state?.goal,
        },
        // provider: location.state.provider,
        uuid: short.generate(),
      }
      const createdGoal = await createGoal(payload)
      if (location.state.meta.investmentType === investmentType.SIP) {
        // https://razorpay.com/docs/api/payments/recurring-payments/emandate/create-authorization-transaction/#113-create-an-authorization-payment
        // const resData = {
        //   success: true,
        //   msg: 'Result fetch successful',
        //   data: {
        //     custName: 'DEVEN MODHA',
        //     email: 'cadevenmodha@gmail.com',
        //     contact: '9974104057',
        //     recurring: 1,
        //     amount: 0,
        //     maxAmount: 100000,
        //     orderId: 'order_M7NqJQTNwXVz3Q',
        //     custId: 'cust_M7NVLpwSA2AQK3',
        //     method: 'emandate',
        //     name: 'LENDBOX',
        //     expireBy: 2709971120,
        //     key: 'rzp_live_YuOAO1TLGadXVB',
        //     notes: {
        //       productInfo: 'User E-Mandate',
        //     },
        //   },
        // }
        const mandateRes = await mandateRegister({
          amount: total,
          goalId: createdGoal.id,
        })
        console.log('mandateRegister', mandateRes)
        if (mandateRes.success && mandateRes.mandateExist) {
          toast.info('Mandate already exist')
          mandateExistCallback(mandateRes.mandateGoal.id)
          // successCallback()
          return
        }
        if (mandateRes.success) {
          const razorPayData = mandateRes.data
          const razorPayOptions = {
            key: razorPayData.key,
            amount: razorPayData.amount,
            order_id: razorPayData.orderId,
            customer_id: razorPayData.custId,
            method: razorPayData.method,
            recurring: razorPayData.recurring,
            expireBy: razorPayData.expireBy,
            max_amount: razorPayData.maxAmount,
            // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
            prefill: {
              name: razorPayData.custName,
              email: razorPayData.email,
              contact: razorPayData.contact,
            },
            // notes: {
            //   product_info: razorPayData.notes.productInfo,
            // },
            description: location.state.services[0]?.description,
            handler: function (response) {
              if (response.razorpay_payment_id) {
                createLog({
                  name: 'razorpay.mandate.success',
                  body: response,
                })
                console.log(response)
                toast.success('Mandate signed success')
                successCallback()
                // updateInvestmentStatus(resData.data.orderId, {
                //   status: 'Active',
                // }).then((res) => {
                //   successCallback()
                //   // history.push('/dashboard')
                // })
                // createGoal(payload).then((res) => {
                //   history.push('/dashboard')
                // })
              }
            },
          }
          openRazorPay(razorPayOptions)
        }
      } else {
        const fundRes = await addFund({
          amount: total,
          goalId: createdGoal.id,
        })
        console.log('addFund', fundRes)
        if (fundRes.success) {
          const razorPayData = fundRes.data
          const razorPayOptions = {
            key: razorPayData.key,
            amount: razorPayData.amount,
            currency: razorPayData.currency,
            order_id: razorPayData.orderId,
            // callback_url: 'https://dev.kashewut.com/v1/lendbox/callback',
            prefill: {
              name: razorPayData.custName,
              email: razorPayData.email,
              contact: razorPayData.contact,
            },
            // notes: {
            //   product_info: razorPayData.notes.productInfo,
            // },
            description: location.state.services[0]?.description,
            handler: function (response) {
              if (response.razorpay_payment_id) {
                createLog({
                  name: 'razorpay.payment.success',
                  body: response,
                })
                console.log(response)
                toast.success('Payment success')
                successCallback()
                // updateInvestmentStatus(resData.data.orderId, {
                //   status: 'Active',
                // }).then((res) => {
                //   successCallback()
                //   // history.push('/dashboard')
                // })
                // createGoal(payload).then((res) => {
                //   history.push('/dashboard')
                // })
              }
            },
          }
          openRazorPay(razorPayOptions)
        }
      }
    }
  }

  const successCallback = () => {
    dispatch(
      uiActions.showSuccessModal({
        title: 'Success',
        description: `${location.state.services[0].description} is in progress`,
        buttonText: 'Go Back To Dashboard',
        redirectUrl: '/dashboard',
      })
    )
    // history.push('/dashboard')
  }

  const mandateExistCallback = (mandateId) => {
    dispatch(
      uiActions.reUsableModal({
        content: (
          <>
            <h4 className="m-3">Mandate already signed</h4>
            <div>
              <div className="pt-3 pb-4">
                Buy Clicking confirm, we aggregate this goal to existing signed
                mandate...
              </div>
              <button
                className="btn btn-block btn-info"
                onClick={() => {
                  confirmMandate(mandateId).then((res) => {
                    dispatch(uiActions.toggleReUsableModal())
                    dispatch(
                      uiActions.showSuccessModal({
                        title: 'Success',
                        description: `${location.state.services[0].description} is in activated`,
                        buttonText: 'Go Back To Dashboard',
                        redirectUrl: '/dashboard',
                      })
                    )
                  })
                }}
              >
                Confirm
              </button>
            </div>
          </>
        ),
      })
    )
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row gutter={[24, 24]}>
          <Col span={24} md={16}>
            <Card className="main-card card" title="Checkout">
              <table className="align-middle text-truncate table table-borderless mb-0">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th className="text-right">Amount</th>
                    <th className="text-right">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {location.state.services.map((service, i) => (
                    <tr key={i}>
                      <td style={{ width: '60%' }}>
                        <div>{service.name}</div>
                        <small>{service.description}</small>
                      </td>
                      <td className="text-right">
                        {formattedPrice(service.amount)}
                      </td>
                      <td className="text-right font-weight-bold">
                        {formattedPrice(service.amount * service.quantity)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="align-middle text-truncate table table-borderless mb-0">
                <tbody>
                  <tr className="border-bottom">
                    <td colSpan={3}></td>
                  </tr>
                  <tr className="small">
                    <td className="text-right" colSpan={2}>
                      Subtotal:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(subTotal)}
                    </td>
                  </tr>
                  <tr className="small border-bottom">
                    <td className="text-right" colSpan={2}>
                      Tax:
                    </td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(taxTotal)}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0" style={{ width: '75%' }}>
                      <Button
                        icon={<FaArrowLeft />}
                        type="text"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    </td>
                    <td className="text-right font-weight-bold">Total:</td>
                    <td className="text-right font-weight-bold">
                      {formattedPrice(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div className="mt-4">
              <UserConsent
                message="*I hereby give consent to share my data with Transactree Technologies Pvt Ltd."
                onAccept={(accepted) => setAcceptCheck(accepted)}
              />
            </div>
          </Col>
          <Col span={24} md={8}>
            <Card className="main-card card" title="Payment">
              <div className="mb-2">Payment Method</div>
              <Flex justify="space-between">
                <Checkbox
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                >
                  RazorPay
                </Checkbox>
                <b>{formattedPrice(total)}</b>
              </Flex>
              <div className="mt-5">
                <Button
                  onClick={validatePin}
                  icon={<FaArrowRight />}
                  iconPosition="end"
                  disabled={!checked || !acceptCheck}
                  type="primary"
                  block
                >
                  Checkout
                </Button>
              </div>
            </Card>
          </Col>
          <Col md={24}>
            {/* <pre>{JSON.stringify(rates, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(location.state, null, 2)}</pre> */}
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default CheckoutLendBox
