import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap'
import CountUp from 'react-countup'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { nest } from 'backend'
import { toast } from 'react-toastify'

const roundToOne = (num) => Math.round(num * 10) / 10

const PlannerModel = ({ goalScheme, maxQuantity, onCancel }) => {
  const history = useHistory()

  const minQtyLease = goalScheme.minQtyLease > 1 ? goalScheme.minQtyLease : 1
  const [submitDisable, setSubmitDisable] = useState(null)
  const [preCalculationResult, setPreCalculationResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(roundToOne(minQtyLease))
  const [agreeWithTerms, setAgreeWithTerms] = useState(false)
  const [galSchemeId, setGoalSchemeId] = useState(null)
  const [termsModal, setTermsModal] = useState(false)
  const [terms, setTerms] = useState(
    '<div style="text-align: center;"><h2>Loading...</h2></div>'
  )
  console.log('terms here ::', terms)

  const onClickPreCalculation = useCallback(
    async (quantityForCal) => {
      // setLoading(true)
      try {
        setQuantity(quantityForCal)
        if (
          goalScheme?.id &&
          quantityForCal &&
          +quantityForCal <= maxQuantity
        ) {
          setSubmitDisable(null)
          setGoalSchemeId(goalScheme?.id)
          const response = await nest.post(`/augmont/fd/calculations`, {
            fdSchemeId: goalScheme.id,
            quantity: quantityForCal,
          })
          if (response?.result?.data)
            setPreCalculationResult(response.result.data)
        } else {
          setSubmitDisable('Entered quantity is greater then your gold balance')
        }
      } catch (error) {
        // history.push('/investment/goal/long')
        setSubmitDisable(error.message ?? 'Please enter valid quantity')
      } finally {
        setLoading(false)
      }
    },
    [maxQuantity, goalScheme]
  )

  const showTnc = () => {
    setTermsModal(true)
    setTerms('<div style="text-align: center;"><h2>Loading...</h2></div>')
    nest.get(`/augmont/fd/fd-terms/${galSchemeId}`).then((terms) => {
      setTerms(terms?.result?.data)
    })
  }

  useEffect(() => {
    if (goalScheme?.minQtyLease) {
      if (goalScheme?.minQtyLease > maxQuantity) {
        history.push('/investment/goal/long')
        toast.error('Insufficient gold amount')
      } else onClickPreCalculation(Math.round(minQtyLease))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalScheme])

  return (
    <>
      <Modal isOpen={true} size="lg" className="gold-plan-model flex row">
        <ModalHeader>{goalScheme?.name}</ModalHeader>
        <div className="content-cards p-3 mb-2">
          {submitDisable ? (
            <span className="text-danger">* {submitDisable}</span>
          ) : null}
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-1 fw-bold">Lease Amount</p>
            <small>
              Minimum Lease Amount: {roundToOne(goalScheme?.minQtyLease)}
            </small>
          </div>
          <div>
            <div className="gold-fd-amount-input">
              <input
                type="number"
                placeholder="Amount"
                onChange={(e) => onClickPreCalculation(e.target.value)}
                min={minQtyLease}
                max={maxQuantity}
                value={quantity}
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <div className="gold-scheme-details">
          <Table className="plan-details">
            <tbody>
              <tr>
                <td>Jeweller Name</td>
                <td className="font-weight-bolder">
                  {goalScheme?.jewellerUserAccountName}
                </td>
              </tr>
              <tr>
                <td>Interest Rate:</td>
                <td className="font-weight-bolder">
                  {goalScheme?.interestRate} %
                </td>
              </tr>
              <tr>
                <td>Start Date:</td>
                <td className="font-weight-bolder">
                  {dayjs(goalScheme?.startDate).format('DD-MM-YYYY') || 'NA'}
                </td>
              </tr>
              <tr>
                <td>End Date:</td>
                <td className="font-weight-bolder">
                  {dayjs(goalScheme?.endDate).format('DD-MM-YYYY') || 'NA'}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {!submitDisable && (
          <>
            {quantity > 0 && (
              <div className="content-cards p-3 mb-2">
                <Row>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>You Invest</div>
                    <div className="font-weight-bold">
                      <CountUp
                        start={0}
                        end={quantity}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=" gms"
                        duration=".5"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div className="text-nowrap">Interest Rate</div>
                    <div className="font-weight-bold">
                      <CountUp
                        start={0}
                        end={goalScheme?.interestRate ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=" %"
                        duration=".5"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>Number of Days</div>
                    <div className="font-weight-bold">
                      <CountUp
                        start={0}
                        end={preCalculationResult?.noOfDays ?? 0}
                        separator=","
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration=".5"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>You Get</div>
                    <div className="font-weight-bold">
                      {preCalculationResult?.endOfLease ? (
                        <CountUp
                          start={0}
                          end={preCalculationResult.endOfLease ?? 0}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=" gms"
                          duration=".5"
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </Col>
                </Row>

                <div
                  className="d-flex mt-2"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div>
                    <span className="text-danger" style={{ fontSize: '14px' }}>
                      * These are approximate values.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className="terms-conditions p-3">
          For Terms & Conditions{' '}
          <span className="text-primary cursor-pointer" onClick={showTnc}>
            Click here
          </span>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              onClick={() => setAgreeWithTerms(!agreeWithTerms)}
              id="confirmation-checkbox"
            />
            <label class="form-check-label" for="confirmation-checkbox">
              I confirm that I have read and agree to the Terms & Conditions
            </label>
          </div>
        </div>
        <ModalFooter>
          <Button color="secondary" className="p-2 px-3" onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            disabled={
              !preCalculationResult || !agreeWithTerms || !!submitDisable
            }
            className="p-2 px-3"
            onClick={() =>
              history.push(
                `/investment/goal/long/fd/${goalScheme.id}/${quantity}`
              )
            }
          >
            Plan Now
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={termsModal} size={'lg'}>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: terms }}></div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setTermsModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PlannerModel
