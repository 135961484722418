import React, { useEffect } from 'react'
import { Col } from 'reactstrap'
import {
  Stepper,
  Step,
  useStepper,
  // StepStatus,
  StepDescription,
  StepNumber,
} from 'Components/ProgressStepper'
import { useLocation } from 'react-router-dom'
import stepperTheme from './stepperTheme'

const UserDetails = () => {
  const { step, incrementStep } = useStepper(0, 4)
  const location = useLocation()
  useEffect(() => {
    console.log(location.pathname)
    if (location.pathname === '/auth/signup') {
    } else if (location.pathname === '/auth/signup/create') {
      incrementStep()
    } else if (location.pathname === '/auth/aadhaar') {
      incrementStep()
      incrementStep()
    } else if (location.pathname === '/auth/pan') {
      incrementStep()
      incrementStep()
    } else if (location.pathname === '/auth/pan/profile') {
      incrementStep()
      incrementStep()
      incrementStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Col
      lg="5"
      className="d-none d-lg-flex signUpSidebar justify-content-center"
    >
      <div className="signUpSidebar-overlay" />
      <Stepper
        step={step}
        vertical={true}
        style={{ marginLeft: '60%' }}
        theme={stepperTheme}
        // dark={true}
      >
        <Step>
          <StepNumber />
          <StepDescription>Mobile Verification</StepDescription>
        </Step>
        <Step>
          <StepNumber />
          <StepDescription>User Account Creation</StepDescription>
        </Step>
        <Step>
          <StepNumber />
          <StepDescription>KYC Verification</StepDescription>
        </Step>
        <Step>
          <StepNumber />
          <StepDescription>Personal Details Verification</StepDescription>
        </Step>
      </Stepper>
    </Col>
  )
}

export default UserDetails
