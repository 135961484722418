import React from 'react'
import { useSelector } from 'react-redux'
import { selectAccount } from 'features/accountSlice'
import PrivateRoutes from '../../routes/PrivateRoutes'

// Recharge
// import Mobile from "./Mobile"
import MobilePrepaid from './MobilePrepaid'
import MobilePostpaid from './MobilePostpaid'
import Landline from './Landline'
import Dth from './DTH'
// import Ott from './OTT'
import Broadband from './Broadband'
import Gas from './Gas'
import Electricity from './Electricity'
import Water from './Water'
import Insurance from './Insurance'
import FasTag from './FasTag'
// import Loan from './Loan'
import LicService from './LIC'
import CCService from './CC'
import MunicipalityService from './Municipality'
// import Checkout from './OTT/Checkout'
import CheckoutServices from './CheckoutServices'
import EMI from './EMI'
import DataCardPrepaid from './DataCardPrepaid'
import DigitalVoucher from './DigitalVoucher'
import EGift from './EGift'
import LPG from './LPG'
import Cable from './Cable'
import TrafficChallan from './TrafficChallan'

const ServiceNav = ({ match }) => {
  const { virtualAccount } = useSelector(selectAccount)
  return (
    <>
      <PrivateRoutes
        path={`${match.url}/checkout`}
        component={CheckoutServices}
      />
      <PrivateRoutes path={`${match.url}/cc`} component={CCService} />
      <PrivateRoutes
        path={`${match.url}/traffic-challan`}
        component={TrafficChallan}
      />
      <PrivateRoutes path={`${match.url}/cable`} component={Cable} />
      <PrivateRoutes path={`${match.url}/lpg`} component={LPG} />
      <PrivateRoutes
        path={`${match.url}/digital-voucher`}
        component={DigitalVoucher}
      />
      <PrivateRoutes path={`${match.url}/e-gift`} component={EGift} />
      <PrivateRoutes
        path={`${match.url}/datacard-prepaid`}
        component={DataCardPrepaid}
      />
      <PrivateRoutes path={`${match.url}/emi`} component={EMI} />
      {/* <PrivateRoutes path={`${match.url}/mobile`} component={Mobile} /> */}
      <PrivateRoutes path={`${match.url}/prepaid`} component={MobilePrepaid} />
      <PrivateRoutes
        path={`${match.url}/postpaid`}
        component={MobilePostpaid}
      />
      <PrivateRoutes path={`${match.url}/landline`} component={Landline} />
      <PrivateRoutes path={`${match.url}/dth`} component={Dth} />
      {/* <PrivateRoutes path={`${match.url}/ott`} component={Ott} /> */}
      {/* <PrivateRoutes
        path={`${match.url}/ottproduct/checkout`}
        component={Checkout}
      /> */}
      <PrivateRoutes path={`${match.url}/broadband`} component={Broadband} />
      <PrivateRoutes path={`${match.url}/gas`} component={Gas} />
      <PrivateRoutes path={`${match.url}/fastag`} component={FasTag} />
      <PrivateRoutes
        path={`${match.url}/electricity`}
        component={Electricity}
      />
      <PrivateRoutes path={`${match.url}/water`} component={Water} />
      {/* <PrivateRoutes path={`${match.url}/loan`} component={Loan} /> */}
      <PrivateRoutes path={`${match.url}/insurance`} component={Insurance} />
      <PrivateRoutes path={`${match.url}/lic`} component={LicService} />
      <PrivateRoutes
        path={`${match.url}/municipality`}
        component={MunicipalityService}
      />
      {!virtualAccount?.id && (
        <div className="disabled-overlay d-flex justify-content-center align-items-center">
          <h2>Please Create Account First</h2>
          {/* <h2>Please Sign E Mandate First</h2> */}
        </div>
      )}
    </>
  )
}

export default ServiceNav
