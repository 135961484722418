import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import PinInput from 'react-pin-input'
import { useAuth } from 'backend/useAuth'
import { pinLength } from '.'
import { useDispatch, useSelector } from 'react-redux'
import { pinActions, selectPin } from 'features/auth/pinSlice'

const UpdatePinModal = () => {
  const { openUpdateModel } = useSelector(selectPin)
  const dispatch = useDispatch()
  const { updatePin } = useAuth()
  const [pin, setPin] = useState('')
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (openUpdateModel === true) {
      setTimeout(() => {
        const el = document.querySelector(
          '.update > .pincode-input-container > input'
        )
        if (el) {
          el.focus()
        }
      }, 800)
    }
  }, [openUpdateModel])

  const toggle = () => {
    dispatch(pinActions.toggleValidateModal())
    dispatch(pinActions.toggleUpdateModal())
  }

  const onSubmit = (e) => {
    e.preventDefault()
    // const encryptedPin = encryptPin(pin)
    updatePin({ pin }).then(() => {
      toggle()
    })
  }

  const onPinChange = (value) => {
    if (value.length === pinLength) {
      setCompleted(true)
    } else {
      setCompleted(false)
    }
    setPin(value)
  }

  // const onComplete = (value, index) => {
  //   console.log(value, value.length)
  //   if (value.length === 4) {
  //     setCompleted(true)
  //   } else {
  //     setCompleted(false)
  //   }
  // };

  return (
    <Modal
      isOpen={openUpdateModel}
      toggle={toggle}
      centered
      size="sm"
      className="shadow-none"
    >
      <ModalHeader toggle={toggle}>
        <i className="pe-7s-key" />
      </ModalHeader>
      {true && (
        <ModalBody className="text-center">
          <form onSubmit={onSubmit}>
            <h4 className="m-3">Create Pin</h4>
            <p>{`Create ${pinLength} digit PIN to secure your Account`}</p>
            <div className="pt-3 pb-4 update">
              <PinInput
                length={pinLength}
                initialValue=""
                secret
                onChange={onPinChange}
                // onChange={(value, index) => { }}
                type="numeric"
                inputMode="number"
                // style={{padding: '10px'}}
                // inputStyle={{ borderColor: '#e1e7ea' }}
                // inputFocusStyle={{ borderColor: '#FFAB2B' }}
                // onComplete={(value, index) => { }}
                // onComplete={onComplete}
                // autoSelect={true}
                // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </div>
            <button className="btn btn-block btn-info" disabled={!completed}>
              Create
            </button>
          </form>
        </ModalBody>
      )}
    </Modal>
  )
}

export default UpdatePinModal
