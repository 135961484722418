import { useAugmont } from 'backend/useAugmont'
import { usePin } from 'context/PinContext'
import { getAugmontFds } from 'features/augmont/augmontSlice'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap'

const FDTable = ({ data, transactionStatus, limit }) => {
  const dispatch = useDispatch()
  const {
    preCloseOrderFd,
    preCloseCalculateFd,
    // getFdDetailsAugmont
  } = useAugmont()
  const [loading, setLoading] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const [preCalculationResult, setPreCalculationResult] = useState({
    scheme: null,
    calculation: null,
  })

  const history = useHistory()

  const { checkPin } = usePin()

  const onClickPreCalculation = (fd) => {
    setLoading(true)
    preCloseCalculateFd(fd.transactionId)
      .then((response) => {
        if (response?.result?.data)
          setPreCalculationResult({
            scheme: fd,
            calculation: response.result.data,
          })
      })
      .finally(() => {
        dispatch(getAugmontFds())
        setLoading(false)
      })
  }

  const closeOrderFd = () => {
    setLoading(true)
    preCloseOrderFd(preCalculationResult.scheme.transactionId, {})
      .then((response) => {
        setConfirmClose(false)
        setPreCalculationResult({ calculation: null, scheme: null })
      })
      .finally(() => {
        dispatch(getAugmontFds())
        setLoading(false)
      })
  }

  const validatePinBeforeClose = async () => {
    checkPin(null, closeOrderFd, 'To close gold order')
  }

  if (!data) return null

  let FDs
  if (limit > 0) {
    FDs = data.filter((d, i) => i < limit)
  } else {
    FDs = data
  }

  return (
    <>
      {transactionStatus && (
        <div className="text-center text-muted py-5">
          Your FD transactions will appear here
        </div>
      )}

      {!transactionStatus && FDs.length === 0 && (
        <div className="text-center text-muted py-5">
          No FD investment found
        </div>
      )}
      {/* <pre>{JSON.stringify(preCalculationResult.calculation, null, 2)}</pre> */}
      {FDs.length > 0 && (
        <Table responsive hover className="align-middle text-truncate">
          <thead>
            <tr>
              {/* <th>*</th> */}
              <th className="text-center">Scheme</th>
              <th className="text-center">Interest Rate</th>
              <th className="text-center">Jeweller Name</th>
              <th className="text-center">No Of Days</th>
              <th className="text-center">Goal Amount</th>
              <th className="text-center">Start Date</th>
              <th className="text-center">End Date</th>
              {/* <th className="text-center">Closed Date</th> */}
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {FDs.map((FD) => (
              <tr key={FD.createdAt}>
                {/*<td>
                  <Button
                    outline
                    className="custom-table-action-btn_danger text-danger"
                    color="danger"
                    onClick={() => getFdDetailsAugmont(FD.transactionId)}
                  >
                    {FD.transactionId}
                  </Button>{' '}
                  ({FD?._count?.goldFDTransactions})
                </td>
                */}
                <td
                  className="text-center text-primary cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: `/investment/fd-details/${FD.transactionId}`,
                      state: FD,
                    })
                  }
                >
                  {FD.schemaName || '-'}
                </td>
                <td className="text-center"> {FD.interestRate}</td>
                <td className="text-center"> {FD.jewellerUserAccountName}</td>
                <td className="text-center"> {FD.noOfDays}</td>
                <td className="text-center"> {FD.goldLease}</td>
                <td className="text-center">
                  {dayjs(FD.startDate).format('DD-MM-YYYY')}
                </td>
                <td className="text-center">
                  {dayjs(FD.endDate).format('DD-MM-YYYY')}
                </td>
                {/* <td className="text-center">
                  {FD.closeDate
                    ? dayjs(FD.closeDate).format('DD-MM-YYYY')
                    : '-'}
                </td> */}
                <td className="text-center">
                  <span
                    className={`badge ${
                      FD.status === 'active'
                        ? 'bg-soft-success text-success'
                        : FD.status === 'pending'
                        ? 'bg-soft-warning text-warning'
                        : FD.status === 'close'
                        ? 'bg-soft-danger text-danger'
                        : ''
                    }`}
                  >
                    {FD.status === 'close' ? 'closed' : FD.status}
                  </span>
                </td>
                <td className="text-center">
                  {FD.status === 'active' && (
                    <Button
                      outline
                      disabled={loading || FD.status === 'Close'}
                      className="custom-table-action-btn_danger text-danger"
                      color="danger"
                      onClick={() => !loading && onClickPreCalculation(FD)}
                    >
                      {FD.status === 'close' ? 'Closed' : 'Close'}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {limit && (
        <Link
          to="/investment/goals?tab=gold-fd"
          className="mx-auto mb-3 text-center bg-soft-primary badge"
        >
          Load more...
        </Link>
      )}

      <Modal
        isOpen={
          !!(preCalculationResult.scheme && preCalculationResult.calculation)
        }
      >
        <ModalHeader>Close FD Scheme</ModalHeader>
        <ModalBody>
          <div>
            <strong>Scheme Name: </strong>
            {preCalculationResult.scheme?.schemaName}
          </div>
          <div>
            <strong>Days: </strong>
            {preCalculationResult.calculation?.noOfDays}
          </div>
          <div>
            <strong>End Of Lease: </strong>
            {preCalculationResult.calculation?.endOfLease}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setConfirmClose(true)}>
            Close Order
          </Button>
          {/* <Button color="success" onClick={onClickPreCalculation}>
            Calculate
          </Button> */}
          <Button
            color="secondary"
            onClick={() => {
              setPreCalculationResult({ calculation: null, scheme: null })
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={confirmClose}>
        <ModalBody>
          <div>Are you sure you want to close the order?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={validatePinBeforeClose}>
            Yes, close order
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setConfirmClose(false)
              setPreCalculationResult({ calculation: null, scheme: null })
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FDTable
