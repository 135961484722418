import { goalsActions } from 'features/giftCardSlice'
import { errMsg, useNest } from './useNest'
import { useDispatch } from 'react-redux'

export function useGiftCard() {
  const { backend, getList, getItem } = useNest()
  const dispatch = useDispatch()

  const getQwikCilverProductBySku = (sku) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
      }
      getItem('qwikcilver/product', sku, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getQwikCilverActivatedCardByOrderId = (orderId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
      }
      getItem('qwikcilver/order', `${orderId}/cards`, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getQwikCilverActivatedCardPinByOrderId = (orderId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
      }
      getItem('qwikcilver/order', `${orderId}/cards/pin`, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getCardTransactionsByOrderId = (orderId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
      }
      getItem('qwikcilver/order', `${orderId}/cards/transactions`, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  // const getCardTransactionsByCardDetails = (payload = {}) =>
  //   new Promise((resolve, reject) => {
  //     backend()
  //       .post('qwikcilver/transactions', payload)
  //       .then((res) => {
  //         return resolve(res)
  //       })
  //       .catch((error) => {
  //         reject(error.response ? error.response.data.message : errMsg)
  //       })
  //   })

  const makeQwikCilverOrder = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...' },
      }
      backend()
        .post('qwikcilver/orders-checkout', payload, config)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getGoalsWithGiftCards = (payload = { status: 'Publish' }) =>
    new Promise((resolve, reject) => {
      getList('goal/goal-giftcards', payload)
        .then((res) => {
          dispatch(goalsActions.loadGoalsWithGiftCards(res?.items))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const getCardImageBySku = (sku) =>
    new Promise((resolve, reject) => {
      getItem(`qwikcilver/image/${sku}`)
        .then((res) => {
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    // getCardTransactionsByCardDetails,
    getQwikCilverProductBySku,
    getCardImageBySku,
    getQwikCilverActivatedCardByOrderId,
    getQwikCilverActivatedCardPinByOrderId,
    getCardTransactionsByOrderId,
    makeQwikCilverOrder,
    getGoalsWithGiftCards,
  }
}
