// /* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-cycle
// import React from 'react'
import { s3GetUrl } from 'config'

export const validMobileRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validPANNumberRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
export const validAadhaarNumberRegex = /d{12}/

export const setTitle = (title) => {
  document.title = `Kashwenut - ${title}`
}

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false
  if (obj.length === 0) return true

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false
  }
  return true
}

// Separate function for email validation
export function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

// Separate function for password strength validation
export function verifyPassword(value) {
  const passwordRex = /^[\S]{6,15}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

// Separate function for field length validation
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true
  }
  return false
}

export function verifyLink(value) {
  // eslint-disable-next-line no-useless-escape
  const linkRex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  if (linkRex.test(value)) {
    return true
  }
  return false
}

export function verifyMobileNumber(value) {
  const mobRex = /^[0-9]{10,14}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

export function verifyUsername(value) {
  const usernameRex = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/
  if (usernameRex.test(value)) {
    return true
  }
  return false
}

export function verifyFullName(value) {
  const fullNameRegex = /^[a-zA-Z ]+$/
  if (fullNameRegex.test(value)) {
    return true
  }
  return false
}

export async function unAuthorized() {
  // const history = useHistory()
  localStorage.removeItem('kashewnutToken')
  // store.dispatch({ type: 'LOGOUT' })
  // history.push('/')
}

export const getCurrency = (accounts, selectedAccount = 0) => {
  if (accounts[selectedAccount] && accounts[selectedAccount].currency) {
    return accounts[selectedAccount].currency
  }
  return 'INR'
}

export const formattedPrice = (
  value,
  currencyISO = 'INR',
  language = 'en-Us'
) => {
  if (isNaN(value)) {
    return value
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyISO.toUpperCase(), // 'INR'
  }).format(value)
}

export const formattedCurrency = (value, options, language = 'en-Us') => {
  if (isNaN(value)) {
    return value
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: options.currencyISO.toUpperCase(), // 'INR'
    ...options,
  }).format(value)
}

export function statusClass(prefix = '', status) {
  let tmp = ''
  if (status) {
    switch (status.toUpperCase()) {
      case 'SUCCESS':
      case 'COMPLETE':
        tmp = `${prefix}success text-success`
        break
      case 'SCHEDULED':
      case 'PROCESSING':
      case 'INITIATED':
      case 'INPROGRESS':
        tmp = `${prefix}info text-info`
        break
      case 'PENDING':
        tmp = `${prefix}warning text-warning`
        break
      case 'ERROR':
      case 'FAILED':
      case 'FAILURE':
      case 'CANCELED':
        tmp = `${prefix}danger text-danger`
        break
      default:
        tmp = `${prefix}info text-info`
    }
  }
  return tmp
}

export function statusLabel(status, deposit = true) {
  let label = ''
  if (status) {
    switch (status.toUpperCase()) {
      case 'SUCCESS':
        label = 'Success'
        break
      case 'COMPLETE':
        // if( deposit )
        //   label = 'Added'
        // else
        //   label = 'Paid'

        label = 'Completed'
        break
      case 'SCHEDULED':
        label = 'Scheduled'
        break
      case 'PENDING':
        label = 'Pending'
        break
      case 'PROCESSING':
        label = 'Processing'
        break
      case 'ERROR':
      case 'CANCELED':
        label = 'Canceled'
        break
      case 'FAILURE':
      case 'FAILED':
        label = 'Failed'
        break
      case 'INITIATED':
        label = 'Initiated'
        break
      default:
        label = 'Pending'
    }
  }
  return label
}

export function statusIcon(status) {
  let icon = 'la-times'
  switch (status.toUpperCase()) {
    case 'SUCCESS':
      icon = 'la-check'
      break
    case 'PENDING':
      icon = 'la-hourglass-half'
      break
    case 'ERROR':
    case 'FAILED':
    case 'FAILURE':
      icon = 'la-times'
      break
    default:
      // icon = 'la-question'
      icon = 'la-hourglass-half'
  }
  return icon
}

export const prettyDate = (time) => {
  let timeStamp = ''
  if (typeof time === 'object') {
    timeStamp = time
  } else {
    timeStamp = new Date(time)
  }
  const diff = (new Date().getTime() - timeStamp.getTime()) / 1000
  const dayDiff = Math.floor(diff / 86400)
  const year = timeStamp.getFullYear()
  const month = timeStamp.getMonth() + 1
  const day = timeStamp.getDate()

  if (isNaN(dayDiff) || dayDiff < 0) {
    return `On ${year.toString()} - ${
      month < 10 ? 0 + month.toString() : month.toString()
    } - ${day < 10 ? 0 + day.toString() : day.toString()}`
  }

  // console.log(dayDiff)
  return (
    (dayDiff === 0 &&
      ((diff < 60 && 'just now') ||
        (diff < 120 && 'a minute ago') ||
        (diff < 3600 && `${Math.floor(diff / 60)} minutes ago`) ||
        (diff < 7200 && 'an hour ago') ||
        (diff < 86400 && `${Math.floor(diff / 3600)} hours ago`))) ||
    (dayDiff === 1 && 'Yesterday') ||
    (dayDiff < 7 && `${dayDiff} days ago`) ||
    (dayDiff < 28 && `${Math.ceil(dayDiff / 7)} weeks ago`) ||
    (dayDiff < 58 && 'last month') ||
    (dayDiff < 365 && `${Math.ceil(dayDiff / 30)} months ago`) ||
    (dayDiff > 365 && 'last year') ||
    (dayDiff > 630 && `${Math.ceil(dayDiff / 365)} years ago`)
  )
}

export const generateInitialImage = (
  firstName = '',
  lastName = '',
  width = 67,
  height = 67
) => {
  let text = ''
  if (firstName) {
    text = firstName.charAt(0)
  }
  // if (lastName) {
  //   text += lastName.charAt(0)
  // }
  const canvas = document.createElement(`canvas`)
  const context = canvas.getContext('2d')
  context.canvas.width = width
  context.canvas.height = height

  // context.fillStyle = '#fff'
  context.fillStyle = '#333'
  context.fillRect(0, 0, width, height)
  // context.fillRect(50, 50, width, height)
  // context.beginPath();
  // context.arc(width, height, 67, 0, 2 * Math.PI);
  // context.fill();
  // context.stroke();

  const fontSize = 30
  context.font = `${fontSize}pt Arial`
  context.textAlign = 'center'
  context.fillStyle = '#fff'
  context.fillText(text, width / 2, height / 2 + fontSize / 2)
  // context.fillText(text, width / 2, (height / 2) + (26 / 2))
  return canvas.toDataURL('image/png')
}

export const getProfilePicture = (user) => {
  if (user.avatar) {
    return s3GetUrl(user.avatar)
  }
  return generateInitialImage(user.firstName, user.lastName)
}

export const generateDescription = (string, operatorCode, operators) => {
  const operatorName = operators.find(
    (operator) => operator.code === operatorCode
  )
  return (string = String(string).replace('{operatorName}', operatorName.name))
  // string = string.toLowerCase()
  // return string.replace(/\w\S*/g, (txt) => {
  //   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  // })
}

export const capitalizedFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function useQueryParams() {
  const params = new URLSearchParams(window ? window.location.search : {})

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop)
    },
  })
}

export function numberWithCommas(x) {
  x = x.toString()
  let afterPoint = ''
  if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length)
  x = Math.floor(x)
  x = x.toString()
  let lastThree = x.substring(x.length - 3)
  let otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers !== '') lastThree = ',' + lastThree
  let res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint
  return res
}

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
