import React from 'react'
import {
  CardHeader,
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
} from 'reactstrap'
import { FaFileInvoiceDollar, FaRupeeSign } from 'react-icons/fa'

const BillDetailsBBPS = ({ data, selectAmount, visible }) => {
  return (
    <>
      {visible && data?.amount && (
        <Card className="mb-3">
          <CardHeader className="card-header-tab">
            <div className="card-header-title">
              <i className="header-icon lnr-license icon-gradient bg-plum-plate" />
              Bill Details
            </div>
          </CardHeader>
          <CardBody>
            <ListGroup className="rm-list-borders" flush>
              <ListGroupItem
                onClick={() => {
                  selectAmount(data?.amount)
                }}
                className="cursor-pointer"
              >
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper align-items-start d-flex">
                    <div className="widget-content-left mr-3">
                      <FaFileInvoiceDollar size="42" />
                    </div>
                    <div className="widget-content-left">
                      <div className="widget-heading">{data?.name}</div>
                      <small className="d-block">
                        <b>Due Date: {data?.duedate || 'NA'}</b>
                      </small>
                      {/* <small className="d-block">
                        Bill Number: {data?.ad2 || 'NA'}
                      </small>
                      <small className="d-block">
                        Bill Number: {data?.ad3 || 'NA'}
                      </small> */}
                      {/* <small className="d-block text-muted">
                        Bill Date: {data?.bill_date || 'NA'}
                      </small>
                      <small className="d-block text-muted">
                        Bill Period: {data?.bill_period || 'NA'}
                      </small> */}
                    </div>
                    <div className="widget-content-right">
                      <div className="font-size-xlg text-muted">
                        {/* <CountUp start={0} end={129} separator="" decimals={0} decimal="." prefix="" duration="10" /> */}
                        <small className="text-success pl-2 d-flex align-items-center">
                          {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                          <FaRupeeSign size="14" />
                          <span>{data?.amount}</span>
                          {/* <FontAwesomeIcon icon={faAngleDown} /> */}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      )}
      {/* <pre style={{ width: '100%' }}>{JSON.stringify(data, null, ' ')}</pre> */}
    </>
  )
}

export default BillDetailsBBPS
