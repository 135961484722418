import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

// import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { validPANNumberRegex } from 'utils/validation'
import showLoader from 'Components/Loader'

// import bg3 from 'assets/utils/images/originals/pan-aadhaar-2.jpg'

import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'
import { authActions } from 'features/auth/authSlice'
import UserDetails from './UserDetails'

// let settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   arrows: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   initialSlide: 0,
//   autoplay: true,
//   adaptiveHeight: true,
// }

const PanKYC = () => {
  const history = useHistory()
  const registerStep = useSelector((state) => state.auth.registerStep)
  const dispatch = useDispatch()
  const { kycPanValidate } = useAuth()

  if (!registerStep) {
    history.push(`/auth/signup`)
  }

  const schema = yup.object().shape({
    panNumber: yup
      .string()
      .required('Pan Number is required')
      .matches(validPANNumberRegex, 'Invalid Pan Number!'),
  })

  const onSubmit = (data) => {
    showLoader(true)
    kycPanValidate({
      panNumber: String(data.panNumber).toUpperCase().trim(),
    }).then((res) => {
      dispatch(authActions.signupStepPanProfile(res.data))
      showLoader(false)
      history.push('/auth/aadhaar')
      // history.push('/auth/pan/profile')
      // setTimeout(() => {
      // }, 300)
      return
    })
  }

  return (
    <div className="h-100">
      <Row className="h-100 no-gutters">
        <Col
          lg="7"
          md="12"
          className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
        >
          <Col lg="6" md="10" sm="12" className="mx-auto app-login-box">
            {/* <pre>{JSON.stringify(registerStep, null, 2)}</pre> */}
            {/* <div className="app-logo" /> */}
            <h4>
              {/* <div>Welcome,</div> */}
              <span>
                Complete Your Pan <span className="text-success">KYC</span>
              </span>
            </h4>
            <div>
              <Form
                onFinish={onSubmit}
                className="login100-form validate-form"
                schemaResolver={yupResolver(schema)}
                mode="onTouched" // onBlur
                initialValues={{ panNumber: '' }}
              >
                <Row form>
                  <Col md="12">
                    <Input.Text
                      label="Pan number"
                      name="panNumber"
                      required
                      // rules={{
                      //   required: 'This is required',
                      // }}
                    />
                  </Col>
                  <Col md="12" className="d-flex justify-content-end">
                    <Submit className="btn custom-btn-animation h-37">
                      Verify Pan Number
                    </Submit>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Col>
        <UserDetails />
      </Row>
    </div>
  )
}

export default PanKYC
