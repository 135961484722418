import React from 'react'
import {
  CardHeader,
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
} from 'reactstrap'
// import PerfectScrollbar from "react-perfect-scrollbar"
import { FaRupeeSign, FaMobileAlt, FaFileInvoice } from 'react-icons/fa'

const DTHPlanDetails = ({ data, selectAmount, service }) => {
  let path = `https://cdn.kashewnut.com/operators/${service?.toLowerCase()}`

  return (
    <Card className="mb-3">
      <CardHeader className="card-header-tab">
        <div className="card-header-title">
          <FaMobileAlt size="24" className="mr-2" />
          {/* <i className="header-icon lnr-license icon-gradient bg-plum-plate"> {" "} </i> */}
          {data?.title || 'DTH Plan'}
        </div>
      </CardHeader>
      <CardBody>
        {data && data?.info && (
          <ListGroup className="rm-list-borders" flush>
            {data.info.map((item, i) => (
              <ListGroupItem
                onClick={() => {
                  selectAmount && selectAmount(item.rs)
                }}
                className="cursor-pointer"
                key={i}
              >
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper align-items-start d-flex">
                    <div className="widget-content-left mr-3">
                      {data.logo ? (
                        <img
                          width={42}
                          className="rounded-circle"
                          src={`${path}/${data.logo}`}
                          alt=""
                        />
                      ) : (
                        <FaFileInvoice size="42" />
                      )}
                    </div>
                    <div className="widget-content-left">
                      <div className="widget-heading">{data.operator}</div>
                      <div className="widget-subheading font-weight-bold">
                        Customer Name: {item.customerName || 'N/A'}
                      </div>
                      <strong className="d-block">
                        Monthly Recharge: {item?.MonthlyRecharge}
                      </strong>
                      <small className="d-block">
                        Balance: {item?.Balance}
                      </small>
                      <small className="d-block">
                        Status: {item?.status || 'NA'}
                      </small>
                      <small className="d-block text-muted">
                        Next Recharge Date: {item?.NextRechargeDate || 'NA'}
                      </small>
                      <small className="text-muted">{item?.planname}</small>
                    </div>
                    <div className="widget-content-right">
                      <div className="font-size-xlg text-muted">
                        {/* <CountUp start={0} end={129} separator="" decimals={0} decimal="." prefix="" duration="10" /> */}
                        <small className="text-success pl-2 d-flex align-items-center">
                          <FaRupeeSign size="14" />
                          <span>{item?.rs}</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {/* {
          <pre style={{ width: '100%' }}>
            {JSON.stringify(data, null, 2)}
          </pre>
        } */}
      </CardBody>
      {/* <CardFooter className="d-block text-right">
        <Button className="btn-wide btn-shadow" color="danger">
          Delete
        </Button>
      </CardFooter> */}
    </Card>
  )
}

export default DTHPlanDetails
