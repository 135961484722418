import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap'

import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  validPANNumberRegex,
  InvalidFeedbackV7,
  preventSubmit,
} from 'utils/validation'

import showLoader from 'Components/Loader'

import { useNest } from 'backend/useNest'
import { authActions, selectAuth } from 'features/auth/authSlice'

const fileInitialStructure = {
  base64: '',
  file: '',
}

const PanVerification = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(selectAuth)
  const [panFile, setPanFile] = useState(fileInitialStructure)
  const { backend } = useNest()

  const schema = yup.object().shape({
    nameAsPerPan: yup.string().required('Name as per PAN number is required'),
    dateOfBirth: yup.string().required('Dob as per PAN number is required'),
    pan: yup
      .string()
      .required('PAN number is required')
      .matches(validPANNumberRegex, 'Please enter valid PAN Number')
      .min(10, 'PAN Number should 10 digits'),
  })

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    // mode: "onTouched|onChanged",
    defaultValues: {
      pan: user.pan,
      nameAsPerPan: user.aadhaarName,
      dateOfBirth: user.dob,
    },
  })

  const onSubmit = (data) => {
    showLoader(true)
    console.log('data', data)
    // const payload = {
    //   ...data,
    //   file: panFile?.base64,
    // }
    // console.log('payload', payload)

    // userPanVerification(formData)
    //   .then((data) => {
    //     // showLoader(false)
    //     // // console.log(data)
    //     // toast.success(data.message)
    //   })

    const formData = new FormData()
    formData.append('pan', data.pan)
    formData.append('nameAsPerPan', data.nameAsPerPan)
    formData.append('dateOfBirth', data.dateOfBirth)
    const file = panFile?.file
    formData.append('file', file)
    // formData.append('file', new Blob([buffer], { type: file.type }), file.name)

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    backend()
      .post('kyc/pan/upload', formData, config)
      .then((data) => {
        if (data.panVerified) {
          dispatch(authActions.panVerified())
        }
        toast.success(data.message)
        console.log('data', data)
        showLoader(false)
      })
      .catch((error) => {
        console.log(error)
        showLoader(false)
      })
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
    // reader.readAsArrayBuffer(img)
  }

  const handleUpload = (event) => {
    if (
      event.target &&
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].name
    ) {
      const uploadedFile = event.target.files[0]
      getBase64(uploadedFile, (imageUrl) => {
        setPanFile({
          ...fileInitialStructure,
          base64: imageUrl,
          file: uploadedFile,
        })
      })
    }
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-config icon-gradient bg-ripe-malin" />
              </div>
              <div>
                Pan Verification
                <div className="page-title-subheading">
                  {user?.panVerified ? (
                    <>
                      <i className="pe-7s-check font-weight-bold text-success" />
                      {' Verified'}
                    </>
                  ) : (
                    <>
                      <i className="pe-7s-close font-weight-bold text-danger" />
                      {' Not Verified'}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col lg={9}>
            <Card className="main-card">
              <CardBody>
                <CardTitle>Pan Verification</CardTitle>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="validate-form was-validate"
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="nameAsPerPan" className="required">
                          Name as per PAN number
                        </Label>
                        <input
                          type="text"
                          id="nameAsPerPan"
                          name="nameAsPerPan"
                          className="form-control"
                          {...register('nameAsPerPan')}
                          // defaultValue={user.nameAsPerPan}
                        />
                        <InvalidFeedbackV7
                          name="nameAsPerPan"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="dateOfBirth" className="required">
                          Dob as per PAN number
                        </Label>
                        <input
                          type="date"
                          id="dateOfBirth"
                          name="dateOfBirth"
                          className="form-control"
                          {...register('dateOfBirth')}
                          // defaultValue={user.dateOfBirth}
                        />
                        <InvalidFeedbackV7
                          name="dateOfBirth"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="pan" className="required">
                          PAN number
                        </Label>
                        <input
                          type="text"
                          id="pan"
                          name="pan"
                          className="form-control"
                          {...register('pan')}
                          // defaultValue={user.pan}
                        />
                        <InvalidFeedbackV7 name="pan" formState={formState} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pb-4">
                      <FormGroup>
                        <Label for="panFile" className="required">
                          Upload Document
                        </Label>
                        <div className="custom-file">
                          <input
                            type="file"
                            id="panFile"
                            name="panFile"
                            className="custom-image-input"
                            onChange={(event) => handleUpload(event)}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="pb-4">
                      <FormGroup>
                        {console.log('panFile', panFile)}
                        {panFile && panFile?.base64 && panFile?.file?.name && (
                          <img
                            src={panFile.base64}
                            alt={panFile?.file?.name}
                            style={{ width: '70%' }}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="text-right">
                      <small className="text-danger">
                        Fields marked with * are required
                      </small>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Verify
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}></Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default PanVerification
