import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Row, Table } from 'reactstrap'

import ReactSlider from 'react-slider'
// import ReactSelect from 'react-select'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { selectGoals } from 'features/giftCardSlice'
import {
  cdnImg,
  investmentType,
  providers,
  shortTermConfig,
} from 'utils/constants'
import CountUp from 'react-countup'
import { s3GetUrl } from 'config'
import { FaRupeeSign } from 'react-icons/fa'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { percentage } from 'utils/finCalculators'

ChartJS.register(ArcElement, Tooltip)

const SortTermPlannerForm = ({ data, url }) => {
  const amountRef = useRef(null)
  const durationRef = useRef(null)
  const { goalId, sku } = useParams()
  const history = useHistory()
  const { goalsWithGiftCards } = useSelector(selectGoals)
  const [amount, setAmount] = useState(shortTermConfig.amount.min)
  const [goalName, setGoalName] = useState('')
  const [goalNameError, setGoalNameError] = useState(null)
  const [investedAmount, setInvestedAmount] = useState(
    shortTermConfig.amount.min
  )
  // const [selectedSku, setSelectedSku] = useState(sku)
  const [selectedGoal, setSelectedGoal] = useState({ name: 'Loading' })
  const [selectedCard, setSelectedCard] = useState({ name: 'Loading' })
  const [timeDurations, setTimeDurations] = useState(
    shortTermConfig.duration.min
  )
  const [selectedInvestmentType, setSelectedInvestmentType] = useState(
    investmentType.SIP
  )
  const [discountRates, setDiscountRates] = useState()

  const handleInvestmentTypeClick = (value) => {
    setSelectedInvestmentType(value)
    setTimeDurations(shortTermConfig.duration.min)
    setDiscountRates({ interest: 0, brandDiscount: 0 })
    setAmount(shortTermConfig.amount.min)
    setInvestedAmount(shortTermConfig.amount.min)
  }

  useEffect(() => {
    setTimeDurations(shortTermConfig.duration.min)
    const tmpSelectedGoal = goalsWithGiftCards.find((g) => g.id === goalId)
    if (tmpSelectedGoal) {
      setSelectedGoal(tmpSelectedGoal)
      const tmpSelectedCard = tmpSelectedGoal.giftCards.find(
        (card) => card.sku === sku
      )
      setAmount(shortTermConfig.amount.min)
      setSelectedCard(tmpSelectedCard)
      calculateDiscount(
        shortTermConfig.amount.min,
        shortTermConfig.duration.min,
        selectedInvestmentType,
        tmpSelectedCard
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId, sku])

  // const handleSelectCard = (sku) => {
  //   if (selectedGoal) {
  //     setSelectedCard(selectedGoal.giftCards.find((card) => card.sku === sku))
  //   }
  // }

  useEffect(() => {
    if (
      amount &&
      timeDurations &&
      selectedInvestmentType &&
      selectedCard?.discount
    ) {
      calculateDiscount(
        amount,
        timeDurations,
        selectedInvestmentType,
        selectedCard
      )
    }
    if (amount === 0) {
      setDiscountRates({ interest: 0, brandDiscount: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, timeDurations, selectedInvestmentType, selectedCard])

  const calculateDiscount = (
    amount,
    timeDurations,
    selectedInvestmentType,
    selectedCard
  ) => {
    if (selectedInvestmentType === investmentType.Lumpsum) {
      const startDate = dayjs()
      const endDate = dayjs().add(timeDurations, 'months')
      const diffInDays = endDate.diff(startDate, 'days') - 1
      const interest = ((amount * shortTermConfig.interestRate) / 100 / 365) * diffInDays
      // const interest = percentage(amount, selectedCard.interestReturn)
      const brandDiscount = percentage(amount, selectedCard.discount)
      setInvestedAmount(amount)
      setDiscountRates({ interest, brandDiscount })
    } else if (selectedInvestmentType === investmentType.SIP) {
      const monthlyAmount = amount
      const brandDiscount =
        (amount * timeDurations * selectedCard.discount) / 100
      let compoundInterest = 0
      const interestPerDay =
        (monthlyAmount * selectedCard.interestReturn) / 100 / 365
      const endDate = dayjs().add(timeDurations, 'months')
      for (let i = 1; i <= timeDurations; i++) {
        const noOfMonth = i - 1
        const startDate = dayjs().add(noOfMonth, 'months')
        const noOfDays = endDate.diff(startDate, 'days') - 1
        compoundInterest += interestPerDay * noOfDays
      }
      setInvestedAmount(amount * timeDurations)
      setDiscountRates({ interest: compoundInterest, brandDiscount })
    }
  }

  const handleChangeAmountInput = (e) => {
    setAmount(e.target.value * 1)
  }

  const handleChangeDurationInput = (e) => {
    setTimeDurations(e.target.value * 1)
  }

  const handleSubmit = () => {
    if (!goalName) {
      setGoalNameError('Please enter goal name')
      return
    }

    if (timeDurations < shortTermConfig.duration.min) {
      alert(`Minimum duration should ${shortTermConfig.duration.min}`)
      setTimeDurations(shortTermConfig.duration.min)
      durationRef.current.focus()
      return
    }
    if (timeDurations > shortTermConfig.duration.max) {
      alert(`Maximum duration should ${shortTermConfig.duration.max}`)
      setTimeDurations(shortTermConfig.duration.max)
      durationRef.current.focus()
      return
    }
    const maxAmount = getMaxAmountByInvestmentType(selectedInvestmentType)
    if (parseFloat(amount) > maxAmount) {
      alert(`Maximum goal amount should ${maxAmount}`)
      setAmount(maxAmount)
      amountRef.current.focus()
      return
    }
    if (parseFloat(amount) < shortTermConfig.amount.min) {
      alert(`Minimum goal amount should ${shortTermConfig.amount.min}`)
      setAmount(shortTermConfig.amount.min)
      amountRef.current.focus()
      return
    }

    let payload = {
      goalId: goalId,
      services: [
        {
          name: selectedGoal.name,
          amount: amount,
          description: `${selectedInvestmentType} for ${selectedCard.name} for ${timeDurations} months`,
          quantity: 1,
          service: providers.qwikCilver,
          duration: timeDurations,
          investmentType: selectedInvestmentType,
        },
      ],
      provider: providers.qwikCilver,
      goal: {
        name: goalName,
        description: `${selectedInvestmentType} for ${selectedCard.name} for ${timeDurations} months`,
        amount: amount,
        gst: 0,
        amountGoal: amount,
        amountReturned:
          discountRates?.interest +
          discountRates?.brandDiscount +
          investedAmount / 100 +
          investedAmount,
        provider: providers.qwikCilver,
        brand: selectedCard.brandName,
        investIn: selectedCard.sku,
        duration: timeDurations, // to convert into Months
        type: selectedInvestmentType,
        term: 'short',
        frequency:
          selectedInvestmentType === investmentType.SIP ? 'Monthly' : 'Once',
      },
      meta: {
        bill: false,
        operator: {
          endpoint: providers.qwikCilver,
        },
        investmentType: selectedInvestmentType,
      },
    }
    history.push({
      pathname: '/investment/short/checkout',
      state: payload,
    })
  }

  const getMaxAmountByInvestmentType = (selectedInvestmentType) => {
    return selectedInvestmentType === investmentType.SIP
      ? shortTermConfig.sip.max
      : shortTermConfig.Lumpsum.max
  }

  const youWillGet =
    discountRates?.interest +
    discountRates?.brandDiscount +
    investedAmount / 100 +
    investedAmount

  const completionBonus =
    selectedInvestmentType === investmentType.Lumpsum
      ? percentage(amount, selectedCard?.completionBonus)
      : percentage(amount * timeDurations, selectedCard?.completionBonus)

  return (
    <Fragment>
      <Row>
        {/* <Col md="12" className="mt-3">
          <pre style={{ width: '100%' }}>
            {JSON.stringify(selectedCard, null, 2)}
          </pre>
        </Col> */}
        <Col md={4} sm={12} className="rounded-card custom-border p-0">
          {/* <img
            src={s3GetUrl(selectedCard?.goalThumb || cdnImg.goals)}
            width="100%"
            style={{ borderRadius: '.25em' }}
            alt=""
          /> */}
          <div className="custom-card">
            <div className="chart-container">
              <Doughnut
                data={{
                  labels: [
                    ' You Invest',
                    ' Return On Investment',
                    ' Co-Brand Return',
                    ' Completion Bonus',
                  ],
                  datasets: [
                    {
                      data: [
                        investedAmount,
                        discountRates?.interest ?? 0,
                        discountRates?.brandDiscount,
                        completionBonus,
                      ],
                      backgroundColor: [
                        'rgba(53, 62, 209, 0.8)',
                        'rgba(12, 191, 188, 0.8)',
                        'rgba(253, 52, 110, 0.8)',
                        'rgba(255, 165, 2, 0.8)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
              />
              <div className="center-text">
                <span>You will get</span>
                <CountUp
                  className="chart-numbers"
                  start={0}
                  end={Math.round(youWillGet) || 0}
                  prefix="₹"
                  useEasing={true}
                  duration="1"
                />
              </div>
            </div>
          </div>
          <div>
            <Table className="plan-details">
              <tbody>
                <tr>
                  <td>Brand Name</td>
                  <td className="font-weight-bolder">
                    {selectedCard?.brandName}
                  </td>
                </tr>
                <tr>
                  <td>Max Card Amount</td>
                  <td className="font-weight-bold">
                    {selectedCard?.maxPrice ? (
                      <CountUp
                        key="brand-discount"
                        start={0}
                        end={selectedCard?.maxPrice ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration="1"
                      />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Brand Discount</td>
                  <td className="font-weight-bold">
                    <CountUp
                      key="brand-discount"
                      start={0}
                      end={selectedCard?.discount || 0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Interest Return</td>
                  <td className="font-weight-bold">
                    <CountUp
                      key={'interest-rate'}
                      start={0}
                      end={selectedCard?.interestReturn || 0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Completion Bonus</td>
                  <td className="font-weight-bold">
                    <CountUp
                      key={'completion-bonus'}
                      start={0}
                      end={selectedCard?.completionBonus || 0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <div className="d-flex align-items-center">
              <div>Brand Name: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  {selectedCard?.brandName}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Brand Discount: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  {selectedCard?.discount && (
                    <CountUp
                      key="brand-discount"
                      start={0}
                      end={selectedCard?.discount || 0}
                      separator=","
                      decimals={2}
                      decimal="."
                      prefix=""
                      useEasing={true}
                      suffix="%"
                      duration="1"
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Interest Return: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  <CountUp
                    key={'interest-rate'}
                    start={0}
                    end={shortTermConfig.interestRate ?? 'NA'}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix=""
                    useEasing={true}
                    suffix="%"
                    duration="1"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Completion Bonus: </div>
              <div className="widget-numbers mx-2">
                <span style={{ fontWeight: '600' }}>
                  <CountUp
                    key={'completion-bonus'}
                    start={0}
                    end={shortTermConfig.completionBonus ?? 'NA'}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix=""
                    useEasing={true}
                    suffix="%"
                    duration="1"
                  />
                </span>
              </div>
            </div> */}
          </div>
        </Col>
        <Col md={8} sm={12}>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex justify-content-between">
              <div>
                <div>Enter Goal Name:</div>
                <div className="my-2">
                  <input
                    className={`goalName ${goalNameError && 'error'}`}
                    type="string"
                    name="goalName"
                    value={goalName}
                    onChange={(e) => {
                      setGoalName(e.target.value)
                      if (e.target.value) setGoalNameError(null)
                      else setGoalNameError('Please enter goal name')
                    }}
                    placeholder="Car Purchase"
                  />
                  {goalNameError && (
                    <div className="error-goal-name">{goalNameError}</div>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-start flex-column">
                <div>Choose your metal:</div>
                <div className="d-flex custom-tab form-item-control metal-radio my-2">
                  <label
                    className={`custom-tab-item metal-radio-item ${
                      selectedInvestmentType === investmentType.Lumpsum
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                  >
                    {investmentType.Lumpsum}
                    <input
                      type="radio"
                      className="d-none"
                      name="investmentType"
                      checked={
                        selectedInvestmentType === investmentType.Lumpsum
                      }
                      value={selectedInvestmentType === investmentType.Lumpsum}
                      onClick={() => {
                        handleInvestmentTypeClick(investmentType.Lumpsum)
                      }}
                    />
                  </label>
                  <label
                    className={`custom-tab-item metal-radio-item ${
                      selectedInvestmentType === investmentType.SIP
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                  >
                    {investmentType.SIP}
                    <input
                      type="radio"
                      className="d-none"
                      name="investmentType"
                      checked={selectedInvestmentType === investmentType.SIP}
                      value={selectedInvestmentType === investmentType.SIP}
                      onClick={() => {
                        handleInvestmentTypeClick(investmentType.SIP)
                      }}
                    />
                  </label>
                </div>
              </div>
              <img
                src={s3GetUrl(selectedCard?.goalThumb || cdnImg.goals)}
                width="10%"
                style={{ borderRadius: '.25em' }}
                alt=""
              />
              {/* {selectedGoal?.giftCards?.length > 0 && (
                  <div style={{ marginRight: '20%' }}>
                    <div>Change Partner:</div>
                    <div
                      className="d-flex align-items-center"
                      style={{ width: '200px' }}
                    >
                      <ReactSelect
                        className="w-100"
                        options={selectedGoal?.giftCards.map((card) => ({
                          value: card.sku,
                          label: card.name,
                        }))}
                        onChange={(value) => {
                          handleSelectCard(value)
                        }}
                      />
                    </div>
                  </div>
                )} */}
            </div>
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                {selectedInvestmentType !== investmentType.Lumpsum
                  ? 'What is your monthly goal?'
                  : 'What is the goal amount you would like to spend?'}
              </p>
              {/* <div className="amount-display p-3 text-center m-1">
                {formattedPrice(amount)}
              </div> */}
              <div className="amount-input-group">
                <FaRupeeSign />
                <input
                  type="number"
                  step={shortTermConfig.amount.step}
                  placeholder="Amount"
                  onChange={handleChangeAmountInput}
                  min={shortTermConfig.amount.min}
                  max={getMaxAmountByInvestmentType(selectedInvestmentType)}
                  value={amount}
                  style={{ width: '60px', minWidth: '2em' }}
                  ref={amountRef}
                />
              </div>
            </div>
            <ReactSlider
              step={shortTermConfig.amount.step}
              // step={selectedCard?.minPrice}
              // step={isLongTerm ? 500 : 1000}
              // min={selectedCard?.minPrice || 10}
              defaultValue={0}
              min={shortTermConfig.amount.min}
              max={getMaxAmountByInvestmentType(selectedInvestmentType)}
              value={amount}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) => setAmount(value)}
            />
          </div>
          <div className="content-cards p-3 mb-2 custom-border">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-1 fw-bold">
                What is the duration for which you would like to invest?
              </p>
              {/* <div className="amount-display p-3 text-center m-1">
                {timeDurations} Month/s
              </div> */}
              <div className="amount-input-group">
                <input
                  type="number"
                  onChange={handleChangeDurationInput}
                  style={{
                    display: 'inline-block',
                    minWidth: '1em',
                    width: '20px',
                    textAlign: 'right',
                    marginRight: '.5em',
                  }}
                  step={1}
                  min={shortTermConfig.duration.min}
                  max={shortTermConfig.duration.max}
                  value={timeDurations}
                  ref={durationRef}
                />
                Month/s
              </div>
            </div>
            <ReactSlider
              step={1}
              min={shortTermConfig.duration.min}
              max={shortTermConfig.duration.max}
              value={timeDurations}
              className="horizontal-slider mt-2"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              onAfterChange={(value) => setTimeDurations(value)}
            />
          </div>
          {discountRates &&
            (discountRates?.interest || discountRates?.brandDiscount) && (
              <div className="content-cards p-3 mb-2 custom-border">
                <Row>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>You Invest</div>
                    <div className="font-weight-bold">
                      <CountUp
                        start={0}
                        end={investedAmount}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration=".5"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>Return On Investment</div>
                    <div className="font-weight-bold">
                      {discountRates?.interest ? (
                        <CountUp
                          start={0}
                          end={discountRates?.interest}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix="₹"
                          useEasing={true}
                          suffix=""
                          duration=".5"
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>Co-Brand Return</div>
                    <div className="font-weight-bold">
                      <CountUp
                        start={0}
                        end={discountRates?.brandDiscount}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration=".5"
                      />
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>Completion Bonus</div>
                    <div className="font-weight-bold">
                      {amount > 0 ? (
                        <CountUp
                          start={0}
                          end={completionBonus}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix="₹"
                          useEasing={true}
                          suffix=""
                          duration=".5"
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <div>You Get</div>
                    <div className="font-weight-bold">
                      {discountRates?.interest ? (
                        <CountUp
                          start={0}
                          end={youWillGet}
                          separator=","
                          decimals={2}
                          decimal="."
                          prefix="₹"
                          useEasing={true}
                          suffix=""
                          duration=".5"
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-between">
                    <Button
                      color="primary"
                      className="p-2 px-3"
                      onClick={handleSubmit}
                    >
                      Plan Now
                    </Button>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <span className="text-danger" style={{ fontSize: '14px' }}>
                      * These are approximate values.
                    </span>
                  </div>
                  <div>
                    Your money will be invested with RBI registered P2P Lendbox
                    at fixed plan.
                  </div>
                </div>
              </div>
            )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default SortTermPlannerForm
