import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  InputGroupText,
  Input,
} from 'reactstrap'
import cx from 'classnames'

import { toast } from 'react-toastify'

import { useForm } from 'react-hook-form'
import { FaRegArrowAltCircleLeft, FaRupeeSign } from 'react-icons/fa'

import {
  validationStatus,
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from 'utils/validation'
import { useServices } from 'backend/useServices'

import { s3GetUrl } from "config"
import { usePin } from "context/PinContext"

const CCBillingForm = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const [otpSent, setOTP] = useState(false)
  const operators = useSelector((state) => state.services.operators)
  const [operator, setOperator] = useState()

  const [amount, setAmount] = useState(100)
  const [billDetails, setBillDetails] = useState()
  const { checkPin } = usePin()
  const { creditCardBillCheck, creditCardBillPay } = useServices()

  useEffect(() => {
    if (operators) {
      setOperator(
        operators.find((op) => op.service === service && op.code === opcode)
      )
    }
  }, [operators, opcode, service])

  useEffect(() => {
    return () => {
      setBillDetails()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState,
  } = useForm()

  // useEffect(() => {
  //   setTimeout(() => {
  //     reset({
  //       name: "DEEPAK SINGHAL N",
  //       card_number: "4281021019786316",
  //       network: "VISA",
  //       mobile: 8470000013,
  //       amount: "100"
  //     })
  //   }, 1500)
  // }, [reset])

  useEffect(() => {
    return () => {
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    // console.log(data)
    if (!otpSent) {
      checkBill(data)
      return
    }

    checkPin(data, handleFormSubmit, `Credit Card Bill`)
  }

  const handleReset = () => {
    setBillDetails()
    reset()
    setOTP(false)
  }

  const checkBill = (data) => {
    // data.remarks = 'Pay {operatorName} Bill'
    // data.type = 'PAY'
    // data.remarks = generateDescription(data.remarks, data.network, operators)
    creditCardBillCheck({
      name: data.name,
      card_number: data.card_number,
      amount: data.amount,
      mobile: data.mobile,
      network: opcode, //data.network,
      // remarks: data.remarks
    })
      .then((res) => {
        console.log('response', res)
        setOTP(true)
        toast.success(res.data.message)
        setBillDetails(res.payload)
      })
      .catch((error) => {
        setOTP(false)
      })
  }

  const handleFormSubmit = (data) => {
    // console.log({
    //   payload: {
    //     name: data.name,
    //     card_number: data.card_number,
    //     amount: data.amount,
    //     mobile: data.mobile,
    //     network: data.network,
    //     remarks: data.remarks,
    //     otp: data.otp,
    //     ...billDetails,
    //   }
    // })
    creditCardBillPay({
      name: data.name,
      card_number: data.card_number,
      amount: data.amount,
      mobile: data.mobile,
      network: opcode, //data.network,
      remarks: data.remarks,
      otp: data.otp,
      ...billDetails,
    }).then((res) => {
      console.log('response', res)
      toast.success(res.data.message)
      handleReset()
      setTimeout(() => {
        history.push('/account/transactions')
      }, 300)
    })
  }

  const handleAmount = (value) => {
    // console.log(value)
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const selectAmount = (value) => {
    setValue('amount', value, { shouldValidate: true })
  }

  // const handleChangeOperator = option => {
  //   // console.log(option.value)
  //   setBillDetails()
  //   setOTP(false)
  // }

  const handleChangeInput = (name, value) => {
    setValue(name, value)
    trigger(name)
    // setOTP(false)
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(operator) || isEmpty(operators)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Pay your {operator?.name} Bill</CardTitle>
              <div className="d-flex align-items-center justify-content-between pr-3">
                <div className="d-flex align-items-center p-2 my-2">
                  {operator.logo ? (
                    <img
                      src={s3GetUrl(operator?.logo)}
                      alt=""
                      style={{
                        width: '64px',
                        borderRadius: 50,
                        backgroundColor: '#eee',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        minWidth: '64px',
                        minHeight: '64px',
                        borderRadius: 50,
                        backgroundColor: '#eee',
                      }}
                    ></div>
                  )}
                  <div>
                    <div className="ml-3">{operator?.name}</div>
                    {/* <div className="ml-3 font-weight-bold">{operator.name}</div> */}
                    {/* {operator?.bill && <small className="ml-3 text-muted">Bill Fetch Supported</small>} */}
                    <small className="ml-3 text-muted">
                      Bill Fetch: {operator?.bill ? 'Yes' : 'No'}
                    </small>
                    {/* {operator?.bbps && <small className="ml-3 text-muted">BBPS Enabled</small>} */}
                    <small className="ml-3 text-muted">
                      BBPS: {operator?.bbps ? 'Yes' : 'No'}
                    </small>
                    {/* <div className="ml-3">{operator?.bill ? "Yes" : "No"}</div> */}
                  </div>
                </div>
                <FaRegArrowAltCircleLeft
                  title="Back to Operators"
                  size="24"
                  className="text-secondary cursor-pointer"
                  onClick={() => history.goBack()}
                />
              </div>
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  <Col lg="12">
                    <Row>
                      {operator?.fields &&
                        operator?.fields.map((field) => (
                          <Col lg="12" key={field.name}>
                            <FormGroup>
                              <Label for={field.name} className="required">
                                {field.label}
                              </Label>
                              <input
                                type="text"
                                placeholder={field.placeholder || field.label}
                                id={field.name}
                                name={field.name}
                                className={cx(
                                  'form-control',
                                  validationStatus(field.name, formState)
                                )}
                                {...register(field.name, {
                                  required: `${field.label} is required`,
                                })}
                                onChange={(e) =>
                                  handleChangeInput(field.name, e.target.value)
                                }
                              />
                              <InvalidFeedbackV7
                                name={field.name}
                                formState={formState}
                              />
                            </FormGroup>
                          </Col>
                        ))}
                      {operator?.bill ? (
                        billDetails && (
                          <Col lg="12">
                            <FormGroup>
                              <Label for="amount" className="required">
                                Amount
                              </Label>
                              <InputGroup>
                                <input
                                  type="text"
                                  placeholder="Enter Amount"
                                  id="amount"
                                  name="amount"
                                  value={amount}
                                  defaultValue={amount}
                                  // defaultValue="100"
                                  // defaultValue={0}
                                  className={cx(
                                    'form-control',
                                    validationStatus('amount', formState)
                                  )}
                                  {...register('amount', {
                                    required: 'Amount is required',
                                    onChange: (e) =>
                                      handleAmount(e.target.value),
                                  })}
                                />
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <FaRupeeSign />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <InvalidFeedbackV7
                                  name="amount"
                                  formState={formState}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        )
                      ) : (
                        <Col lg="12">
                          <FormGroup>
                            <Label for="amount" className="required">
                              Amount
                            </Label>
                            <InputGroup>
                              <input
                                type="text"
                                placeholder="Enter Amount"
                                id="amount"
                                name="amount"
                                value={amount}
                                defaultValue={amount}
                                // defaultValue={0}
                                className={cx(
                                  'form-control',
                                  validationStatus('amount', formState)
                                )}
                                {...register('amount', {
                                  required: 'Amount is required',
                                  onChange: (e) => handleAmount(e.target.value),
                                })}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FaRupeeSign />
                                </InputGroupText>
                              </InputGroupAddon>
                              <InvalidFeedbackV7
                                name="amount"
                                formState={formState}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {!otpSent && (
                        <Col lg="12">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="primary"
                              disabled={preventSubmit(formState)}
                            >
                              Generate OTP
                            </Button>
                          </FormGroup>
                          <input
                            type="hidden"
                            name="otp"
                            defaultValue="1111"
                            {...register('otp')}
                          />
                        </Col>
                      )}
                      {otpSent && (
                        <>
                          <Col lg="12">
                            <FormGroup>
                              <Label for="otp" className="required">
                                OTP
                              </Label>
                              <Input
                                type="text"
                                placeholder="Enter OTP"
                                id="otp"
                                name="otp"
                                // defaultValue="222111"
                                {...register('otp')}
                              />
                              <InvalidFeedbackV7
                                name="otp"
                                formState={formState}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Button
                                size="lg"
                                block
                                className="mt-3"
                                color="danger"
                                type="button"
                                onClick={handleReset}
                              >
                                Reset
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Button
                                size="lg"
                                block
                                className="mt-3"
                                color="success"
                                disabled={preventSubmit(formState)}
                              >
                                Pay Bill
                              </Button>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                      {/* {operator?.bill ?
                        !billDetails ?
                          <>
                            <Col lg="12">
                              <FormGroup>
                                <Button
                                  size="lg"
                                  block
                                  className="mt-3"
                                  color="primary"
                                  disabled={preventSubmit(formState)}
                                >
                                  Check Bill
                                </Button>
                              </FormGroup>
                            </Col>
                          </>
                          :
                          <>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  size="lg"
                                  block
                                  className="mt-3"
                                  color="danger"
                                  type="button"
                                  onClick={handleReset}
                                >
                                  Reset
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Button
                                  size="lg"
                                  block
                                  className="mt-3"
                                  color="success"
                                  disabled={preventSubmit(formState)}
                                >
                                  Pay Bill
                                </Button>
                              </FormGroup>
                            </Col>
                          </>
                        :
                        <Col lg="12">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="primary"
                              disabled={preventSubmit(formState)}
                            >
                              Proceed
                            </Button>
                          </FormGroup>
                        </Col>
                      } */}
                    </Row>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          {otpSent && (
            <Card className="card-shadow-primary card-border mb-3">
              <div className="dropdown-menu-header">
                <div className="dropdown-menu-header-inner bg-dark">
                  <div className="menu-header-content">
                    <div>
                      <h5 className="menu-header-title">Bill Detail:</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <ListGroup className="rm-list-borders" flush>
                  <ListGroupItem
                    onClick={() => {
                      selectAmount(billDetails?.amount)
                    }}
                    className="cursor-pointer"
                  >
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper align-items-start d-flex ">
                        {/* <div className="widget-content-left mr-3">
                            <img width={42} className="rounded-circle" src={logo} alt="" />
                          </div> */}
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Customer Name: {billDetails?.name}
                          </div>
                        </div>
                        {/* <div className="widget-content-right">
                            <div className="font-size-xlg text-muted">
                              <small className="text-success pl-2">
                                <FontAwesomeIcon icon={faRupeeSign} />
                                {billDetails?.amount}
                              </small>
                            </div>
                          </div> */}
                      </div>
                    </div>
                    <div className="widget-content p-0">
                      {/* <div className="widget-content-wrapper">
                          <div className="widget-subheading text-muted">
                            {billDetails?.message}
                          </div>
                        </div> */}
                      <div className="widget-subheading">
                        Card Number: {billDetails?.card_number}
                      </div>
                      <div className="widget-subheading">
                        Card Type: {billDetails?.network}
                      </div>
                      <div className="widget-subheading">
                        Amount: <FaRupeeSign /> {billDetails?.amount}
                      </div>
                      {/* <div className="widget-subheading">
                          Bill Date: {billDetails?.bill_date}
                        </div>
                        <div className="widget-subheading">
                          Bill Due Date: {billDetails?.bill_due_date}
                        </div>
                        <div className="widget-subheading text-muted">
                          Partial Bill Amount: {billDetails?.partial_bill}
                        </div>
                        <div className="widget-subheading text-muted">
                          Verify Bill ID: {billDetails?.refid}
                        </div> */}
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default CCBillingForm
