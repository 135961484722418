import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const CSSTransitionGroup = ({ children: child, ...props }) => {
  // NOTICE THE SPREAD! THIS IS REQUIRED!

  // return (<Transition {...props}>
  //       {transitionState => React.cloneElement(child, {
  //         style: getStyleForTransitionState(transitionState)
  //       })}
  //     </Transition>
  // )
  return (
    <TransitionGroup>
      <CSSTransition {...props}>
        <div>{child}</div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default CSSTransitionGroup
