import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  description: '',
  openValidateModel: false,
  openUpdateModel: false,
  validated: false,
}

export const pinSlice = createSlice({
  name: 'pin',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    checkPin: (state, action) => {
      state.description = action.payload.description
      state.openValidateModel = true
    },
    validatePin: (state) => {
      state.description = ''
      state.openValidateModel = false
      state.validated = true
    },
    openValidateModal: (state) => {
      state.openValidateModel = true
    },
    toggleValidateModal: (state) => {
      state.openValidateModel = !state.openValidateModel
    },
    openUpdateModal: (state) => {
      state.openUpdateModel = true
    },
    toggleUpdateModal: (state) => {
      state.openUpdateModel = !state.openUpdateModel
    },
  },
})

// Action creators are generated for each case reducer function
export const pinActions = pinSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectPin = (state) => state.pin

export default pinSlice.reducer
