import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import PinInput from 'react-pin-input'
import { pinLength } from '.'
import { useAuth } from 'backend/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { pinActions, selectPin } from 'features/auth/pinSlice'

const ValidatePinModal = ({ validatePin }) => {
  const { openValidateModel, description } = useSelector(selectPin)
  const dispatch = useDispatch()
  const [enteredPin, setPin] = useState('')
  const { validatePinBackend } = useAuth()
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (openValidateModel === true) {
      setTimeout(() => {
        const el = document.querySelector('.validate > .pincode-input-container > input')
        if (el) {
          el.focus()
        }
      }, 500)
    }
  }, [openValidateModel])

  const toggle = () => {
    dispatch(pinActions.toggleValidateModal())
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const newDescription = description ? description : 'Validate Pin'
    // const encryptedPin = encryptPin(pin)
    // getPin(encryptedPin)
    validatePinBackend({ pin: enteredPin, description: newDescription })
      .then(() => {
        validatePin(true)
      })
      .catch(() => {
        validatePin(false)
      })
  }

  const onPinChange = (value) => {
    if (value.length === pinLength) {
      setCompleted(true)
    } else {
      setCompleted(false)
    }
    setPin(value)
  }

  // const onComplete = (value, index) => {
  //   console.log(value, value.length)
  //   if (value.length === pinLength) {
  //     setCompleted(true)
  //   } else {
  //     setCompleted(false)
  //   }
  // };

  // const onError = (errors, e) => console.log(errors, e)

  return (
    <Modal
      isOpen={openValidateModel}
      toggle={toggle}
      centered
      size="sm"
      className="shadow-none"
    >
      <ModalHeader toggle={toggle}>
        <i className="pe-7s-key" />
      </ModalHeader>
      <ModalBody className="text-center">
        <form onSubmit={onSubmit}>
          <div className="modal-status-icon">
            <span className="bg-orange brround">
              <i className="las la-key"></i>
            </span>
          </div>
          <h4 className="m-3">Enter Pin</h4>
          {/* <p>{description}</p> */}
          <div className="pt-3 pb-4 validate">
            <PinInput
              length={pinLength}
              initialValue=""
              // secret
              onChange={onPinChange}
              // onChange={(value, index) => { }}
              type="numeric"
              inputMode="number"
              // style={{padding: '10px'}}
              // inputStyle={{ borderColor: '#e1e7ea' }}
              // inputFocusStyle={{ borderColor: '#FFAB2B' }}
              // onComplete={(value, index) => { }}
              // onComplete={onComplete}
              // autoSelect={true}
              // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <button
            className="btn btn-primary"
            disabled={!completed}
            // onClick={onSubmit}
          >
            Verify
          </button>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ValidatePinModal
