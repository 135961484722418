import dayjs from 'dayjs'
// export const lumpsumCalculator = (amount, tenure, interest = 1) => {
//   let investMentReturn = 1
//   for (let i = 1; i <= tenure; i++) {
//     investMentReturn += amount * interest * tenure /12
//   }
//   return investMentReturn
// }
export const getMonthsFromFrequency = (frequency) => {
  switch (frequency) {
    case 'Monthly':
      return 12
    case 'Weekly':
      return 52
    case 'Daily':
      return 365
    default:
      return 1
  }
}

export const sipCalculatorV2 = (
  amount,
  timeDurations,
  annualizedReturn = 1,
  frequency
) => {
  let interest = 0
  const interestPerDay = (amount * annualizedReturn) / 100 / 365
  const endDate = dayjs().add(timeDurations, 'years')
  if (frequency === 'Monthly') {
    const durationInMonths = timeDurations * 12
    for (let i = 1; i <= durationInMonths; i++) {
      const startDate = dayjs().add(i - 1, 'months')
      const noOfDays = endDate.diff(startDate, 'days') - 1
      interest += interestPerDay * noOfDays
    }
  }
  if (frequency === 'Weekly') {
    const durationInWeeks = timeDurations * 52
    for (let i = 1; i <= durationInWeeks; i++) {
      const startDate = dayjs().add(i - 1, 'weeks')
      const noOfDays = endDate.diff(startDate, 'days') - 1
      interest += interestPerDay * noOfDays
    }
  }
  if (frequency === 'Daily') {
    const durationInDays = timeDurations * 365
    for (let i = 1; i <= durationInDays; i++) {
      const startDate = dayjs().add(i - 1, 'days')
      const noOfDays = endDate.diff(startDate, 'days') - 1
      interest += interestPerDay * noOfDays
    }
  }
  return interest
  // let investmentReturn = 0
  // const interestPerDay = (amount * annualizedReturn) / 100 / 365
  // const endDate = dayjs().add(timeDurations, 'months')
  // for (let i = 1; i <= timeDurations; i++) {
  //   const noOfMonth = i - 1
  //   const startDate = dayjs().add(noOfMonth, 'months')
  //   const noOfDays = endDate.diff(startDate, 'days') - 1
  //   investmentReturn += interestPerDay * noOfDays
  // }
  // return investmentReturn
}

export const sipCalculator = (amount, timeDurations, interest = 1) => {
  let investmentReturn = 0
  const interestPerDay = (amount * interest) / 100 / 365
  const endDate = dayjs().add(timeDurations, 'months')
  for (let i = 1; i <= timeDurations; i++) {
    const noOfMonth = i - 1
    const startDate = dayjs().add(noOfMonth, 'months')
    const noOfDays = endDate.diff(startDate, 'days') - 1
    investmentReturn += interestPerDay * noOfDays
  }
  return investmentReturn
}

export const lumpsumCalculatorV1 = (amount, timeDurations, interest = 1) => {
  const startDate = dayjs()
  const endDate = dayjs().add(timeDurations, 'years')
  const diffInMonths = endDate.diff(startDate, 'months')
  const investmentReturn =
    ((amount * interest) / 100 / diffInMonths) * diffInMonths

  return investmentReturn
  // return (
  //   (amount * (1+ interest / timeDurations)) ^ (timeDurations)
  // )
}

export const lumpsumCalculator = (amount, timeDurations, interest = 1) => {
  let investmentReturn = ((amount * interest) / 100) * timeDurations // * diffInMonths
  return investmentReturn

  // // console.log('diffInMonths', diffInMonths)
  // let investmentReturn = 0
  // for (let i = 0; i < diffInMonths; i++) {
  //   if (i !== 0 && i % 12 === 0) {
  //     // console.log('i % 12', i, i % 12)
  //     amount += investmentReturn
  //   }
  //   investmentReturn += (amount * interest) / 100 / 12
  // }
  // return Math.round(investmentReturn)
}

// export const lumpsumCalculator = (amount, timeDurations, interest = 1) => {
//   let investmentReturn = 0
//   const interestPerMonth = (amount * interest) / 100 / 12
//   const noOfMonths = timeDurations * 12
//   // const endDate = dayjs().add(timeDurations, 'years')
//   // const startDate = dayjs().add(1, 'months')
//   // const noOfMonths = endDate.diff(startDate, 'months') + 1
//   console.log('noOfMonths', noOfMonths, interestPerMonth)
//   for (let i = 1; i < noOfMonths; i++) {
//     if (i % 12 === 1) {
//       console.log('i % 12', i, i % 12)
//       investmentReturn += Math.round((amount * interest) / 100 / 12)
//     }
//     investmentReturn += Math.round((amount * interest) / 100 / 12)
//   }
//   return Math.round(investmentReturn)
//   // return (
//   //   (amount * (1+ interest / timeDurations)) ^ (timeDurations)
//   // )
// }

// export const lumpsumCalculator = (
//   amount,
//   timeDurations,
//   interest = 1
// ) => {
//   let investmentReturn = 0
//   const interestPerDay = (amount * interest) / 100 / 365
//   const endDate = dayjs().add(timeDurations, 'years')
//   const durationInMonths = timeDurations * 12
//   console.log('durationInMonths', durationInMonths)
//   for (let i = 1; i <= durationInMonths; i++) {
//     const noOfMonth = i - 1
//     const startDate = dayjs().add(noOfMonth, 'months')
//     const noOfDays = endDate.diff(startDate, 'days') - 1
//     investmentReturn += interestPerDay * noOfDays / durationInMonths
//   }
//   return investmentReturn
// }

export const withdrawalCharge = (amount, rate) => {
  const charge = (parseFloat(amount) * rate) / 100
  if (charge <= 15) {
    return charge
  }
  return 15
}

export function percentage(num, per = 1) {
  return (num / 100) * per
}
