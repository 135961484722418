import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { useHistory } from 'react-router-dom'

import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import { FaRupeeSign } from 'react-icons/fa'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useVirtualAccount } from 'backend/useVirtualAccount'
import { useSelector } from 'react-redux'
import { Form, Input, Submit } from 'ReactstrapUi'
import { usePin } from 'context/PinContext'
import { isEmpty, validMobileRegex } from 'utils/validation'
import { useState } from 'react'
import { selectAccount } from 'features/accountSlice'
import { formattedPrice } from 'utils/helper'
import { withdrawalCharge } from 'utils/finCalculators'
import { withdrawalConfig } from 'utils/constants'
import usePageTitle from 'hooks/usePageTitle'
// import { selectAuth } from "features/auth/authSlice"

const Withdraw = () => {
  usePageTitle('Withdraw')
  const history = useHistory()
  const { withdrawTransfer } = useVirtualAccount()
  const [formData, setFormData] = useState({})
  const { checkPin } = usePin()
  const { virtualAccount, bankAccount } = useSelector(selectAccount)
  const [transferAmount, setTransferAmount] = useState(
    virtualAccount.presentBalance
  )

  const withdrawalChargeAmount = withdrawalCharge(
    virtualAccount.presentBalance,
    withdrawalConfig.percentage
  )
  const withdrawableAmount =
    virtualAccount.presentBalance - withdrawalChargeAmount

  const schema = yup.object().shape({
    // accountNumber: yup.string().required('Account Number is required'),
    mobile: yup
      .string()
      .required('Mobile is required')
      .matches(validMobileRegex, 'Please enter valid mobile number')
      .min(10, 'Mobile Number should 10 digits'),
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter valid email'),
    // name: yup.string().required('Name is required'),
    // address: yup.string().required('Address is required'),
    // ifscCode: yup
    //   .string()
    //   .required('IFSC Code is required')
    //   .min(10, 'Please enter valid IFSC Code'),
    // transfer_type: yup.string().required('Select Transfer Type'),
    message: yup
      .string()
      .required('Please Enter Message of Transfer')
      .min(5, 'Message should more than 5 characters')
      .max(50, 'Message should less than 50 characters'),
    transferAmount: yup
      .string()
      .required('Please Enter Amount to Transfer')
      .test(
        'min',
        `Minimum Amount should 15`,
        (value) => parseFloat(value) >= 14
      )
      .test(
        'max',
        `Amount should not more than account balance`,
        (value) => parseFloat(value) < virtualAccount.presentBalance
      ),
  })

  const getWithdrawalCharge = (transferAmount) => {
    return transferAmount > 0
      ? transferAmount -
          withdrawalCharge(transferAmount, withdrawalConfig.percentage)
      : 0
  }

  const onSubmit = async (data) => {
    setFormData(data)
    checkPin(data, handleFormSubmit, 'To withdraw amount')
  }

  const handleFormSubmit = (data) => {
    const payload = {
      toAccountNumber: bankAccount.accountNumber,
      transferAmount: String(transferAmount),
      fromAccount: virtualAccount.accountNumber,
      message: data.message,
      beneficiaryDetails: {
        mobile: virtualAccount.mobile,
        email: virtualAccount.email,
        accountName: bankAccount.accountName,
        ifscCode: bankAccount.ifscCode,
      },
      beneficiaryName: bankAccount.accountName,
      beneficiaryMobile: virtualAccount.mobile,
      beneficiaryEmail: virtualAccount.email,
      beneficiaryIfscCode: bankAccount.ifscCode,
      beneficiaryAccountNumber: bankAccount.accountNumber,

      withdrawalCharge: String(
        transferAmount - getWithdrawalCharge(transferAmount)
      ),
    }

    withdrawTransfer(payload).then((res) => {
      console.log(res)
      history.push('/')
    })
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Row>
          <Col lg={9}>
            <Card className="main-card mb-2">
              <CardBody>
                <CardTitle>Virtual Account</CardTitle>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Virtual Account Balance
                  </span>{' '}
                  {formattedPrice(virtualAccount.presentBalance)}
                </div>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    Processing Fee (1.5%)
                  </span>
                  {formattedPrice(-withdrawalChargeAmount)}
                </div>
                <div className="details-show">
                  <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                    withdrawable amount
                  </span>
                  {formattedPrice(withdrawableAmount)}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={9}>
            <Card className="main-card">
              {virtualAccount?.id && (
                <CardBody>
                  <CardTitle>Withdraw</CardTitle>
                  <Form
                    onFinish={onSubmit}
                    className="validate-form was-validate"
                    schemaResolver={yupResolver(schema)}
                    initialValues={
                      !isEmpty(formData)
                        ? formData
                        : {
                            mobile: virtualAccount.mobile,
                            email: virtualAccount.email,
                            name: bankAccount.accountName,
                            accountNumber: bankAccount.accountNumber,
                            ifscCode: bankAccount.ifscCode,
                            message: 'Transfer from Kashewnut Virtual account',
                            transferAmount: transferAmount,
                            // withdrawalCharge: withdrawalCharge(
                            //   transferAmount,
                            //   withdrawalChargeAmount
                            // ),
                          }
                    }
                  >
                    <Row>
                      <Col md="6">
                        <Input.Text
                          label="Mobile"
                          id="mobile"
                          name="mobile"
                          placeholder="Enter Mobile Number"
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <Input.Email
                          label="Email"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          readOnly
                        />
                      </Col>
                      <Col md="12">
                        <Input.Text
                          label="Name"
                          name="name"
                          placeholder="Enter name"
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <Input.Text
                          label="Account Number"
                          name="accountNumber"
                          placeholder="Enter account number"
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <Input.Text
                          label="IFSC"
                          name="ifscCode"
                          placeholder="Enter IFSC code"
                          readOnly
                        />
                      </Col>
                      <Col md="12">
                        <Input.Text
                          label="Message"
                          name="message"
                          placeholder="Enter Purpose of Transfer"
                          required
                        />
                      </Col>
                      <Col md="12">
                        <Input.Number
                          label="Amount"
                          name="transferAmount"
                          placeholder="Enter amount"
                          // min="100"
                          step="10"
                          max={withdrawableAmount}
                          rules={{
                            onChange: (e) => setTransferAmount(e.target.value),
                          }}
                          append={
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <FaRupeeSign />
                              </InputGroupText>
                            </InputGroupAddon>
                          }
                          required
                        />
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label for="withdrawalCharge">
                            Withdrawal Charge
                          </label>
                          <div className="input-group">
                            <input
                              id="withdrawalCharge"
                              className="form-control"
                              disabled
                              value={withdrawalCharge(
                                transferAmount,
                                withdrawalConfig.percentage
                              )}
                            />
                            <div className="input-group-append">
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <FaRupeeSign />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label for="withdrawalCharge">You will Get</label>
                          <div className="input-group">
                            <input
                              id="withdrawalCharge"
                              className="form-control"
                              disabled
                              value={getWithdrawalCharge(transferAmount)}
                            />
                            <div className="input-group-append">
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <FaRupeeSign />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="text-right">
                        <small className="text-danger">
                          Fields marked with * are required
                        </small>
                      </Col>
                      <Col md="12" className="d-flex justify-content-end">
                        <FormGroup>
                          <Submit className="mt-3 btn btn-primary">
                            Withdraw
                          </Submit>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Withdraw
