const providers = {
  kwikApi: 'kwikApi',
  apiScript: 'apiScript',
  paySprint: 'paySprint',
  qwikCilver: 'qwikCilver',
  augmont: 'augmont',
  phonePay: 'phonePay',
}

export { providers }
// Long term
export const metalType = {
  Gold: 'Gold',
  Silver: 'Silver',
}
export const longTermConfig = {
  duration: {
    min: 1,
    max: 15,
  },
  amount: {
    min: 100,
    max: 20000,
  },
}
export const shortTermConfig = {
  sip: {
    min: 500,
    max: 10000,
  },
  Lumpsum: {
    min: 500,
    max: 120000,
  },
  duration: {
    min: 1,
    max: 12,
  },
  amount: {
    min: 500,
    step: 100,
    max: 20000,
  },
  interestRate: 9.5,
  completionBonus: 1,
}

export const investmentType = {
  SIP: 'SIP',
  Lumpsum: 'Lumpsum',
}

export const cdnImg = {
  goals: 'goals/default.jpg',
  augmont: {
    gold: 'augmont/gold.jpg',
    silver: 'augmont/silver.jpg',
  },
}

export const withdrawalConfig = {
  percentage: 1.5,
  max: 15,
}

export function getAverageRGB(e) {
  try {
    e.persist()

    const img = e.target
    const max = 10 // Max size (Higher num = better precision but slower)
    const productId = img.getAttribute('productId')
    const { naturalWidth: iw, naturalHeight: ih } = img

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const sr = Math.min(max / iw, max / ih) // Scale ratio
    const w = Math.ceil(iw * sr) // Width
    const h = Math.ceil(ih * sr) // Height
    // const area = w * h // Area
    ctx.canvas.width = w
    ctx.canvas.height = h
    ctx.drawImage(img, 0, 0, w, h)

    const data = ctx.getImageData(0, 0, w, h).data
    let r = 0,
      g = 0,
      b = 0,
      count = 0

    for (let i = 0; i < data.length; i += 4) {
      const alpha = data[i + 3] // Alpha channel (transparency)

      if (alpha > 0) {
        r += data[i]
        g += data[i + 1]
        b += data[i + 2]
        count++
      }
    }

    if (count > 0) {
      r = ~~(r / count)
      g = ~~(g / count)
      b = ~~(b / count)
    }

    document
      .getElementsByClassName(`card-${productId}`)[0]
      .style.setProperty('background-color', `rgba(${r},${g},${b}, .2)`)
    document
      .getElementsByClassName(`brandStroke-${productId}`)[0]
      .style.setProperty('background-color', `rgba(${r},${g},${b}, .4)`)

    document
      .getElementsByClassName(`brandText-${productId}`)[0]
      .style.setProperty('color', `rgba(${r},${g},${b}, 1)`)
  } catch (e) {
    console.log(e)
  }
}
