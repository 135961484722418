import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

// AUTH PAGES
import Login from './Login'
import Register from './Signup'
import CreateAccount from './Signup/create-account'
import AadhaarKYC from './Signup/aadhaar-kyc-step-1-otp'
// import AadhaarProfile from './Signup/aadhaar-kyc-step-2-profile'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import PanKYC from "./Signup/pan-kyc-step-1"
import PanProfile from "./Signup/pan-kyc-step-2-profile"

const UserPages = ({ match }) => (
  <Fragment>
    <div className="app-container">
      <Route path={`${match.url}/login`} component={Login} />
      <Route exact path={`${match.url}/register`} component={Register} />
      <Route
        exact
        path={`${match.url}/register/create`}
        component={CreateAccount}
      />
      <Route exact path={`${match.url}/signup`} component={Register} />
      <Route
        exact
        path={`${match.url}/signup/create`}
        component={CreateAccount}
      />
      <Route exact path={`${match.url}/aadhaar`} component={AadhaarKYC} />
      {/* <Route exact path={`${match.url}/profile`} component={AadhaarProfile} /> */}
      <Route exact path={`${match.url}/pan`} component={PanKYC} />
      <Route exact path={`${match.url}/pan/profile`} component={PanProfile} />
      <Route path={`${match.url}/password-forgot`} component={ForgotPassword} />
      <Route
        path={`${match.url}/password-reset/:token`}
        component={ResetPassword}
      />
    </div>
  </Fragment>
)

export default UserPages
