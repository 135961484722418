import { createSlice } from '@reduxjs/toolkit'
// import AirtelSvg from '../assets/svg/airtel.svg'
// import BSNLSvg from '../assets/svg/bsnl.png'
// import MTNLSvg from '../assets/svg/mtnl.png'
// import JioSvg from '../assets/svg/jio.png'
// import ACTPng from '../assets/svg/act.png'
// import AirjaldiPng from '../assets/svg/airjaldi.png'
// import ViPng from '../assets/svg/vi.png'
// import DishWebp from '../assets/svg/dish-tv.webp'
// import SunWebp from '../assets/svg/sun-direct.webp'
// import TataWebp from '../assets/svg/tata-sky.webp'
// import AsiaNetPng from '../assets/svg/asianet.png'
// import ComwayPng from '../assets/svg/comway.png'
// import ConnectPng from '../assets/svg/connect.png'
// import DenPng from '../assets/svg/den.png'
// import ExcellPng from '../assets/svg/excell.png'
// import FicusPng from '../assets/svg/ficus.png'
// import FlashFibernetPng from '../assets/svg/flash-fibernet.png'
// import FusionnetPng from '../assets/svg/fusionnet.png'
// import GTPLPng from '../assets/svg/gtpl.png'
// import HathwayPng from '../assets/svg/hathway.png'
// import InstalinksPng from '../assets/svg/instalinks.png'
// import InstanetPng from '../assets/svg/instanet.png'
// import IonPng from '../assets/svg/ion.png'
// import KeralaVisionPng from '../assets/svg/kerala-vision.png'
// import MnetPng from '../assets/svg/mnet.png'
// import NetplusPng from '../assets/svg/NetPlus.png'
// import NextraPng from '../assets/svg/nextra.png'
// import SkylinkPng from '../assets/svg/skylink.png'
// import SpectranetPng from '../assets/svg/spectranet.png'
// import SwifttelePng from '../assets/svg/swift-tele.png'
// import TikonaPng from '../assets/svg/tikona.png'
// import TimblPng from '../assets/svg/timbl.png'
// import TtnPng from '../assets/svg/ttn.png'
// import UcnPng from '../assets/svg/ucn.png'
// import VfibernetPng from '../assets/svg/vfibernet.png'
// import WishnetPng from '../assets/svg/wish-net.png'
// import VideoconPng from '../assets/svg/videocon-d2h.webp'
// import AdaniGasPng from '../assets/svg/adanigas.png'
// import AssamGasCompanyPng from '../assets/svg/assam_gas_company.png'
// import BhagyanagarGasPng from '../assets/svg/bhagyanagar_gas.png'
// import CGSMLPng from '../assets/svg/cgsml.png'
// import CUPGLPng from '../assets/svg/cupgl.png'
// import GailPNG from '../assets/svg/gail.png'
// import GailGasPNG from '../assets/svg/gail.png'
// import GreenGasPNG from '../assets/svg/green_gas.png'
// import GujGasPNG from '../assets/svg/gujgas.png'
// import HaryanaCityGasPNG from '../assets/svg/hcg.png'
// import IndraprasthGasPNG from '../assets/svg/igl.png'
// import IOAGasPNG from '../assets/svg/ioag.png'
// import IRMGasPNG from '../assets/svg/irm_gas.png'
// import MeghaGasPNG from '../assets/svg/MEGH00000NATLV.png'
// import MGLGasPNG from '../assets/svg/mgl.png'
// import MNGLGasPNG from '../assets/svg/mngl.png'
// import NavgplPNG from '../assets/svg/navgpl.png'
// import SabarmatiGasPNG from '../assets/svg/sabarmati.png'
// import SanwariyaGasPNG from '../assets/svg/SanwariyaGasLtd.png'
// import SitiGasPNG from '../assets/svg/sitigas.png'
// import TNGCLPNG from '../assets/svg/TNGCLOB00TRI01.png'
// import TorrentGasPNG from '../assets/svg/torrent_gas_logo.png'
// import UcpgplPNG from '../assets/svg/ucpgpl.png'
// import VadodraGasPNG from '../assets/svg/vadodara_gas.png'
// import BicholimMCPNG from '../assets/svg/bicholim_municipal_council.png'
// import MhapsaMCPNG from '../assets/svg/mhapsa_municipal_council.png'
// import MargaoMCPNG from '../assets/svg/margao_municipal_council.png'
// import CanConaMCPNG from '../assets/svg/cancona_municiple_council.png'
// import CuncolimMCPNG from '../assets/svg/cuncolim_municiple_council.png'
// import CurchoremMCPNG from '../assets/svg/curchorem_cacora_municipale_council.png'
// import GreaterChennaiPNG from '../assets/svg/greater_chennai_corporation.png'
// import GulbargaCityPNG from '../assets/svg/gulbarga_city_corporation.png'
// import HubliMCPNG from '../assets/svg/hubli_dharwad_municipal_corporation.png'
// import KalyanDomMCPNG from '../assets/svg/kalyan_dombivali_municipal_corporation.png'
// import KolhapurMCPNG from '../assets/svg/kolhapur_municipal_corporation.png'
// import MPUrbanPNG from '../assets/svg/madhya_pradesh_urban.png'
// import MCMeerutPNG from '../assets/svg/minicipal_corporation_meerut.png'
// import MormugaoMCPNG from '../assets/svg/mormugao_municipal_council.png'
// import MCRohtakPNG from '../assets/svg/municipal_corporation_rohtak.png'
// import NagarNigamAgraPNG from '../assets/svg/nagar_nigam_agra.png'
// import NagarPalikaPaliaPNG from '../assets/svg/nagar_palika_palia_kalan.png'
// import NagarPalikaLalitPNG from '../assets/svg/nagar_palika_parishad_lalitpur.png'
// import OrangeRetailPNG from '../assets/svg/orange_retail_finance_india.png'
// import PernemMCPNG from '../assets/svg/pernem_municipale_council.png'
// import RajkotMCPNG from '../assets/svg/rajkot_municipale_corporation.png'
// import PortBlairMCPNG from '../assets/svg/port_blair_municipal_council.png'
// import PrayagrajNagarPNG from '../assets/svg/prayagraj_nagar_nigam.png'
// import PuducherryUrbanPNG from '../assets/svg/puducherry_urban_development_agency.png'
// import PondaMCPNG from '../assets/svg/ponda_municipale_council.png'
// import SanguemMCPNG from '../assets/svg/sanguem_municipale_council.png'
// import ShivamoggaCPNG from '../assets/svg/shivamogga_city_corporation.png'
// import UddUttrakhandPNG from '../assets/svg/UDD_uttarakhand.png'
// import ValpoiMCPNG from '../assets/svg/valpoi_municipale_council.png'
// import VasaiVirarMCPNG from '../assets/svg/vasai_virar_municipal_corporation.png'
// import QuepemMCPNG from '../assets/svg/quepem_municipale_council.png'
// import AxisBankWebp from '../assets/svg/axis-bank.webp'
// import BobWebp from '../assets/svg/bob.webp'
// import EquitasWebp from '../assets/svg/equitas.webp'
// import IHMCLWebp from '../assets/svg/ihmcl.webp'
// import FederalWebp from '../assets/svg/federal-bank.webp'
// import HDFCWebp from '../assets/svg/hdfc-bank.webp'
// import IciciWebp from '../assets/svg/icici-bank.webp'
// import IDBIWebp from '../assets/svg/idbi-bank.webp'
// import IDFCFWebp from '../assets/svg/idfc-first.webp'
// import IndusindWebp from '../assets/svg/indusind.webp'
// import JKWebp from '../assets/svg/j-k.webp'
// import KotakWebp from '../assets/svg/kotak.webp'
// import PaulWebp from '../assets/svg/paul-merchants.webp'
// import PaytmWebp from '../assets/svg/paytm.webp'
// import UcoWebp from '../assets/svg/uco-bank.webp'
// // water
// import BhopalWaterJPG from '../assets/svg/water/bhopal.jpg'
// import BangaloreWaterJPG from '../assets/svg/water/bwssb.png'
// import DJBWaterJPG from '../assets/svg/water/DJB.jpg'
// import GwmcWaterJPG from '../assets/svg/water/gwmc.png'
// import HmwssbWaterJPG from '../assets/svg/water/hmwssb.png'
// import IndoreWaterJPG from '../assets/svg/water/indore.png'
// import JabalpurWaterJPG from '../assets/svg/water/jabalpur.jpg'
// import JalandharWaterJPG from '../assets/svg/water/jabalpur.jpg'
// import JammuKashmirWaterJPG from '../assets/svg/water/JAMM00000JAKVA.png'
// import MCGWaterJPG from '../assets/svg/water/mcg.png'
// import ChandiWaterJPG from '../assets/svg/water/MUNI00000CHANI.png'
// import AliWaterJPG from '../assets/svg/water/NAGA00000ALG45.png'
// import NDMCWaterJPG from '../assets/svg/water/ndmc.png'
// import PHEDHaryanaWaterJPG from '../assets/svg/water/PUBL00000HARBB.png'
// import PunjabMCPNG from '../assets/svg/water/punjab_municipal_corporation_council.png'
// import RanchiMCPNG from '../assets/svg/water/rmc.png'
// import SilvasaMCPNG from '../assets/svg/water/smc.png'
// import UITBPNG from '../assets/svg/water/uitb.png'
// import UJSBPNG from '../assets/svg/water/ujs.png'
// import UNNPNG from '../assets/svg/water/unn.png'
// import VasaiPNG from '../assets/svg/water/VASA00000THAE9.png'
// import VatvaPNG from '../assets/svg/water/VATV00000GUJ91.png'
// import VijaypuraWaterPNG from '../assets/svg/water/VIJA00000KARUT.png'
// import WaterHaryanaPNG from '../assets/svg/water/Water_Hariyana_urban_development.png'
// import WaterKeralaPNG from '../assets/svg/water/Water_Kerala_Water_Authority.png'

const defaultState = {
  operators: [],
  bbpsOperators: [],
  circles: [],
  refreshOperator: true,
  bundleOperators: [],
  giftCards: [],
  mobileCircles: [
    {
      label: 'Andhra Pradesh Telangana',
      value: 'Andhra Pradesh Telangana',
    },
    { label: 'Assam', value: 'Assam' },
    { label: 'Bihar Jharkhand', value: 'Bihar Jharkhand' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Delhi NCR', value: 'Delhi NCR' },
    { label: 'Gujarat', value: 'Gujarat' },
    { label: 'Haryana', value: 'Haryana' },
    { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { label: 'Jammu Kashmir', value: 'Jammu Kashmir' },
    { label: 'Karnataka', value: 'Karnataka' },
    { label: 'Kerala', value: 'Kerala' },
    { label: 'Kolkata', value: 'Kolkata' },
    {
      label: 'Madhya Pradesh Chhattisgarh',
      value: 'Madhya Pradesh Chhattisgarh',
    },
    { label: 'Maharashtra Goa', value: 'Maharashtra Goa' },
    { label: 'Mumbai', value: 'Mumbai' },
    { label: 'North East', value: 'North East' },
    { label: 'Orissa', value: 'Orissa' },
    { label: 'Punjab', value: 'Punjab' },
    { label: 'Rajasthan', value: 'Rajasthan' },
    { label: 'Tamil Nadu', value: 'Tamil Nadu' },
    { label: 'UP East', value: 'UP East' },
    { label: 'UP West', value: 'UP West' },
    { label: 'West Bengal', value: 'West Bengal' },
  ],
}

export const servicesSlice = createSlice({
  name: 'services',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // queueUpdateInterval: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.interval = state.interval + 5
    // },
    loadOperators: (state, action) => {
      state.operators = state.operators.concat(action.payload)
      // state.operators = addLogo(action.payload)
      state.refreshOperator = false
    },
    loadBBPSOperators: (state, action) => {
      state.bbpsOperators = state.bbpsOperators.concat(action.payload)
      state.refreshOperator = false
    },
    loadRechargeOperators: (state, action) => {
      state.bbpsOperators = state.bbpsOperators.concat(action.payload)
      // state.bbpsOperators = addLogo(state.bbpsOperators)
      // state.bbpsOperators = action.payload
      state.refreshOperator = false
    },
    loadFastTagOperators: (state, action) => {
      state.bbpsOperators = state.bbpsOperators.concat(action.payload)
      // state.bbpsOperators = action.payload
      state.refreshOperator = false
    },
    loadLPGOperators: (state, action) => {
      state.bbpsOperators = state.bbpsOperators.concat(action.payload)
      // state.bbpsOperators = action.payload
      state.refreshOperator = false
    },
    loadMunicipalityOperators: (state, action) => {
      state.bbpsOperators = state.bbpsOperators.concat(action.payload)
      // state.bbpsOperators = action.payload
      state.refreshOperator = false
    },
    loadGiftCards: (state, action) => {
      state.giftCards = action.payload
      // state.bbpsOperators = action.payload
      state.refreshOperator = false
    },
    loadBundledOperators: (state, action) => {
      state.bundleOperators = action.payload
      state.refreshOperator = false
    },
    loadCircles: (state, action) => {
      state.circles = action.payload
    },
    clearServices: (state) => {
      state.queue = []
    },
  },
})

// function addLogo(optData) {
//   if (!optData?.length) return optData
//   for (let i = 0; i < optData?.length; i++) {
//     const curr = optData[i]
//     if (curr) curr.logo = findLogoByName(curr?.name)
//   }
//   return optData
// }

// function findLogoByName(name) {
//   const str = name?.toLowerCase()
//   const key = keys.find((k) => str.includes(k))
//   return logos[key]
// }

// const logos = {
//   activline: '',
//   'flash fibernet': FlashFibernetPng,
//   vfibernet: VfibernetPng,
//   'kerala vision': KeralaVisionPng,
//   'wish net': WishnetPng,
//   fusionnet: FusionnetPng,
//   swifttele: SwifttelePng,
//   spectranet: SpectranetPng,
//   instalinks: InstalinksPng,
//   instanet: InstanetPng,
//   videocon: VideoconPng,
//   skylink: SkylinkPng,
//   airjaldi: AirjaldiPng,
//   'ttn ': TtnPng,
//   'ucn ': UcnPng,
//   vodafone: ViPng,
//   nextra: NextraPng,
//   timbl: TimblPng,
//   netplus: NetplusPng,
//   asianet: AsiaNetPng,
//   connect: ConnectPng,
//   hathway: HathwayPng,
//   comway: ComwayPng,
//   airtel: AirtelSvg,
//   tikona: TikonaPng,
//   excell: ExcellPng,
//   ficus: FicusPng,
//   idea: ViPng,
//   bsnl: BSNLSvg,
//   gtpl: GTPLPng,
//   mtnl: MTNLSvg,
//   dish: DishWebp,
//   tata: TataWebp,
//   mnet: MnetPng,
//   sun: SunWebp,
//   den: DenPng,
//   jio: JioSvg,
//   act: ACTPng,
//   'ion broadband': IonPng,
//   'vi ': ViPng,
//   'adani gas': AdaniGasPng,
//   'assam gas company': AssamGasCompanyPng,
//   'bhagyanagar gas': BhagyanagarGasPng,
//   'charotar gas sahakari mandali': CGSMLPng,
//   'central u.p. gas': CUPGLPng,
//   'gail gas': GailGasPNG,
//   'green gas': GreenGasPNG,
//   'gujarat gas': GujGasPNG,
//   'gail ': GailPNG,
//   'haryana city gas': HaryanaCityGasPNG,
//   'indraprastha gas': IndraprasthGasPNG,
//   'indian oil-adani gas': IOAGasPNG,
//   'irm energy': IRMGasPNG,
//   'megha gas': MeghaGasPNG,
//   'mahanagar gas': MGLGasPNG,
//   'maharashtra natural gas': MNGLGasPNG,
//   'naveriya gas': NavgplPNG,
//   'sabarmati gas': SabarmatiGasPNG,
//   'sanwariya gas': SanwariyaGasPNG,
//   'siti energy': SitiGasPNG,
//   'tripura natural gas': TNGCLPNG,
//   'torrent gas': TorrentGasPNG,
//   'unique central piped gas': UcpgplPNG,
//   'vadodara gas': VadodraGasPNG,
//   'bicholim municipal council': BicholimMCPNG,
//   'rajkot municipal corporation': RajkotMCPNG,
//   'canacona municipal council': CanConaMCPNG,
//   'mhapsa municipal council': MhapsaMCPNG,
//   'quepem municipal council': QuepemMCPNG,
//   'margao municipal council': MargaoMCPNG,
//   'curchorem cacora municipal council': CurchoremMCPNG,
//   'greater chennai corporation': GreaterChennaiPNG,
//   'gulbarga city corporation': GulbargaCityPNG,
//   'hubli-dharwad municipal corporation': HubliMCPNG,
//   'kalyan dombivali municipal corporation': KalyanDomMCPNG,
//   'kolhapur municipal corporation': KolhapurMCPNG,
//   'madhya pradesh urban': MPUrbanPNG,
//   'minicipal corporation - meerut': MCMeerutPNG,
//   'mormugao municipal council': MormugaoMCPNG,
//   'municipal corporation rohtak': MCRohtakPNG,
//   'nagar nigam agra': NagarNigamAgraPNG,
//   'nagar palika palia kalan': NagarPalikaPaliaPNG,
//   'nagar palika parishad lalitpur': NagarPalikaLalitPNG,
//   'orange retail finance': OrangeRetailPNG,
//   'pernem municipal council': PernemMCPNG,
//   'ponda municipal council': PondaMCPNG,
//   'port blair municipal council': PortBlairMCPNG,
//   'prayagraj nagar nigam': PrayagrajNagarPNG,
//   'puducherry urban development': PuducherryUrbanPNG,
//   'cuncolim municipal council': CuncolimMCPNG,
//   'sanguem municipal council': SanguemMCPNG,
//   'shivamogga city corporation': ShivamoggaCPNG,
//   'udd uttarakhand': UddUttrakhandPNG,
//   'valpoi municipal council': ValpoiMCPNG,
//   'vasai virar municipal council': VasaiVirarMCPNG,
//   'axis bank': AxisBankWebp,
//   'bank of baroda': BobWebp,
//   'equitas fastag': EquitasWebp,
//   'indian highways management': IHMCLWebp,
//   'federal bank': FederalWebp,
//   'hdfc bank': HDFCWebp,
//   'icici bank': IciciWebp,
//   'idbi bank': IDBIWebp,
//   'idfc first bank': IDFCFWebp,
//   'indusind bank': IndusindWebp,
//   'jammu and kashmir bank': JKWebp,
//   'kotak mahindra bank': KotakWebp,
//   'paul merchants': PaulWebp,
//   'paytm payments bank': PaytmWebp,
//   'uco bank': UcoWebp,

//   // water
//   'bhopal municipal corporation': BhopalWaterJPG,
//   'bangalore water supply': BangaloreWaterJPG,
//   'delhi jal board': DJBWaterJPG,
//   'greater warangal municipal corporation': GwmcWaterJPG,
//   'hyderabad metropolitan water supply': HmwssbWaterJPG,
//   'indore municipal corporation': IndoreWaterJPG,
//   'jabalpur municipal corporation': JabalpurWaterJPG,
//   'municipal corporation jalandhar': JalandharWaterJPG,
//   'jammu kashmir water': JammuKashmirWaterJPG,
//   'municipal corporation of gurugram': MCGWaterJPG,
//   'municipal corporation of chandigarh': ChandiWaterJPG,
//   'nagar nigam aligarh': AliWaterJPG,
//   'new delhi municipal council': NDMCWaterJPG,
//   'public health engineering department, haryana': PHEDHaryanaWaterJPG,
//   'punjab municipal corporations': PunjabMCPNG,
//   'ranchi municipal corporation': RanchiMCPNG,
//   'silvasa municipal council': SilvasaMCPNG,
//   'urban investment trust (uit) - bhiwadi': UITBPNG,
//   'uttarakhand jal sansthan': UJSBPNG,
//   'ujjain nagar nigam': UNNPNG,
//   'vasai virar municipal corporation': VasaiPNG,
//   'vatva industrial estate infrastructure': VatvaPNG,
//   'vijaypura water board': VijaypuraWaterPNG,
//   'haryana urban development authority': WaterHaryanaPNG,
//   'kerala water authority (kwa)': WaterKeralaPNG,
// }
// const keys = Object.keys(logos).sort((a, b) => b.length - a.length)

// Action creators are generated for each case reducer function
export const { loadOperators, loadCircles, loadBundledOperators } =
  servicesSlice.actions

export const serviceActions = servicesSlice.actions
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.augmont.value)`
export const selectServices = (state) => state.services

export default servicesSlice.reducer
