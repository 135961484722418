import React from 'react'
import { selectFixerra } from 'features/fixerra/fixerraSlice'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'
import classNames from 'classnames'
import { formattedPrice, statusClass } from 'utils/helper'
import dayjs from 'dayjs'

function FixerraFDTable({ limit = 0 }) {
  const { fds } = useSelector(selectFixerra)

  let filtered = []
  if (limit > 0) {
    filtered = fds?.items.filter((d, i) => i < limit)
  } else {
    filtered = fds?.items
  }

  return (
    <div>
      {filtered?.length > 0 && (
        <Table responsive hover className="align-middle text-truncate mb-5">
          <thead>
            <tr>
              {/* FD PARTNER NAME START DATE COMPLETION DATE AMOUNT INVESTED STATUS /ACTION */}
              <th className="text-center">FD Partner</th>
              <th className="text-center">Name</th>
              <th className="text-center">Start Date</th>
              <th className="text-center">Completion Date</th>
              <th className="text-center">Amount Invested</th>
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {(filtered || []).map((fd) => (
              <tr key={fd.id}>
                <td className="text-center text-primary">{fd.issuer}</td>
                <td className="text-center">{fd?.name}</td>
                <td className="text-center">
                  {dayjs(fd?.createdAt).format('DD-MM-YYYY')}
                </td>
                <td className="text-center">
                  {fd?.maturityAt ? dayjs(fd?.maturityAt).format('DD-MM-YYYY') : '...'}
                </td>
                <td className="text-center">
                  {formattedPrice(fd?.amount, 'INR')}
                </td>
                <td className="text-center">
                  {/* <span className="badge badge-pill badge-default"> */}
                  <span
                    className={classNames(
                      'badge',
                      statusClass('bg-soft-', fd?.status)
                    )}
                  >
                    {fd?.status}
                  </span>
                  {/* </span> */}
                </td>
                <td className="text-center cursor-pointer">
                  {fd?.total_interest_amount}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {/* <pre>{JSON.stringify(fds, null, 2)}</pre> */}
    </div>
  )
}

export default FixerraFDTable
