import React, { Fragment } from 'react'
import { Route } from 'react-router'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'
// import BBPSPrepaidForm from "../BBPSPrepaidForm"
import OperatorList from '../OperatorList'
import DTHForm from './DTHForm'
import usePageTitle from 'hooks/usePageTitle'

const service = 'dth'

const RechargeDTH = ({ match }) => {
  usePageTitle('DTH Recharge')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <Route path={`${match.url}`} exact>
          <OperatorList
            title="All DTH Providers"
            category={service}
            url={match.url}
            showBill={false}
            endpoint="rechargeV1"
          />
        </Route>
      </CSSTransitionGroup>
      <Route path={`${match.url}/:opcode`} exact>
        <DTHForm service={service} />
      </Route>
    </Fragment>
  )
}

export default RechargeDTH
