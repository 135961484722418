import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import LongTermPlannerForm from './PlannerForm'
import usePageTitle from 'hooks/usePageTitle'

const LongTermPlanner = () => {
  usePageTitle('Invest In Gold/Silver')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        {/* <pre style={{ width: '100%' }}>
          {JSON.stringify(goalsWithGiftCards, null, 2)}
        </pre> */}
        <LongTermPlannerForm />
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default LongTermPlanner
