import React, { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { setTitle } from 'utils/helper'

import { passwordLength, mediumRegex } from 'utils/validation'

import pattern from 'assets/utils/images/sidebar/pattern-img_dark.png'
// import bg1 from 'assets/utils/images/originals/city.jpg'
// import bg2 from 'assets/utils/images/originals/citydark.jpg'
// import bg3 from 'assets/utils/images/originals/citynights.jpg'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
}

const ResetPassword = ({ match }) => {
  setTitle('Reset Password')
  // const location = useLocation()
  const history = useHistory()
  const { resetPassword } = useAuth()
  // const [isTokenValid, setIsTokenValid] = useState(false)

  // useEffect(() => {
  //   console.log(match.params.token)
  //   verifyResetTokenAction({ token: match.params.token })
  //     .then((res) => {
  //       // setIsTokenValid(true)
  //       // toast.success(res.message)
  //     }).catch((error) => {
  //       // history.push('/auth/login')
  //       toast.error(error)
  //     })
  // }, [match.params.token])

  const schema = yup.object().shape({
    sNewPassword: yup
      .string()
      .required('New Password is required')
      .matches(
        mediumRegex,
        'Password should one lowercase one uppercase one number and one special character'
      )
      .min(
        passwordLength,
        `Password should at least ${passwordLength} character`
      ),
    sNewRetypedPassword: yup
      .string()
      .required('Retype Password is required')
      .test(
        'match',
        'Password and retype Password does not match',
        (value) => value === document.querySelector('#sNewPassword').value
      ),
  })

  const onSubmit = (data) => {
    resetPassword(data.sNewPassword, match.params.token).then((res) => {
      setTimeout(() => {
        history.push('/auth/login')
      }, 1000)
    })
  }

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-10"
                    style={{
                      backgroundImage: 'url(' + pattern + ')',
                    }}
                  />
                </div>
                {/* <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg1 + ')',
                    }}
                  />
                  <div className="slider-content">
                    <h3>Perfect Balance</h3>
                    <p>
                      ArchitectUI is like a dream. Some think it's too good to
                      be true! Extensive collection of unified React Bootstrap
                      Components and Elements.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg3 + ')',
                    }}
                  />
                  <div className="slider-content">
                    <h3>Scalable, Modular, Consistent</h3>
                    <p>
                      Easily exclude the components you don't require.
                      Lightweight, consistent Bootstrap based styles across all
                      elements and components
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: 'url(' + bg2 + ')',
                    }}
                  />
                  <div className="slider-content">
                    <h3>Complex, but lightweight</h3>
                    <p>
                      We've included a lot of components that cover almost all
                      use cases for any type of application.
                    </p>
                  </div>
                </div> */}
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="10" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>Reset Password</div>
                <span>Reset your password.</span>
              </h4>
              <div>
                <Form
                  onFinish={onSubmit}
                  className="login100-form validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onTouched" // onBlur
                >
                  <Row form>
                    <Col md="6">
                      <Input.Password
                        label="New Password"
                        id="sNewPassword"
                        name="sNewPassword"
                        placeholder="Enter New Password"
                        required
                        // rules={{
                        //   required: 'Email is required',
                        // }}
                      />
                    </Col>
                    <Col md="6">
                      <Input.Password
                        label="New Password"
                        id="sNewRetypedPassword"
                        name="sNewRetypedPassword"
                        placeholder="Retype New Password"
                        required
                        // rules={{
                        //   required: 'Email is required',
                        // }}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex align-items-center">
                    <h6 className="mb-0">
                      <Link to="/auth/login" className="text-primary">
                        Sign in existing account
                      </Link>
                    </h6>
                    <div className="ml-auto">
                      <Submit className="btn btn-primary btn-lg">
                        Update Password
                      </Submit>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default ResetPassword
