import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectUi, uiActions } from 'features/uiSlice'
// import { AiOutlineCheckCircle } from 'react-icons/ai'
import './style.css'

const SuccessModal = () => {
  const history = useHistory()
  const { successModal } = useSelector(selectUi)
  const dispatch = useDispatch()

  const onConfirm = () => {
    // const encryptedPin = encryptPin(pin)
    toggle()
  }

  const toggle = () => {
    dispatch(uiActions.toggleSuccessModal())
    if (successModal?.redirectUrl) {
      history.push(successModal.redirectUrl)
    }
  }

  return (
    <Modal
      isOpen={successModal.open}
      toggle={toggle}
      centered
      size="md"
      className="shadow-none"
    >
      {/* <ModalHeader toggle={toggle}>
        <i className="pe-7s-check" /> Success
      </ModalHeader> */}
      {true && (
        <ModalBody className="text-center">
          {/* <AiOutlineCheckCircle size="6em" color="green" /> */}
          <div className="success-animation">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
          <h4 className="m-3">{successModal.title}</h4>
          <div className="pt-3 pb-4">{successModal.description}</div>
          {successModal?.redirectUrl && (
            <button className="btn btn-block btn-success" onClick={onConfirm}>
              {successModal?.buttonText ?? 'Go Back To Dashboard'}
            </button>
          )}
        </ModalBody>
      )}
    </Modal>
  )
}

export default SuccessModal
