import React, { useEffect, useState } from "react"
import classnames from "classnames"

import {
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
} from "reactstrap"
// import PerfectScrollbar from "react-perfect-scrollbar"
import { FaRupeeSign, FaMobileAlt } from "react-icons/fa"

const PlanDetails = ({ data, selectAmount, service }) => {
  const [activeCat, setActiveCat] = useState("")
  let path = `https://cdn.kashewnut.com/operators/${service?.toLowerCase()}`
  useEffect(() => {
    if (data) {
      if (data.plans && data.plans[0]) {
        setActiveCat(data.plans[0].cat)
      }
    }
  }, [data])

  const toggle = (cat) => {
    if (activeCat !== cat) {
      setActiveCat(cat)
    }
  }

  return (
    <Card className="mb-3">
      <CardHeader className="card-header-tab">
        <div className="card-header-title">
          <FaMobileAlt size="24" className="mr-2" />
          {/* <i className="header-icon lnr-license icon-gradient bg-plum-plate"> {" "} </i> */}
          {data?.title || 'Prepaid Plans'}
        </div>
        <Nav>
          {data &&
            data?.plans &&
            data.plans.map((plan) => (
              <NavItem key={plan.cat}>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeCat === plan.cat,
                  })}
                  onClick={() => {
                    toggle(plan.cat)
                  }}
                >
                  {plan.title}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
      </CardHeader>
      <CardBody>
        {data &&
          data?.plans &&
          data.plans.map(
            (plan, i) =>
              plan.cat === activeCat && (
                // <PerfectScrollbar>
                <ListGroup className="rm-list-borders" flush key={plan.cat}>
                  {plan.items.map((item, i) => (
                    <ListGroupItem
                      onClick={() => {
                        selectAmount(item.rs)
                      }}
                      className="cursor-pointer"
                      key={i}
                    >
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper align-items-start d-flex">
                          <div className="widget-content-left mr-3">
                            {data.logo ? (
                              <img
                                width={42}
                                className="rounded-circle"
                                src={`${path}/${data.logo}`}
                                alt=""
                              />
                            ) : (
                              <FaMobileAlt size="42" />
                            )}
                          </div>
                          <div className="widget-content-left">
                            <div className="widget-heading">
                              {data.operator}
                            </div>
                            <div className="widget-subheading font-weight-bold">
                              Validity: {item.validity ? item.validity : 'N/A'}
                            </div>
                            <small>{item?.desc}</small>
                            <small className="d-block">
                              Circle: {data?.circle || 'NA'}
                            </small>
                            <small className="text-muted">
                              Type: {item?.type || 'NA'}
                            </small>
                          </div>
                          <div className="widget-content-right">
                            <div className="font-size-xlg text-muted">
                              {/* <CountUp start={0} end={129} separator="" decimals={0} decimal="." prefix="" duration="10" /> */}
                              <small className="text-success pl-2 d-flex align-items-center">
                                <FaRupeeSign size="14" />
                                <span>{item?.rs}</span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <small className="widget-subheading text-muted">
                      {item?.desc}
                    </small>
                  </div>
                  <small className="widget-subheading d-block">
                    Circle: {data?.circle || "NA"}
                  </small>
                  <small className="widget-subheading text-muted">
                    Type: {item?.type || "NA"}
                  </small>
                </div> */}
                    </ListGroupItem>
                  ))}
                </ListGroup>
                // </PerfectScrollbar>
              )
          )}
      </CardBody>
      {/* <CardFooter className="d-block text-right">
        <Button className="btn-wide btn-shadow" color="danger">
          Delete
        </Button>
      </CardFooter> */}
    </Card>
  )
}

export default PlanDetails
