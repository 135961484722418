import { s3GetUrl } from 'config'
import React from 'react'
import { getAverageRGB } from 'utils/constants'

const  FdCard = ({ id,name,brandLogo, cdnImg, description }) => {
  return (
    <div
      className={`d-flex flex-column sip-cards  card-${id}`}
      // style={{ backgroundColor: 'rgba(94, 78, 13, 0.2)' }}
      title={name}
    >
      <div className="d-flex align-item-center justify-content-center custom-image-holder">
        <img
          alt=""
          src={brandLogo || s3GetUrl(cdnImg.goals)}
          onLoad={getAverageRGB}
          productId={id}
          crossOrigin="anonymous"
          style={{ maxWidth: '150px', maxHeight: '50px', alignSelf: 'center' }}
        />
      </div>
      <div className="sip-card-body">
        <div className="brandStrokeContainer">
          <div className={`brandStroke brandStroke-${id}`}></div>
        </div>
        <div
          className={`font-weight-bold overflow-hidden font-poppins-md text-center align-items-center mt-2 brandText-${id}`}
          // style={{ color: 'rgb(94, 78, 13)' }}
          id={id}
        >
          {description}
        </div>
      </div>
    </div>
  )
}

export default FdCard
