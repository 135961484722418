import { accountActions } from 'features/accountSlice'
import { queueActions } from 'features/queueSlice'
import { serviceActions } from 'features/servicesSlice'
import { useDispatch } from 'react-redux'
import { useNest } from './useNest'

function useMunicipality() {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const getOperators = () => {
    let config = { loader: true, msg: '' }
    backend()
      .get('/municipality/operators', config)
      .then(({ data }) => {
        dispatch(serviceActions.loadMunicipalityOperators(data))
      })
  }

  const fetchBill = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/municipality/fetchDetails', payload, config)
        .then((response) => {
          return resolve(response)
        })
    })

  const payBill = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Recharge success.' },
      }
      backend()
        .post('/municipality/payBill', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          // dispatch(
          //   queueActions.queueAdd({
          //     id: data.recharge_id,
          //     type: 'BBPS',
          //   })
          // ) // it is used to check status of transaction
          return resolve(data)
        })
    })

  const checkStatus = (referenceId) =>
    new Promise((resolve) => {
      backend()
        .get('/municipality/status', { referenceId })
        .then((response) => {
          dispatch(queueActions.queueRemove({ id: referenceId })) // it is used to check status of transaction
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(response.data)
        })
    })

  return {
    getOperators,
    fetchBill,
    payBill,
    checkStatus,
  }
}

export { useMunicipality }
