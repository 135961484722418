import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'

import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  selectThemeOptions,
  setEnableMobileMenu,
} from 'features/ThemeOptions'
import MainLogo from '../../assets/img/logo_primary.png'
import ShortLogo from '../../assets/img/short_logo.png'

import Nav from 'Layout/AppNav/VerticalNavWrapper'

const AppSidebar = () => {
  const {
    enableSidebarShadow,
    enableMobileMenu,
    backgroundColor,
    enableClosedSidebar,
  } = useSelector(selectThemeOptions)
  const dispatch = useDispatch()
  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu))
  }

  if (window.location.href.search('auth/') !== -1) {
    return null
  }

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <CSSTransitionGroup
        component="div"
        className={cx('app-sidebar', backgroundColor, {
          'sidebar-shadow': enableSidebarShadow,
        })}
        classNames="SidebarAnimation"
        appear={true}
        timeout={{ appear: 1500 }}
        enter={false}
        exit={false}
      >
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <div className="logo-height">
              {!enableClosedSidebar ? (
                <div className="primary-logo-container logo-container">
                  <img className="primary-logo" src={MainLogo} alt="Logo" />
                </div>
              ) : (
                <div className="short-logo-container logo-container">
                  <img className="short-logo" src={ShortLogo} alt="Logo" />
                </div>
              )}
            </div>
            <Nav />
          </div>
        </PerfectScrollbar>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default AppSidebar
