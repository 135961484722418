// import { goalsActions } from 'features/giftCardSlice'
import { objectToString } from 'utils/object'
import { errMsg, useNest } from './useNest'
// import { useDispatch } from 'react-redux'

export function usePhonePay() {
  const { backend } = useNest()
  // const dispatch = useDispatch()

  const pay = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing Payment...' },
      }
      backend()
        .post('phonepe/pay', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          if (res.success) {
            var url = res.data.instrumentResponse.redirectInfo.url
            var height = 720
            var width = 400
            var top = window.screen.height / 2 - height / 2
            var left = window.screen.width / 2 - width / 2
            const windowFeatures = {
              // popup: 'yes',
              titlebar: 0,
              menubar: 0,
              toolbar: 0,
              resizable: 0,
              location: 0,
              scrollbars: 'yes',
              height: height,
              width: width,
              left: left,
              top: top,
            }
            window.open(url, 'pay', objectToString(windowFeatures))
          }
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const addMoney = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing Payment...' },
      }
      backend()
        // .post('phonepe/pay', payload.provider, config)
        .post('phonepe/addMoney', payload, config)
        .then((res) => {
          if (res.success) {
            var url = res.data.instrumentResponse.redirectInfo.url
            var height = 720
            var width = 400
            var top = window.screen.height / 2 - height / 2
            var left = window.screen.width / 2 - width / 2
            const windowFeatures = {
              // popup: 'yes',
              titlebar: 0,
              menubar: 0,
              toolbar: 0,
              resizable: 0,
              location: 0,
              scrollbars: 'yes',
              height: height,
              width: width,
              left: left,
              top: top,
            }
            window.open(url, 'pay', objectToString(windowFeatures))
          }
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const refund = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Generating Payment Intent...' },
      }
      backend()
        .post('phonepe/refund', payload, config)
        .then((res) => {
          // dispatch(goalsActions.loadSingleGiftCard(res))
          return resolve(res)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  return {
    pay,
    addMoney,
    refund,
  }
}
