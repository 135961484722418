import { nest } from 'backend'
import { useEffect, useState } from 'react'

const useFetch = (url) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  const fetchData = async () => {
    setLoading(true)

    try {
      const response = await nest.get(url, null)
      setData(response)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    return () => {
      setError(null)
      setData(null)
      setLoading(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { data, loading, error, fetchData }
}

export default useFetch
