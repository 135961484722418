import UpdatePinModal from './updatePinModal'
import ValidatePinModal from './validatePinModal'

const pinLength = parseInt(process.env.REACT_APP_PIN_LENGTH) || 4

export {
  UpdatePinModal,
  ValidatePinModal,
  pinLength,
}
