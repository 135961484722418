import CustomTable from 'Components/Table/CustomTable'
import useFetch from 'backend/useFetch'
import dayjs from 'dayjs'
import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import LoadingGif from 'assets/components/react-widgets/img/loading.gif'
import usePageTitle from 'hooks/usePageTitle'
import { formattedPrice } from "utils/helper"

const MyGoalDetails = () => {
  const { goalId } = useParams()

  usePageTitle('Goal Investment History')

  const history = useHistory()

  const { data, loading } = useFetch(`/investment/goal-details/${goalId}`)

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '100vh', width: '100%' }}
      >
        <img src={LoadingGif} alt="Loading..." />
      </div>
    )
  }

  if (!goalId) return null

  const goalDetails = data?.goalDetails

  if (!goalDetails) return null

  const tableCols = [
    {
      key: 'date',
      title: 'DATE',
      className: 'text-center',
      render: (data) => <>{dayjs(data.createdAt).format("DD-MM-YYYY")}</>,
    },
    {
      key: 'amount',
      title: 'AMOUNT',
      render: (data) => <>{data.amount}</>,
    },
    {
      key: 'currency',
      title: 'CURRENCY',
      render: (data) => <>{data.currency}</>,
    },
    {
      key: 'balance',
      title: 'BALANCE',
      render: (data) => (
        <span
          className={`badge ${
            data.status === 'Paid'
              ? 'bg-soft-success text-success'
              : data.status === 'Pending'
              ? 'bg-soft-warning text-warning'
              : ''
          }`}
        >
          {data.status}
        </span>
      ),
    },
  ]

  return (
    <>
      <Card className="my-4 mx-auto" style={{ maxWidth: '100%' }}>
        <CardHeader className="custom-card-header height-auto pt-4 pb-3">
          <div className="custom-table-header">
            <div className="table-title goal-investment-header">
              <div>
                <div className="d-flex align-items-center">
                  {goalDetails.name}
                  <span
                    className={`badge ml-3 ${
                      goalDetails.status === 'Active'
                        ? 'bg-soft-success text-success border-success'
                        : goalDetails.status === 'Pending'
                        ? 'bg-soft-warning text-warning'
                        : ''
                    }`}
                  >
                    {goalDetails.status}
                  </span>
                </div>
                {/* <small>{goalDetails.description}</small> */}
              </div>
              <button
                onClick={() => history.goBack()}
                className="btn btn-secondary back-button"
              >
                <FaArrowLeft className="mr-2" />
                <span>Back</span>
              </button>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Amount:</strong> {formattedPrice(goalDetails.amount)}
            </Col>
            <Col md={4} xs={12}>
              <strong>Amount To Be Returned:</strong>{' '}
              {formattedPrice(goalDetails.amountReturned)}
            </Col>
            <Col md={4} xs={12}>
              <strong>Type:</strong> {goalDetails.type}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Start Date:</strong>{' '}
              {dayjs(goalDetails.startDate).format('DD-MM-YYYY')}
            </Col>
            <Col md={4} xs={12}>
              <strong>End Date:</strong>{' '}
              {dayjs(goalDetails.endDate).format('DD-MM-YYYY')}
            </Col>
            <Col md={4} xs={12}>
              <strong>Partner:</strong> {goalDetails?.partner}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4} xs={12}>
              <strong>Brand:</strong> {goalDetails.brand}
            </Col>
            {goalDetails.type === 'SIP' ? (
              <>
                <Col md={4} xs={12}>
                  <strong>Duration:</strong> {goalDetails.duration} months
                </Col>
                <Col md={4} xs={12}>
                  <strong>Frequency:</strong> {goalDetails.frequency}
                </Col>
              </>
            ) : null}
          </Row>
          {goalDetails.type === 'SIP' ? (
            <Row className="mb-3">
              <Col md={4} xs={12}>
                <strong>Total Premium:</strong> {goalDetails.premiumTotal}
              </Col>
              <Col md={4} xs={12}>
                <strong>Premium Paid:</strong> {goalDetails.premiumPaid}
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>

      <CustomTable
        data={data?.transactionList ?? []}
        cols={tableCols}
        tableName="Payment transaction"
      />
    </>
  )
}

export default MyGoalDetails
