import React, { useEffect, useState } from 'react'
import voucherImg from 'assets/img/small-1.jpg'
import { Table } from 'reactstrap'
import StatementTable from 'Components/Dashboard/StatementTable'
import dayjs from 'dayjs'
import { useGiftCard } from 'backend/useGiftCard'
import { usePin } from 'context/PinContext'
import { useParams } from 'react-router-dom'
import { formattedPrice } from 'utils/helper'
import CountUp from 'react-countup'
import { FaEye } from 'react-icons/fa'

const ShowVoucherTransactions = (props) => {
  const [transactions, setTransactions] = useState()
  const [cardsData, setCardsData] = useState([])
  const { checkPin } = usePin()
  const { orderId } = useParams()
  const {
    getQwikCilverActivatedCardByOrderId,
    getQwikCilverActivatedCardPinByOrderId,
    getCardTransactionsByOrderId,
    // getCardImageBySku,
  } = useGiftCard()

  const validatePin = (validated) => {
    // toggleIsPinMasked()
    checkPin('', toggleIsPinMasked, 'To show voucher pin')
  }

  const toggleIsPinMasked = () => {
    getQwikCilverActivatedCardPinByOrderId(orderId)
      .then((res) => {
        if (res.cards?.length > 0) {
          // setCardData(res.cards[0])
          setCardsData(res.cards)
          // setProduct(res.products[sku])
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getQwikCilverActivatedCardByOrderId(orderId)
      .then((res) => {
        if (res.cards?.length > 0) {
          // setCardData(res.cards[0])
          setCardsData(res.cards)
          // setProduct(res.products[sku])
        }
        console.log(res, 'resp')
      })
      .catch((err) => {
        console.log('err', err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    getCardTransactionsByOrderId(orderId)
      .then((res) => {
        if (res.cards?.length && res.cards[0]?.Transactions?.length) {
          setTransactions(res.cards[0].Transactions)
        }
        console.log('getCardTransactionsByOrderId', res)
      })
      .catch((err) => {
        console.log('err', err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  // useEffect(() => {
  //   // if (cardsData.length > 0) {
  //   //   cardsData.forEach((card, i) => {
  //   //     const payload = {
  //   //       cards: [
  //   //         {
  //   //           cardNumber: card?.cardNumber,
  //   //           pin: card?.cardPin,
  //   //           sku: card?.sku,
  //   //         },
  //   //       ],
  //   //     }
  //   //     getCardTransactionsByCardDetails(payload)
  //   //       .then((res) => {
  //   //         if (res.cards?.length && res.cards[i]?.Transactions?.length) {
  //   //           // setCardData2(res.cards[0])
  //   //           setTransactions(res.cards[i].Transactions)
  //   //         }
  //   //         console.log(res, 'resp')
  //   //       })
  //   //       .catch((err) => {
  //   //         console.log('err', err)
  //   //       })
  //   //   })
  //   // }
  //   // if (cardData?.cardNumber) {
  //   //   const payload = {
  //   //     cards: [
  //   //       {
  //   //         cardNumber: cardData?.cardNumber,
  //   //         pin: cardData?.cardPin,
  //   //         sku: cardData?.sku,
  //   //       },
  //   //       // {
  //   //       //   cardNumber: '8090920032765874',
  //   //       //   pin: 'WH6TSW8ABXCK5R6A',
  //   //       //   sku: 'CLAIMCODE',
  //   //       // },
  //   //     ],
  //   //   }
  //   //   getCardTransactionsByCardDetails(payload)
  //   //     .then((res) => {
  //   //       if (res.cards?.length && res.cards[0]?.Transactions?.length) {
  //   //         setCardData2(res.cards[0])
  //   //         setTransactions(res.cards[0].Transactions)
  //   //       }
  //   //       console.log(res, 'resp')
  //   //     })
  //   //     .catch((err) => {
  //   //       console.log('err', err)
  //   //     })
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cardsData])

  return (
    <div>
      {cardsData.length > 0 &&
        cardsData.map((card) => (
          <div
            className="d-flex"
            style={{
              background: 'white',
              padding: '2rem',
            }}
          >
            <div className="d-flex flex-column align-items-end">
              <img
                // src={getCardImageBySku(card?.sku)}
                src={voucherImg}
                className="mx-2"
                alt={card?.productName}
              />
              {/* {card?.cardPin && (
                <div
                  className="d-flex m-1 mx-3 align-items-center"
                  style={{ justifyContent: 'space-between' }}
                >
                  <small className="m-1">
                  {isPinMasked
                    ? card?.cardPin.replace(/\w/g, '*')
                    : card?.cardPin}
                </small>
                  {card?.cardPinEncrypted && (
                    <Button color="primary" onClick={validatePin}>
                      Show {card?.labels?.cardPin || 'Card Pin'}
                    </Button>
                  )}
                </div>
              )} */}
            </div>
            <div style={{ width: '40%' }} className="mx-2">
              <Table className="gift-card-details">
                <tbody>
                  <tr>
                    <td>Card</td>
                    <td className="font-weight-bolder">{card?.productName}</td>
                  </tr>
                  <tr>
                    <td>{card?.labels?.cardNumber || 'Card Number'}</td>
                    <td className="font-weight-bold">{card?.cardNumber}</td>
                  </tr>
                  {card?.cardPin && (
                    <tr>
                      <td>{card?.labels?.cardPin || 'Card Pin'}</td>
                      <td className="font-weight-bold d-flex align-items-middle">
                        <div className="pr-4">{card?.cardPin}</div>
                        {card?.cardPinEncrypted && (
                          <FaEye
                            className="cursor-pointer text-primary"
                            color="primary"
                            onClick={validatePin}
                            title={`Show ${
                              card?.labels?.cardPin || 'Card Pin'
                            }`}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                  {card?.activationUrl && (
                    <tr>
                      <td>{card?.labels?.activationUrl || 'Activation Url'}</td>
                      <td className="font-weight-bold">
                        <a
                          href={card?.activationUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      </td>
                    </tr>
                  )}
                  {card?.activationCode && (
                    <tr>
                      <td>
                        {card?.labels?.activationCode || 'Activation Code'}
                      </td>
                      <td className="font-weight-bold">{card?.cardPin}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Amount</td>
                    <td className="font-weight-bold">
                      <CountUp
                        key={'completion-bonus'}
                        start={0}
                        end={card?.amount ?? 0}
                        separator=","
                        decimals={2}
                        decimal="."
                        prefix="₹"
                        useEasing={true}
                        suffix=""
                        duration="1"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{card?.labels?.validity || 'Valid till'}</td>
                    <td className="font-weight-bold">
                      {dayjs(card?.validity).format('DD-MMM-yyyy')}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {false && (
                <>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      Card:
                    </span>{' '}
                    {card?.productName}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      {card?.labels?.cardNumber || 'Card Number'}:
                    </span>{' '}
                    {card?.cardNumber}
                  </div>
                  {card?.cardPin && (
                    <div>
                      <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                        {card?.labels?.cardPin || 'Card Pin'}:
                      </span>{' '}
                      {/* {isPinMasked
                  ? card?.cardPin.replace(/\w/g, '*')
                  : card?.cardPin} */}
                      {card?.cardPin}
                    </div>
                  )}
                  {card?.activationUrl && (
                    <div>
                      <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                        {card?.labels?.activationUrl || 'Activation Url'}:
                      </span>{' '}
                      <a
                        href={card?.activationUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click Here
                      </a>
                    </div>
                  )}
                  {card?.activationCode && (
                    <div>
                      <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                        {card?.labels?.activationCode || 'Activation Code'}:
                      </span>{' '}
                      {card?.activationCode}
                    </div>
                  )}
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      Amount:{' '}
                    </span>
                    <b>{formattedPrice(card?.amount)}</b>
                  </div>
                  {/* <div>
                <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                  Balance:{' '}
                </span>
                {formattedPrice(cardData2?.balance)}
              </div> */}
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      {card?.labels?.validity || 'Valid till'}:
                    </span>
                    {dayjs(card?.validity).format('DD-MMM-yyyy')}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      <div className="mt-4">
        <h4>Transactions</h4>
        <StatementTable
          currency={'INR'}
          isGiftCard={true}
          data={transactions}
        />
      </div>
    </div>
  )
}

export default ShowVoucherTransactions
