import React, { Fragment, useEffect } from 'react'
import { useGiftCard } from 'backend/useGiftCard'
import { useSelector } from 'react-redux'
import { selectGoals } from 'features/giftCardSlice'
import { useState } from 'react'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import { FaSearch } from 'react-icons/fa'
import usePageTitle from 'hooks/usePageTitle'
import BrandCardContainer from 'assets/components/custom-card/BrandCardContainer'

const Goals = () => {
  usePageTitle('Brand Voucher')
  const { getGoalsWithGiftCards } = useGiftCard()
  const { goalsWithGiftCards } = useSelector(selectGoals)
  const [list, setList] = useState(goalsWithGiftCards)

  useEffect(() => {
    setList(goalsWithGiftCards)
  }, [goalsWithGiftCards])

  useEffect(() => {
    getGoalsWithGiftCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (e) => {
    const myArray = goalsWithGiftCards
    setList(
      myArray
        .map((goals) => {
          const giftCards = goals.giftCards.filter((card) =>
            new RegExp(e.target.value, 'i').test(card.name)
          )
          if (giftCards.length) {
            return { ...goals, giftCards }
          }
          return null
        })
        .filter((p) => p)
    )
  }
  return (
    <Fragment>
      {/* <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      > */}
      <Row className="align-items-center mt-3">
        <Col md="9">
          {/* <h5 className="font-weight-bold mb-3">Title</h5> */}
        </Col>
        <Col md="3">
          <FormGroup>
            <InputGroup>
              <input
                type="text"
                className="form-control"
                onChange={handleSearch}
                placeholder="Type to Search..."
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      {list.length > 0 &&
        list.map((el, i) => (
          <BrandCardContainer data={el} cardFor="voucher" key={el.id} />
        ))}
    </Fragment>
  )
}

export default Goals
