import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { useSelector } from 'react-redux'
import { Form, Input, Select } from 'antd'
import { useBank } from 'backend/useBank'
import { selectAccount } from 'features/accountSlice'
import { ifscRegex } from 'utils/validation'
import { selectAuth } from 'features/auth/authSlice'

const BankDetailsForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { bankAccount } = useSelector(selectAccount)
  const { user } = useSelector(selectAuth)
  const { bankVerification, updateBankAccount, searchByISFC } = useBank()

  const onSubmit = async (data) => {
    if (
      bankAccount?.accountNumber !== data.accountNumber &&
      bankAccount?.ifsc !== data.ifsc
    ) {
      const resData = await bankVerification({
        accountNumber: data.accountNumber,
        ifsc: data.ifsc,
      })
      if (resData.account_exists) {
        updateBank({
          accountNumber: data.accountNumber,
          accountName: data.accountName,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          ifsc: data.ifsc,
          accountType: data.accountType,
          bankName: data.bankName,
        })
      }
    } else {
      updateBank({
        accountNumber: data.accountNumber,
        accountName: data.accountName,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        ifsc: data.ifsc,
        accountType: data.accountType,
        bankName: data.bankName,
      })
    }
  }

  const validateIfsc = async (e) => {
    const { value } = e.target
    console.log(value)
    if (value.length === 11) {
      return searchByISFC(value).then((res) => {
        if (res.status === 'valid') {
          form.setFieldsValue({
            bankName: res.data.bank,
          })
        }
        console.log('res', res)
      })
    }
  }

  const updateBank = (data) => {
    updateBankAccount(data).then(() => {
      setTimeout(() => {
        history.push('/account-setup/nominee')
      }, 300)
    })
  }

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <h3>Bank Details</h3>
          {/* <pre>{JSON.stringify(bankAccount, null, 2)}</pre> */}
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            className="validate-form was-validate"
            initialValues={
              bankAccount.firstName
                ? {
                    ...bankAccount,
                  }
                : {
                    firstName: user?.firstName,
                    middleName: user?.middleName,
                    lastName: user?.lastName,
                    ...bankAccount,
                  }
            }
          >
            <Row>
              <Col md="4">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'First Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Your First Name"
                  />
                </Form.Item>
              </Col>
              <Col md="4">
                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  rules={[
                    {
                      required: true,
                      message: 'Middle Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Your Middle Name"
                  />
                </Form.Item>
              </Col>
              <Col md="4">
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Last Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Your Last Name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  label="Your Name as per Bank"
                  name="accountName"
                  rules={[
                    {
                      required: true,
                      message: 'Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Your Name as per Bank"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  label="Bank Account Number"
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Account Number is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Bank Account Number"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Bank Account Type"
                  name="accountType"
                  rules={[
                    {
                      required: true,
                      message: 'Bank Account Type is required',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Account Type"
                    options={[
                      { label: 'Savings', value: 'Savings' },
                      { label: 'Current', value: 'Current' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Item
                  label="Bank IFSC Code"
                  name="ifsc"
                  rules={[
                    {
                      required: true,
                      message: 'Bank IFSC Code is required',
                    },
                    {
                      pattern: ifscRegex,
                      message: 'Please enter valid IFSC',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="IFSC Code"
                    onChange={validateIfsc}
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Bank Name"
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: 'Bank Name is required',
                    },
                  ]}
                >
                  <Input
                    className="form-control"
                    placeholder="Your Bank Name"
                    // value={formData?.bankName}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="d-flex justify-content-end">
                <Form.Item>
                  <button
                    type="submit"
                    className="mt-3 btn btn-primary btn-shadow"
                  >
                    Verify/Update
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}

export default BankDetailsForm
