import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'
import GoalCard from './GoalCard'
import { useSelector } from 'react-redux'
import { selectAugmont } from 'features/augmont/augmontSlice'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import InsufficientBalanceCard from './InsufficientBalanceCard'
import PlannerModel from './PlannerModel'

const SCROLL_VALUE = 600

const checkIsEnd = (container) =>
  Math.floor(container.scrollWidth - container.scrollLeft) <=
  container.offsetWidth

const GoalFDSchemes = () => {
  const [insufficientBalance, setInsufficientBalance] = useState(null)
  const [planGoalScheme, setPlanGoalScheme] = useState(null)

  const { augmontUser, passbook, fdSchemas } = useSelector(selectAugmont)

  const goalSchemes = fdSchemas.items.filter((itm) => itm?.status !== 'close')

  const containerRef = useRef(null)
  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(true)

  const slide = (shift) => {
    const container = containerRef.current
    const newPosition = container.scrollLeft + shift

    container.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    })

    setScrollX(newPosition)

    setScrollEnd(checkIsEnd(container))
  }

  const scrollCheck = useCallback(() => {
    const container = containerRef.current
    if (container) {
      setScrollX(container.scrollLeft)

      const isEnd = checkIsEnd(container)
      setScrollEnd(isEnd)

      return isEnd
    }
    return false
  }, [containerRef])

  useEffect(() => {
    if (!scrollCheck()) setScrollX(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickOnCard = (scheme) => {
    console.log(scheme)
    if (+scheme.minQtyLease < +(passbook.goldGrms ?? 1)) {
      // history.push(`/investment/goal/long/fd/${scheme.id}`)
      setPlanGoalScheme(scheme)
    } else if (insufficientBalance?.id !== scheme.id) {
      setInsufficientBalance({
        id: scheme.id,
        minQtyLease: scheme.minQtyLease,
      })
    } else {
      setInsufficientBalance(null)
    }
  }

  return augmontUser?.id ? (
    <>
      <Row className="align-items-center">
        <Col md="12">
          <h5 className="font-weight-bold mb-4">Explore our Gold FDs</h5>
        </Col>
      </Row>
      <div className="voucher-card-container">
        <div
          onClick={() => slide(-SCROLL_VALUE)}
          className={`left-arrow ${scrollX < 1 ? 'arrow-disabled' : ''}`}
        >
          <i className="pe-7s-angle-left bg-soft-primary text-primary arrow-container"></i>
        </div>
        <div
          className="voucher-cards"
          ref={containerRef}
          onScroll={scrollCheck}
        >
          <div className="d-flex card-item-container">
            {goalSchemes &&
              goalSchemes.map((el) => (
                <div
                  key={el.id}
                  className="cursor-pointer mr-6"
                  onClick={() => clickOnCard(el)}
                >
                  {insufficientBalance && insufficientBalance?.id === el.id ? (
                    <CSSTransitionGroup
                      component="div"
                      classNames="TabsAnimation"
                      appear={true}
                      timeout={{ appear: 500 }}
                      enter={true}
                      exit={true}
                    >
                      <InsufficientBalanceCard
                        id={insufficientBalance.id}
                        minQtyLease={insufficientBalance.minQtyLease}
                      />
                    </CSSTransitionGroup>
                  ) : (
                    <GoalCard goal={el} />
                  )}
                </div>
              ))}
          </div>
        </div>
        <div
          onClick={() => slide(+SCROLL_VALUE)}
          className={`right-arrow ${!scrollEnd ? '' : 'arrow-disabled'}`}
        >
          <i className="pe-7s-angle-right bg-soft-primary text-primary arrow-container"></i>
        </div>
      </div>
      {planGoalScheme?.id ? (
        <PlannerModel
          goalScheme={planGoalScheme}
          maxQuantity={Number(passbook.goldGrms ?? 1)}
          onCancel={() => setPlanGoalScheme(null)}
        />
      ) : null}
    </>
  ) : null
}

export default GoalFDSchemes
