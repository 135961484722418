import React, { Fragment, useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

// import Slider from 'react-slick'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { validAadhaarNumberRegex } from 'utils/validation'
import showLoader from 'Components/Loader'

// import bg3 from 'assets/utils/images/originals/pan-aadhaar-2.jpg'

import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'ReactstrapUi'
// import { authActions } from 'features/auth/authSlice'
import UserDetails from './UserDetails'
import { authActions, selectAuth } from 'features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import Timer from './timer'

// let settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   arrows: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   initialSlide: 0,
//   autoplay: true,
//   adaptiveHeight: true,
// }

const AadhaarKYC = () => {
  const history = useHistory()
  // const registerStep = useSelector(state => state.auth.registerStep)
  const dispatch = useDispatch()
  // const dispatch = useDispatch()
  const { kycPanValidate, kycAadhaarGenerateOTP, kycAadhaarValidate } =
    useAuth()
  const { user, registerStep } = useSelector(selectAuth)
  const [minutes, setMinutes] = useState(1)
  const [aadhaar, setAadhaar] = useState('')
  const [otpBlock, showOtpBlock] = useState(false)
  const [otpStatus, setOtpStatus] = useState('')
  const [aadhaarToken, setAadhaarToken] = useState('')
  // const [mobileExist, setMobileExist] = useState(false)

  const panNumber = registerStep?.pan?.panNumber || user?.pan
  const aadhaarNoMasked =
    registerStep?.pan?.maskedAadhaar || user?.aadhaarNoMasked

  if (!panNumber) {
    history.push(`/auth/signup`)
  }

  // const getLastFourDigits = (str, n = 4) => {
  //   if (str && str.length > 0) {
  //     return str.substring(str.length - n)
  //   }
  //   return 0
  // }

  useEffect(() => {
    if (otpBlock === true) {
      document.querySelector('#otp').focus()
    }
  }, [otpBlock])

  const schema = yup.object().shape({
    aadhaar: yup
      .string()
      .required('Aadhaar Card Number is required')
      .matches(
        validAadhaarNumberRegex,
        'Please enter valid Aadhaar Card Number, it should be 12 digits.'
      ),
    // .min(12, 'Aadhaar Card Number should 12 digits'),
    // .matches(validMobileRegex, 'Please enter valid aadhaar number')
    // .test(
    //   'aadhaarPanValidation',
    //   `Aadhaar number not ends with ${getLastFourDigits(aadhaarNoMasked)}`,
    //   // async (value) => (await fetch('/is-jimmy/' + value)).responseText === 'true'
    //   // async (value) => await isMobileExist(value) //.then(res => console.log(res))
    //   (value) => {
    //     return getLastFourDigits(aadhaarNoMasked) === getLastFourDigits(value)
    //   }
    // ),
    otpStatus: yup.string(),
    otp: yup.string().when('otpStatus', {
      is: 'sent',
      then: () => yup.string().required('OTP is required'),
      // .min(otp_length, `'OTP should at least ${otp_length} character`)
      // .matches(otp, 'OTP is not valid!'),
      otherwise: () => yup.string(),
    }),
  })

  const onSubmit = (data) => {
    console.log(data)
    if (data.otpStatus === 'sent') {
      validateOTP(data)
    } else {
      handleSendOTP(data)
    }
  }

  const handleSendOTP = async (data) => {
    await kycAadhaarGenerateOTP({ aadhaarNumber: data.aadhaar }).then(
      (resData) => {
        // toast.success('OTP sent to your Aadhaar Registered Mobile number.')
        if (resData.exist) {
          dispatch(authActions.signupStepAadhaarProfile(resData.data))
          showLoader(false)
          setTimeout(() => {
            history.push('/auth/pan/profile')
          }, 300)
        }
        if (resData.success) {
          if (resData.data?.otp_sent) {
            setAadhaar(data.aadhaar)
            setOtpStatus('sent')
            showOtpBlock(true)
          }
          setAadhaarToken(resData.data.client_id)
          return
        }
      }
    )
  }

  const handleReSendOTP = () => {
    // setAadhaar('')
    setOtpStatus('')
    showOtpBlock(false)
    setAadhaarToken()
    setMinutes(minutes + 1)
    handleSendOTP({ aadhaar: aadhaar })
  }

  const validateOTP = (data) => {
    showLoader(true)
    kycAadhaarValidate({
      // aadhaar: data.aadhaar,
      otp: data.otp,
      clientId: aadhaarToken,
    }).then((res) => {
      if (res.success) {
        dispatch(authActions.signupStepAadhaarProfile(res.data))
        if (!registerStep?.pan?.panNumber) {
          kycPanValidate({
            panNumber: panNumber,
          }).then((res) => {
            dispatch(authActions.signupStepPanProfile(res.data))
            showLoader(false)
            setTimeout(() => {
              history.push('/auth/pan/profile')
            }, 300)
            return
          })
          return
        } else {
          setTimeout(() => {
            showLoader(false)
            history.push('/auth/pan/profile')
          }, 300)
        }
      }
    })
  }

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col
            lg="7"
            md="12"
            className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
          >
            <Col lg="6" md="10" sm="12" className="mx-auto app-login-box">
              {/* <div className="app-logo" /> */}
              <h4>
                {/* <div>Welcome,</div> */}
                <span>
                  Complete Your Aadhaar{' '}
                  <span className="text-success">KYC</span>
                </span>
              </h4>
              <div>
                <Form
                  onFinish={onSubmit}
                  className="login100-form validate-form"
                  schemaResolver={yupResolver(schema)}
                  mode="onBlur" // onBlur
                  initialValues={{
                    aadhaar,
                    otpStatus,
                  }}
                >
                  <Row form>
                    <Col md="12">
                      <div className="text-muted text-sm mb-3">
                        Hint: Please enter aadhaar number something like this{' '}
                        <b>{aadhaarNoMasked}</b>
                      </div>
                      <Input.Text
                        label={`Aadhaar number`}
                        name="aadhaar"
                        required
                        // rules={{
                        //   required: 'This is required',
                        // }}
                        rules={{
                          onBlur: (e) => setAadhaar(e.target.value),
                        }}
                      />
                      <Input.Hidden
                        id="otpStatus"
                        name="otpStatus"
                        // value={otpStatus}
                      />
                    </Col>
                    {!otpBlock ? (
                      <Col md="12" className="d-flex justify-content-end">
                        <Submit className="btn custom-btn-animation h-37">
                          Submit
                        </Submit>
                      </Col>
                    ) : (
                      <Col md="12">
                        <Input.Text
                          label="OTP"
                          name="otp"
                          required
                          // rules={{
                          //   required: 'This is required',
                          // }}
                        />
                      </Col>
                    )}
                    {otpBlock && (
                      <Col
                        md="12"
                        className="d-flex justify-content-between align-items-center"
                      >
                        {/* <span ref={countDownRef}></span> */}
                        <Timer
                          initialMinute={minutes}
                          initialSeconds={30}
                          resend={handleReSendOTP}
                          resentText="Resend"
                        />
                        <Submit className="btn btn-primary btn-wide btn-pill btn-shadow btn-hover-shine">
                          Validate OTP
                        </Submit>
                      </Col>
                    )}
                  </Row>
                  {/* <div className="mt-4 d-flex align-items-center">
                    <h5 className="mb-0">
                      Already have an account?{" "}
                      <Link to="/auth/login" className="text-primary">Sign in</Link>
                    </h5>
                  </div> */}
                </Form>
              </div>
            </Col>
          </Col>
          <UserDetails />
        </Row>
      </div>
    </Fragment>
  )
}

export default AadhaarKYC
