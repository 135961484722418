import React from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { Route } from 'react-router-dom'

import PrivateRoutes from '../../routes/PrivateRoutes'
import Auth from '../../Pages/Auth'

import AppMain from '../../Layout/AppMain'
import { PinProvider } from 'context/PinContext'
import { selectThemeOptions } from 'features/ThemeOptions'
import SuccessModal from 'Components/SuccessModal'
import ReusableModal from 'Components/ReusableModal'

const Main = () => {
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = useSelector(selectThemeOptions)

  return (
    <PinProvider>
      <div
        className={cx(
          'app-container app-theme-' + colorScheme,
          { 'fixed-header': enableFixedHeader },
          { 'fixed-sidebar': enableFixedSidebar || window.innerWidth < 1250 },
          { 'fixed-footer': enableFixedFooter },
          { 'closed-sidebar': enableClosedSidebar || window.innerWidth < 1250 },
          {
            'closed-sidebar-mobile':
              closedSmallerSidebar || window.innerWidth < 1250,
          },
          { 'sidebar-mobile-open': enableMobileMenu },
          { 'body-tabs-shadow-btn': enablePageTabsAlt }
        )}
      >
        <Route path="/auth" component={Auth} />
        <PrivateRoutes path="/" component={AppMain} />
      </div>
      <SuccessModal />
      <ReusableModal />
    </PinProvider>
  )
}

export default Main
