import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import BrandCard from 'assets/components/custom-card/BrandCard'
import { Col, Row } from 'reactstrap'
import { cdnImg } from 'utils/constants'

const SCROLL_VALUE = 600

const checkIsEnd = (container) =>
  Math.floor(container.scrollWidth - container.scrollLeft) <=
  container.offsetWidth

const BrandCardContainer = ({ data, cardFor = 'goal' }) => {
  const history = useHistory()

  const containerRef = useRef(null)
  const [scrollX, setScrollX] = useState(0)
  const [scrollEnd, setScrollEnd] = useState(true)

  const slide = (shift) => {
    const container = containerRef.current
    const newPosition = container.scrollLeft + shift

    container.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    })

    setScrollX(newPosition)

    setScrollEnd(checkIsEnd(container))
  }

  const scrollCheck = useCallback(() => {
    const container = containerRef.current
    if (container) {
      setScrollX(container.scrollLeft)

      const isEnd = checkIsEnd(container)
      setScrollEnd(isEnd)

      return isEnd
    }
    return false
  }, [containerRef])

  useEffect(() => {
    if (!scrollCheck()) setScrollX(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    data.giftCards.length > 0 && (
      <Fragment>
        <Row className="align-items-center">
          <Col md="12">
            <h5 className="font-weight-bold mb-4">{data?.name}</h5>
          </Col>
        </Row>
        <div className="voucher-card-container">
          <div
            onClick={() => slide(-SCROLL_VALUE)}
            className={`left-arrow ${scrollX < 1 ? 'arrow-disabled' : ''}`}
          >
            <i className="pe-7s-angle-left bg-soft-primary text-primary arrow-container"></i>
          </div>
          <div
            className="voucher-cards"
            ref={containerRef}
            onScroll={scrollCheck}
          >
            <div className="d-flex card-item-container">
              {[...(data?.giftCards ? [...data.giftCards] : [])].map((el) => (
                <div
                  key={el.sku}
                  className="cursor-pointer mr-4"
                  onClick={() =>
                    history.push(
                      cardFor === 'goal'
                        ? `/investment/goal/short/${data?.id}/${el?.sku}`
                        : `/voucher/brands/${el?.sku}`
                    )
                  }
                >
                  <BrandCard brand={el} cdnImg={cdnImg} />
                </div>
              ))}
            </div>
          </div>
          <div
            onClick={() => slide(+SCROLL_VALUE)}
            className={`right-arrow ${!scrollEnd ? '' : 'arrow-disabled'}`}
          >
            <i className="pe-7s-angle-right bg-soft-primary text-primary arrow-container"></i>
          </div>
        </div>
      </Fragment>
    )
  )
}

export default BrandCardContainer
