import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
} from 'reactstrap'

import { useForm } from 'react-hook-form'

import {
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from '../../../utils/validation'

import SelectedGiftCard from './SelectedGiftCard'

const Form = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  // const params = new URLSearchParams(window ? window.location.search : {})
  const giftCards = useSelector((state) => state.services.giftCards)
  const [giftCard, setGiftCard] = useState()

  useEffect(() => {
    if (giftCards) {
      setGiftCard(giftCards.find((op) => op.ProductNo === opcode))
    }
  }, [giftCards, opcode, service])

  useEffect(() => {
    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, reset, formState } = useForm()

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      product_no: giftCard.ProductNo,
      quantity: 1,
      amount: data.amount,
      mode: 'online',
    }
    console.log('payload', payload)
    history.push({
      pathname: `/service/checkout`,
      state: {
        services: [
          {
            amount: data.amount,
            name: giftCard.ProductName,
            description: `Payment for ${giftCard.ProductName} gift card`,
            quantity: 1,
            service: 'e-gift',
          },
        ],
        provider: payload,
        meta: {
          bill: false,
          operator: { ...giftCard, endpoint: 'e-gift' },
        },
      },
    })
  }

  // const handleChangeInput = (name, value) => {
  //   setValue(name, value)
  //   trigger(name)
  // }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(giftCard) || isEmpty(giftCards)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{giftCard?.ProductName}</CardTitle>
              <SelectedGiftCard opcode={opcode} category={service} />
              {/* <pre>{JSON.stringify(params, null, 2)}</pre> */}
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  <Col lg="12">
                    <FormGroup className="select-wrapper">
                      <Label for="amount" className="required">
                        Denomination
                      </Label>
                      <select
                        id="amount"
                        name="amount"
                        className="form-control custom-select"
                        {...register('amount', {
                          required: `Please select Denomination`,
                        })}
                      >
                        <option value="">Select Denomination...</option>
                        {String(giftCard?.Denomination)
                          .split(',')
                          .sort((a, b) => a - b)
                          .map((denomination, i) => (
                            <option value={denomination} key={i}>
                              {denomination}
                            </option>
                          ))}
                      </select>
                      <InvalidFeedbackV7 name="amount" formState={formState} />
                    </FormGroup>
                  </Col>
                  {/* <Col lg="12">
                    <FormGroup>
                      <Label for={'amount'} className="required">
                        Denomination
                      </Label>
                      <input
                        type="text"
                        placeholder="Denomination"
                        id="amount"
                        name="amount"
                        defaultValue={params.get('d')}
                        className="form-control"
                        {...register('amount', {
                          required: `Denomination is required`,
                          pattern: {
                            value: new RegExp('^(0|[1-9][0-9]*)$'),
                            message: `Please enter valid Denomination`,
                          },
                        })}
                        onChange={(e) =>
                          handleChangeInput('amount', e.target.value)
                        }
                      />
                      <InvalidFeedbackV7
                        name={'amount'}
                        formState={formState}
                      />
                    </FormGroup>
                  </Col> */}
                  {/* <Col lg="12" key={field?.name}>
                    <FormGroup>
                      <Label for={field?.name} className="required">
                        {field.label}
                      </Label>
                      <input
                        type="text"
                        placeholder={field.placeholder}
                        id={field?.name}
                        name={field?.name}
                        className="form-control"
                        {...register(field?.name, {
                          required: `${field.label} is required`,
                          pattern: field.regex && {
                            value: new RegExp(field.regex),
                            message: `Please enter valid ${field.label}`,
                          },
                        })}
                        onChange={(e) =>
                          handleChangeInput(field?.name, e.target.value)
                        }
                      />
                      <InvalidFeedbackV7
                        name={field?.name}
                        formState={formState}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col lg="12" className="d-flex justify-content-end">
                    <FormGroup>
                      <Button
                        size="lg"
                        block
                        className="mt-3"
                        color="primary"
                        disabled={preventSubmit(formState)}
                      >
                        Proceed
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <strong>Description</strong>
          <div
            dangerouslySetInnerHTML={{ __html: giftCard.Description }}
            className="mb-2"
          />

          <strong>How to use?</strong>
          <div
            dangerouslySetInnerHTML={{ __html: giftCard.HowToUse }}
            className="mb-2"
          />

          <strong>Terms & Condition</strong>
          <div
            dangerouslySetInnerHTML={{ __html: giftCard.TermsCondition }}
            className="mb-2"
          />

          <strong>Validity</strong>
          <div
            dangerouslySetInnerHTML={{ __html: giftCard.Validity }}
            className="mb-2"
          />
          {/* {
            <pre style={{ width: '100%' }}>
              {JSON.stringify(giftCard, null, ' ')}
            </pre>
          } */}
        </Col>
      </Row>
    </Fragment>
  )
}

export default Form
