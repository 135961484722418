import { useEffect } from 'react'
import { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Tooltip,
} from 'reactstrap'

const CustomToolTip = ({ operator }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        className="mt-3 font-weight-bold overflow-hidden h-1"
        id={operator?.ProductNo}
      >
        {operator?.ProductName}
      </div>
      <Tooltip
        placement={'bottom'}
        isOpen={isOpen}
        target={operator?.ProductNo}
        toggle={(value) => {
          setIsOpen(!isOpen)
        }}
        // className="tooltip-light"
      >
        {operator?.ProductName}
      </Tooltip>
    </>
  )
}

// const CustomToolTip2 = ({ operator }) => {
//   return (
//     <>
//       <div
//         className="mt-3 font-weight-bold overflow-hidden h-1"
//         id={operator?.ProductNo}
//         title={operator?.ProductName}
//       >
//         {operator?.ProductName}
//       </div>
//     </>
//   )
// }

const GiftCardList = ({
  title,
  service,
  category = '',
  url,
  endpoint = '',
}) => {
  const [list, setList] = useState([])
  const [currentList, setCurrentListList] = useState([])
  const [total, setTotal] = useState(0)
  const history = useHistory()
  const giftCards = useSelector((state) => state.services.giftCards)

  useEffect(() => {
    const myArray = giftCards
    const tmpArray = endpoint
      ? myArray.filter((operator) => operator.endpoint === endpoint)
      : myArray
    setList(tmpArray)
    setCurrentListList(tmpArray)
    setTotal(tmpArray.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, service])

  const handleSearch = (e) => {
    const myArray = currentList
    setList(
      myArray.filter(
        (item) => new RegExp(e.target.value, 'i').test(item.ProductName)
        // new RegExp('^.*' + e.target.value + '.*', 'i').test(item.name)
      )
    )
  }

  return (
    <Row className="align-items-center">
      <Col md="9">
        <h5>{title || 'All Providers'}</h5>
      </Col>
      <Col md="3">
        {total > 15 && (
          <FormGroup>
            <InputGroup>
              <input
                type="text"
                className="form-control"
                onChange={handleSearch}
                placeholder="Type to Search..."
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        )}
      </Col>
      <Col md="12" className="mt-2">
        <Row>
          {/* {
            <pre style={{ width: '100%' }}>
              {JSON.stringify(giftCards, null, ' ')}
            </pre>
          } */}
          {list.map((card) => (
            <Col
              lg="3"
              key={card.ProductNo}
              className="cursor-pointer"
              onClick={() =>
                history.push(`${url}/${card?.ProductNo}`)
              }
            >
              <div className="bg-white text-center p-4 mb-4">
                {card?.ProductImage ? (
                  <img
                    src={card?.ProductImage}
                    alt=""
                    style={{
                      width: '64px',
                      height: '64px',
                      borderRadius: 50,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      minWidth: '64px',
                      minHeight: '64px',
                      borderRadius: 50,
                      // backgroundColor: card?.color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '1.5em',
                      fontWeight: '500',
                    }}
                  >
                    {card?.ProductName.charAt(0)}
                  </div>
                )}
                <CustomToolTip operator={card} />
                {/* {JSON.parse(card?.Denomination).map((denomination) => (
                      <span
                        className="cursor-pointer"
                        style={{
                          padding: '.25rem .5rem',
                          boxShadow:
                            '0 10px 10px -3px #0000001a,0 4px 6px -4px #0000001a',
                          border: '1px solid rgba(90, 90, 90, .3)',
                          borderRadius: '5px'
                        }}
                        onClick={() =>
                          history.push(
                            `${url}/${card?.ProductNo}?d=${denomination}`
                          )
                        }
                      >
                        {denomination}
                      </span>
                    ))} */}
                {/* {JSON.parse(card?.Denomination)} */}
                {/* <div className="mt-4">
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
                      gridGap: '.5rem',
                    }}
                  >
                    {String(card?.Denomination)
                      .split(',')
                      .map((denomination) => (
                        <span
                          className="cursor-pointer"
                          style={{
                            padding: '.25rem .5rem',
                            boxShadow:
                              '0 10px 10px -3px #0000001a,0 4px 6px -4px #0000001a',
                            border: '1px solid rgba(90, 90, 90, .3)',
                            borderRadius: '5px',
                          }}
                          onClick={() =>
                            history.push(
                              `${url}/${card?.ProductNo}?d=${denomination}`
                            )
                          }
                        >
                          {denomination}
                        </span>
                      ))}
                  </div>
                </div> */}
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default GiftCardList
