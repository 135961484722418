import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import usePageTitle from 'hooks/usePageTitle'
import { selectAccount } from 'features/accountSlice'
import PlanDetails from './PlanDetails'
import GoalFDSchemes from './Goal-FD'

const LongTerm = () => {
  const { bankAccount } = useSelector(selectAccount)

  usePageTitle('Long Term Goal')

  return !bankAccount.id ? (
    <Redirect
      to={{
        pathname: '/account-setup/account',
        state: {
          title: 'Setup Bank Account First to Create Long Term Goal',
          redirectTo: '/investment/goal/long',
        },
      }}
    />
  ) : (
    <Fragment>
      <PlanDetails />
      <GoalFDSchemes />
    </Fragment>
  )
}

export default LongTerm
