import { useDispatch } from 'react-redux'
import { accountActions } from 'features/accountSlice'
import { serviceActions } from 'features/servicesSlice'
import { errMsg } from 'backend'
import { useNest } from './useNest'

export function useServices() {
  const { backend } = useNest()
  const dispatch = useDispatch()

  const getOperators = () => {
    let config = { loader: true, msg: '' }
    backend()
      .get('/services/operators', config)
      .then(({ data }) => {
        // console.log(data)
        dispatch(serviceActions.loadOperators(data))
      })
  }

  const getCircles = () => {
    let config = { loader: true, msg: '' }
    backend()
      .get('/services/circles', config)
      .then(({ data }) => {
        // console.log(data)
        dispatch(serviceActions.loadCircles(data?.circles))
      })
  }

  const getPlans = ({ circle = '', operator = '' } = {}) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: '' }
      backend()
        .get(`/services/plans/${circle}/${operator}`, config)
        .then(({ data }) => {
          return resolve(data)
        })
    })

  const getOTTPlans = (payload = {}) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: '' }
      backend()
        .get(`/services/ott/plans/${payload.opid}`, config)
        .then(({ data }) => {
          return resolve(data)
        })
    })

  const subscribeOtt = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Subscribed successfully.' },
      }
      backend()
        .post('/services/ott/subscribe', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const getBundleOperators = () => {
    let config = { loader: true, msg: '' }
    backend()
      .post('paysprint/ott/bundle/operators', config)
      .then(({ data }) => {
        // console.log(data)
        dispatch(serviceActions.loadBundledOperators(data))
      })
  }

  const getBundlePlans = (payload = {}) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: '' }
      backend()
        .get(`/services/ott/bundle/plans/${payload.opid}`, config)
        .then(({ data }) => {
          return resolve(data)
        })
    })

  const subscribeBundlePlan = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Subscribed Successfully' },
      }
      backend()
        .post('/services/ott/bundle/subscribe', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const getBillDetails = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/services/getbill', payload, config)
        .then((response) => {
          return resolve(response)
        })
    })

  const getMunicipalityBillDetails = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/services/municipality/fetchBill', payload, config)
        .then((response) => {
          return resolve(response)
        })
    })

  const subscribeMunicipality = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Subscribed successfully.' },
      }
      backend()
        .post('/services/municipality/payBill', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const prepaid = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/prepaid', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const postpaid = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/postpaid', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const payment = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/payment', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const loanPayment = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/loan/payment', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const insurancePayment = (payload = {}) =>
    new Promise((resolve) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/insurance/payment', payload, config)
        .then(({ data }) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(data)
        })
    })

  const licCheckBill = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: {
          loading: 'Processing...',
          success: 'Bill retrieved successfully.',
        },
      }
      backend()
        .post('/services/lic/checkbill', payload, config)
        .then((response) => {
          // dispatch(accountActions.refreshData()) // it refresh balance and statements
          if (response.data.data) {
            if (response.data.data.status) {
              return resolve(response.data)
            }
          }
          reject(
            response.data.data.message ? response.data.data.message : errMsg
          )
        })
    })

  const licPayBill = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/lic/paybill', payload, config)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.status) {
              return resolve(response.data)
            }
          }
          reject(
            response.data.data.message ? response.data.data.message : errMsg
          )
          // dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(response.data)
        })
    })

  const creditCardBillCheck = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = { loader: true, msg: '' }
      backend()
        .post('/services/cc/generateotp', payload, config)
        .then((response) => {
          // dispatch(accountActions.refreshData()) // it refresh balance and statements
          if (response.data.data) {
            if (response.data.data.status) {
              return resolve(response.data)
            }
          }
          reject(
            response.data.data.message ? response.data.data.message : errMsg
          )
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const creditCardBillPay = (payload = {}) =>
    new Promise((resolve, reject) => {
      let config = {
        loader: true,
        msg: { loading: 'Processing...', success: 'Payment success.' },
      }
      backend()
        .post('/services/cc/paybill', payload, config)
        .then((response) => {
          if (response.data.data) {
            if (response.data.data.status) {
              return resolve(response.data)
            }
          }
          reject(
            response.data.data.message ? response.data.data.message : errMsg
          )
          // dispatch(accountActions.refreshData()) // it refresh balance and statements
          // return resolve(response.data)
        })
        .catch((error) => {
          reject(error.response ? error.response.data.message : errMsg)
        })
    })

  const checkStatus = (id = {}) =>
    new Promise((resolve) => {
      backend()
        .post('/recharge/status', { recharge_id: id })
        .then((response) => {
          dispatch(accountActions.refreshData()) // it refresh balance and statements
          return resolve(response.data)
        })
    })

  return {
    // operators,
    getOperators,
    getCircles,
    getPlans,
    getOTTPlans,
    subscribeOtt,
    getBillDetails,
    prepaid,
    postpaid,
    payment,
    loanPayment,
    insurancePayment,
    creditCardBillCheck,
    creditCardBillPay,
    checkStatus,
    licCheckBill,
    licPayBill,
    getBundleOperators,
    getBundlePlans,
    subscribeBundlePlan,
    // OperatorsOptions
    getMunicipalityBillDetails,
    subscribeMunicipality,
  }
}
