import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row, Button, Form, FormGroup, Label } from 'reactstrap'

// import Slider from 'react-slick'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  InvalidFeedbackV7,
  passwordLength,
  preventSubmit,
} from 'utils/validation'
import PasswordEye from 'Components/PasswordInput'

// import bg3 from "assets/utils/images/originals/citynights.jpg"
// import bg3 from 'assets/utils/images/originals/pan-aadhaar-2.jpg'
import { useAuth } from 'backend/useAuth'
import UserDetails from './UserDetails'
// import { validateKYCAction } from 'redux/actions/bank'

// let settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   arrows: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   fade: true,
//   initialSlide: 0,
//   autoplay: true,
//   adaptiveHeight: true,
// }

const RegisterStep1 = () => {
  const history = useHistory()
  const registerStep = useSelector((state) => state.auth.registerStep)
  const { isEmailExist, signup } = useAuth()
  const [sNewPassword, setNewPassword] = useState('')
  const [email, setEmail] = useState('')
  // const [oKYC, setKYCObject] = useState('')
  const [emailExist, setEmailExist] = useState(false)

  if (!registerStep?.mobile) {
    history.push(`/auth/mobile`)
  }

  const validateEmail = (value) =>
    new Promise((resolve) => {
      if (value.length > 5) {
        if (emailExist && email === value) {
          return resolve(emailExist)
        }
        isEmailExist(value).then((exist) => {
          setEmailExist(!exist)
          return resolve(!exist)
        })
      } else {
        return resolve(false)
      }
    })

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required')
      .email('Please enter valid email')
      .test(
        'exist',
        'This email id already exists',
        // async (value) => (await fetch('/is-jimmy/' + value)).responseText === 'true'
        (value) => validateEmail(value)
      ),
    password: yup
      .string()
      .required('Password is required')
      .min(
        passwordLength,
        `Password should at least ${passwordLength} character`
      ),
    rePassword: yup
      .string()
      .required('Retype Password is required')
      .test(
        'match',
        'Password and retype Password does not match',
        (value) => value === sNewPassword
      ),
  })

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'onTouched',
    // mode: "onTouched|onChanged"
  })

  const onSubmit = (data) => {
    // console.log('submitted')
    // const payload = {
    //   email: data.email,
    //   password: data.password,
    //   mobile: registerStep.mobile,
    //   // accountType: registerStep.accountType,
    // }
    console.log(data.email, data.password, registerStep.mobile)
    signup(data.email, data.password, registerStep.mobile).then((res) => {
      history.push(`/auth/pan`)
      // setTimeout(() => {
      // }, 300)
      // showSuccessBlock(true)
    })
  }

  const onError = (errors) => console.log(errors)

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col
            lg="7"
            md="12"
            className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
            style={{ height: '60vh', overflow: 'auto' }}
          >
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                {/* <div>Welcome,</div> */}
                <span>
                  Create <span className="text-success">account</span>
                </span>
              </h4>
              <div>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Row form>
                    <Col md="12">
                      <FormGroup>
                        <Label for="email" className="required">
                          Email id
                        </Label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-control"
                          {...register('email', {
                            onBlur: (e) => setEmail(e.target.value),
                          })}
                        />
                        <InvalidFeedbackV7 name="email" formState={formState} />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="password" className="required">
                          Password
                        </Label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          autoComplete="new-password"
                          className="form-control"
                          {...register('password', {
                            onChange: (e) => setNewPassword(e.target.value),
                          })}
                        />
                        <PasswordEye target="password" />
                        <InvalidFeedbackV7
                          name="password"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label for="rePassword" className="required">
                          Retype Password
                        </Label>
                        <input
                          type="password"
                          id="rePassword"
                          name="rePassword"
                          // autoComplete="new-password"
                          className="form-control"
                          {...register('rePassword')}
                        />
                        <PasswordEye target="rePassword" />
                        <InvalidFeedbackV7
                          name="rePassword"
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex align-items-center">
                    <h5 className="mb-0">
                      Already have an account?{' '}
                      <Link to="/auth/login" className="text-primary">
                        Sign in
                      </Link>
                    </h5>
                    <div className="ml-auto d-flex justify-content-end">
                      <Button
                        className="custom-btn-animation"
                        disabled={preventSubmit(formState)}
                      >
                        Create Account
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Col>
          <UserDetails />
        </Row>
      </div>
    </Fragment>
  )
}

export default RegisterStep1
