import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectUi, uiActions } from 'features/uiSlice'
// import { AiOutlineCheckCircle } from 'react-icons/ai'
import './style.css'

const ReusableModal = () => {
  const { reUsableModal } = useSelector(selectUi)
  const dispatch = useDispatch()

  const toggle = () => {
    dispatch(uiActions.toggleReUsableModal())
  }

  return (
    <Modal
      isOpen={reUsableModal.open}
      toggle={toggle}
      centered
      size="md"
      className="shadow-none"
    >
      <ModalBody className="text-center">
        {reUsableModal?.content}
        {/* <h4 className="m-3">{reUsableModal.title}</h4>
        <div className="pt-3 pb-4">{reUsableModal.description}</div>
        {reUsableModal?.redirectUrl && (
          <button className="btn btn-block btn-success" onClick={onConfirm}>
            {reUsableModal?.buttonText ?? 'Go Back To Dashboard'}
          </button>
        )} */}
      </ModalBody>
    </Modal>
  )
}

export default ReusableModal
