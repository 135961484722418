import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { Col, Row } from 'reactstrap'
import { cdnImg } from 'utils/constants'
import FdCard from './FdCard'
import { getImagePath } from 'Pages/Investment/Goals/FD'
import Slider from 'react-slick'

const FdScrollCard = ({ scheme: schemes, name = '', f_code }) => {
  const history = useHistory()

  const PrevArrow = (props) => (
    <div onClick={props.onClick} className={`left-arrow z-index-500`}>
      <i className="pe-7s-angle-left bg-soft-primary text-primary arrow-container"></i>
    </div>
  )

  const NextArrow = (props) => (
    <div onClick={props.onClick} className={`right-arrow z-index-500`}>
      <i className="pe-7s-angle-right bg-soft-primary text-primary arrow-container"></i>
    </div>
  )

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6.5,
    slidesToScroll: 2,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    schemes.length > 0 && (
      <Fragment>
        <Row
          className={`align-items-center ${
            f_code === 'PNBH' ? 'mt-5 pt-3' : 'mt-3'
          }`}
        >
          <Col md="12">
            <h5 className="font-weight-normal">
              Interest Payout :<span className="font-weight-bold"> {name}</span>
            </h5>
          </Col>
        </Row>
        <div className="voucher-card-container slider-container w-100">
          <Slider
            minTrackWidth={200}
            {...settings}
            className=""
            style={{
              maxWidth: '1240px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {[...(schemes ? [...schemes] : [])].map((scheme) => (
              <div
                key={scheme?.f_product_id}
                style={{ margin: '20px 10px', minHeight: '215px' }}
                className="cursor-pointer mr-4 min-h-215"
                onClick={() =>
                  history.push({
                    pathname: `/investment/goal/fd/${scheme?.f_investment_vehicle_id}`,
                    state: { scheme, schemeCode: f_code },
                  })
                }
              >
                <FdCard
                  description={
                    <div
                      className="font-weight-bold overflow-hidden"
                      id={scheme?.product_interest}
                    >
                      {scheme?.product_interest}
                      <small>%</small> <small>/</small> Yearly
                      {/* {scheme.product_interest_freq} */}
                    </div>
                  }
                  name={scheme?.name}
                  brandLogo={getImagePath(f_code)}
                  id={scheme?.f_investment_vehicle_id}
                  cdnImg={cdnImg}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Fragment>
    )
  )
}

export default FdScrollCard
