import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'

import { useForm } from 'react-hook-form'
import { FaRupeeSign } from 'react-icons/fa'

import {
  InvalidFeedbackV7,
  isEmpty,
  preventSubmit,
} from 'utils/validation'

import SelectedOperatorBBPS from '../SelectedOperatorBBPS'
import BillDetailsBBPS from '../BBPSBillDetails'
import { useFasTag } from "backend/useFasTag"

const FasTagBillingForm = ({ service }) => {
  const history = useHistory()
  const { opcode } = useParams()
  const operators = useSelector((state) => state.services.bbpsOperators)
  const [operator, setOperator] = useState()

  const [amount, setAmount] = useState(100)
  const [billDetails, setBillDetails] = useState()

  const { fetchBill } = useFasTag()

  useEffect(() => {
    if (operators) {
      setOperator(
        operators.find((op) => op.service === service && op.code === opcode)
      )
    }
  }, [operators, opcode, service])

  useEffect(() => {
    return () => {
      setBillDetails()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { register, handleSubmit, reset, setValue, trigger, formState } =
    useForm()

  const onSubmit = async (data) => {
    if (operator.bill && !billDetails) {
      await checkBill(data)
      return
    }
    handleFormSubmit(data)
  }

  const handleReset = (e) => {
    e.preventDefault()
    setBillDetails()
    reset()
  }

  const checkBill = async (data) => {
    let payload = {
      ...data,
      operator: operator.id,
      canumber: data.canumber,
      // mode: 'online',
      // opid: operator?.opid,
    }
    fetchBill(payload).then((data) => {
      if (data) {
        console.log(data)
        setBillDetails(data)
        setValue('amount', data.amount)
      }
    })
  }

  const handleFormSubmit = (data) => {
    const payload = {
      ...data,
      operator: operator.code,
      amount: data.amount,
      // referenceid: '20018575947', generated at backend.
      mode: 'online',
    }
    if (billDetails?.bill_fetch) {
      payload.bill_fetch = {
        billAmount: billDetails.bill_fetch.amount,
        billnetamount: data.amount,
        billdate: dayjs(new Date()).format( 'DDMMMYYYY'),
        dueDate: billDetails.bill_fetch.duedate,
        cellNumber: data.canumber,
        acceptPayment: true,
        acceptPartPay: false,
        userName: billDetails.bill_fetch.name,

        // name: 'DUMMY NAME',
        // duedate: '2021-06-16',
        // ad2: 'HDA55697373',
        // ad3: 'VDA27163977',

        // billAmount: '820.0',
        // billnetamount: '820.0',
        // billdate: '01Jan1990',
        // dueDate: '2021-01-06',
        // acceptPayment: true,
        // acceptPartPay: false,
        // cellNumber: '102277100',
        // userName: 'SALMAN',
      }
    }
    console.log('payload', payload)
    history.push({
      pathname: `/service/checkout`,
      state: {
        services: [
          {
            amount: data.amount,
            name: operator.name,
            description: `Pay ${operator.name} ${operator.category} bill`,
            quantity: 1,
            service: operator.category,
          },
        ],
        provider: payload,
        meta: {
          bill: operator?.bill,
          operator,
        },
      },
    })
  }

  const handleAmount = (value) => {
    // console.log(value)
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const selectAmount = (value) => {
    setAmount(value)
    setValue('amount', value, { shouldValidate: true })
  }

  const handleChangeInput = (name, value) => {
    setValue(name, value)
    trigger(name)
    setBillDetails()
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  if (isEmpty(operator) || isEmpty(operators)) {
    return ''
  }

  return (
    <Fragment>
      <Row>
        <Col lg="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Pay your {operator?.name} Bill</CardTitle>
              <SelectedOperatorBBPS
                opcode={opcode}
                category={service}
                showBillStatus
              />
              {/* <pre>{JSON.stringify(operator, null, 2)}</pre> */}
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="validate-form was-validate"
              >
                <Row>
                  {operator?.fields &&
                    operator?.fields.map((field) => (
                      <Col lg="12" key={field?.name}>
                        <FormGroup>
                          <Label for={field?.name} className="required">
                            {field.label} {field.regex}
                          </Label>
                          <input
                            type="text"
                            placeholder={field.placeholder}
                            id={field?.name}
                            name={field?.name}
                            className="form-control"
                            {...register(field?.name, {
                              required: `${field.label} is required`,
                              pattern: field.regex && {
                                value: new RegExp(field.regex),
                                message: `Please enter valid ${field.label}`,
                              },
                              onChange: (e) =>
                                handleChangeInput(field?.name, e.target.value),
                            })}
                          />
                          <InvalidFeedbackV7
                            name={field?.name}
                            formState={formState}
                          />
                        </FormGroup>
                      </Col>
                    ))}

                  {/* <Col lg="12" key="canumber">
                    <FormGroup>
                      <Label for="canumber" className="required">
                        {operator.displayname} {operator.regex}
                      </Label>
                      <input
                        type="text"
                        placeholder={
                          operator.placeholder ||
                          `Enter ${operator.displayname}`
                        }
                        id="canumber"
                        name="canumber"
                        className="form-control"
                        {...register('canumber', {
                          required: `${operator.displayname} is required`,
                          pattern: operator.regex && {
                            value: new RegExp(operator.regex),
                            message: `Please enter valid ${operator.displayname}`,
                          },
                        })}
                        onChange={(e) =>
                          handleChangeInput('canumber', e.target.value)
                        }
                      />
                      <InvalidFeedbackV7
                        name="canumber"
                        formState={formState}
                      />
                    </FormGroup>
                  </Col> */}
                  {/* {operator?.ad1_name && (
                    <Col lg="12" key={operator?.ad1_name}>
                      <FormGroup>
                        <Label for={operator?.ad1_name} className="required">
                          {operator.ad1_d_name} {operator.ad1_regex}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad1_d_name}`
                          }
                          id={operator?.ad1_name}
                          name={operator?.ad1_name}
                          className="form-control"
                          {...register(operator?.ad1_name, {
                            required: `${operator.ad1_d_name} is required`,
                            pattern: operator.ad1_regex && {
                              value: new RegExp(operator.ad1_regex),
                              message: `Please enter valid ${operator.ad1_d_name}`,
                            },
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad1_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad1_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )} */}
                  {/* {operator?.ad2_name && (
                    <Col lg="12" key={operator?.ad2_name}>
                      <FormGroup>
                        <Label for={operator?.ad2_name} className="required">
                          {operator.ad2_d_name} {operator.ad2_regex}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad2_d_name}`
                          }
                          id={operator?.ad2_name}
                          name={operator?.ad2_name}
                          className="form-control"
                          {...register(operator?.ad2_name, {
                            required: `${operator.ad2_d_name} is required`,
                            pattern: operator.ad2_regex && {
                              value: new RegExp(operator.ad2_regex),
                              message: `Please enter valid ${operator.ad2_d_name}`,
                            },
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad2_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad2_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )} */}
                  {/* {operator?.ad3_name && (
                    <Col lg="12" key={operator?.ad3_name}>
                      <FormGroup>
                        <Label for={operator?.ad3_name} className="required">
                          {operator.ad3_d_name} {operator.ad3_regex}
                        </Label>
                        <input
                          type="text"
                          placeholder={
                            operator.placeholder ||
                            `Enter ${operator.ad3_d_name}`
                          }
                          id={operator?.ad3_name}
                          name={operator?.ad3_name}
                          className="form-control"
                          {...register(operator?.ad3_name, {
                            required: `${operator.ad3_d_name} is required`,
                            pattern: operator.ad3_regex && {
                              value: new RegExp(operator.ad3_regex),
                              message: `Please enter valid ${operator.ad3_d_name}`,
                            },
                          })}
                          onChange={(e) =>
                            handleChangeInput(
                              operator?.ad3_name,
                              e.target.value
                            )
                          }
                        />
                        <InvalidFeedbackV7
                          name={operator?.ad3_name}
                          formState={formState}
                        />
                      </FormGroup>
                    </Col>
                  )} */}
                  {operator?.bill ? (
                    billDetails && (
                      <Col lg="12">
                        <FormGroup>
                          <Label for="amount" className="required">
                            Amount
                          </Label>
                          <InputGroup>
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              id="amount"
                              name="amount"
                              value={amount}
                              // defaultValue="100"
                              // defaultValue={0}
                              className="form-control"
                              {...register('amount', {
                                required: 'Amount is required',
                                onChange: (e) => handleAmount(e.target.value),
                              })}
                            />
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FaRupeeSign />
                              </InputGroupText>
                            </InputGroupAddon>
                            <InvalidFeedbackV7
                              name="amount"
                              formState={formState}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )
                  ) : (
                    <Col lg="12">
                      <FormGroup>
                        <Label for="amount" className="required">
                          Amount
                        </Label>
                        <InputGroup>
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            id="amount"
                            name="amount"
                            value={amount}
                            className="form-control"
                            {...register('amount', {
                              required: 'Amount is required',
                              onChange: (e) => handleAmount(e.target.value),
                            })}
                          />
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FaRupeeSign />
                            </InputGroupText>
                          </InputGroupAddon>
                          <InvalidFeedbackV7
                            name="amount"
                            formState={formState}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row>
                  {operator?.bill ? (
                    !billDetails ? (
                      <>
                        <Col lg="12" className="d-flex justify-content-end">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="primary"
                              disabled={preventSubmit(formState)}
                            >
                              Check Bill
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="danger"
                              type="button"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Button
                              size="lg"
                              block
                              className="mt-3"
                              color="success"
                              disabled={preventSubmit(formState)}
                            >
                              Pay Bill
                            </Button>
                          </FormGroup>
                        </Col>
                      </>
                    )
                  ) : (
                    <Col lg="12">
                      <FormGroup>
                        <Button
                          size="lg"
                          block
                          className="mt-3"
                          color="primary"
                          disabled={preventSubmit(formState)}
                        >
                          Proceed
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <BillDetailsBBPS
            data={billDetails}
            selectAmount={selectAmount}
            visible={operator?.bill}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

export default FasTagBillingForm
