import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Slider } from 'react-burgers'

import AppMobileMenu from '../AppMobileMenu'

import { selectThemeOptions, setEnableClosedSidebar } from '../../features/ThemeOptions'

const HeaderLogo = () => {
  const { enableClosedSidebar } = useSelector(selectThemeOptions)
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  // const [mobile, setMobile] = useState(false)
  // const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(
  //   false
  // )

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar))
  }

  // const state = {
  //   openLeft: false,
  //   openRight: false,
  //   relativeWidth: false,
  //   width: 280,
  //   noTouchOpen: false,
  //   noTouchClose: false,
  // }

  return (
    <Fragment>
      <div className="app-header__logo d-none">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  )
}

export default HeaderLogo
