import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
import MetisMenu from 'react-metismenu'
import { MainNav, AccountNav, ServiceNav, GoalNav } from './NavItems'

class CustomLink extends React.Component {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    if (this.props.hasSubMenu) this.props.toggleSubMenu(e)
    else {
      /*
       * your own operation using "to"
       * myGotoFunc(this.props.to);
       * or
       * this.props.activateMe //(Parameters to pass "onSelected" event listener);
       */

      this.props.activateMe({
        newLocation: this.props.to,
        selectedMenuLabel: this.props.label,
      })
    }
  }

  render() {
    return (
      <Link
        to={this.props.to}
        className="metismenu-link"
        onClick={this.onClick}
      >
        {this.props.children}
      </Link>
    )
  }
}

const Nav = () => {
  // const location = useLocation()
  // const { enableMobileMenu } = useSelector(selectThemeOptions)
  // const dispatch = useDispatch()

  // const toggleMobileSidebar = () => {
  //   // dispatch(setEnableMobileMenu(!enableMobileMenu))
  //   // console.log('toggleMobileSidebar')
  // }
  // const pathActiveLink = (path) => {
  //   console.log('pathActiveLink', `#${location.pathname}`)
  //   // if (location.pathname.startsWith(path)) {
  //   //   // return `#${location.pathname}`
  //   // }
  //   return '/'
  // }

  // const isPathActive = (path) => {
  //   // console.log('isPathActive', path)
  //   // console.log('matched', location.pathname)
  //   return location.pathname.startsWith(path)
  // }

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu
        content={MainNav}
        // onSelected={toggleMobileSidebar}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        // activeLinkFromLocation={isPathActive('/dashboard')}
        activeLinkFromLocation={true}
        // activeLinkTo={pathActiveLink('/dashboard')}
        LinkComponent={CustomLink}
      />

      <h5 className="app-sidebar__heading">History</h5>
      <MetisMenu
        content={AccountNav}
        // onSelected={toggleMobileSidebar}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        // activeLinkFromLocation={isPathActive('/account')}
        activeLinkFromLocation={true}
        // activeLinkTo={pathActiveLink('/account')}
        LinkComponent={CustomLink}
      />

      {/* <h5 className="app-sidebar__heading">QwikCilver Testing</h5>
      <MetisMenu
        content={QcNav}
        // onSelected={toggleMobileSidebar}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        // activeLinkFromLocation={this.isPathActive('/investment')}
        activeLinkFromLocation={true}
        // activeLinkTo={this.pathActiveLink('/investment')}
        LinkComponent={CustomLink}
      /> */}

      <h5 className="app-sidebar__heading">Goal</h5>
      <MetisMenu
        content={GoalNav}
        // onSelected={toggleMobileSidebar}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        // activeLinkFromLocation={isPathActive('/investment')}
        activeLinkFromLocation={true}
        // activeLinkTo={pathActiveLink('/investment')}
        LinkComponent={CustomLink}
      />
      <h5 className="app-sidebar__heading">Services</h5>
      <MetisMenu
        content={ServiceNav}
        // onSelected={toggleMobileSidebar}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
        // activeLinkFromLocation={isPathActive('/recharge')}
        activeLinkFromLocation={true}
        // activeLinkTo={pathActiveLink('/recharge')}
        LinkComponent={CustomLink}
      />
    </Fragment>
  )
}

export default Nav
