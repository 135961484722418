import { s3GetUrl } from 'config'
import React from 'react'
import { getAverageRGB } from 'utils/constants'

const BrandCard = ({ brand, cdnImg }) => {
  return (
    <div
      className={`d-flex flex-column sip-cards card-${brand.id}`}
      title={brand.name}
    >
      <div className="d-flex align-item-center justify-content-center custom-image-holder">
        <img
          alt=""
          src={s3GetUrl(brand?.goalThumb || cdnImg.goals)}
          onLoad={getAverageRGB}
          productId={brand?.id}
          crossOrigin="anonymous"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      <div className="sip-card-body">
        <div className="brandStrokeContainer">
          <div className={`brandStroke brandStroke-${brand.id}`}></div>
        </div>
        <div
          className={`font-weight-bold overflow-hidden font-poppins-md text-center mt-2 brandText-${brand.id}`}
          id={brand?.sku}
        >
          {/* <CardText className="mb-1">{el?.brandName}</CardText> */}
          {/* <CardText>
                      <small className="img-text-small">
                        {el?.offerWordings}
                        {`Upto 9.5% Interest + Brand discount upto ${el?.discount}%`}
                      </small>
                    </CardText> */}
          {brand?.discount}% Reward
        </div>
      </div>
    </div>
  )
}

export default BrandCard
