import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { Form, Input, Select } from 'antd'
import { useAuth } from 'backend/useAuth'
import { selectAuth } from 'features/auth/authSlice'

const occupations = [
  { value: 'Business', label: 'Business' },
  { value: 'Professional', label: 'Professional' },
  { value: 'Self Employed', label: 'Self Employed' },
  { value: 'Retired', label: 'Retired' },
  { value: 'Housewife', label: 'Housewife' },
  { value: 'Student', label: 'Student' },
  { value: 'Public Sector', label: 'Public Sector' },
  { value: 'Private Sector', label: 'Private Sector' },
  { value: 'Government Sector', label: 'Government Sector' },
  { value: 'Not Categorized', label: 'Not Categorized' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Others', label: 'Others' },
  { value: 'Salaried', label: 'Salaried' },
]

const educations = [
  { value: 'Under Graduate', label: 'Under Graduate' },
  { value: 'Graduate', label: 'Graduate' },
  { value: 'Post Graduate', label: 'Post Graduate' },
]

const sourceOfIncomes = [
  { value: 'Salary', label: 'Salary' },
  { value: 'Business', label: 'Business' },
  { value: 'Investment', label: 'Investment' },
  { value: 'Dependent', label: 'Dependent' },
  { value: 'Other', label: 'Other' },
]

const annualIncomes = [
  { value: 'Upto 5L Per Annum', label: 'Upto 5L Per Annum' },
  { value: '5L - 10L Per Annum', label: '5L - 10L Per Annum' },
  { value: '10 - 15L Per Annum', label: '10 - 15L Per Annum' },
  { value: '15L - 25L Per Annum', label: '15L - 25L Per Annum' },
  { value: '25L - 50L Per Annum', label: '25L - 50L Per Annum' },
  { value: '50L - 1Cr Per Annum', label: '50L - 1Cr Per Annum' },
  { value: '1Cr and Above', label: '1Cr and Above' },
]

const maritalStatuses = [
  { value: 'Married', label: 'Married' },
  { value: 'Unmarried', label: 'Unmarried' },
  { value: 'Other', label: 'Other' },
]

const PersonalDetailsForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { user } = useSelector(selectAuth)
  const { updatePersonalDetails } = useAuth()

  const onSubmit = (data) => {
    const payload = {
      ...data,
    }
    console.log(payload)
    updatePersonalDetails(payload).then((data) => {
      // console.log(data)
      setTimeout(() => {
        history.push('/account-setup/finish')
      }, 300)
    })
  }

  const onFinishFailed = (e) => {
    console.log('onFinishFailed', e)
  }

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <h3>Personal Details</h3>
          {/* <pre>{JSON.stringify(nominee, null, 2)}</pre> */}
          <Form
            form={form}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            initialValues={{
              ...user,
            }}
          >
            <Row>
              <Col md="6">
                <Form.Item
                  label="Occupation"
                  name="occupation"
                  rules={[
                    {
                      required: true,
                      message: 'Occupation is required',
                    },
                  ]}
                >
                  <Select options={occupations} placeholder="Occupation" />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Education"
                  name="education"
                  rules={[
                    {
                      required: true,
                      message: 'Education is required',
                    },
                  ]}
                >
                  <Select options={educations} placeholder="Education" />
                </Form.Item>
              </Col>
              <Col md="12">
                <Form.Item
                  label="Mother Name"
                  name="motherName"
                  rules={[
                    {
                      required: true,
                      message: 'Mother Name is required',
                    },
                  ]}
                >
                  <Input className="form-control" placeholder="Mother Name" />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Source of Income"
                  name="sourceOfIncome"
                  rules={[
                    {
                      required: true,
                      message: 'Source of Income is required',
                    },
                  ]}
                >
                  <Select
                    options={sourceOfIncomes}
                    placeholder="Source of Income"
                  />
                </Form.Item>
              </Col>
              <Col md="6">
                <Form.Item
                  label="Annual Income"
                  name="annualIncome"
                  rules={[
                    {
                      required: true,
                      message: 'Annual Income is required',
                    },
                  ]}
                >
                  <Select options={annualIncomes} placeholder="Annual Income" />
                </Form.Item>
              </Col>
              <Col md="12">
                <Form.Item
                  label="Marital Status"
                  name="maritalStatus"
                  rules={[
                    {
                      required: true,
                      message: 'Marital Status is required',
                    },
                  ]}
                >
                  <Select
                    options={maritalStatuses}
                    placeholder="Marital Status"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <small className="text-danger">
                  Fields marked with * are required
                </small>
              </Col>
              <Col md="12" className="d-flex justify-content-end">
                <Form.Item>
                  <button
                    type="submit"
                    className="mt-3 btn btn-primary btn-shadow"
                  >
                    Proceed
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Fragment>
  )
}

export default PersonalDetailsForm
