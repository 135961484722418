import React, { Fragment } from 'react'
import { Route } from 'react-router'

import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import OperatorList from '../OperatorList'
import Form from './Form'

const service = 'digital-voucher'

const DigitalVoucher = ({ match }) => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-gift icon-gradient bg-ripe-malin" />
              </div>
              <div>
                Digital Voucher
                <div className="page-title-subheading">
                  Generate Digital Voucher..
                </div>
              </div>
            </div>
          </div>
        </div>
        <Route path={`${match.url}`} exact>
          <OperatorList
            title="All Digital Voucher Providers"
            category={service}
            url={match.url}
          />
        </Route>
      </CSSTransitionGroup>
      <Route path={`${match.url}/:opcode`} exact>
        <Form service={service} />
      </Route>
    </Fragment>
  )
}

export default DigitalVoucher
