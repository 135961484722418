// import { useDispatch } from 'react-redux'
import { useNest } from './useNest'

function useFixerra() {
  const { backend } = useNest()
  // const dispatch = useDispatch()

  const getPurchaseUrl = (sessionId, payload) =>
    new Promise((resolve) => {
      let config = { loader: true, msg: '' }
      backend()
        .post(`/fixerra/get-purchase-url/${sessionId}`, payload, config)
        .then((data) => {
          return resolve(data)
          // dispatch(serviceActions.loadFastTagOperators(data))
        })
    })

  // const checkStatus = (referenceId) =>
  //   new Promise((resolve) => {
  //     backend()
  //       .get('/fixerra/status', { referenceId })
  //       .then((response) => {
  //         return resolve(response.data)
  //       })
  //   })

  return {
    getPurchaseUrl,
    // checkStatus,
  }
}

export { useFixerra }
