import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Slider } from 'react-burgers'

// import cx from 'classnames'

// import { FaEllipsisV } from 'react-icons/fa'

// import { Button } from 'reactstrap'

import {
  selectThemeOptions,
  setEnableMobileMenu,
  // setEnableMobileMenuSmall,
} from '../../features/ThemeOptions'
import UserBox from 'Layout/AppHeader/Components/UserBox'

const AppMobileMenu = () => {
  const {
    // closedSmallerSidebar,
    enableMobileMenu,
    // enableMobileMenuSmall,
  } = useSelector(selectThemeOptions)
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  // const [mobile, setMobile] = useState(false)
  // const [activeSecondaryMenuMobile, setActiveSecondaryMenuMobile] = useState(
  //   false
  // )

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu))
  }

  // const toggleMobileSmall = () => {
  //   dispatch(setEnableMobileMenuSmall(!enableMobileMenuSmall))
  // }

  // const state = {
  //   openLeft: false,
  //   openRight: false,
  //   relativeWidth: false,
  //   width: 280,
  //   noTouchOpen: false,
  //   noTouchClose: false,
  // }

  return (
    <Fragment>
      <div className="app-header__mobile-menu justify-content-between w-100">
        <div onClick={toggleMobileSidebar}>
          <Slider
            width={26}
            lineHeight={2}
            lineSpacing={5}
            color="#6c757d"
            active={active}
            onClick={() => setActive(!active)}
          />
        </div>
        <div className="app-header-right">
          {/* <HeaderDots /> */}
          <UserBox />
          {/* <HeaderRightDrawer /> */}
        </div>
      </div>

      {/* <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button
            size="sm"
            className={cx('btn-icon btn-icon-only', {
              active: activeSecondaryMenuMobile,
            })}
            color="primary"
            onClick={() =>
              setActiveSecondaryMenuMobile(!activeSecondaryMenuMobile)
            }
          >
            <div className="btn-icon-wrapper">
              <FaEllipsisV />
            </div>
          </Button>
        </span>
      </div> */}
    </Fragment>
  )
}

export default AppMobileMenu
