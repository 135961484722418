import { createSlice } from '@reduxjs/toolkit'
import { loading, rejected } from 'features/status'

const defaultState = {
  status: loading,
  user: {
    displayName: 'Loading...',
  },
  token: null,
  logged: false,
  panVerified: false,
  claims: {},
  roles: [],
  pin: null,
  creditScore: 300,
  signup: null,
  // beneficiaries: [],
  // mandate: {
  //   mandateId: '',
  //   entityId: '',
  //   isfc: '',
  //   signed: false,
  //   amount: 0,
  //   stage: 1,
  // },
  // finbox: {
  //   entityId: '',
  //   linkId: '',
  //   progress: '',
  //   reason: '',
  //   dateTime: '',
  // },
  // pin: "55dc87c4",
  registerStep: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // action: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.actionName = state.actionName + 5
    // },
    signupStepMobile: (state, action) => {
      state.registerStep = { mobile: action.payload }
    },
    signupStepAadhaarProfile: (state, action) => {
      state.registerStep = { ...state.registerStep, aadhaar: action.payload }
    },
    signupStepPanProfile: (state, action) => {
      state.registerStep = { ...state.registerStep, pan: action.payload }
    },
    signup: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.roles = action.payload.roles
      state.claims = action.payload.claims
      state.logged = true
      state.status = action.payload.status
    },
    login: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.roles = action.payload.roles
      state.claims = action.payload.claims
      state.pin = action.payload.pin
      // state.mandate = action.payload.mandate
      // state.beneficiaries = action.payload.beneficiaries
      state.logged = true
      state.status = action.payload.status
    },
    updateProfile: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
    updatePin: (state, action) => {
      state.pin = action.payload.pin
    },
    authError: (state) => {
      state.token = ''
      state.status = rejected
    },
    updateFinbox: (state, action) => {
      state.finbox = action.payload
    },
    updateMandate: (state, action) => {
      state.mandate = action.payload
    },
    updateCreditScore: (state, action) => {
      state.creditScore = action.payload
    },
    panVerified: (state) => {
      state.panVerified = true
    },
    logout: (state) => {
      state = defaultState
    },
  },
})

// Action creators are generated for each case reducer function
// export const {} = authSlice.actions
export const authActions = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.augmont.value)`
export const selectAuth = (state) => state.auth

export default authSlice.reducer
