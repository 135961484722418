export default function calculateAge(dob) {
  // Convert DOB string to Date object
  const dobDate = new Date(dob)

  // Get current date
  const currentDate = new Date()

  // Calculate age
  let age = currentDate.getFullYear() - dobDate.getFullYear()

  // Check if birthday has occurred this year
  const dobMonth = dobDate.getMonth()
  const currentMonth = currentDate.getMonth()
  const dobDay = dobDate.getDate()
  const currentDay = currentDate.getDate()

  if (
    currentMonth < dobMonth ||
    (currentMonth === dobMonth && currentDay < dobDay)
  ) {
    age--
  }

  return age
}
