import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button, Card, CardBody, Row, Col, CardTitle } from 'reactstrap'

import { useForm } from 'react-hook-form'
import { FaRegArrowAltCircleLeft } from 'react-icons/fa'
import { useGiftCard } from 'backend/useGiftCard'
import { formattedPrice } from 'utils/helper'
import voucherImg from 'assets/img/sample_voucher.png'
import { InvalidFeedbackV7, preventSubmit } from 'utils/validation'
import { selectAuth } from 'features/auth/authSlice'
import usePageTitle from 'hooks/usePageTitle'

// const MINIMUM_AMT = 150000
// const MAXIMUM_AMT = 180000

// const TEMP_HTML_DESC =
//   'aaaaaaaaa 109F.com Gift Voucher 109°F is the urban woman’s cult Western-wear Fashion Brand. Discover the latest trends in lifestyle and shop for Women’s western wear on www.109F.com. Get the best online shopping experience with 109F.com that offers you FREE Shipping, 15-day hassle-free returns guarantee, and a convenient cash-on-delivery option.'
// const TEMP_HTML_TNC = `
// 109°F E-Vouchers are issued by Encompass Design India Pvt Ltd
// The 109°F E-Vouchers can be redeemed only at www.109f.com
// If you're having problems redeeming a 109°F E-Voucher, it's possible that the 109°F E-Voucher have already been applied to your account
// After you've entered the 109°F E-Voucher on the www.109f.com site, it will keep the balance in your account for any future purchases so that you don't need to enter the 109°F E-Voucher again
// If your balance is sufficient to buy a product, you can complete the purchase with your 109°F E-Voucher balance only. If your balance is not sufficient to buy the product, you can use a combination of your gift card balance and other payment options - Credit Card, Debit Card or Net Banking to purchase your product. You cannot use a combination of a Gift Card and COD or Gift Card and EMI
// 109°F E-Voucher cannot be reloaded or resold
// Validity of 109°F E-Voucher cannot be extended, new 109°F E-Voucher cannot be provided against the expired/unused Vouchers
// You are solely responsible for the safety and security of the 109°F E-Voucher. 109f.com or its affiliates are not responsible for any acts of misuse if 109°F E-Voucher is lost, stolen or used
// The holder of the 109°F E-Voucher is deemed to be the beneficiary
// This Gift Voucher is valid until 6 months from the date of issue
// Any dispute should be referred to the company from where the 109°F E-Voucher has been received, decision of the company shall be final
// Once ordered, Gift vouchers or Gift codes cannot be cancelled or returned nor can points be refunded
// Validity
// 6 Months`

// const TEMP_HTML_HOWTOUSE = `The 109F e-vouchers can be redeemed only at www.109f.com In the field under add gift card / voucher code to account, enter your claim code and click add to your account`

const PurchaseVoucher = ({ service }) => {
  const history = useHistory()
  const { user } = useSelector(selectAuth)

  usePageTitle("Purchase Voucher")

  // const params = new URLSearchParams(window ? window.location.search : {})
  const [tab, setTab] = useState('description')
  const [giftCard, setCardData] = useState({
    sku: null,
    name: 'Loading',
    description: 'Loading...',
    price: {
      min: '10',
      max: '10000',
      denominations: [],
    },
  })
  const { getQwikCilverProductBySku } = useGiftCard()

  const { sku } = useParams()

  useEffect(() => {
    if (!giftCard.sku) {
      getQwikCilverProductBySku(sku).then((data) => {
        setCardData(data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku])

  const { register, handleSubmit, formState } = useForm()

  const onSubmit = async (data) => {
    const payload = {
      services: [
        {
          amount: data.amount,
          name: giftCard.name,
          description: `Buy ${giftCard.name}`,
          quantity: 1,
          service: 'qwikCilver',
          address: {
            firstname: user.firstName, // 'Vikesh',
            lastname: user.lastName, // 'Agravat',
            email: user.email, // 'vicky.agravat@gmail.com',
            telephone: '+91987654321',
            line1: 'Kashewnut',
            line2: '111, JMC',
            city: 'Jamnagar',
            region: 'Gujarat',
            country: 'IN',
            postcode: '361008',
            company: '',
            billToThis: true,
          },
          billing: {
            firstname: user.firstName, // 'Vikesh',
            lastname: user.lastName, // 'Agravat',
            email: user.email, // 'vicky.agravat@gmail.com',
            telephone: '+91987654321',
            line1: 'billing 1',
            line2: 'Billing 2',
            city: 'Jamnagar',
            region: 'Gujarat',
            country: 'IN',
            postcode: '361008',
            company: '',
          },
        },
      ],
      provider: {
        sku: sku,
        amount: parseFloat(data.amount), // 100,
        service: 'qwikCilver',
      },
      meta: {
        bill: false,
        operator: {
          endpoint: 'qwikCilver',
        },
      },
    }
    // payload.amount = parseFloat(data.amount)
    history.push({
      pathname: '/service/checkout',
      state: payload,
    })

    // history.push(`${history?.location.pathname}/:uwgyeduweg`)
    // makeQwikCilverOrder().then(() => {})
  }

  // eslint-disable-next-line
  const onError = (errors) => console.log(errors)

  return (
    <Fragment>
      {giftCard.sku ? (
        <Row>
          <Col lg="5">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>
                  <div className="d-flex justify-content-between">
                    {giftCard.name}
                    <FaRegArrowAltCircleLeft
                      title="Back to giftCards"
                      size="24"
                      className="text-secondary cursor-pointer"
                      onClick={() => history.goBack()}
                    />
                  </div>
                </CardTitle>
                <div className="d-flex align-items-center justify-content-between pr-3">
                  <div className="d-flex align-items-center p-2 my-2">
                    <img src={giftCard?.images?.small || voucherImg} alt="" />
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="validate-form was-validate"
                >
                  <div
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '1rem',
                    }}
                    className="d-flex flex-column"
                  >
                    {giftCard?.price?.denominations.length > 0 ? (
                      <div>
                        <label style={{ fontWeight: 'bold' }}>
                          Select Amount
                        </label>
                        <select
                          className="line-input form-control"
                          defaultValue={giftCard.price.min}
                          {...register('amount', {
                            required: 'Amount is required',
                          })}
                        >
                          <option value={giftCard.price.min}>
                            {giftCard.price.min}
                          </option>
                          {giftCard?.price?.denominations
                            .filter(
                              (denomination) =>
                                parseFloat(giftCard.price.min) !==
                                parseFloat(denomination)
                            )
                            .map((denomination) => (
                              <option value={denomination} key={denomination}>
                                {denomination}
                              </option>
                            ))}
                        </select>
                        <InvalidFeedbackV7
                          name="amount"
                          formState={formState}
                        />
                      </div>
                    ) : (
                      <div>
                        <label style={{ fontWeight: 'bold' }}>
                          Enter Amount
                        </label>
                        <input
                          className="line-input form-control"
                          defaultValue={giftCard.price.min}
                          {...register('amount', {
                            required: 'Amount is required',
                            min: {
                              value: giftCard.price.min,
                              message: `Minimum Amount should be ${giftCard.price.min}`,
                            },
                            max: {
                              value: giftCard.price.max,
                              message: `Minimum Amount should be ${giftCard.price.max}`,
                            },
                          })}
                        />
                        <InvalidFeedbackV7
                          name="amount"
                          formState={formState}
                        />
                        <div
                          className="d-flex"
                          style={{ justifyContent: 'end' }}
                        >
                          <span
                            style={{ fontSize: '12px', textAlign: 'right' }}
                          >
                            Min: {formattedPrice(giftCard.price.min)} Max:{' '}
                            {formattedPrice(giftCard.price.max)}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        className="mt-3"
                        disabled={preventSubmit(formState)}
                      >
                        Purchase Now
                      </Button>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="7">
            <Card style={{ padding: '20px' }}>
              {/* <div style={{ background: 'white', padding: '1rem' }}> */}
              <div className="tabs-on-goals mb-3">
                <div className="d-flex justify-content-center">
                  <div
                    className={`p-2 long-short-term-text w-50 ${
                      tab === 'description' ? 'active-class' : null
                    }`}
                    onClick={() => setTab('description')}
                  >
                    Description
                  </div>
                  {/* <div
                  className={`p-2 long-short-term-text w-50 ${
                    tab === 'howToUse' ? 'active-class' : null
                  }`}
                  onClick={() => setTab('howToUse')}
                >
                  How to use?
                </div> */}
                  <div
                    className={`p-2 long-short-term-text w-50 ${
                      tab === 'tnc' ? 'active-class' : null
                    }`}
                    onClick={() => setTab('tnc')}
                  >
                    Terms and Conditions
                  </div>
                </div>
              </div>
              <div className="mb-2">
                {tab === 'description' ? (
                  <Description giftCard={giftCard} />
                ) : tab === 'tnc' ? (
                  <TermsAndConditions giftCard={giftCard} />
                ) : null}
              </div>
              {/* <pre style={{ width: '100%' }}>
              {JSON.stringify(giftCard, null, ' ')}
            </pre> */}
            </Card>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  )
}

export default PurchaseVoucher

const TermsAndConditions = ({ giftCard }) => {
  return (
    <div>
      {giftCard?.tnc?.content && (
        <div
          dangerouslySetInnerHTML={{
            __html: `&bull; ${giftCard.tnc.content.replace(
              /<br ?\/?>/g,
              '<br/>&bull; '
            )}`,
          }}
        />
      )}
      {giftCard?.tnc?.link && (
        <div>
          For More Details{' '}
          <a
            href={giftCard?.tnc?.link}
            className="bg-soft-primary badge"
            target="_blank"
            rel="noreferrer"
          >
            Click Here
          </a>
        </div>
      )}
    </div>
  )
}

const Description = ({ giftCard }) => {
  return <div>{giftCard?.description}</div>
}
