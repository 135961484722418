import React, { Fragment } from 'react'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
// import sipSvg from 'assets/svg/sip.svg'
import { useSelector } from 'react-redux'
import { selectGoals } from 'features/giftCardSlice'
import { useParams } from 'react-router-dom'
import SortTermPlannerForm from './PlannerForm'
import usePageTitle from 'hooks/usePageTitle'

const SortTermPlanner = () => {
  const { goalId } = useParams()
  const { goalsWithGiftCards } = useSelector(selectGoals)
  const selectGoal = goalsWithGiftCards.find((g) => g.id === goalId)
  usePageTitle(selectGoal?.name || 'Short Term Goal')
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        {/* <pre style={{ width: '100%' }}>
          {JSON.stringify(goalsWithGiftCards, null, 2)}
        </pre> */}
        <SortTermPlannerForm />
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default SortTermPlanner
