import { Fragment, useState, useEffect } from 'react'
import GoalsTable from 'Components/Dashboard/GoalsTable'
import { selectAccount } from 'features/accountSlice'
import { useDispatch, useSelector } from 'react-redux'
import CSSTransitionGroup from 'Components/CSSTransitionGroup'
import { Card, CardBody, CardHeader } from 'reactstrap'
import usePageTitle from 'hooks/usePageTitle'
import FDTable from 'Components/Dashboard/FDTable'
import { useQueryParams } from 'utils/helper'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getAugmontFds, selectAugmont } from 'features/augmont/augmontSlice'
import { idle, loading } from 'features/status'

const Goal = () => {
  usePageTitle('Goals')
  const history = useHistory()
  const { tab } = useQueryParams()
  const { goals } = useSelector(selectAccount)
  const { augmontFDs } = useSelector(selectAugmont)
  const dispatch = useDispatch()
  const [filteredGoals, setFilteredGoals] = useState({
    goals: [],
    goalType: tab || 'long',
  })

  // Set goal data when goals is changed
  useEffect(() => {
    if (filteredGoals.goalType !== 'gold-fd')
      setFilteredGoals({
        goals:
          goals?.items?.filter(
            (goal) => goal.term === filteredGoals.goalType
          ) ?? [],
        goalType: filteredGoals.goalType,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals])

  /**
   * This function will handle when user change goal type from "long" to "short"
   * And same as reverse this will update the filteredGoals state and show the specific filtered data
   */
  // const changeGoalType = useCallback(
  //   (goalType) => {
  //     if (goalType !== 'gold-fd')
  //       // Set updated data in state
  //       setFilteredGoals({
  //         goals: goals?.items?.filter((goal) => goal.term === goalType) ?? [],
  //         goalType,
  //       })
  //     // If we needs to show the gold fd table
  //     else setFilteredGoals({ goals: [], goalType })
  //   },
  //   [goals]
  // )

  useEffect(() => {
    if (augmontFDs.status === idle) {
      dispatch(getAugmontFds())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        classNames="TabsAnimation"
        appear={true}
        timeout={{ appear: 500 }}
        enter={false}
        exit={false}
      >
        {/* <pre>{JSON.stringify(augmontFDs, null, 2)}</pre> */}
        <Card className="main-card mb-3">
          <>
            <CardHeader className="custom-card-header">
              <div className="custom-table-header">
                <div className="table-title mr-5">Your Goal details</div>
                <div className="d-flex custom-tab form-item-control ml-2 max-w-350">
                  <div
                    className={`custom-tab-item metal-radio-item py-1 ${
                      filteredGoals.goalType === 'long'
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                    onClick={() => history.push('/investment/goals?tab=long')}
                  >
                    Long Term
                  </div>
                  <div
                    className={`custom-tab-item metal-radio-item py-1 ${
                      filteredGoals.goalType === 'short'
                        ? 'custom-tab-item-active'
                        : ''
                    }`}
                    onClick={() => history.push('/investment/goals?tab=short')}
                  >
                    Short Term
                  </div>
                </div>
              </div>
            </CardHeader>

            {!goals?.items?.length ? (
              <div className="w-100 d-flex justify-content-center align-items-center">
                <CardHeader>No Goal Investments Found</CardHeader>
              </div>
            ) : (
              <CardBody>
                {filteredGoals.goalType !== 'gold-fd' ? (
                  <GoalsTable
                    data={filteredGoals.goals}
                    goalsType={filteredGoals.goalType}
                    initialStatus={0}
                    transactionStatus={goals.status}
                    limit={100}
                  />
                ) : (
                  <FDTable
                    data={augmontFDs.items}
                    transactionStatus={
                      augmontFDs.status === loading ? true : false
                    }
                    needModal={true}
                  />
                )}
              </CardBody>
            )}
          </>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Goal
