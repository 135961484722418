import React from 'react'
import InsufficientBalance from '../../../../../assets/svg/insufficient_amount.svg'
import { getAverageRGB } from 'utils/constants'

const InsufficientBalanceCard = ({ minQtyLease }) => {
  return (
    <div
      className={`d-flex flex-column sip-cards gold-fd-cards insufficient-card`}
    >
      <div className="d-flex align-item-center justify-content-center gold-fd-image-holder"></div>
      <div className="sip-card-body">
        <div className="d-flex align-item-center justify-content-center gold-fd-image-holder insufficient-card-img">
          <img
            alt=""
            src={InsufficientBalance}
            onLoad={getAverageRGB}
            crossOrigin="anonymous"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </div>
        <div
          className={`font-weight-bold overflow-hidden font-poppins-md text-center mt-2`}
        >
          Min quantity lease:
          <br />
          {Math.round(minQtyLease * 100) / 100} GMs
        </div>
      </div>
    </div>
  )
}

export default InsufficientBalanceCard
