import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  reUsableModal: {
    open: false,
    content: '',
  },
  successModal: {
    open: false,
    title: 'Title',
    description: 'Description',
    buttonText: '',
    redirectUrl: '',
  },
  pageTitle: '',
  pageDescription: '',
  formData: {},
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: defaultState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showSuccessModal: (state, { payload }) => {
      state.successModal = {
        open: true,
        title: payload?.title || '',
        description: payload?.description || '',
        buttonText: payload?.buttonText || '',
        redirectUrl: payload?.redirectUrl || '',
      }
    },
    toggleSuccessModal: (state) => {
      state.successModal = {
        open: false,
      }
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload
    },
    reUsableModal: (state, { payload }) => {
      state.reUsableModal = {
        open: true,
        content: payload?.content || '',
      }
    },
    toggleReUsableModal: (state) => {
      state.reUsableModal = {
        open: false,
        content: '',
      }
    },
    setFormData: (state, { payload }) => {
      state.formData = payload
    },
    resetFormData: (state) => {
      state.formData = {}
    },
  },
})

// Action creators are generated for each case reducer function
export const uiActions = uiSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectUi = (state) => state.ui

export default uiSlice.reducer
