import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authActions } from 'features/auth/authSlice'
import { accountActions } from 'features/accountSlice'
import { fulfilled } from 'features/status'
import { useNest } from 'backend/useNest'
import { augmontActions } from 'features/augmont/augmontSlice'

export function useAuth() {
  const dispatch = useDispatch()
  const { removeToken, updateToken, getToken, backend } = useNest()

  const history = useHistory()
  const loader = true

  const tokenLogin = () => {
    const authToken = getToken()
    if (window.location.href.search('redirect') === 0) {
      return
    }
    if (authToken !== null) {
      backend()
        .get('/auth/profile')
        .then((data) => {
          const { user, roles } = data
          dispatch(
            authActions.login({
              user: user,
              token: authToken,
              roles: roles,
              // mandate: data.mandate,
              ...data,
              status: fulfilled,
            })
          )
          dispatch(
            accountActions.loadAllAccounts({
              bankAccount: data.bankAccount,
              fixerraAccount: data.fixerraAccount,
              virtualAccount: data.virtualAccount,
              lendBoxAccount: data.lendBoxAccount,
            })
          )
          dispatch(accountActions.loadNominee(data.nominee))
          dispatch(augmontActions.loadAugmontAccount(data.augmontAccount))

          if (!user.panVerified || !user.aadhaarVerified) {
            history.push('/auth/pan')
            // } else if (!user.aadhaarVerified) {
            //   history.push('/auth/aadhaar')
          } else {
            const backTo = window.localStorage.getItem('backTo')
            window.localStorage.clear('backTo')
            history.push(backTo || '/')
          }
        })
        .catch(() => {
          if (window.location.href.search('auth/') === -1) {
            dispatch(authActions.authError())
            history.push('/auth/login')
          }
        })
    } else {
      dispatch(authActions.authError())
      if (window.location.href.search('auth/') === -1) {
        history.push('/auth/login')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  /**
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const login = (email, password, remember) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Authenticating...',
          success: 'Logged in successfully.',
        },
      }
      backend()
        .post('/auth/login', { email, password }, config)
        .then((data) => {
          const { user, token, roles } = data
          updateToken(token, remember)
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
              ...data,
              status: fulfilled,
            })
          )
          dispatch(
            accountActions.loadAllAccounts({
              bankAccount: data.bankAccount,
              fixerraAccount: data.fixerraAccount,
              virtualAccount: data.virtualAccount,
              lendBoxAccount: data.lendBoxAccount,
            })
          )
          dispatch(accountActions.loadNominee(data.nominee))
          dispatch(augmontActions.loadAugmontAccount(data.augmontAccount))
          return resolve(user)
        })
    })

  /**
   * @param {*} email
   * @param {*} password
   * @param {*} profile
   * @returns
   */
  const signup = (email, password, mobile) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: { loading: 'Registering...', success: 'Registered successfully.' },
      }
      backend()
        .post('/auth/signup', { email, password, mobile }, config)
        .then(({ user, token, roles }) => {
          updateToken(token)
          dispatch(
            authActions.signup({
              user: user,
              token: token,
              roles: roles,
              status: fulfilled,
            })
          )
          // updateToken(token)
          return resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })

  const signupProfile = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Creating Profile...', success: 'Profile Created.' },
      }
      backend()
        .post('/auth/signup-profile', payload, config)
        .then(({ user }) => {
          dispatch(authActions.updateProfile(user))
          return resolve()
        })
    })

  const getUserProfile = () =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Retrieving...' },
      }
      backend()
        .get('/auth/profile', config)
        .then(({ user, token, roles }) => {
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
              // ...data,
              status: fulfilled,
            })
          )
          return resolve()
        })
    })

  const logout = () =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Requesting...', success: 'Logged out successfully.' },
      }
      backend()
        .post('/auth/logout', {}, config)
        .then(() => {
          dispatch(authActions.logout())
          removeToken()
          // history.push('/auth/login')
          return resolve()
        })
    })

  const forgotPassword = (email) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Requesting...',
          success: 'Password Reset Email Sent!',
        },
      }
      const url =
        window.location.protocol +
        '//' +
        window.location.host +
        '/auth/password-reset'
      backend()
        .post('/auth/forgot', { email, url }, config)
        .then(() => {
          return resolve()
        })
    })

  const resetPassword = (newPassword, passwordResetCode) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      }
      backend()
        .put('/auth/reset', { newPassword, passwordResetCode }, config)
        .then(() => {
          return resolve()
        })
    })

  const updateUserProfile = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Profile Updated.' },
      }
      backend()
        .post('/auth/profile', payload, config)
        .then((data) => {
          dispatch(authActions.updateProfile(payload))
          return resolve()
        })
    })

  const updatePersonalDetails = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Details Updated.' },
      }
      backend()
        .put('/auth/personal-details', payload, config)
        .then((data) => {
          // dispatch(authActions.updatePin(data))
          return resolve()
        })
    })

  const updatePassword = (payload, customConfig) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      }
      backend()
        .put('/auth/password', payload, config)
        .then(() => {
          if (customConfig.logout) {
            setTimeout(() => {
              dispatch(authActions.logout())
              removeToken()
              history.push('/auth/login')
            }, 500)
          }
          return resolve()
        })
    })

  const updatePin = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Pin Updated.' },
      }
      backend()
        .put('/auth/pin', payload, config)
        .then((data) => {
          dispatch(authActions.updatePin(data))
          return resolve()
        })
    })

  const validatePinBackend = (payload) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: { loading: 'Validating...', success: 'Pin Validated.' },
      }
      backend()
        .post('/auth/pin', payload, config)
        .then((res) => {
          if (res.status) {
            return resolve()
          }
          reject(false)
        })
        .catch(() => {
          reject(false)
        })
    })

  const sendOTP = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Sending...', success: 'OTP Sent.' },
      }
      backend()
        .post('/sms/send', payload, config)
        .then((data) => {
          return resolve(data)
        })
    })

  const kycAadhaarGenerateOTP = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Sending...', success: 'OTP Sent.' },
      }
      backend()
        .post('/kyc/aadhaar/generate-otp', payload, config)
        .then((data) => {
          return resolve(data)
        })
    })

  const kycAadhaarValidate = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Validating...', success: 'Aadhaar Validated.' },
      }
      backend()
        .post('/kyc/aadhaar/validate', payload, config)
        .then((data) => {
          return resolve(data)
        })
    })

  const kycPanValidate = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Validating...', success: 'Pan Number Validated.' },
      }
      backend()
        .post('/kyc/pan/validate', payload, config)
        .then((data) => {
          return resolve(data)
        })
    })

  const isEmailExist = (email) =>
    new Promise((resolve) => {
      let config = {}
      backend()
        .get(`/auth/exist/email/${email}`, config)
        .then(({ exist }) => {
          return resolve(exist)
        })
    })

  const isMobileExist = (mobile) =>
    new Promise((resolve) => {
      let config = {}
      backend()
        .get(`/auth/exist/mobile/${mobile}`, config)
        .then(({ exist }) => {
          return resolve(exist)
        })
    })

  const findAccountFromMobile = (mobile) =>
    new Promise((resolve) => {
      let config = {}
      backend()
        .get(`/auth/findAccount/${mobile}`, config)
        .then((data) => {
          return resolve(data)
        })
    })

  const userPanVerification = (payload) =>
    new Promise((resolve) => {
      let config = {}
      backend()
        .post('/kyc/pan/upload', payload, config)
        .then((response) => {
          return resolve(response.data)
        })
    })

  return {
    tokenLogin,
    sendOTP,
    kycAadhaarGenerateOTP,
    kycAadhaarValidate,
    kycPanValidate,
    signup,
    signupProfile,
    login,
    logout,
    getUserProfile,
    updatePassword,
    forgotPassword,
    resetPassword,
    updateUserProfile,
    updatePersonalDetails,
    updatePin,
    validatePinBackend,
    isEmailExist,
    isMobileExist,
    findAccountFromMobile,
    userPanVerification,
  }
}
